import React , { useState } from 'react';
import useResize from 'use-resize';  
import GameTableEngine from './GameTableEngine';


const Section = () => {
    const [ Searching ] = useState(null);

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    return (<div style={{
        "height" : `${currentSize.height}px`,
        "width": `${currentSize.width}px`,
    }} className="BlurIt" onClick={()=> {}}>
                <div style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>

                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    <div className="TableBackgroundOverlay" style={{
                        "position" : "fixed",
                        "zIndex" : "20",
                        "height" :  `100%`,
                        "width" : `100%`,
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        < GameTableEngine GameData = {null} />
                    </div>
                </div> 

                { Searching === true && 
                    <div style={{
                        "position" : 'fixed',
                        "width" : `100%`,
                        "zIndex" : "21",
                        "top" : "80%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        <div className="pulsate-fwd" style={{
                            "margin" : "0 auto",
                            "width" : "70%",
                            "textAlign" : "center",
                            "fontFamily" : "HighlightText",
                            "fontSize" : "1.5em",
                            "color" : "#00f9ff",
                        }}>
                            <div>SEARCHING FOR</div>
                            <div style={{
                                "marginTop" : "5%",
                            }}>OPPONENTS</div>
                        </div>
                    </div>
                }
            
        </div>                
    </div>)
}

export default Section