import React from 'react';
import CloseMatchMaker from './CloseMatchMaker'

const Section = () => {
    

    return (<div onClick={()=> {}}>
                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>

                
                <div style={{
                    "position" : "relative",
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",
                }}>
                    <div style={{
                        "margin" : "0 auto",
                        "width" : "70%",
                        "textAlign" : "center",
                        "fontFamily" : "HighlightText",
                        "fontSize" : "1.5em",
                        "color" : "#00f9ff",
                    }}>
                        <div>YOU WERE DISCONNECTED</div>
                        <div style={{
                            "marginTop" : "5%",
                        }}>FROM MATCHMAKER</div>

                        <div onClick={()=>window.location.replace('/freetoplay/profile')} style={{
                            "marginTop" : "15%",
                        }}>CLICK HERE TO GO BACK</div>
                    </div>
                </div>


                    

                < CloseMatchMaker />
                    
                </div>                
    </div>)
}

export default Section