import React , { useState } from 'react';
import useResize from 'use-resize';
import { motion } from 'framer-motion';
import QuitIcon from '../../../../Icons/Tutorial.png'
import SoundTypeOne from '../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';
import Tutorial1 from '../../../../Icons/1.png';
import Tutorial2 from '../../../../Icons/2.png';
import Tutorial3 from '../../../../Icons/3.png';
import Tutorial4 from '../../../../Icons/4.png';
import Tutorial5 from '../../../../Icons/5.png';
import Tutorial6 from '../../../../Icons/6.png';
import Tutorial7 from '../../../../Icons/7.png';
import Tutorial8 from '../../../../Icons/8.png';

const Section = (Values) => {
    const [ ViewState , ViewStateUpdate ] = useState(false)
    const ActiveStateMaker = Values.ActiveStateMaker ;
    const SoundStatus = Values.SoundStatus ; 
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }



    const Selection = () => {
        ViewStateUpdate(true)
        if(SoundStatus === true){
            playOne()
        }
        ActiveStateMaker( function (){
            return "QUIT"
        })
    }

    const [ CurrentTutorialPage , CurrentTutorialPageUpdate ] = useState(1);
    const [ TutorialPageAnimation , TutorialPageAnimationUpdate ] = useState({
        y : 0 , x : 0
    })
    
    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const TutorialStyle = {
        "position" : "relative",
        "top" : "45%",
        "left": "50%",
        "transform" : "translate(-50%, -50%)",
        'width' : `${percentage(Dimensions.width , 65)}px`,
        'height' : `${percentage(Dimensions.width , 120)}px`,
        "borderStyle" : "solid",
        "borderColor" : "grey",
        "borderWidth" : `${percentage(Dimensions.width , 0.35)}px`,
        "boxShadow" : `0 0 ${percentage(Dimensions.width , 12)}px black`,
        "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 12)}px black`,
        "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 12)}px black`,
        "borderRadius" : `${percentage(Dimensions.width , 5)}px`, 
        "backgroundColor" : "rgba(0,0,0,0.5)"
    }

    const TutPageStyle = {
        "width" : `${percentage(Dimensions.width , 100)}px`,
        "height" : `${percentage(Dimensions.height , 100)}px`,                
        "position" : "fixed",
    }
    

    const TutNext = () => {
        TutorialPageAnimationUpdate({
            y : 0 , x : 300 , scale : 0.5
        })
        CurrentTutorialPageUpdate(CurrentTutorialPage + 1)
    }

    const TutPrev = () => {
        TutorialPageAnimationUpdate({
            y : 0 , x : -300 , scale : 0.5
        })
        CurrentTutorialPageUpdate(CurrentTutorialPage - 1)
    }



    const CloseTutorial = () => {
        CurrentTutorialPageUpdate(1);
        ViewStateUpdate(false)
    }


    
    
        return (
            <div>
            { ViewState === false &&
                <motion.div 
                animate = {{
                    opacity : Values.OpacityValue
                }}>
                    <motion.div 
                        onTapStart={ Selection }                        
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            'position' : "absolute", 
                            "top" : `2%`,
                            'left' : "2%",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",    
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25"
                        }}> < img alt="Avatar" 
                                style={{
                                'width' : `${percentage(currentSize.width , 7.5)}px`,
                                "height" : "auto",
                                }}
                                src={ QuitIcon} />
                    </motion.div>

                </motion.div>
            
            }

            { ViewState === true &&
                <motion.div>
                    <motion.div 
                    initial={{ x: 0,  y: 0 , scale : 0.9 }}
                    animate={{ x: 0 , y: 0 ,  scale : 1 }}
                    style={{
                        "position" : "fixed",
                        "width" : `${percentage(Dimensions.width , 100)}px`,                
                        "height" : `${percentage(Dimensions.height , 100)}px`,
                        "backgroundColor" : "rgba(0,0,0,0.9)", 
                        "left" : `0`, 
                        "top" : `0`,  
                        "zIndex" : "200"            
                    }}>

                    { CurrentTutorialPage === 1 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial1 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 2 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial2 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 3 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial3 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 4 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial4 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 5 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial5 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 6 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial6 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 7 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial7 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 8 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial8 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage < 8 &&
                        <motion.div style={{
                            "position" : "fixed",
                            "bottom" : "2%",
                            "right" : "0"
                        }}>
                            <motion.div style={{
                                "width" : `${percentage(Dimensions.width , 30)}px`,
                                "height" : `${percentage(Dimensions.width , 10)}px`,
                                "fontSize" : `${percentage(Dimensions.width , 30)/8}px`,
                                "backgroundColor" : "rgba(0,0,0,0.2)",
                                "textAlign" : "center",
                                "boxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                                "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                                "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                            }} onTap={ TutNext }>
                                <div style={{
                                    "height" : `${percentage(Dimensions.width , 2.5)}px`,
                                }}></div>
                                NEXT
                            </motion.div>
                        </motion.div>
                    }

                    { CurrentTutorialPage > 1 &&
                        <motion.div style={{
                            "position" : "fixed",
                            "bottom" : "2%",
                            "left" : "0"
                        }}>
                                <motion.div style={{
                                "width" : `${percentage(Dimensions.width , 30)}px`,
                                "height" : `${percentage(Dimensions.width , 10)}px`,
                                "fontSize" : `${percentage(Dimensions.width , 30)/8}px`,
                                "backgroundColor" : "rgba(0,0,0,0.2)",
                                "textAlign" : "center",
                                "boxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                                "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                                "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                            }} onTap={ TutPrev }>
                                <div style={{
                                    "height" : `${percentage(Dimensions.width , 2.5)}px`,
                                }}></div>
                                PREV
                            </motion.div>
                        </motion.div>
                    }

                    <motion.div style={{
                        "position" : "fixed",
                        "top" : "0",
                        "right" : "0"
                    }}>
                         <motion.div 
                         animate={{ rotate : 45 , x: 35 , y : 5}}
                         style={{
                            "width" : `${percentage(Dimensions.width , 30)}px`,
                            "height" : `${percentage(Dimensions.width , 10)}px`,
                            "fontSize" : `${percentage(Dimensions.width , 30)/8}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "textAlign" : "center",
                            "color" : "red",
                            "boxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                            "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                            "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                        }} onTap={ CloseTutorial }>
                            <div style={{
                                "height" : `${percentage(Dimensions.width , 2.5)}px`,
                            }}></div>
                            CLOSE
                        </motion.div>
                    </motion.div>
                    

                    </motion.div>
                </motion.div>   
            }
            </div>
    )

}

export default Section