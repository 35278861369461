import React from 'react';
import useResize from 'use-resize';
import { motion } from 'framer-motion';
import SoundOnIcon from '../../../../Icons/SoundToggle.png'
import SoundOffIcon from '../../../../Icons/SoundToggle.png'
import SoundTypeOne from '../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';
import socket from '../../../../SocketConnectionManager';

const Section = (Values) => {
    const SoundSwitch = Values.SoundStatus
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const SoundOff = () => {
        socket.emit('SwitchOffSound')
    }

    const SoundOn = () => {
        socket.emit('SwitchOnSound')
        playOne();
    }

    
    
        return (
            <div>
            { SoundSwitch === true &&
                <motion.div 

                style={{
                }}>
                    <motion.div 
                        onTapStart={ SoundOff }                        
                        animate = {{
                            opacity : Values.OpacityValue
                        }}
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",                            
                            'position' : "absolute", 
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "top" : `2%`,
                            'left' : "88%",                            
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25",                            
                        }}>                        
                            < img alt="Avatar" 
                                style={{
                                'height' : `${percentage(currentSize.width , 7.5)}px`,
                                "width" : "auto",
                                }}
                                src={ SoundOnIcon } />
                    </motion.div>

                </motion.div>
            
            }

            { SoundSwitch === false &&
                <motion.div 

                style={{
                }}>
                    <motion.div 
                        onTapStart={ SoundOn }                        
                        animate = {{
                            opacity : Values.OpacityValue
                        }}
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",                            
                            'position' : "absolute", 
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "top" : `2%`,
                            'left' : "88%",                            
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25",                            
                        }}>    
                            <motion.div
                                animate={{ opacity : 0.1}}
                            >
                            < img alt="Avatar" 
                                style={{
                                'height' : `${percentage(currentSize.width , 7.5)}px`,
                                "width" : "auto",
                                }}
                                src={ SoundOffIcon} />
                            </motion.div>                                                
                    </motion.div>

                </motion.div>
                }
            </div>
    )

}

export default Section