import React , { useState } from 'react';

const UserName = (Values) => {
    const PlayerValues = Values.PlayerValues ;
    const isAgnostic = Values.isAgnostic ;
    const BlockSize = Values.BlockSize
    const [ BackgroundColor ] = useState("linear-gradient(rgba(0,0,0,0.9) ,rgba(0,0,0,0.8),rgba(0,0,0,0.8))");
    const [ BlockShadowWidth  ] = useState(4);

    const ShadowColor = () => {
        if(Values.PlayerValues.IsHandWinner === true){
            return "#FDA80D"
        } else {
            return "#00f9ff"
        }
    }

    const BlockShadowColor = ShadowColor();
    
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = BlockSize

    return (
        <div>
            <div style={{
                "position" : "absolute",
                "color" : "#00f9ff",
                "bottom" : "-20%",
                "fontSize" : `${percentage(width , 11)}px`,
                "width" : `${percentage(width , 100)}px`,
                "backgroundImage" : BackgroundColor ,
                "textAlign" : "center",
                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,            
            }}>

                { isAgnostic !== true &&
                    <div style={{
                    "paddingTop" : `${percentage(width , 2)}px`,
                    "paddingBottom" : `${percentage(width , 2)}px`,
                    }}>
                    { PlayerValues.UserName}</div>
                }
                
                { PlayerValues.RemainingLife > 0 &&
                    <div style={{
                    }}>LIFE - { PlayerValues.RemainingLife}</div>
                }

                { PlayerValues.RemainingLife === 0 &&
                    <div style={{
                        "color" : "red"
                    }}>LIFE - { PlayerValues.RemainingLife}</div>
                }
            </div>
        </div>
        
    )
}

export default UserName