import React , { useState } from 'react';
import useResize from 'use-resize';
import useOrientationChange from "use-orientation-change";
import Config from '../../../GlobalFunctions/Config'
import { motion } from "framer-motion";
import WheelOfFortune from '../../../Icons/WheelOfFortune.png'
import ArrowHead from '../../../Icons/WheelArrow.png'
import GetRewardsData from '../../../GlobalFunctions/PracticeMode/GetRewardsDataCB';
import ClaimReward from '../../../GlobalFunctions/PracticeMode/ClaimRewardCB';
import UpdateGamerData from '../../../GlobalFunctions/PracticeMode/UpdateGamerData';
import { Redirect } from 'react-router';

const Section = () => {
    const [ Duration , DurationUpdate ] = useState({
        Time : 0.25,
        Delay : 0
    });
    const [ FinalPoint , FinalPointUpdate ] = useState(0);
    const [ SpinActivation , SpinActivationUpdate ] = useState(false);
    
    
    const [ RewardsData , RewardsDataUpdate ] = useState(null)
    const [ Claiming , ClaimingUpdate ] = useState(false);
    const [ Claimed , ClaimedUpdate ] = useState(false);
    const [ ButtonRedirectValue , ButtonRedirectValueUpdate ] = useState(null);
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const orientation = useOrientationChange()
    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const pageVariants = {
        initial: {
            opacity: 0,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0.5,
        },
    }


    var NavigationSwitchHeight = ((percentage(Dimensions.width , 24)/16) * 2) + (percentage(Dimensions.width , 24)/8)

    var PageStartingPosition = percentage(Dimensions.width , 32) + NavigationSwitchHeight ;
    

    if(RewardsData === null){
        GetRewardsData( function (Data){
           
            DurationUpdate({
                Time : 2,
                Delay : 0.25
           })

           RewardsDataUpdate(Data)
        })
    }
    

    const UpdateValues = () => {
        SpinActivationUpdate(true)
        ClaimingUpdate(true)
        DurationUpdate({
            Time : 5,
            Delay : 0.5
        });

        if(RewardsData.Slot === 1){
            FinalPointUpdate( [ 360 * 10 , 180 ]);
        } else if(RewardsData.Slot === 2){
            FinalPointUpdate( [ 360 * 10 , 360 ]);
        } else if(RewardsData.Slot === 3){
            FinalPointUpdate( [ 360 * 10 , 90 ]);
        }

        ClaimedUpdate(true)

        ClaimReward(RewardsData.WinValue , function (Update){
            if(Update){
                UpdateGamerData(function (GamerData){
                    if(GamerData){
                        ButtonRedirectValueUpdate("/freetoplay/cb/profile")
                    }
                })
            }
        })
    }

    const RedirectMe = () => {
        RedirectPropertyUpdate(true)
    }

    const RewardWins = Config.practiceModeDataCB.RewardWins;
    const RewardMaxWins = Config.practiceModeDataCB.RewardMaxWins;

    const DotProvider = (Value) => {
        return(
            <motion.div style={{
                "backgroundColor" : "#FDA80D",
                "height" : `${percentage(currentSize.width , 2)}px`,
                "width" : `${percentage(currentSize.width , 2)}px`,
                "borderRadius" : "50%",
                "position" : "relative",
                "left" : `${Value.Left}%`,
                "top" : `${Value.Top}%`,
                "transform" : "translate(-50%, -50%)",
            }}></motion.div>
        )
    }

    const LineProvider = (Value) => {
        return (
            <motion.div initial={{
                rotate : Value.Rotate
            }}
            style={{
                "backgroundColor" : "#FDA80D",
                "height" : `${percentage(currentSize.width , 20)}px`,
                "width" : `${percentage(currentSize.width , 0.5)}px`,
                "position" : "relative",
                "left" : `${Value.Left}%`,
                "top" : `${Value.Top}%`,
                "transform" : "translate(-50%, -50%)",
            }}></motion.div>
        )
    }

    const BlockProvider = (Value) => {
        return(
        <motion.div 
            style={{
                "position" : "relative",
                "left" : `${Value.Left}%`,
                "top" : `${Value.Top}%`,
                "transform" : "translate(-50%, -50%)",
                "backgroundImage" : "linear-gradient(rgba(232, 232,  232 , 0.4) ,rgba(0,0,0,0.1), rgba(232, 232,  232, 0.2))"                    ,
                "height" : `${percentage(currentSize.width , 10)}px`,
                "width" : `${percentage(currentSize.width , 20)}px`,
                "backgroundColor" : "rgba(0,0,0,0.9)",
                "boxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                "textAlign" : "center",
                "fontSize" : `${percentage(currentSize.width , 20)/8}px`,
            }}>
                <div style={{
                    "height" : `${percentage(currentSize.width , 20)/16}px`,
                }}/>
                <div>{ Value.ChipValue }</div>
                <div style={{
                    "height" : `${percentage(currentSize.width , 20)/20}px`,
                }}/>
                <div>CHIPS</div>
        </motion.div>
        )
    }

    const SpinBlockProvider = (Value) => {
        
        var Color = "red";
        if(Value.Slot === 1){
            Color = "#00ffe8"
        } else if(Value.Slot === 3){
            Color = "#181616"
        } else if(Value.Slot === 2){
            Color = "#ff1400"
        }

        return(
        <motion.div className="BlurIt" 
            style={{
                "position" : "relative",
                "left" : `${Value.Left}%`,
                "top" : `${Value.Top}%`,
                "transform" : "translate(-50%, -50%)",
                "backgroundImage" : "linear-gradient(rgba(232, 232,  232 , 0.4) ,rgba(0,0,0,0.1), rgba(232, 232,  232, 0.2))"                    ,
                "height" : `${percentage(currentSize.width , 10)}px`,
                "width" : `${percentage(currentSize.width , 20)}px`,
                "backgroundColor" : "rgba(0,0,0,0.6)",
                "boxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                "textAlign" : "center",
                "fontSize" : `${percentage(currentSize.width , 20)/8}px`,
            }}>
                <div style={{
                    "height" : `${percentage(currentSize.width , 20)/16}px`,
                }}/>
                <div>{ Value.ChipValue }</div>
                <div style={{
                    "height" : `${percentage(currentSize.width , 20)/20}px`,
                }}/>
                <div>CHIPS</div>

                <motion.div initial={{ 
                    rotate : Value.ColorRotator,
                    x : Value.Placement.x ,
                    y : Value.Placement.y ,
                }}
                style={{
                    "height" : `${percentage(currentSize.width , 20)/8}px`,
                    "width" : `${percentage(currentSize.width , 20)/8}px`,
                    "backgroundColor" : Color,
                    "position" : "relative",
                    "top" : "-90%",
                    "left" : `${Value.Placement.leftValue}%`,
                    "boxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                }}>
                </motion.div>
        </motion.div>
        )
    }


    if(orientation === "landscape"){
        return (<div>

        </div>)
    } else {
          
        
        return (<div style={{
            "position" : "absolute",
            "height" : `${percentage(Dimensions.width , 100)}px`,
            "width" : `${percentage(Dimensions.width , 100)}px`, 
            "marginTop" : `${PageStartingPosition}px`,
        }}>

            { RedirectProperty === true &&
                <Redirect to={ ButtonRedirectValue } />
            }

            <motion.div
                style={{
                    "position" : "absolute",
                    "height" : `${percentage(Dimensions.width , 100)}px`,
                    "width" : `${percentage(Dimensions.width , 100)}px`, 
                }}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
            >
                
                <motion.div 
                    style={{
                    "position" : "relative",
                    "height" : `${percentage(currentSize.width , 40)}px`,
                    "width" : `${percentage(currentSize.width , 40)}px`,
                    "left" : "30%",
                    "top" : "10%",
                    "transform" : "translate(-50%, -50%)",
                    }}
                    initial={{
                        x : 0 , y : 0 , opacity : 1 ,
                    }}
                    animate={{
                        x : 0 , y : 0 , opacity : 1 , rotate: FinalPoint 
                    }}
                    transition={{ duration : Duration.Time , delay : Duration.Delay}}
                    >
                    <img 
                    alt="Wheel"
                    style={{
                        "height" : `${percentage(currentSize.width , 40)}px`,
                        "width" : "auto"
                    }}
                    src={ WheelOfFortune } />   
                </motion.div>

                { SpinActivation === false &&
                    <div style={{
                            "position" : "absolute",
                            "height" : `${percentage(Dimensions.width , 100)}px`,
                            "width" : `${percentage(Dimensions.width , 100)}px`, 
                    }}>

                        < DotProvider Top={"-20"} Left={"50"} />

                        < DotProvider Top={"-2"} Left={"50"} />

                        < DotProvider Top={"-14"} Left={"40"} />

                        < DotProvider Top={"-16"} Left={"60.25"} />

                        < LineProvider Rotate={110}  Top={"-15"} Left={"58.65"} />

                        < LineProvider Rotate={-140}  Top={"-55"} Left={"66"} />

                        < LineProvider Rotate={90}  Top={"-68"} Left={"29.5"} />

                        < LineProvider Rotate={110}  Top={"-101.5"} Left={"39.5"} />

                        { RewardsData !== null && 
                            < BlockProvider  Top={"-118.5"} Left={"24.5"} ChipValue = { RewardsData.RewardThree } />
                        }

                        { RewardsData !== null && 
                            < BlockProvider  Top={"-108.5"} Left={"15.5"} ChipValue = { RewardsData.RewardOne } />
                        }

                        { RewardsData !== null && 
                            < BlockProvider  Top={"-100.5"} Left={"76.5"} ChipValue = { RewardsData.RewardThree } />
                        }

                        { RewardsData !== null && 
                            < BlockProvider  Top={"-145.5"} Left={"79.5"} ChipValue = { RewardsData.RewardTwo } />
                        }

                        <motion.div 
                        initial={{ rotate : -180 , scale : 0.5}}
                        style={{
                            "height" : `${percentage(currentSize.width , 20)}px`,
                            "width" : `${percentage(currentSize.width , 30)}px`,
                            "position" : "relative",
                            "top" : "-147%",
                            "left" : "60%",
                        }}>
                            <motion.div 
                            initial={{ rotate : 90 }}
                            transition={{ duration : 0.3}}
                            >
                            <img 
                                alt="Wheel"
                                style={{
                                    "height" : `${percentage(currentSize.width , 30)}px`,
                                    "width" : "auto",
                                    "position" : "relative",
                                }}
                                src={ ArrowHead } />   
                            </motion.div>
                        </motion.div>

                    </div>
                }

                        

                { SpinActivation === true &&
                    <motion.div 
                    initial={{ scale : 1 , y : 0 , opacity : 1}}
                    animate={{ scale : 1 , y: 0 , opacity : 1}}
                    transition={{ duration : 0.3}}
                    style={{
                            "position" : "absolute",
                            "height" : `${percentage(Dimensions.width , 100)}px`,
                            "width" : `${percentage(Dimensions.width , 100)}px`, 
                    }}>

                        { RewardsData !== null && 
                            < SpinBlockProvider  Top={"-25.5"} Left={"15"} 
                            ColorRotator = { 45 }
                            Slot={3}
                            Placement ={{ x : 0 , y : 0 , leftValue : 90}}
                            ChipValue = { RewardsData.RewardThree } />
                        }

                        { RewardsData !== null && 
                            < SpinBlockProvider  Top={"-5.5"} Left={"15"} 
                            ColorRotator = { 45 }
                            Slot={1}
                            Placement ={{ x : 0 , y : 0 , leftValue : 90}}
                            ChipValue = { RewardsData.RewardOne } />
                        }

                        { RewardsData !== null && 
                            < SpinBlockProvider  Top={"-45.5"} Left={"85"} 
                            ColorRotator = { -45 }
                            Slot={3}
                            Placement ={{ x : 0 , y : 0 , leftValue : 0}}
                            ChipValue = { RewardsData.RewardThree } />
                        }

                        { RewardsData !== null && 
                            < SpinBlockProvider  Top={"-25.5"} Left={"85"} 
                            ColorRotator = { -45 }
                            Slot={2}
                            Placement ={{ x : 0 , y : 0 , leftValue : 0}}
                            ChipValue = { RewardsData.RewardTwo } />
                        }

                        <motion.div 
                        initial={{ rotate : -90 , scale : 0.5}}
                        style={{
                            "height" : `${percentage(currentSize.width , 20)}px`,
                            "width" : `${percentage(currentSize.width , 30)}px`,
                            "position" : "relative",
                            "top" : "-60%",
                            "left" : "55%",
                        }}>
                            <motion.div 
                            initial={{ y : 0 , x: 0 }}
                            animate={{ y : -40 , x: 15 }}
                            transition={{ duration : 0.6}}
                            >
                            <img 
                                alt="Wheel"
                                style={{
                                    "height" : `${percentage(currentSize.width , 30)}px`,
                                    "width" : "auto",
                                    "position" : "relative",
                                }}
                                src={ ArrowHead } />   
                            </motion.div>
                        </motion.div>
                    </motion.div>
                }

                { RewardsData !== null && RewardsData.Access === false &&
                    <motion.div 
                        animate={{
                            x : 0 , y : 0 , rotate : 0
                        }}
                        style={{
                            "left" : "24%",
                            "top" : "65%",
                            "transform" : "translate(-50%, -50%)",
                            "backgroundColor" : "black",
                            "height" : `${percentage(currentSize.width , 10)}px`,
                            "width" : `${percentage(currentSize.width , 55)}px`,
                            "position" : "absolute",
                            "zIndex" : "19",
                            "fontSize" : `${percentage(currentSize.width , 55)/20}px`,
                            "textAlign" : "center",  
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px #00f9ff`,  
                            "borderRadius" : `${percentage(currentSize.width , 2)}px`,                            
                        }}>
                            <div style={{
                            "height" : `${percentage(currentSize.width , 55)/40}px`,
                            }}/>
                            <div>YOU NEED { RewardMaxWins - RewardWins } WINS</div>
                            <div>TO UNLOCK REWARD</div>
                    </motion.div>
                }

                { RewardsData !== null && RewardsData.Access === true && Claiming !== true &&
                    <motion.div 
                        onTap={ UpdateValues }
                        animate={{
                            x : 0 , y : 0 , rotate : 0
                        }}
                        style={{
                            "left" : "24%",
                            "top" : "65%",
                            "transform" : "translate(-50%, -50%)",
                            "backgroundColor" : "#0055ff",
                            "height" : `${percentage(currentSize.width , 10)}px`,
                            "width" : `${percentage(currentSize.width , 55)}px`,
                            "position" : "absolute",
                            "zIndex" : "19",
                            "fontSize" : `${percentage(currentSize.width , 55)/20}px`,
                            "textAlign" : "center",   
                            "borderRadius" : `${percentage(currentSize.width , 2)}px`,                           
                        }}>
                            <div style={{
                            "height" : `${percentage(currentSize.width , 55)/40}px`,
                            }}/>
                            <div>CLICK HERE TO SPIN THE WHEEL AND</div>
                            <div>CLAIM YOUR REWARD</div>
                    </motion.div>
                }

                { Claiming === true && 
                    <motion.div 
                        animate={{
                            x : 0 , y : 0 , rotate : 0
                        }}
                        style={{
                            "left" : "24%",
                            "top" : "65%",
                            "transform" : "translate(-50%, -50%)",
                            "backgroundColor" : "#0055ff",
                            "height" : `${percentage(currentSize.width , 10)}px`,
                            "width" : `${percentage(currentSize.width , 55)}px`,
                            "position" : "absolute",
                            "zIndex" : "19",
                            "fontSize" : `${percentage(currentSize.width , 55)/20}px`,
                            "textAlign" : "center",
                            "borderRadius" : `${percentage(currentSize.width , 2)}px`,                                
                        }}>
                            <div style={{
                            "height" : `${percentage(currentSize.width , 55)/40}px`,
                            }}/>
                            <div>GETTING YOUR</div>
                            <div>REWARD</div>
                    </motion.div>
                }

                { Claimed === true && 
                  ButtonRedirectValue !== null &&
                    <motion.div 
                        onTap={ RedirectMe }
                        initial={{
                            x : 0 , y : 0 , scale : 0 , opacity : 1
                        }}
                        animate={{
                            x : 0 , y : 0 , scale : 1 , opacity : 1
                        }}
                        transition={{delay : 5.2}}
                        style={{
                            "left" : "24%",
                            "top" : "65%",
                            "transform" : "translate(-50%, -50%)",
                            "backgroundColor" : "#0055ff",
                            "height" : `${percentage(currentSize.width , 10)}px`,
                            "width" : `${percentage(currentSize.width , 55)}px`,
                            "position" : "absolute",
                            "zIndex" : "19",
                            "fontSize" : `${percentage(currentSize.width , 55)/20}px`,
                            "textAlign" : "center",
                            "borderRadius" : `${percentage(currentSize.width , 2)}px`,                                
                        }}>
                            <div style={{
                            "height" : `${percentage(currentSize.width , 55)/40}px`,
                            }}/>
                            <div style={{
                                "height" : `${percentage(currentSize.width , 55)/25}px`,
                                }}/>
                                <div>YOU WON - { RewardsData.WinValue } CHIPS</div>
                    </motion.div>
                }

                

                

            </motion.div>
           
                            
        </div>)
    }
}

export default Section