import Config from '../Config';


const NetworkCall = function (Result) {

    const Env = getParameterByName('env');
    const Token = getParameterByName('authToken');

    const URL = Config.url_api + "/PMG/GetiTapUserData/" + Token + "/" + Env ;

    Config.AgnosticAccessPoint = window.location.href ;
     
    fetch(URL , {
        method: 'GET',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
        },
        credentials : "include"
    }).then((response) => response.json())
    .then((json) => {
        return Result(json)
    })

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}

export default NetworkCall