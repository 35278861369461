import React from 'react';
import Avatar1 from '../../../../../Icons/iTap.jfif';

const Section = (Value) => {
        const BlockSize = Value.BlockSize    
        
        function percentage(num, per)
        {
                return (num/100)*per;
        }

        const width = BlockSize
        const AvatarSize = 70;
        

        return (<div  style={{
        }}>
                <div style={{"height" : `${percentage(width , 0.5)}px`,}}></div>

                < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        "borderRadius" : "50%"
                        }}
                        src={ Avatar1 } />
                
        </div>)
}

export default Section