import React , { useState } from 'react';
import useOrientationChange from "use-orientation-change";
import Rendering from './ProfileComponents/MatchMakerAgnostic'
import IsAgnosticAccess from '../../GlobalFunctions/PracticeMode/IsAgnosticAccess';
import CreateAgnosticAccount from '../../GlobalFunctions/PracticeMode/CreateAgnosticAccount';
import GetiTapUserData from '../../GlobalFunctions/PracticeMode/GetiTapUserData'
import useSetTimeout from 'use-set-timeout';
import IsAccess from '../../GlobalFunctions/PracticeMode/IsGamerAccessCB'
import { Redirect } from 'react-router-dom';

const Section = () => {
    const [ RedirectValue , RedirectValueUpdate ] = useState(false)
    const orientation = useOrientationChange();


    useSetTimeout(()=> {
        IsAgnosticAccess(function (AccessData){
            if(AccessData === false){
                CreateAgnosticAccount(function (UserData){
                    GetiTapUserData(
                        function (
                        ThirdPartyData){
                            if(ThirdPartyData !== false){
                                UpdateConfig()
                            }
                        })
                })
            } else if(AccessData === true){
                GetiTapUserData(
                function (
                ThirdPartyData){
                    if(ThirdPartyData !== false){
                        UpdateConfig()
                    }
                })
            }
        })
    }, 100)

    const UpdateConfig = () => {
        IsAccess(function (){
            RedirectValueUpdate(true)
        })        
    }
    

    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else if(RedirectValue === false){
        return (
            <div>
                < Rendering /> 
            </div>
        )
    } else if(RedirectValue === true){
        return <Redirect to={'/freetoplay/cb/matchmaker'}/>
    }
}

export default Section