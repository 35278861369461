import React, { useState}  from 'react';
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import Avatar1 from '../../../Icons/Avatars/Avatar-M1.png';
import Avatar2 from '../../../Icons/Avatars/Avatar-M2.png'; 
import Avatar3 from '../../../Icons/Avatars/Avatar-M3.png'; 
import Avatar4 from '../../../Icons/Avatars/Avatar-M4.png'; 
import Avatar5 from '../../../Icons/Avatars/Avatar-M5.png'; 
import Avatar6 from '../../../Icons/Avatars/Avatar-M6.png'; 
import Avatar7 from '../../../Icons/Avatars/Avatar-M7.png'; 
import Avatar8 from '../../../Icons/Avatars/Avatar-M8.png'; 
import Avatar9 from '../../../Icons/Avatars/Avatar-M9.png'; 
import Avatar10 from '../../../Icons/Avatars/Avatar-M10.png'; 
import Avatar11 from '../../../Icons/Avatars/Avatar-M11.png'; 
import Avatar12 from '../../../Icons/Avatars/Avatar-M12.png'; 
import Avatar13 from '../../../Icons/Avatars/Avatar-M13.png'; 
import Avatar14 from '../../../Icons/Avatars/Avatar-M14.png'; 
import Avatar30 from '../../../Icons/Avatars/Avatar-M15.png';
import Avatar15 from '../../../Icons/Avatars/Avatar-W1.png'; 
import Avatar16 from '../../../Icons/Avatars/Avatar-W2.png'; 
import Avatar17 from '../../../Icons/Avatars/Avatar-W3.png'; 
import Avatar18 from '../../../Icons/Avatars/Avatar-W4.png'; 
import Avatar19 from '../../../Icons/Avatars/Avatar-W5.png'; 
import Avatar20 from '../../../Icons/Avatars/Avatar-W6.png'; 
import Avatar21 from '../../../Icons/Avatars/Avatar-W7.png'; 
import Avatar22 from '../../../Icons/Avatars/Avatar-W8.png'; 
import Avatar23 from '../../../Icons/Avatars/Avatar-W9.png'; 
import Avatar24 from '../../../Icons/Avatars/Avatar-W10.png'; 
import Avatar25 from '../../../Icons/Avatars/Avatar-W11.png'; 
import Avatar26 from '../../../Icons/Avatars/Avatar-W12.png'; 
import Avatar27 from '../../../Icons/Avatars/Avatar-W13.png'; 
import Avatar28 from '../../../Icons/Avatars/Avatar-W14.png'; 
import Avatar29 from '../../../Icons/Avatars/Avatar-W15.png'; 
import ArrowIcon from '../../../Icons/Arrow.png'; 
import Config from '../../../GlobalFunctions/Config'
import ChangeAvatar from '../../../GlobalFunctions/PracticeMode/ChangeAvatar';
import UpdateGamerData from '../../../GlobalFunctions/PracticeMode/UpdateGamerData';

const Section = () => {
    const [ ShowSet1 , ShowSet1Update ] = useState(true);
    const [ ShowSet2 , ShowSet2Update ] = useState(false);
    const [ ShowSet3 , ShowSet3Update ] = useState(false);
    const [ ShowSet4 , ShowSet4Update ] = useState(false);
    const [ ShowSet5 , ShowSet5Update ] = useState(false);
    const [ ShowSet6 , ShowSet6Update ] = useState(false);
    const [ ShowSet7 , ShowSet7Update ] = useState(false);
    const [ ShowSet8 , ShowSet8Update ] = useState(false);
    const [ ShowSet9 , ShowSet9Update ] = useState(false);
    const [ ShowSet10 , ShowSet10Update ] = useState(false);
    const [ HeadingText , HeadingTextUpdate ] = useState("CHANGE YOUR EXISTING AVATAR")
    const [ AvatarNumber , AvatarNumberUpdate ] = useState(Config.practiceModeData.Avatar)
    const [ AnimationData, AnimationDataUpdate ] = useState({
        initial : {scale: 0.75 , x: 200},
        animate : {scale: 0.75 , x: 10},
    })

    const [ UpdateAnimationData , UpdateAnimationDataUpdated ] = useState({
        initial: {x: 100, y: 100},
        animate : {scale: 0.75, x: 0, y: 0}
    })

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const BackShow = () => {
        if(ShowSet1 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(true)
        } else if(ShowSet2 === true){
            ShowSet1Update(true)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet3 === true){
            ShowSet1Update(false)
            ShowSet2Update(true)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet4 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(true)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet5 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(true)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet6 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(true)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet7 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(true)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet8 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(true)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet9 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(true)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet10 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(true)
            ShowSet10Update(false)
        }

        AnimationDataUpdate({
            initial : {scale: 0.75 , x: 200},
            animate : {scale: 0.75 , x: 10},
        })
    }

    const ForwardShow = () => {
        if(ShowSet1 === true){
            ShowSet1Update(false)
            ShowSet2Update(true)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet2 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(true)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet3 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(true)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet4 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(true)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet5 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(true)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet6 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(true)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet7 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(true)
            ShowSet9Update(false)
            ShowSet10Update(false)
        } else if(ShowSet8 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(true)
            ShowSet10Update(false)
        } else if(ShowSet9 === true){
            ShowSet1Update(false)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(true)
        } else if(ShowSet10 === true){
            ShowSet1Update(true)
            ShowSet2Update(false)
            ShowSet3Update(false)
            ShowSet4Update(false)
            ShowSet5Update(false)
            ShowSet6Update(false)
            ShowSet7Update(false)
            ShowSet8Update(false)
            ShowSet9Update(false)
            ShowSet10Update(false)
        }

        AnimationDataUpdate({
            initial : {scale: 0.75 , x: -200},
            animate : {scale: 0.75 , x: 10},
        })
    }

    const UpdateAvatar = (NewValue) => {
        AvatarNumberUpdate(NewValue)
        HeadingTextUpdate("YOUR UPDATED AVATAR")
        UpdateAnimationDataUpdated({
            initial: {x: 0, y: 100},
            animate : {scale: 0.75, x: 0, y: 0}
        })

        ChangeAvatar(NewValue , function(Update){
            if(Update){
                UpdateGamerData(function (){
                })
            }
        })
    }

    return (<div>

        <motion.div 
              initial={{ x: 0, y: -100 , scale: 0.10}}
              animate={{ x: 0 , y: 0 , scale: 1}} >

                  <div className="BlurIt" style={{
                      "backgroundImage" : "linear-gradient(rgba(0,0,0,0.1) ,rgba(0,0,0,0.1), rgba(0,0,0,0.1))",
                      "boxShadow" : `0 0 ${percentage(currentSize.width , 3)}px black`,
                      "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px black`,
                      "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px black`,
                      "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                      "borderStyle" : "solid",
                      "borderColor" : "black",
                      "width" : `${percentage(currentSize.width , 100)}px`,
                  }}>
                    
                    <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                    <div style={{
                        "textAlign" : "center",
                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                        "color" : "#00f9ff"
                    }}>{HeadingText}</div>

                    <motion.div initial={UpdateAnimationData.initial}
                    animate={UpdateAnimationData.animate}
                    style={{
                        "textAlign" : "center"
                    }}>

                        { AvatarNumber === 1 && < img alt="Avatar" 
                                style={{
                                'height' : `${percentage(currentSize.width , 20)}px`,
                                'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar1 } />
                        }

                        { AvatarNumber === 2 && < img alt="Avatar" 
                                style={{
                                'height' : `${percentage(currentSize.width , 20)}px`,
                                'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar2 } />
                        }

                        { AvatarNumber === 3 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar3 } />
                        }

                        { AvatarNumber === 4 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar4 } />
                        }

                        { AvatarNumber === 5 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar5 } />
                        }

                        { AvatarNumber === 6 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar6 } />
                        }

                        { AvatarNumber === 7 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar7 } />
                        }

                        { AvatarNumber === 8 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar8 } />
                        }

                        { AvatarNumber === 9 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar9 } />
                        }

                        { AvatarNumber === 10 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar10 } />
                        }

                        { AvatarNumber === 11 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar11 } />
                        }

                        { AvatarNumber === 12 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar12 } />
                        }

                        { AvatarNumber === 13 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar13 } />
                        }

                        { AvatarNumber === 14 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar14 } />
                        }

                        { AvatarNumber === 15 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar15 } />
                        }

                        { AvatarNumber === 16 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar16 } />
                        }

                        { AvatarNumber === 17 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar17 } />
                        }

                        { AvatarNumber === 18 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar18 } />
                        }

                        { AvatarNumber === 19 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar19 } />
                        }

                        { AvatarNumber === 20 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar20 } />
                        }

                        { AvatarNumber === 21 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar21 } />
                        }

                        { AvatarNumber === 22 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar22 } />
                        }

                        { AvatarNumber === 23 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar23 } />
                        }

                        { AvatarNumber === 24 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar24 } />
                        }

                        { AvatarNumber === 25 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar25 } />
                        }

                        { AvatarNumber === 26 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar26 } />
                        }

                        { AvatarNumber === 27 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar27 } />
                        }

                        { AvatarNumber === 28 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar28 } />
                        }

                        { AvatarNumber === 29 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar29 } />
                        }

                        { AvatarNumber === 30 && < img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 20)}px`,
                                        'width' : `${percentage(currentSize.width , 20)}px`,
                                }}
                                src={ Avatar30 } />
                        }

                        </motion.div>

                        <div style={{
                            "textAlign" : "center",
                            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                            "color" : "#00f9ff"
                        }}>
                            SELECT AVATAR
                        </div>

                        <div style={{"height" : `${percentage(currentSize.width , 4)}px`,}}></div>

                        <div style={{
                            "display" : "flex"
                        }}>

                        <motion.div onTap={ () => BackShow() } initial={{y : -35 , x : 10, rotate : 180}}>
                        <img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 5)}px`,
                                        'width' : `${percentage(currentSize.width , 10)}px`,
                                }}
                                src={ ArrowIcon } />
                        </motion.div>

                        { ShowSet1 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(1) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar1 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(2) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar2 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(3) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar3 } />
                                </motion.div>
                
                            </motion.div>
                        }

                        { ShowSet2 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(15) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar15 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(16) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar16 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(17) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar17 } />
                                </motion.div>
                            </motion.div>
                        }

                        { ShowSet3 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(4) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar4 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(5) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar5 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(6) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar6 } />
                                </motion.div>
                            </motion.div>
                        }

                        { ShowSet4 === true &&
                            <motion.div initial={AnimationData.initial} animate={AnimationData.animate}  style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(18) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar18 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(19) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar19 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(20) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar20 } />
                                </motion.div>
                            </motion.div>
                        }
                        
                        { ShowSet5 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(7) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar7 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(8) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar8 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(9) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar9 } />
                                </motion.div>
                            </motion.div>
                        }

                        { ShowSet6 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(21) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar21 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(22) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar22 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(23) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar23 } />
                                </motion.div>
                            </motion.div>
                        }

                        { ShowSet7 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(10) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar10 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(11) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar11 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(12) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar12 } />
                                </motion.div>
                            </motion.div>
                        }

                        { ShowSet8 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(24) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar24 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(25) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar25 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(26) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar26 } />
                                </motion.div>
                            </motion.div>
                        }

                        { ShowSet9 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(13) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar13 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(14) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar14 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(30) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar30 } />
                                </motion.div>
                            </motion.div>
                        }

                        { ShowSet10 === true &&
                            <motion.div animate={AnimationData.animate} initial={AnimationData.initial} style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div onTap={()=>UpdateAvatar(27) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar27 } />
                                </motion.div>
                                
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                                <motion.div onTap={()=>UpdateAvatar(28) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar28 } />
                                </motion.div>
                
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 8)}px`,
                                }}></div>
                
                
                                <motion.div onTap={()=>UpdateAvatar(29) }>
                                    <img alt="Avatar" 
                                    style={{
                                            'height' : `${percentage(currentSize.width , 20)}px`,
                                            'width' : `${percentage(currentSize.width , 20)}px`,
                                    }}
                                    src={ Avatar29 } />
                                </motion.div>
                            </motion.div>
                        }

                        <motion.div onTap={ ()=> ForwardShow() } initial={{y : 30 , x : 10, rotate : 360}}>
                        <img alt="Avatar" 
                                style={{
                                        'height' : `${percentage(currentSize.width , 5)}px`,
                                        'width' : `${percentage(currentSize.width , 10)}px`,
                                }}
                                src={ ArrowIcon } />
                        </motion.div>


                        </div>

                        <div style={{"height" : `${percentage(currentSize.width , 4)}px`,}}></div>
                        


                  </div>

                  

        </motion.div>
</div>)
}

export default Section