import React , { useState } from 'react';
import useResize from 'use-resize';  
import { Redirect } from 'react-router';
import GameTableEngine from './GameTableEngine';
import IsAccess from '../../../GlobalFunctions/PracticeMode/IsGamerAccess'
import useSetTimeout from 'use-set-timeout';
import CloseMatchMaker from './CloseMatchMaker'

const Section = () => {
    const [ ConnectionStatus , ConnectionStatusUpdate ] = useState(null);


    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    useSetTimeout(()=>{
        IsAccess(function (){
            if(ConnectionStatus === null){
                ConnectionStatusUpdate(true)
            }                
        })
    }, 2000)    
    
    if(ConnectionStatus === null){
        return(
            <div style={{
            "height" : `${currentSize.height}px`,
            "width": `${currentSize.width}px`,
        }} className="BlurIt" onClick={()=> {}}>
                    <div style={{
                        "position" : "fixed",
                        "top" : "0%",
                        "left" : "0%",
                        "zIndex" : "20",
                        "height" :  `100%`,
                        "width" : `100%`,
                    }}>
    
                    <div className="TableBackground" style={{
                        "position" : "fixed",
                        "top" : "0%",
                        "left" : "0%",
                        "zIndex" : "20",
                        "height" :  `100%`,
                        "width" : `100%`,
                    }}>
                        <div className="TableBackgroundOverlay" style={{
                            "position" : "fixed",
                            "zIndex" : "20",
                            "height" :  `100%`,
                            "width" : `100%`,
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                        }}>
                            < GameTableEngine GameData = {null} />
                        </div>
                    </div> 
    
                    <div style={{
                        "fontSize" : "1.55em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "zIndex" : "21",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        <div className="pulsate-fwd">
                            <div>ESTABLISHING CONNECTION</div>
                            <div>WITH MATCHMAKER</div>
                        </div>                                                
                    </div>

            </div>

            <div style={{
                "position" : 'fixed',
                "width" : `100%`,
                "zIndex" : "25",
                "top" : "7%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
            }}>
                < CloseMatchMaker />
            </div>

        </div>)
    } else {
        return < Redirect to={"/freetoplay/matchmaker"} />
    }

    
}

export default Section