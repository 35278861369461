import React  from 'react';
import Table from './GameTableAssets/Table';

const Section = (Data) => {
    const GameData =  Data.GameData ;
    const isAgnostic = Data.isAgnostic ;
    
    return (<div>

        <div style={{
            "height" : "100%",
            "width" : "100%",
            "position" : "fixed",
            "top" : "0px",
            "backgroundImage" : "radial-gradient(rgba(0,0,0,0.1) , rgba(0,0,0,0.2))",
        }}>
            <div style={{
                "position" : "relative",
                "textAlign" : "center",
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
            }}>
                < Table GameData = { GameData } isAgnostic = { isAgnostic } />
            </div>
        </div>
        
    </div>)

}

export default Section