import React from 'react';
import useOrientationChange from "use-orientation-change";
import Rendering from './ProfileComponents/MatchMakerPlayAgain'

const Section = () => {
    const orientation = useOrientationChange()

    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else {
        return (
            <div>
               < Rendering />
            </div>
        )
    }
}

export default Section