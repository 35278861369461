import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import PlayerOneHandCard from './HandAssets/PlayerOneHandCard';
import PlayerTwoHandCard from './HandAssets/PlayerTwoHandCard';
import PlayerThreeHandCard from './HandAssets/PlayerThreeHandCard';
import PlayerFourHandCard from './HandAssets/PlayerFourHandCard';
import PlayingCardCreator from './PlayingCardCreator';
import TableHeightCalculator from "./TableHeightCalculator";
import HandWonScore from './SoundAssets/HandWon';
import HandPlayedScore from './SoundAssets/HandPlayed';
import EventScore from './SoundAssets/EventFx';
import socket from '../../../../SocketConnectionManager';

const Hand = (Value) => {
    const [ HandPlayedMusic , HandPlayedMusicUpdate ] = useState(false);
    const [ HandWonMusic , HandWonMusicUpdate ] = useState(false);
    const [ EventMusic , EventMusicUpdate ] = useState(false);
    const MusicOn = Value.Music

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const GameData = Value.GameData;
    const HandCards = GameData.HandCards ;
    const HandWinner = GameData.HandWinner ;



    socket.on('onHandPlayed' , function (){
        HandPlayedMusicUpdate(true)        
    })

    socket.on('onHandPlayedMusicOff' , function (){
        HandPlayedMusicUpdate(false)        
    })

    socket.on('onHandWon' , function (){
        HandPlayedMusicUpdate(false)  
        HandWonMusicUpdate(true)
    })

    socket.on('onHandWonMusicOff' , function (){
        HandWonMusicUpdate(false)
    })

    socket.on('onHandStart' , function (){
        EventMusicUpdate(true)
    })

    socket.on('onEventMusicOff' , function (){
        EventMusicUpdate(false)
    })

    const PlayerOne = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1 && HandWinner === null){
            return HandCards.Player1
        } else if(GameData.MySeat === 1 && HandWinner !== null){
            return HandWinner.HandData.HandData.Player1
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1 && HandWinner === null){
            return HandCards.Player2
        } else if(GameData.MySeat === 1 && HandWinner !== null){
            return HandWinner.HandData.HandData.Player2
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1 && HandWinner === null){
            return HandCards.Player3
        } else if(GameData.MySeat === 1 && HandWinner !== null){
            return HandWinner.HandData.HandData.Player3
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1 && HandWinner === null){
            return HandCards.Player4
        } else if(GameData.MySeat === 1 && HandWinner !== null){
            return HandWinner.HandData.HandData.Player4
        }
    }

    const WinnerSeat = () => {
        if(HandWinner === null){
            return null
        } else if(GameData.MySeat === 1 && HandWinner.Seat === 1){
            return 1
        } else if(GameData.MySeat === 1 && HandWinner.Seat === 2){
            return 2
        } else if(GameData.MySeat === 1 && HandWinner.Seat === 3){
            return 3
        } else if(GameData.MySeat === 1 && HandWinner.Seat === 4){
            return 4
        }
    }

    const WinnerOfThisHand = WinnerSeat();

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const TableHeight = TableHeightCalculator();

    
    const SeatOneHandWinner = () => {
        const SeatData = PlayerOne();

        
        var Opacity = 0 ;

        if(WinnerOfThisHand === 1){
            Opacity = 1
        } else {
            Opacity = 0.2
        }

        if(SeatData === null){
            return (
                <motion.div 
                    style={{
                            "height" : `${percentage(currentSize.width , 11)}px`,
                            "width" : `${percentage(currentSize.width , 9)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
                            "position" : "absolute",
                            "bottom" : "-20%",
                            "left" : "21.5%"
                    }}>

                </motion.div>
            )
        } else {
            return (
                <motion.div 
                    animate={{ opacity : Opacity}}
                    style={{
                            "height" : `${percentage(currentSize.width , 11)}px`,
                            "width" : `${percentage(currentSize.width , 9)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
                            "position" : "absolute",
                            "bottom" : "-20%",
                            "left" : "21.5%"
                    }}>
                        < PlayingCardCreator CardData={{
                            Suit : SeatData.Suit,
                            Series : SeatData.Value
                        }} Dimensions = { Dimensions } />
                </motion.div>
            )
        }
        
    }

    const SeatTwoHandWinner = () => {
        const SeatData = PlayerTwo();

        var Opacity = 0 ;

        if(WinnerOfThisHand === 2){
            Opacity = 1
        } else {
            Opacity = 0.2
        }


        if(SeatData === null){
            return (
                <motion.div 
                    style={{
                            "height" : `${percentage(currentSize.width , 11)}px`,
                            "width" : `${percentage(currentSize.width , 9)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
                            "position" : "absolute",
                            "top" : "20%",
                            "left" : "-44%"
                    }}>

                </motion.div>
            )
        } else {
            return (
                <motion.div 
                    animate={{ opacity : Opacity}}
                    style={{
                            "height" : `${percentage(currentSize.width , 11)}px`,
                            "width" : `${percentage(currentSize.width , 9)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
                            "position" : "absolute",
                            "top" : "20%",
                            "left" : "-44%"
                    }}>
                        < PlayingCardCreator CardData={{
                            Suit : SeatData.Suit,
                            Series : SeatData.Value
                        }} Dimensions = { Dimensions } />
                </motion.div>
            )
        }
    }

    const SeatThreeHandWinner = () => {
        const SeatData = PlayerThree();

        var Opacity = 0 ;

        if(WinnerOfThisHand === 3){
            Opacity = 1
        } else {
            Opacity = 0.2
        }


        if(SeatData === null){
            return (
                <motion.div 
                    style={{
                            "height" : `${percentage(currentSize.width , 11)}px`,
                            "width" : `${percentage(currentSize.width , 9)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
                            "position" : "absolute",
                            "top" : "-20%",
                            "left" : "21.5%"
                    }}>

                </motion.div>
            )
        } else {
            return (
                <motion.div 
                    animate={{ opacity : Opacity}}
                    style={{
                            "height" : `${percentage(currentSize.width , 11)}px`,
                            "width" : `${percentage(currentSize.width , 9)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
                            "position" : "absolute",
                            "top" : "-20%",
                            "left" : "21.5%"
                    }}>
                        < PlayingCardCreator CardData={{
                            Suit : SeatData.Suit,
                            Series : SeatData.Value
                        }} Dimensions = { Dimensions } />
                </motion.div>
            )
        }
    }

    const SeatFourHandWinner = () => {
        const SeatData = PlayerFour();

        var Opacity = 0 ;

        if(WinnerOfThisHand === 4){
            Opacity = 1
        } else {
            Opacity = 0.2
        }


        if(SeatData === null){
            return (
                <motion.div 
                        style={{
                            "height" : `${percentage(currentSize.width , 11)}px`,
                            "width" : `${percentage(currentSize.width , 9)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
                            "position" : "absolute",
                            "top" : "20%",
                            "left" : "87%"
                        }}>
                            
                </motion.div>
            )
        } else {
            return (
                <motion.div 
                        animate={{ opacity : Opacity}}
                        style={{
                            "height" : `${percentage(currentSize.width , 11)}px`,
                            "width" : `${percentage(currentSize.width , 9)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
                            "position" : "absolute",
                            "top" : "20%",
                            "left" : "87%"
                        }}>
                            < PlayingCardCreator CardData={{
                                Suit : SeatData.Suit,
                                Series : SeatData.Value
                            }} Dimensions = { Dimensions } />
                </motion.div>
            )
        }
    }

    const DisappearData = () => {
        if(HandWinner !== null){
            if(WinnerOfThisHand === 1){
                return ({
                    opacity : 0
                })
            } else if(WinnerOfThisHand === 2){
                return ({
                    opacity : 0
                })
            } else if(WinnerOfThisHand === 3){
                return ({
                    opacity : 0
                })
            } else if(WinnerOfThisHand === 4){
                return ({
                    opacity : 0
                })
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const AnimateData = () => {
        if(HandWinner !== null){
            if(WinnerOfThisHand === 1){
                return ({
                    "top" : "65%"
                })
            } else if(WinnerOfThisHand === 2){
                return ({
                    "left" : "20%"
                })
            } else if(WinnerOfThisHand === 3){
                return ({
                    "top" : "10%"
                })
            } else if(WinnerOfThisHand === 4){
                return ({
                    "left" : "80%"
                })
            } else {
                return null
            }
        } else {
            return null
        }
        
    }

    const TransitionData = () => {
        if(HandWinner !== null){
            return ({
                delay : 1,
                duration : 0.4 
            })
        } else {
            return ({
                duration : 0.2 
            })
        }
    }

    const DiappearTransitionData = () => {
        if(HandWinner !== null){
            return ({
                delay : 2,
                duration : 0.4 
            })
        } else {
            return null
        }
    }

    
    return (<motion.div style={{
        
    }}>

        <motion.div 
        style={{
            "height" : `${TableHeight}px`,
            "width" : `${percentage(currentSize.width , 75)}px`,
            "position" : "absolute",
            "top" : "50%",
            "left": "50%",
            "transform" : "translate(-50%, -50%)",
            "zIndex" : "30"
        }}>
            <motion.div animate = { AnimateData() }
            transition = { TransitionData() }
            style={{
                "position" : "absolute",
                "top" : `${percentage(TableHeight , 30)}px`,
                "width" : `${percentage(currentSize.width , 18)}px`,
                "height" : `${percentage(currentSize.width , 18)}px`,
                "left": "51%",
                "transform" : "translate(-50%, -50%)",
            }}>
                
                { HandWinner === null &&
                    <motion.div>

                        < PlayerOneHandCard GameData = { GameData } />

                        < PlayerTwoHandCard GameData = { GameData } />

                        < PlayerThreeHandCard GameData = { GameData } />

                        < PlayerFourHandCard GameData = { GameData } />
                        
                    </motion.div>
                }

                { HandWinner !== null &&
                    <motion.div
                        animate = { DisappearData() }
                        transition = { DiappearTransitionData() }
                    >
                        < SeatOneHandWinner />

                        < SeatTwoHandWinner />

                        < SeatThreeHandWinner />

                        < SeatFourHandWinner />
                    </motion.div>
                }
                
            </motion.div>

            { MusicOn === true &&
                <div>
                    < HandPlayedScore Status={ HandPlayedMusic } />

                    < HandWonScore Status={ HandWonMusic } />

                    < EventScore Status={ EventMusic } />
                </div>                
            }  

        </motion.div>

           
    </motion.div>
    
    )
    
            
}

export default Hand