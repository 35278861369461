import React from 'react';
import ChangeUsername from './SettingsNavigationBarComponent/ChangeUsername';
import ChangeAvatar from './SettingsNavigationBarComponent/ChangeAvatar';

const Section = (Value) => {
    const Size = Value.SizeData ;
    const SelectedValue = Value.SelectedValue ;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function HandleCallback(ThisValue){
        Value.ParentCallback(ThisValue);
    }
    

    const width = Size.width ;
    
    return (<div className="BlurIt" style={{
        "position" : "fixed",
        "top" : `${percentage(width , 30)}px`,
        "left" : `${percentage(width , 0.5)}px`,
        "display" : "flex",
        "justifyContent" : "center"
    }}>
        < ChangeUsername SizeData = { Size } ParentCallback = { HandleCallback } SelectedValue={SelectedValue}/>
        <div style={{'width' : `${percentage(width , 1)}px`}}></div>
        < ChangeAvatar SizeData = { Size } ParentCallback = { HandleCallback } SelectedValue={SelectedValue}/>
    </div>)
    
}

export default Section