import React , {useState } from 'react';
import useResize from 'use-resize';
import useOrientationChange from "use-orientation-change";  
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import LandingFooter from './LandingFooter';
import LoaderScreen from './LoaderScreen';
import IsAccess from '../GlobalFunctions/PracticeMode/OnBoard'
import IsGamerProfile from '../GlobalFunctions/PracticeMode/IsGamerProfile';
import CreateGamerProfile from '../GlobalFunctions/PracticeMode/CreateGamerProfile';
import Analytics from '../GlobalFunctions/Analytics';
import Config from '../GlobalFunctions/Config';

const UUIDonBoard = () => {
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null)
    const [ IsGamerAccount , IsGamerAccountUpdate ] = useState(false)
    const CurrentBuildVersion = Config.app_version ;
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const orientation = useOrientationChange()
    const currentSize = SizeInfo();

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }


    const pageVariants = {
        initial: {
            opacity: 0.2,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0.5,
        },
    }

    if(IsAccessData === null){
        IsAccess(function (AccessValue){
            IsAccessDataUpdate(AccessValue)

            if(AccessValue === true){
                IsGamerProfile(function (AccountValues){
                    if(AccountValues === true){
                        IsGamerAccountUpdate(true)
                    } else {
                        IsGamerAccountUpdate(false)

                        CreateGamerProfile( function (ProfileValue){
                            if(ProfileValue === true){
                                Analytics("User-Gamer-Profile-Created")
                                IsGamerAccountUpdate(true)
                            } else {
                                window.location.replace("/?" +  CurrentBuildVersion)
                            }
                        })

                    }
                })
            } 
        })
    }
    
    

    if(orientation === "landscape"){
        return (<div>
            
        </div>)
    } else if(IsAccessData === null){
        return < LoaderScreen />
    } else if(IsAccessData === false){
        window.location.replace("/?" +  CurrentBuildVersion)
    } else if(
        IsAccessData === true &&
        IsGamerAccount === false 
    ){
          
        return (<div className="BlurIt" style={{
                "position" : "fixed",
                "width" : `${percentage(Dimensions.width , 100)}px`,
                "height" : `${percentage(Dimensions.height , 100)}px`,
            }}>
                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                    >

                    <div style={{
                        "position" : "absolute",
                        "top" : "35%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "width" : `${percentage(Dimensions.width , 100)}px`,
                    }}>
                        
                        <div style={{
                            "color" : "#00f9ff"
                        }} className="pulsate-fwd">ACCESSING YOUR PROFILE</div>
                        
                        


                    </div>

                    </motion.div>
                < LandingFooter />
        </div>)
    } else if (IsAccessData === true && IsGamerAccount === true){
        return (
            <div>
                { Config.HomeButtonAccess === null && <Redirect to="/freetoplay/profile" /> }
                { Config.HomeButtonAccess === "Callbreak" && <Redirect to="/freetoplay/cb/profile" /> }        
            </div>
        )
        
        
    }
}

export default UUIDonBoard
