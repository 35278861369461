import Config from '../Config';


const NetworkCall = function (Result) {

    const URL = Config.url_api + "/PMG/isGamerAccess";
    const URL2 = Config.url_api + "/PMG/CB/isGamerAccess";

    fetch(URL , {
        method: 'GET',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
        },
        credentials : "include"
    }).then((response) => response.json())
    .then((json) => {
            Config.practiceModeData = json
            return Result(Config.practiceModeData)
        })

    fetch(URL2 , {
        method: 'GET',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
        },
        credentials : "include"
    }).then((response) => response.json())
    .then((json) => {
            Config.practiceModeDataCB = json
            return Result(Config.practiceModeDataCB)
        })

}

export default NetworkCall