import React , { useState } from 'react';
import useOrientationChange from "use-orientation-change";
import Rendering from './SettingsComponents/SettingsRendering'
import IsAccess from '../../GlobalFunctions/PracticeMode/IsGamerAccessCB'
import LoaderScreen from '../LoaderScreen';
import { Redirect } from 'react-router';
import socket from '../../SocketConnectionManager';
import Config from '../../GlobalFunctions/Config' ;
import Analytics from '../../GlobalFunctions/Analytics';

const Section = () => {
    const orientation = useOrientationChange()
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null);

    if(IsAccessData === null){
        IsAccess(function (AccessDataValue){
            IsAccessDataUpdate(AccessDataValue)
            Analytics("Profile-Settings-Accessed")
        })
    }

    socket.on('connect' , function (){
        Config.practiceModeDataCB = null ;
        IsAccessDataUpdate(null)
    })



    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else {
        return (
            <div style={{
                "position" : "relative",
            }}>
                { IsAccessData === null && < LoaderScreen /> }
                { IsAccessData !== null && IsAccessData !== false && < Rendering /> }
                { IsAccessData === false && <Redirect to="/" />}
            </div>
        )
    }
}

export default Section