import React from 'react';
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";

const CurrentBluff = (Values) => {
        const SeatSize = 20;
        const GameData = Values.GameData;

        const BlufferSeat = GameData.CurrentBluff.Bluffer.Seat;

        function SizeInfo() {
            const size = useResize()
            
            return size;
        }

        const currentSize = SizeInfo();

        function percentage(num, per)
        {
            return (num/100)*per;
        }

        const ShadowWidth = 4;
        const ShadowColor = "black";


        
        const ClaimProvider = (Value) => {
            if(Value === "A"){
                return ("ACE(S)")
            } else if(Value === "K"){
                return ("KING(S)")
            } else if(Value === "Q"){
                return ("QUEEN(S)")
            } else if(Value === "J"){
                return ("JACK(S)")
            } else if(Value === "10"){
                return ("TEN(S)")
            } else if(Value === "9"){
                return ("NINE(S)")
            } else {
                return ("WTF")
            }
        }

        const YMover = () => {
            if(BlufferSeat === 2 || BlufferSeat === 4){
                return (-30)
            } else {
                return 0
            }
        }

        return (<motion.div
        animate={{ x: 0 , y: YMover()}}
        style={{
            "position" : "absolute",
            "zIndex" : "25",
        }}>
                <div style={{
                    "backgroundColor" : "rgba(0,0,0,0.8)",
                    "width" : `${percentage(currentSize.width , SeatSize)}px`,
                    "height" : `${percentage(currentSize.width , SeatSize/2)}px`,
                    "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                    "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                    "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                    "borderStyle" : "solid",
                    "borderColor" : "black",
                }}>

                <div style={{
                    "color" : "#00d6ff",
                    "backgroundColor" : "black",
                    "borderTopLeftRadius" : `${percentage(currentSize.width , 2)}px`,
                    "borderTopRightRadius" : `${percentage(currentSize.width , 2)}px`,
                    "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                    "textAlign" : "center"
                }}>
                    <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                    CLAIMED
                    <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                </div>

                <div style={{
                    "fontSize" : `${percentage(currentSize.width , 3)}px`,
                }}>
                    <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>

                    <div style={{
                        "display" : "flex",
                        "justifyContent" : "center"
                    }}>
                        <div>{ GameData.CurrentBluff.NumberOfCardsBluffed }</div>
                        <div style={{"width" : `${percentage(currentSize.width , 2)}px`,}}></div>
                        <div>{ ClaimProvider(GameData.CurrentBluff.Claim) }</div>
                    </div>
                </div>

                </div>
        </motion.div>)
}

export default CurrentBluff