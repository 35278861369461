import React from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import Avatar from './Avatar';
import RankImage from '../../../../../Icons/Rank.png' ;
import ITapper from './iTapper'

const Mover = (Data) => {
   
    const Rank = Data.Rank
    const isAgnostic = Data.Agnostic ;
    const currentSize = SizeInfo();
    const width = currentSize.width ;
    const BlockShadowWidth = 5;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const RankColor = (Value) => {
        if(Value === 1){
            return ("#D4AF37")
        } else if(Value === 2){
            return ("#C0C0C0")
        } else {
            return ("#8C7853")
        }
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    if(Rank.item.Seat !== 1){
        return(<motion.div>
            
            <motion.div style={{
                    "width" : `${percentage(currentSize.width , 15)}px`,
                    "height" : `${percentage(currentSize.width , 15)}px`,
                    "position" : "absolute",
                    
                }}>
                    < img 
                    style={{
                        "width" : `${percentage(currentSize.width , 20)}px`,
                        "height" : `${percentage(currentSize.width , 20)}px`,
                        "position" : "absolute",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",          
                    }}
                    alt="Avatar" src={ RankImage } />
                </motion.div>
    
                
                <motion.div style={{
                        "width" : `${percentage(currentSize.width , 15)}px`,
                        "height" : `${percentage(currentSize.width , 15)}px`,
                        "position" : "absolute",
                        "fontSize" : `${percentage(currentSize.width , 8)}px`,
                        "fontFamily" : "CardText",
                        "color" : RankColor(Rank.rank)
                    }}>
                        <div style={{
                                "position" : "absolute",
                                "top" : "50%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",          
                        }}>
                            { Rank.rank}
                        </div>
                </motion.div>
    
                <motion.div 
                className="BlurIt"
                style={{
                    "width" : `${percentage(currentSize.width , 65)}px`,
                    "height" : `${percentage(currentSize.width , 15)}px`,
                    "position" : "absolute",
                    "left" : `${percentage(currentSize.width , 20)}px`,
                    "borderRadius" : "5%",
                    "borderStyle" : "solid",
                    "backgroundColor" : "rgba(0,0,0,0.4)",
                    "borderColor" : "black",
                    "borderWidth" : `${percentage(currentSize.width , 0.7)}px`,
                    "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                    "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                    "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                }}>
                        
    
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 6)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "fontFamily" : "CardText",
                            "position" : "absolute",
                            "top" : "53%",
                            "left": "16%",
                            "transform" : "translate(-50%, -50%)",          
                        }}>
                        < Avatar Number={ Rank.Avatar } BlockSize={percentage(currentSize.width , 25)} />
                        </motion.div>
    
                        <motion.div 
                        style={{
                            "top" : "15%",
                            "left": "45%",
                            "transform" : "translate(-50%, -50%)",  
                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "position" : "absolute",
                        }}>
                            { Rank.UserName }
                        </motion.div>
    
                        <motion.div 
                        
                        style={{
                            "top" : "83%",
                            "left": "45%",
                            "transform" : "translate(-50%, -50%)",  
                            "fontSize" : `${percentage(currentSize.width , 4)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "fontFamily" : "CardText",
                            "position" : "absolute",
                        }}>
                        BIDS - { Rank.item.Hands } / { Rank.item.Bids } 
                        </motion.div>
                               
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 5)}px`,
                            "width" : `${percentage(currentSize.width , 15)}px`,  
                            "fontFamily" : "CardText",     
                            "position" : "absolute",    
                            "top" : "15%",
                            "left": "88%",
                            "transform" : "translate(-50%, -50%)",                               
                        }}>                            
                            SCORE
                        </motion.div>
    
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 10)}px`,
                            "width" : `${percentage(currentSize.width , 15)}px`,
                            "textAlign" : "right",                 
                            "fontFamily" : "CardText",    
                            "position" : "absolute",    
                            "top" : "65%",
                            "left": "80%",
                            "transform" : "translate(-50%, -50%)",                                                  
                        }}>
                            { Rank.item.Score } 
                        </motion.div>
    
            </motion.div>  
        </motion.div>)   

    } else if(Rank.item.Seat === 1 && isAgnostic === false){
        return(<motion.div>
            
            <motion.div style={{
                    "width" : `${percentage(currentSize.width , 15)}px`,
                    "height" : `${percentage(currentSize.width , 15)}px`,
                    "position" : "absolute",
                    
                }}>
                    < img 
                    style={{
                        "width" : `${percentage(currentSize.width , 20)}px`,
                        "height" : `${percentage(currentSize.width , 20)}px`,
                        "position" : "absolute",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",          
                    }}
                    alt="Avatar" src={ RankImage } />
                </motion.div>
    
                
                <motion.div style={{
                        "width" : `${percentage(currentSize.width , 15)}px`,
                        "height" : `${percentage(currentSize.width , 15)}px`,
                        "position" : "absolute",
                        "fontSize" : `${percentage(currentSize.width , 8)}px`,
                        "fontFamily" : "CardText",
                        "color" : RankColor(Rank.rank)
                    }}>
                        <div style={{
                                "position" : "absolute",
                                "top" : "50%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",          
                        }}>
                            { Rank.rank}
                        </div>
                </motion.div>
    
                <motion.div 
                className="BlurIt"
                style={{
                    "width" : `${percentage(currentSize.width , 65)}px`,
                    "height" : `${percentage(currentSize.width , 15)}px`,
                    "position" : "absolute",
                    "left" : `${percentage(currentSize.width , 20)}px`,
                    "borderRadius" : "5%",
                    "borderStyle" : "solid",
                    "backgroundColor" : "rgba(0,0,0,0.4)",
                    "borderColor" : "black",
                    "borderWidth" : `${percentage(currentSize.width , 0.7)}px`,
                    "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                    "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                    "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                }}>
                        
    
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 6)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "fontFamily" : "CardText",
                            "position" : "absolute",
                            "top" : "53%",
                            "left": "16%",
                            "transform" : "translate(-50%, -50%)",          
                        }}>
                        < Avatar Number={ Rank.Avatar } BlockSize={percentage(currentSize.width , 25)} />
                        </motion.div>
    
                        <motion.div 
                        style={{
                            "top" : "15%",
                            "left": "45%",
                            "transform" : "translate(-50%, -50%)",  
                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "position" : "absolute",
                        }}>
                            { Rank.UserName }
                        </motion.div>
    
                        <motion.div 
                        
                        style={{
                            "top" : "83%",
                            "left": "45%",
                            "transform" : "translate(-50%, -50%)",  
                            "fontSize" : `${percentage(currentSize.width , 4)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "fontFamily" : "CardText",
                            "position" : "absolute",
                        }}>
                        BIDS - { Rank.item.Hands } / { Rank.item.Bids } 
                        </motion.div>
                               
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 5)}px`,
                            "width" : `${percentage(currentSize.width , 15)}px`,  
                            "fontFamily" : "CardText",     
                            "position" : "absolute",    
                            "top" : "15%",
                            "left": "88%",
                            "transform" : "translate(-50%, -50%)",                               
                        }}>                            
                            SCORE
                        </motion.div>
    
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 10)}px`,
                            "width" : `${percentage(currentSize.width , 15)}px`,
                            "textAlign" : "right",                 
                            "fontFamily" : "CardText",    
                            "position" : "absolute",    
                            "top" : "65%",
                            "left": "80%",
                            "transform" : "translate(-50%, -50%)",                                                  
                        }}>
                            { Rank.item.Score } 
                        </motion.div>
    
            </motion.div>  
        </motion.div>)   
    } else if(Rank.item.Seat === 1 && isAgnostic === true){
        return(<motion.div>
            
            <motion.div style={{
                    "width" : `${percentage(currentSize.width , 15)}px`,
                    "height" : `${percentage(currentSize.width , 15)}px`,
                    "position" : "absolute",
                    
                }}>
                    < img 
                    style={{
                        "width" : `${percentage(currentSize.width , 20)}px`,
                        "height" : `${percentage(currentSize.width , 20)}px`,
                        "position" : "absolute",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",          
                    }}
                    alt="Avatar" src={ RankImage } />
                </motion.div>
    
                
                <motion.div style={{
                        "width" : `${percentage(currentSize.width , 15)}px`,
                        "height" : `${percentage(currentSize.width , 15)}px`,
                        "position" : "absolute",
                        "fontSize" : `${percentage(currentSize.width , 8)}px`,
                        "fontFamily" : "CardText",
                        "color" : RankColor(Rank.rank)
                    }}>
                        <div style={{
                                "position" : "absolute",
                                "top" : "50%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",          
                        }}>
                            { Rank.rank}
                        </div>
                </motion.div>
    
                <motion.div 
                className="BlurIt"
                style={{
                    "width" : `${percentage(currentSize.width , 65)}px`,
                    "height" : `${percentage(currentSize.width , 15)}px`,
                    "position" : "absolute",
                    "left" : `${percentage(currentSize.width , 20)}px`,
                    "borderRadius" : "5%",
                    "borderStyle" : "solid",
                    "backgroundColor" : "rgba(0,0,0,0.4)",
                    "borderColor" : "black",
                    "borderWidth" : `${percentage(currentSize.width , 0.7)}px`,
                    "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                    "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                    "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${RankColor(Rank.rank)}`,
                }}>
                        
    
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 6)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "fontFamily" : "CardText",
                            "position" : "absolute",
                            "top" : "53%",
                            "left": "16%",
                            "transform" : "translate(-50%, -50%)",          
                        }}>
                            < ITapper Number={ Rank.Avatar } BlockSize={percentage(currentSize.width , 25)} />
                        </motion.div>
    
                        <motion.div 
                        style={{
                            "top" : "15%",
                            "left": "45%",
                            "transform" : "translate(-50%, -50%)",  
                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "position" : "absolute",
                        }}>
                            ITAP PLAYER
                        </motion.div>
    
                        <motion.div 
                        
                        style={{
                            "top" : "83%",
                            "left": "45%",
                            "transform" : "translate(-50%, -50%)",  
                            "fontSize" : `${percentage(currentSize.width , 4)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "fontFamily" : "CardText",
                            "position" : "absolute",
                        }}>
                        BIDS - { Rank.item.Hands } / { Rank.item.Bids } 
                        </motion.div>
                               
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 5)}px`,
                            "width" : `${percentage(currentSize.width , 15)}px`,  
                            "fontFamily" : "CardText",     
                            "position" : "absolute",    
                            "top" : "15%",
                            "left": "88%",
                            "transform" : "translate(-50%, -50%)",                               
                        }}>                            
                            SCORE
                        </motion.div>
    
                        <motion.div style={{
                            "fontSize" : `${percentage(currentSize.width , 10)}px`,
                            "width" : `${percentage(currentSize.width , 15)}px`,
                            "textAlign" : "right",                 
                            "fontFamily" : "CardText",    
                            "position" : "absolute",    
                            "top" : "65%",
                            "left": "80%",
                            "transform" : "translate(-50%, -50%)",                                                  
                        }}>
                            { Rank.item.Score } 
                        </motion.div>
    
            </motion.div>  
        </motion.div>)   
    } else {
        return (<motion.div>

        </motion.div>)
    }
    
}

export default Mover