import React from 'react';
import Music from '../../../../../Sound/ChipsCollected.mp3';
import useSound from 'use-sound';

const Section = (Value) => {
    const ToPlay = Value.Status;
    const [ play , { stop }] = useSound(Music , { interrupt : false});

    return (
        <div>
            { ToPlay === true && play() }
            { ToPlay === false && stop() }
        </div>
    )
    
}

export default Section