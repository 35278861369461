import React , { useState } from 'react';
import useResize from 'use-resize';
import useOrientationChange from "use-orientation-change";
import CoinsIcon from '../../../Icons/Chips.png';
import { motion } from "framer-motion";
import Config from '../../../GlobalFunctions/Config'

const Section = () => {
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

   
    const BackGroundGradient_1_step1 = "linear-gradient(rgba(232, 232,  232  ,0.4) ,rgba(0,0,0,0.1), rgba(0,0,0,0.2), rgba(0,0,0,0.3), rgba(0,0,0,0.4))"
   
    const BackGroundGradient_2 = "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.5), rgba(0,0,0,0.6), rgba(0,0,0,0.6))"
    
    const BackGroundGradient_3_step1 = "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.4), rgba(0,0,0,0.3), rgba(0,0,0,0.2), rgba(232, 232,  232  ,0.4))"
    
    const [ BackgroundOne  ] = useState(BackGroundGradient_1_step1)
    const [ BackgroundTwo  ] = useState(BackGroundGradient_3_step1)

    var HouseDebt = 0 ;

    if(Config.practiceModeDataCB.FreeModeWallet.Balance < 0 ){
        HouseDebt = Config.practiceModeDataCB.FreeModeWallet.Balance
    }
    

    const orientation = useOrientationChange()
    const currentSize = SizeInfo();

    const width = currentSize.width ;
    const height = currentSize.height ;


    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const pageVariants = {
        initial: {
            opacity: 0.5,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0.5,
        },
    }

        var NavigationSwitchHeight = ((percentage(Dimensions.width , 24)/16) * 2) + (percentage(Dimensions.width , 24)/8)

        var PageStartingPosition = percentage(Dimensions.width , 32) + NavigationSwitchHeight ;
        var PageEndingPosition = percentage(Dimensions.width , 20) + percentage(Dimensions.height , 3);
        var PageHeight = percentage(Dimensions.height , 98) - (PageStartingPosition + PageEndingPosition);
        var AreaConsumed = percentage(Dimensions.height , 15) * 3
        var AreaLeft = PageHeight - AreaConsumed
    


    if(orientation === "landscape"){
        return (<div>

        </div>)
    } else {
          
        
        return (<div style={{
            "position" : "fixed",
            "marginTop" : `${PageStartingPosition}px`,
            "height" : `${PageHeight}px`,
            "width" : `${percentage(Dimensions.width , 100)}px`, 
        }}>

        
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
            >
                <div style={{'height' : `${percentage(AreaLeft , 30)}px`}}></div>

                <div className="BlurIt" style={{
                    "display" : "flex",
                    "fontSize" : `${percentage(width , 3.5)}px`,
                    "backgroundImage" : BackgroundOne ,
                    "paddingTop" : `${percentage(height , 3)}px`,
                    "paddingBottom" : `${percentage(height , 3)}px`,
                    "borderColor" : "black",
                    "borderStyle" : "solid",
                    "borderWidth" : `${percentage(width , 1)}px`,
                    "width" : `${percentage(width , 95)}px`,
                    "borderTopLeftRadius" :"10%",
                    "borderTopRightRadius" :"10%",
                    "margin" : "0 auto"
                }}>

                <div style={{
                    "width" : `${percentage(width , 50)}px`,
                    "paddingLeft" : `${percentage(width , 4)}px`,
                }}>
                    <div>TOTAL BOOT PAID ( - )</div>
                </div>

                <div style={{
                    "display" : "flex" ,
                    "marginLeft" : `${percentage(width , 4)}px`,
                    "marginRight" : `${percentage(width , 4)}px`,
                    "width" :  `${percentage(width , 30)}px`,
                    "justifyContent" : "flex-end"
                }}>
                    <div>{ Config.practiceModeDataCB.FreeModeWallet.BootPaid  }</div>

                    < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 4)}px`,
                            'width' : `${percentage(width , 4)}px`,
                            "marginLeft" : `${percentage(width , 1)}px`,
                            }}
                        src={ CoinsIcon } /> 
                </div>
               
            </div> 

            <div style={{'height' : `${percentage(AreaLeft , 1)}px`}}></div>

            <div className="BlurIt" style={{
                    "display" : "flex",
                    "fontSize" : `${percentage(width , 3.5)}px`,
                    "backgroundImage" : BackGroundGradient_2 ,
                    "paddingTop" : `${percentage(height , 3)}px`,
                    "paddingBottom" : `${percentage(height , 3)}px`,
                    "borderColor" : "black",
                    "borderStyle" : "solid",
                    "borderWidth" : `${percentage(width , 1)}px`,
                    "width" : `${percentage(width , 95)}px`,
                    "margin" : "0 auto"
                }}>

                <div style={{
                    "width" : `${percentage(width , 50)}px`,
                    "paddingLeft" : `${percentage(width , 4)}px`,
                }}>
                    <div>TOTAL REWARDS EARNED ( + )</div>
                </div>

                <div style={{
                    "display" : "flex" ,
                    "marginLeft" : `${percentage(width , 4)}px`,
                    "marginRight" : `${percentage(width , 4)}px`,
                    "width" :  `${percentage(width , 30)}px`,
                    "justifyContent" : "flex-end"
                }}>
                    <div>{Config.practiceModeDataCB.FreeModeWallet.Rewards}</div>

                    < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 4)}px`,
                            'width' : `${percentage(width , 4)}px`,
                            "marginLeft" : `${percentage(width , 1)}px`,
                            }}
                        src={ CoinsIcon } /> 
                </div>
                
            </div> 

            <div style={{'height' : `${percentage(AreaLeft , 1)}px`}}></div>

            <div className="BlurIt" style={{
                    "display" : "flex",
                    "fontSize" : `${percentage(width , 3.5)}px`,
                    "backgroundImage" : BackGroundGradient_2 ,
                    "paddingTop" : `${percentage(height , 3)}px`,
                    "paddingBottom" : `${percentage(height , 3)}px`,
                    "borderColor" : "black",
                    "borderStyle" : "solid",
                    "borderWidth" : `${percentage(width , 1)}px`,
                    "width" : `${percentage(width , 95)}px`,
                    "margin" : "0 auto"
                }}>

                <div style={{
                    "width" : `${percentage(width , 50)}px`,
                    "paddingLeft" : `${percentage(width , 4)}px`,
                }}>
                    <div>TOTAL MATCH WINNINGS ( + )</div>
                </div>

                <div style={{
                    "display" : "flex" ,
                    "marginLeft" : `${percentage(width , 4)}px`,
                    "marginRight" : `${percentage(width , 4)}px`,
                    "width" :  `${percentage(width , 30)}px`,
                    "justifyContent" : "flex-end"
                }}>
                    <div>{Config.practiceModeDataCB.FreeModeWallet.MatchWinnings}</div>

                    < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 4)}px`,
                            'width' : `${percentage(width , 4)}px`,
                            "marginLeft" : `${percentage(width , 1)}px`,
                            }}
                        src={ CoinsIcon } /> 
                </div>
                
            </div> 


            <div style={{'height' : `${percentage(AreaLeft , 1)}px`}}></div>

            <div className="BlurIt" style={{
                "display" : "flex",
                "fontSize" : `${percentage(width , 3.5)}px`,
                "backgroundImage" : BackgroundTwo ,
                "paddingTop" : `${percentage(height , 3)}px`,
                "paddingBottom" : `${percentage(height , 3)}px`,
                "borderColor" : "black",
                "borderStyle" : "solid",
                "borderWidth" : `${percentage(width , 1)}px`,
                "width" : `${percentage(width , 95)}px`,
                "margin" : "0 auto",
                "borderBottomLeftRadius" :"10%",
                "borderBottomRightRadius" :"10%",
            }}>

            <div style={{
                "width" : `${percentage(width , 50)}px`,
                "paddingLeft" : `${percentage(width , 4)}px`,
            }}>
                <div>HOUSE DEBT ( - )</div>
            </div>

            <div style={{
                "display" : "flex" ,
                "marginLeft" : `${percentage(width , 4)}px`,
                "marginRight" : `${percentage(width , 4)}px`,
                "width" :  `${percentage(width , 30)}px`,
                "justifyContent" : "flex-end"
            }}>
                <div>{ HouseDebt }</div>

                < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 4)}px`,
                        'width' : `${percentage(width , 4)}px`,
                        "marginLeft" : `${percentage(width , 1)}px`,
                        }}
                    src={ CoinsIcon } /> 
            </div>

            </div> 
               

            <div style={{'height' : `${percentage(AreaLeft , 5)}px`}}></div>

            <div className="BlurIt" style={{
                "display" : "flex",
                "fontSize" : `${percentage(width , 3.5)}px`,               
                "backgroundImage" : BackGroundGradient_2 ,
                "backgroundColor" : "rgba(232, 232,  232  ,0.1)",
                "paddingTop" : `${percentage(height , 3)}px`,
                "paddingBottom" : `${percentage(height , 3)}px`,
                "borderColor" : "black",
                "borderStyle" : "solid",
                "borderWidth" : `${percentage(width , 1)}px`,
                "width" : `${percentage(width , 95)}px`,
                "margin" : "0 auto"
            }}>

            <div style={{
                "width" : `${percentage(width , 50)}px`,
                "paddingLeft" : `${percentage(width , 4)}px`,
            }}>
                <div>AVAILABLE BALANCE</div>
            </div>

            <div style={{
                "display" : "flex" ,
                "marginLeft" : `${percentage(width , 4)}px`,
                "marginRight" : `${percentage(width , 4)}px`,
                "width" :  `${percentage(width , 30)}px`,
                "justifyContent" : "flex-end"
            }}>
                <div>{ Config.practiceModeDataCB.FreeModeWallet.Balance }</div>

                < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 4)}px`,
                        'width' : `${percentage(width , 4)}px`,
                        "marginLeft" : `${percentage(width , 1)}px`,
                        }}
                    src={ CoinsIcon } /> 
            </div>

            </div> 
                
            </motion.div>
 
        </div>)
    }
}

export default Section