import mixpanel from 'mixpanel-browser';
import Config from './Config';

mixpanel.init('7ce471e05b2667af795bba46977a1e6f'); // Prod

mixpanel.register_once({
    'First Access Date': new Date().toISOString()
});

const Analytics = (EventType) => {
    mixpanel.track(EventType);

    const URL = Config.url_api + "/Analytics/" + EventType ;

    fetch(URL , {
        method: 'GET',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
        },
        credentials : "include"
    }).then((response) => response.json())
    .then((json) => {

    })
}

export default Analytics
