import React from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import CardStack from '../../../../Icons/CardStack.png';

const Mover = (Value) => {
    const GameData = Value.GameData ;
    const SeatSize = 20;
    const currentSize = SizeInfo();
    const ShadowWidth = 4;
    const ShadowColor = "black";

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    
    return (
        <motion.div 
            style={{
                "position" : "absolute",
                "backgroundColor" : "rgba(0,0,0,0.5)",
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize/1.75)}px`,
                "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "borderColor" : "black",
                "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                "borderStyle" : "solid",
            }}>
    
                
                <div style={{
                    "color" : "#00d6ff",
                    "backgroundColor" : "black",
                    "borderTopLeftRadius" : `${percentage(currentSize.width , 2)}px`,
                    "borderTopRightRadius" : `${percentage(currentSize.width , 2)}px`,
                    "textAlign" : "center"
                }}>
                    <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                    CARD PILE
                    <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                </div>
    
                < img alt="Avatar" 
                            style={{
                                'height' : `${percentage(currentSize.width , 3.5)}px`,
                                'width' : `auto`,
                                "position" : "absolute",
                                "top" : "60%",
                                "left": "50%",
                                "zIndex" : "25",
                                "transform" : "translate(-50%, -50%)",
                                }}
                                src={ CardStack} />

                { GameData.CurrentChallenge !== null &&
                    <div style={{
                        "position" : "absolute",
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "borderRadius" : "50%",
                        "color" : "#00d6ff",
                        "height" : `${percentage(currentSize.width , 6)}px`,
                        "width" : `${percentage(currentSize.width , 6)}px`,
                        "fontSize" : `${percentage(currentSize.width , 3)}px`,
                        "textAlign": "center",
                        "top" : "100%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                        "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                    }}> 
                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                        { GameData.CurrentChallenge.BluffData.CardPile }
                        <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                    </div>
                }
                
            </motion.div>
    )
    
}

            

export default Mover