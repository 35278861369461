import { motion }  from "framer-motion";
import NoSuit from '../../../../../Icons/NoSuit.svg';
import BlackKing from '../../../../../Icons/KingBlack.png'
import BlackQueen from '../../../../../Icons/QueenBlack.png'
import BlackJack from '../../../../../Icons/JackBlack.png'


const PlayingCardCreator = (Value) => {
    const width = Value.Dimensions.width ;
    const CardData = Value.CardData ;
    const Series = CardData.Series;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TextColor = () =>{
            return("black")
    }


    
    return (<motion.div 
        style={{
        "height" : `${percentage(width , 12)}px`,
        "width" : `${percentage(width , 10)}px`,
        "backgroundColor" : "white",
        "borderStyle" : "solid",
        "borderRadius" : `${percentage(width , 0.75)}px`,
        "borderColor" : "black",
        "borderWidth" : `${percentage(width , 0.25)}px`,
    }}>

        { Series === "A" && 
            <div>
                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 3.5)}px`,
                    "color" : TextColor(),
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 4, y: 0}}>
                    {Series}
                </motion.div>

                <motion.div style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 2, y: 15}}>
                        < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 3)}px`,
                        "width" : "auto",
                            }}
                        src={ NoSuit } />
                </motion.div>

                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 8.5)}px`,
                    "color" : TextColor(),
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 15, y: 13}}>
                    {Series}
                </motion.div>

            </div>
        }

        { Series === "9" && 
            <div>
                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 3.5)}px`,
                    "color" : TextColor(),
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 4, y: 0}}>
                    {Series}
                </motion.div>

                <motion.div style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 2, y: 15}}>
                        < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 3)}px`,
                        "width" : "auto",
                            }}
                        src={ NoSuit } />
                </motion.div>

                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 7.5)}px`,
                    "color" : TextColor(),
                    "fontFamily" : "HighlightText",
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 19, y: 18}}>
                    {Series}
                </motion.div>

            </div>
        }

        { Series === "10" && 
            <div>
                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 3.5)}px`,
                    "color" : TextColor(),
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 1, y: 0}}>
                    {Series}
                </motion.div>

                <motion.div style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 2, y: 15}}>
                        < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 3)}px`,
                        "width" : "auto",
                            }}
                        src={ NoSuit } />
                </motion.div>

                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 7.5)}px`,
                    "color" : TextColor(),
                    "fontFamily" : "HighlightText",
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 17, y: 18}}>
                    {Series}
                </motion.div>

            </div>
        }

        { Series === "K" && 
            <div>
                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 3.5)}px`,
                    "color" : TextColor(),
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 4, y: 0}}>
                    {Series}
                </motion.div>

                <motion.div style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 2, y: 15}}>
                        < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 3)}px`,
                        "width" : "auto",
                            }}
                        src={ NoSuit } />
                </motion.div>

                <motion.div  style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 17, y: 17}}>
                    < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 7)}px`,
                        "width" : "auto",
                            }}
                        src={ BlackKing } />
                </motion.div>

            </div>
        }

        { Series === "Q" && 
            <div>
                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 3.5)}px`,
                    "color" : TextColor(),
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 4, y: 0}}>
                    {Series}
                </motion.div>

                <motion.div style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 3, y: 15}}>
                        < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 3)}px`,
                        "width" : "auto",
                            }}
                        src={ NoSuit } />
                </motion.div>

                <motion.div  style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 17, y: 17}}>
                    < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 7)}px`,
                        "width" : "auto",
                            }}
                        src={ BlackQueen } />
                </motion.div>

            </div>
        }   

        { Series === "J" && 
            <div>
                <motion.div  style={{
                    "fontSize" :  `${percentage(width , 3.5)}px`,
                    "color" : TextColor(),
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 4, y: 0}}>
                    {Series}
                </motion.div>

                <motion.div style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 3, y: 15}}>
                        < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 3)}px`,
                        "width" : "auto",
                            }}
                        src={ NoSuit } />
                </motion.div>

                <motion.div  style={{
                    "position" : "absolute"
                    }} initial={{ opacity : 1, x: 17, y: 17}}>
                    < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 7)}px`,
                        "width" : "auto",
                            }}
                        src={ BlackJack } />
                </motion.div>

            </div>
        }   

    </motion.div>
    )
    
    
    
}

export default PlayingCardCreator 