import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import useSetTimeout from 'use-set-timeout';

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);
    const Size = Value.SizeData ;
    const [ ShadowWidth ] = useState(4);
    const [ ShadowColor , ShadowColorUpdate ] = useState("#FF5733");

    useSetTimeout(() => {
        ShadowColorUpdate("#00f9ff")        
    }, 500)

    
    


    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }


    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
    const height = Size.height ;

    return (<div  style={{
            "height" : `${percentage(height , 15)}px`,
            "width" : `${percentage(width , 48)}px`,
    }}>

            { ShadowColor !== null &&
                <motion.div
                initial={{ opacity : 0.3 , color : "#FF5733",
                "boxShadow" : `0 0 ${percentage(width , ShadowWidth)}px #FF5733`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px #FF5733`,
                "MozBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px #FF5733`,                                
                }}
                animate={{ opacity : 1 , color : "#00f9ff",
                "boxShadow" : `0 0 ${percentage(width , ShadowWidth)}px #00f9ff`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px #00f9ff`,
                "MozBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px #00f9ff`,                                
            }}
                transition={{ duration : 1 , repeat : Infinity , repeatDelay : 20}}
                style={{
                    "fontSize" : `${percentage(width , 8.5)}px`,
                    "width" : `${percentage(width , 40)}px`,                
                    "textAlign" : "center",
                    "backgroundImage" : BackgroundColor ,
                    "borderRadius" :"10%",
                    "position" : "relative",
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",                    
                    "borderStyle" : "solid",
                    "borderColor" : "black",
                    "borderWidth" : `${percentage(width , 0.5)}px`,
                    "fontFamily" : "HighlightText",                    
                }}>
                    <div style={{"height" : `${percentage(width , 3)}px`}}></div>
                        <motion.div onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>
                            <div className="TextAwesomeNess">PLAY NOW</div>
                        </motion.div>
                    <div style={{"height" : `${percentage(width , 3)}px`}}></div>
                </motion.div>

        }
            
            {   RedirectProperty === true &&
                < Redirect to={'/freetoplay/cb/matchmaker'} />
            }
    </div>)
}

export default Section