import React , {useState } from 'react';
import { motion } from "framer-motion";
import Home from '../../../Icons/MainButton.png'
import { Redirect } from 'react-router';
import {  useLocation} from 'react-router-dom'

const Section = (Value) => {
    const location = useLocation();
    const Size = Value.SizeData ;
    const width = Size.width ;
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false); 
    const [ IconSize , IconSizeUpdate ] = useState(7)
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232, 0.2) ,rgba(0,0,0,0.1), rgba(232, 232,  232, 0.2))"

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    

    

    function onTapStart() {
        IconSizeUpdate(8)
    }

    function onTapCancel() {
        IconSizeUpdate(7)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }
    
    return (
    
        <div>

        { location.pathname === "/freetoplay/profile" &&

            

            <div className="BlurIt" style={{
                "textAlign" : "center",
                'width' : `${percentage(width , 44)}px`,
                "backgroundImage" : BackGroundGradient_8,
                "padding" : `${percentage(width , 2)}px`,
                "height" : `${percentage(width , 12)}px`,
                "color" : "#00f9ff",
                "borderBottomStyle" : "solid",
                "borderBottomColor" : "#00f9ff"
            }}>
                
                    < img alt="Logo" 
                            style={{
                            'height' : `${percentage(width , 7.5)}px`,
                            'width' : `${percentage(width , 7.5)}px`,
                            }}
                            src={ Home } />
                    < div style={{'fontSize' : `${percentage(width , 10)/3.5}px`,}}>MAIN</div>
                
            </div>

        }
       

       { location.pathname !== "/freetoplay/profile"  &&

            <motion.div onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>

            <div className="BlurIt" style={{
                "textAlign" : "center",
                'width' : `${percentage(width , 44)}px`,
                "backgroundImage" : BackGroundGradient_8,
                "padding" : `${percentage(width , 2)}px`,
                "height" : `${percentage(width , 12)}px`,
                "color" : "#00f9ff",
            }}>
                
                    < img alt="Logo" 
                            style={{
                            'height' : `${percentage(width , IconSize)}px`,
                            'width' : `${percentage(width , IconSize)}px`,
                            }}
                            src={ Home } />
                    < div style={{'fontSize' : `${percentage(width , 10)/3.5}px`,}}>MAIN</div>
                
            </div>

            </motion.div>
        }  


        { RedirectProperty === true &&  <Redirect to="/freetoplay/profile" />}

        </div>
    
    )
    
}

export default Section