import React , { useState } from 'react';
import useResize from 'use-resize';
import useOrientationChange from "use-orientation-change";  
import { motion } from "framer-motion";
import PracticeModeButton from './LandingMobileComponents/PracticeModeButton'
import LandingFooter from './LandingFooter';
import IsAccess from '../GlobalFunctions/PracticeMode/OnBoard'
import CreateFreeAccount from '../GlobalFunctions/PracticeMode/CreateFreeAccount';
import LoaderScreen from './LoaderScreen';
import Analytics from '../GlobalFunctions/Analytics';

const LandingMobile = () => {
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null)


    if(IsAccessData === null){
        IsAccess(function (AccessValue){
            IsAccessDataUpdate(AccessValue)

            if(AccessValue === false){
                CreateFreeAccount(function (Value){
                    if(Value === "Success"){
                        Analytics("User-Account-Created")
                        IsAccessDataUpdate(true)
                    } else {
                        Analytics("User Account Creation Failed")
                        IsAccessDataUpdate(null)
                    }
                })
            }
        })
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const orientation = useOrientationChange()
    const currentSize = SizeInfo();

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const pageVariants = {
        initial: {
            opacity: 0.2,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0.5,
        },
        }


    if(orientation === "landscape"){
        return (<div>

        </div>)
    } else {
          
        return (<div className="BlurIt" style={{
                "position" : "fixed",
                "width" : `${percentage(Dimensions.width , 100)}px`,
                "height" : `${percentage(Dimensions.height , 100)}px`,
            }}>

                { IsAccessData === null && < LoaderScreen /> }

                { IsAccessData === false &&
                     <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                    >
                        <div style={{
                            "position" : "absolute",
                            "top" : "35%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                            "textAlign" : "center",
                            "width" : `${percentage(Dimensions.width , 100)}px`,
                        }}>
                        
                        <div style={{
                            "color" : "#00f9ff"
                        }} className="pulsate-fwd">CREATING YOUR ACCOUNT</div>

                    </div>

                    </motion.div>
                }

                {  IsAccessData === true &&
                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                    >
                
                        < PracticeModeButton SizeData = { Dimensions } />   

                    </motion.div>
                }

                

            < LandingFooter />

            
            
        </div>)
    }
}

export default LandingMobile