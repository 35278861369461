import React , { useState } from 'react';
import Avatar1 from '../../../../../Icons/iTap.jfif';

const Section = (Value) => {
        const BlockSize = Value.BlockSize

        const BlockShadowColor = "#00f9ff";
        const [ BlockShadowWidth ] = useState(2);

        function percentage(num, per)
        {
                return (num/100)*per;
        }

        const width = BlockSize
        const AvatarSize = 80;
        

        return (<div style={{
                "height" : `${percentage(width , AvatarSize+1.5)}px`,
                "width" : `${percentage(width , AvatarSize+1.5)}px`,
                "textAlign" : "center",
                "borderWidth" : `${percentage(width , 0.35)}px`,
                "position" : "absolute",
                "top" : "-10%",
                "left" : "50%",
                "transform" : "translate(-50%, 20%)",
                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "borderRadius" : "50%"
        }}>
                <div style={{"height" : `${percentage(width , 0.5)}px`,}}></div>

                < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        "borderRadius" : "50%"
                        }}
                        src={ Avatar1 } />                

        </div>)
}

export default Section