import React , {useState} from 'react';
import useResize from 'use-resize';
import BluffHideCards from './BluffHideCards';
import ShowCards from './ShowCards';
import { motion }  from "framer-motion";
import TableHeightCalculator from "../TableHeightCalculator";
import SoundTypeOne from '../../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';

const Section = (Value) => {
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    const SoundStatus = Value.Music ; 
    
    const currentSize = SizeInfo();
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const TableHeight = TableHeightCalculator();


    
    const isTable = Value.isTable ;
    const TableData = Value.TableData ;
    const PlayerValue = Value.PlayerValue ;
    const PlayerTotalCards = Value.PlayerValue.TotalCards;
    const Winner = TableData.Winner ;

    const [ SelectBluff , SelectBluffUpdate ] = useState(false);

    const [ BluffCancelColor , BluffCancelColorUpdate ] = useState({
        "backgroundColor" : "rgba(0,0,0,0.5)",
        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
        "width" : `${percentage(currentSize.width , 75/3)}px`,
        "margin" : "0 auto",
        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
        "borderStyle" : "solid",
        "borderColor" : "#00d6ff",
        "color" : "#00d6ff",
        "textAlign" : "center"
    })

    const [ SelectBluffColor , SelectBluffColorUpdate ] = useState({
        "backgroundColor" : "rgba(0,0,0,0.5)",
        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
        "width" : `${percentage(currentSize.width , 75/3)}px`,
        "margin" : "0 auto",
        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
        "borderStyle" : "solid",
        "borderColor" : "#00d6ff",
        "color" : "#00d6ff",
        "textAlign" : "center"
    })
    
    const CancelStart = () => {
        BluffCancelColorUpdate({
            "backgroundColor" : "rgba(0,0,0,0.9)",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const CancelCancel = () => {
        BluffCancelColorUpdate({
            "backgroundColor" : "rgba(0,0,0,0.5)",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const CancelDone = () => {
        BluffCancelColorUpdate({
            "backgroundColor" : "rgba(0,0,0,0.5)",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
        if(SoundStatus === true){
            playOne()
        }   
        SelectBluffUpdate(false)
    }

    const SelectBluffStart = () => {
        SelectBluffColorUpdate({
            "backgroundColor" : "rgba(0,0,0,0.9)",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const SelectBluffCancel = () => {
        SelectBluffColorUpdate({
            "backgroundColor" : "rgba(0,0,0,0.5)",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const SelectBluffDone = () => {
        SelectBluffColorUpdate({
            "backgroundColor" : "rgba(0,0,0,0.5)",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })

        if(SoundStatus === true){
            playOne()
        }   

        SelectBluffUpdate(true) ;
    }


    return (<div style={{
        "height" : `${TableHeight}px`,
        "width" : `${percentage(currentSize.width , 75)}px`,
        "position" : "absolute",
        "zIndex" : "21",
        "top" : "50%",
        "left": "50%",
        "transform" : "translate(-50%, -50%)",
    }}>        

        { SelectBluff === true && 
            isTable === true && 
            TableData !== null && 
            PlayerValue !== null && 
            <div>

            <motion.div initial={{left : "0%", bottom : "0%" }}
                style={{
                    "position" : "absolute",
                    "backgroundColor" : "black",
                    "bottom" : "0",
                    "zIndex" : "60",
                    "height" : `${percentage(currentSize.width , 45)}px`,
                    "width" : `${percentage(currentSize.width , 75)}px`,
                }}>
                < ShowCards GameData = { TableData } />

                <motion.div 
                style={{
                    "position" : "absolute",
                    "width" : `${percentage(currentSize.width , 75)}px`,
                    "zIndex" : "60",
                    "bottom" : "-18%"
                }}>
                        <motion.div 
                        onTapStart={ CancelStart }
                        onTapCancel={ CancelCancel }
                        onTap={ CancelDone }
                        className="BlurIt" style={BluffCancelColor}>
                            <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                            <div>CANCEL</div>
                            <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>                                
                        </motion.div>
                    </motion.div>
                </motion.div>

            </div>

        }

        { SelectBluff === false && 
            isTable === true && 
            TableData !== null && 
            PlayerValue !== null && 
            PlayerTotalCards > 0 &&
                    <div style={{
                        "position" : "absolute",
                        "bottom" : "0%",
                        "left" : "0%"
                    }}>

                        <motion.div initial={{x: "-40%" , y: "25%" , scale : 1}}>
                            < BluffHideCards GameData = { TableData } />
                        </motion.div>

                        <motion.div initial={{x: "-39%", y: "0%" }}
                                onTapStart={ SelectBluffStart }
                                onTapCancel={ SelectBluffCancel }
                                onTap={ SelectBluffDone }
                                className="BlurIt" style={SelectBluffColor}>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                    <div>PLAY BLUFF</div>
                                    <div style={{
                                        "fontSize" : `${percentage(currentSize.width , 1.75)}px`,
                                    }}>CLICK TO MAXIMIZE</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>                                
                        </motion.div>
                </div>
            }


        { SelectBluff === false && 
            isTable === true && 
            TableData !== null && 
            PlayerValue !== null && 
            PlayerTotalCards === 0 &&
            Winner === false &&
                <div>
                    <motion.div 
                        className="BlurIt"
                        style={{
                            "position" : "absolute",
                            "bottom" : "0",
                            "left" : "-13%",
                            "width" : `${percentage(currentSize.width , 75/2.5)}px`,
                            "zIndex" : "51",
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "borderRadius" : `${percentage(currentSize.width , 1.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderStyle" : "solid" ,
                            "color" : "#00d6ff",
                            "borderColor" : "#00d6ff",
                            "textAlign" : "center",
                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`
                        }}>
                            <motion.div>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                <div>YOU HAVE PLAYED</div>
                                <div>ALL YOUR CARDS</div>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                <div>AWAITING MATCH RESULT</div>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                            </motion.div>
                    </motion.div>
                </div>
            }


           
    </div>)
}

export default Section