import { motion } from 'framer-motion';
import React , { useState } from 'react';
import useResize from 'use-resize'; 
import OnNoTurnCardControl from './CardControlElements/OnNoTurnCardControl';
import OnTurnCardControl from './CardControlElements/OnTurnCardControl';
import TableHeightCalculator from './TableHeightCalculator';
import PlayAgain from '../../../../Icons/PlayAgain.png';
import ExitGame from '../../../../Icons/ExitGame.png';
import socket from '../../../../SocketConnectionManager';
import Config from '../../../../GlobalFunctions/Config' ;
import EventScore from './SoundAssets/HandWon';
import AgnosticEnd from '../../../../GlobalFunctions/PracticeMode/AgnosticEnd'
import GetAgnosticAccessPoint from '../../../../GlobalFunctions/PracticeMode/GetAgnosticAccessPoint'

const EmitSignal =  "LeaveGameNow-Bluff";

const PlayerControls = (Value) => {
    const isAgnostic = Value.isAgnostic ;
    const [ MusicOn , MusicOnUpdate ] = useState(Config.DefaultBluffSound);
    const [ AgnosticUpdater , AgnosticUpdaterUpdate ] = useState(null)

    if(Config.DefaultBluffSound === null){
        Config.DefaultBluffSound = true
        MusicOnUpdate(true)
    }

    socket.on('onStopMusic' , function (){
        MusicOnUpdate(false)
    })

    socket.on('onStartMusic' , function (){
        MusicOnUpdate(true)
    })

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const PlayerData = (GameData) => {
        if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const isTable = true;
    const TableData = Value.GameData;
    const PlayerValue  = PlayerData(Value.GameData);
    const TableHeight = TableHeightCalculator();

    if(AgnosticUpdater === null && isAgnostic === true && TableData.Winner === true){
        AgnosticUpdaterUpdate(false)
        AgnosticEnd(TableData, function (Data){

        })
    }

    const ExitFunction = () => {
        socket.emit(EmitSignal)
    }

    const PlayAgainFunction = () => {
        window.location.replace('/freetoplay/matchmaker_playagain')
    }

    const PlayAgainAgnosticFunction = () => {
        GetAgnosticAccessPoint("bluff" , function (Data){
            Config.AgnosticAccessPoint = Data
            window.location.replace(Config.AgnosticAccessPoint)
        })
    }

    const UpdateAgnostic = () => {
        

        return (<div></div>)
    }

    if(TableData !== null && TableData.Winner !== true){
        return (
            <div style={{
                "width" : `${percentage(currentSize.width , 75)}px`,
                "position" : "absolute",
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
                "zIndex" : "21",
            }}>
        
                    <div style={{
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "position" : "absolute",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
        
                        {  isTable === true && 
                                TableData !== null && 
                                    PlayerValue !== null && 
                                    PlayerValue.Turn !== true &&
                                    PlayerValue.isChallenger === false && 
                                    PlayerValue.isBluffer === false &&
                                < OnNoTurnCardControl isTable={ isTable } TableData = { TableData } PlayerValue = { PlayerValue } Music = { MusicOn } />
                        }
        
                        {  isTable === true && 
                                TableData !== null && 
                                    PlayerValue !== null && 
                                    PlayerValue.Turn !== true &&
                                ( PlayerValue.isChallenger === true || 
                                    PlayerValue.isBluffer === true ) &&
                                    PlayerValue.ChallengeWinner !== null &&
                                < OnNoTurnCardControl isTable={ isTable } TableData = { TableData } PlayerValue = { PlayerValue } Music = { MusicOn } />
                        }
        
        
                        {  isTable === true && 
                                TableData !== null && 
                                    PlayerValue !== null && 
                                    PlayerValue.Turn !== true &&
                                ( PlayerValue.isChallenger === true || 
                                    PlayerValue.isBluffer === true ) &&
                                    PlayerValue.ChallengeWinner === null &&
                                < OnNoTurnCardControl isTable={ isTable } TableData = { TableData } PlayerValue = { PlayerValue } Music = { MusicOn }/>
                        }
        
                        {  isTable === true && 
                                TableData !== null && 
                                    PlayerValue !== null && 
                                    PlayerValue.Turn !== true &&
                                    PlayerValue.isChallenger === null && 
                                    PlayerValue.isBluffer === false  &&
                                    PlayerValue.ChallengeWinner === null &&
                                < OnNoTurnCardControl isTable={ isTable } TableData = { TableData } PlayerValue = { PlayerValue } Music = { MusicOn } />
                        }
        
                        {  isTable === true && 
                                TableData !== null && 
                                    PlayerValue !== null && 
                                    PlayerValue.Turn !== true &&
                                    PlayerValue.isChallenger === null && 
                                    PlayerValue.isBluffer === null  &&
                                    PlayerValue.ChallengeWinner === null &&
                                < OnNoTurnCardControl isTable={ isTable } TableData = { TableData } PlayerValue = { PlayerValue } Music = { MusicOn } />
                        }
        
                        {  isTable === true && 
                                TableData !== null && 
                                    PlayerValue !== null && 
                                    PlayerValue.Turn === true &&
                                < OnTurnCardControl isTable={ isTable } TableData = { TableData } PlayerValue = { PlayerValue } Music = { MusicOn } />
                        }
        
                </div>
        
            </div>
        )
    } else if(TableData.Winner === true && isAgnostic === false){
        return(<motion.div>
                            <motion.div 
                                    initial={{ opacity : 0 , scale : 0}}
                                    animate={{ opacity : 1 , scale : 1}}
                                    transition={{ delay : 2 }}                                    
                                    style={{
                                        "position" : "absolute",
                                        "top" : `${percentage(TableHeight , 70)}px`,
                                        "width" : `${percentage(currentSize.width , 75)}px`,
                                        "textAlign" : "center",
                                        "left": "12.75%",
                                        "transform" : "translate(-50%, -50%)",
                                        "fontFamily" : "CardText",
                                        "color" : "grey",
                                        "fontSize" : `${percentage(currentSize.width , 10)}px`,
                                        "zIndex" : "100",
                                    }}>
                                        <motion.div style={{
                                            "display" : "flex",
                                            "justifyContent" : "center"
                                        }}>

                                            <motion.div 
                                            onTapStart={ ExitFunction }
                                            style={{
                                                "backgroundColor" : "rgba(0,0,0,0.7)",
                                                "width" : `${percentage(currentSize.width , 35)}px`,
                                                'height' : `${percentage(currentSize.width , 15)}px`,
                                                "textAlign" : "center",                                            
                                                "borderRadius" : "15%",
                                                "borderStyle" : "solid",
                                                "borderColor" : "black",
                                                "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                            }}>
                                                <div style={{
                                                    "height" : `${percentage(currentSize.width , 2.5)}px`,
                                                }}></div>
                                                < img alt="Avatar" 
                                                    style={{
                                                    'height' : `${percentage(currentSize.width , 10)}px`,
                                                    "width" : "auto",
                                                    }}
                                                src={ ExitGame } />
                                            </motion.div>

                                            <motion.div style={{
                                                "width" : `${percentage(currentSize.width , 3)}px`,
                                            }}></motion.div>

                                            <motion.div 
                                            onTapStart={ PlayAgainFunction }
                                            style={{
                                                "backgroundColor" : "rgba(0,0,0,0.7)",
                                                "width" : `${percentage(currentSize.width , 35)}px`,
                                                'height' : `${percentage(currentSize.width , 15)}px`,
                                                "textAlign" : "center",
                                                "borderRadius" : "15%",
                                                "borderStyle" : "solid",
                                                "borderColor" : "black",
                                                "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                            }}>
                                                <div style={{
                                                    "height" : `${percentage(currentSize.width , 2.5)}px`,
                                                }}></div>
                                                < img alt="Avatar" 
                                                    style={{
                                                    'height' : `${percentage(currentSize.width , 10)}px`,
                                                    "width" : "auto",
                                                    }}
                                                src={ PlayAgain } />
                                            </motion.div>

                                        </motion.div>
                                        
                                        { MusicOn === true && < EventScore Status={ true } /> }     

                                 </motion.div>
        </motion.div>)
    } else if(TableData.Winner === true && isAgnostic === true){
        return(<motion.div>
            <motion.div 
                    initial={{ opacity : 0 , scale : 0}}
                    animate={{ opacity : 1 , scale : 1}}
                    transition={{ delay : 2 }}                                    
                    style={{
                        "position" : "absolute",
                        "top" : `${percentage(TableHeight , 70)}px`,
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "textAlign" : "center",
                        "left": "12.75%",
                        "transform" : "translate(-50%, -50%)",
                        "fontFamily" : "CardText",
                        "color" : "grey",
                        "fontSize" : `${percentage(currentSize.width , 10)}px`,
                        "zIndex" : "100",
                    }}>

                        { < UpdateAgnostic /> }
                        <motion.div style={{
                            "display" : "flex",
                            "justifyContent" : "center"
                        }}>

                            <motion.div 
                            onTapStart={ PlayAgainAgnosticFunction }
                            style={{
                                "backgroundColor" : "rgba(0,0,0,0.7)",
                                "width" : `${percentage(currentSize.width , 35)}px`,
                                'height' : `${percentage(currentSize.width , 15)}px`,
                                "textAlign" : "center",
                                "borderRadius" : "15%",
                                "borderStyle" : "solid",
                                "borderColor" : "black",
                                "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                            }}>
                                <div style={{
                                    "height" : `${percentage(currentSize.width , 2.5)}px`,
                                }}></div>
                                < img alt="Avatar" 
                                    style={{
                                    'height' : `${percentage(currentSize.width , 10)}px`,
                                    "width" : "auto",
                                    }}
                                src={ PlayAgain } />
                            </motion.div>

                        </motion.div>
                        
                        { MusicOn === true && < EventScore Status={ true } /> }     

                    </motion.div>
        </motion.div>)
    } else {
        return(<div>

        </div>)
    }
    
    
}

export default PlayerControls