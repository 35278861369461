import React , { useState } from 'react';
import { isMobileOnly } from "react-device-detect";
import ContentMobile from './ContentMobile';
import socket from './SocketConnectionManager';
import DisconnectionScreen from './DisconnectionScreen';
import ConnectionFailure from './ConnectionFailure';
import ChangeOrientation from './ChangeOrientation';
import useSetInterval from 'use-set-interval';
import useOrientationChange from "use-orientation-change";
import { useLocation} from 'react-router-dom';
import Config from './GlobalFunctions/Config';

const Body = () => {
    const location = useLocation();
    const orientation = useOrientationChange()
    const [ OrientationState , OrientationStateUpdate ] = useState("NORMAL");
    const [ ConnectionState , ConnectionStateUpdate ] = useState(null);
    const [ BuildDeployed , BuildDeployedUpdate ] = useState(null)
    const CurrentBuildVersion = Config.app_version ;
    
    socket.on('disconnect' , function (){
        ConnectionStateUpdate(false)
    })

    socket.on('connect' , function (){
        ConnectionStateUpdate(true)
        socket.emit("HealthCheck")
    })

    socket.on('GoodHeath' , function(){
        ConnectionStateUpdate(true)
    })

    useSetInterval(()=>{ 
        if(ConnectionState === true){
            socket.emit("HealthCheck")
        }
    }, 3000)

    const OrientationChangeDetected = (Value) => {
        OrientationStateUpdate(Value)
    }
    
    const GetStateOfOrientation = () => {
        if(OrientationState === "NORMAL"){
            return ("")
        } else if(OrientationState === "YUP"){
            if(orientation === "landscape"){                
                return ("")
            }else if(orientation === "portrait"){                
                window.location.reload()
                return ("")
            }
        }
    }

    if(BuildDeployed === null){
        BuildDeployedUpdate(true)
        if(location.pathname === "/"){
//            console.log("Home Page Identified")
            if(location.search === "?" + CurrentBuildVersion){
//                console.log("Latest Build Deployed")
            } else {
                window.location.replace("/?" +  CurrentBuildVersion)
            }
        }
    }
    


    if(isMobileOnly === true){
        return (
            <div>
                    { orientation === "landscape" && < ChangeOrientation CallBackValue={ OrientationChangeDetected } />}

                    
                    { orientation === "portrait" &&
                        <div style={{
                            "fontFamily" : "Custom",
                        }}>                            
                            { ConnectionState === false &&                  
                                < DisconnectionScreen />
                            }

                            { ConnectionState === null &&                  
                                < ConnectionFailure />
                            }

                            <div style={{
                            }}>{GetStateOfOrientation()}</div>

                            < ContentMobile />
                        </div>                            
                    }
                        
            </div>
        )
    } else {
        return (
            <div style={{
                "position" : "absolute",
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
                "textAlign" : "center"
            }}>
                <div>JUVIA GAMES</div>
                <div>IS NOT SUPPORTED ON</div>
                <div>DESKTOPS AND TABLETS</div>

                <div style={{
                    "marginTop" : "5%"
                }}>
                    ACCESS juvia.games FROM YOUR MOBILE BROWSER
                </div>
            </div>)
    }

    
}

export default Body