import React from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import TableHeightCalculator from "./TableHeightCalculator";
import CurrentBluff from "./CurrentBluff";

const Mover = (Value) => {
    const GameData = Value.GameData ;
    const currentSize = SizeInfo();

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }


    const SeatOneValues = PlayerData() ;
    const SeatTwoValues = PlayerTwo() ;
    const SeatThreeValues = PlayerThree() ;
    const SeatFourValues = PlayerFour() ;

    const TableHeight = TableHeightCalculator();

    const SmallTable = percentage(currentSize.width , 120)
    const MediumTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 20)
    const LongTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 45)
    const VeryLongTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 55)

    const Animater = () => {
        const Seat = PlayerTurn()

        if(TableHeight === MediumTable){
            if(Seat === 1){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "68%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 2){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "26%",
                        "left" : "4%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 3){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "4%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 4){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "26%",
                        "left" : "76%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            }    
        }

        if(TableHeight === SmallTable){
            if(Seat === 1){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "68%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 2){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "25%",
                        "left" : "4%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 3){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "2%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 4){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "25%",
                        "left" : "76%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            }    
        }

        if(TableHeight === LongTable){
            if(Seat === 1){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "73%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 2){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "26%",
                        "left" : "4%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 3){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "3%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 4){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "26%",
                        "left" : "76%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            }    
        }

        if(TableHeight === VeryLongTable){
            if(Seat === 1){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "75%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 2){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "27%",
                        "left" : "4%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 3){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "4%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 4){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "27%",
                        "left" : "76%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            }    
        }
        

        return ({
            scale : 0,
            opacity : 0,
            left : percentage(currentSize.width , 40),
            top : "30%"
        })
        
    }

    const PlayerTurn = () => {
        if(GameData === null){
            return null
        } else if(SeatOneValues.isBluffer ===  true){
            return 1
        } else if(SeatTwoValues.isBluffer === true){
            return 2
        } else if(SeatThreeValues.isBluffer === true){
            return 3
        } else if(SeatFourValues.isBluffer === true){
            return 4
        }
    }

    const ClaimPosition = () => {
        const SeatValue = PlayerTurn ()
        
        if(SeatValue === null){
            return null
        } else if(SeatValue === 1 || SeatValue === 2 || SeatValue === 3){
            if(TableHeight === SmallTable){
                return ({
                    x : 100,
                    y : 30,
                })
            } else if(TableHeight === MediumTable){
                return ({
                    x : 100,
                    y : 30,
                })
            } else if(TableHeight === LongTable){
                return ({
                    x : 100,
                    y : 35,
                })
            } else if(TableHeight === VeryLongTable){
                return ({
                    x : 100,
                    y : 30,
                })
            }            
        } else if(SeatValue === 4){
            if(TableHeight === SmallTable){
                return ({
                    x : -100,
                    y : 30,
                })
            } else if(TableHeight === MediumTable){
                return ({
                    x : -100,
                    y : 30,
                })
            } else if(TableHeight === LongTable){
                return ({
                    x : -100,
                    y : 35,
                })
            } else if(TableHeight === VeryLongTable){
                return ({
                    x : -100,
                    y : 30,
                })
            }
        }
        
        
    }
    
    
    return (
        <motion.div style={{
            "position" : "absolute",
            "zIndex" : "20",
        }}
        animate={Animater()}
        transition={{ duration : 0.5 }}
        >
            <div
                style={{                
                "width" : `${percentage(currentSize.width , 20)}px`,
                "height" : `${percentage(currentSize.width , 20/2.5)}px`,
                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.7) ,rgba(0,0,0,0.9), rgba(0,0,0,0.9))",
                "borderBottomStyle" : "solid",
                "borderTopStyle" : "solid",
                "borderColor" : "#00d6ff",
                "color" : "#00d6ff",
                "borderTopWidth" : `${percentage(currentSize.width , 0.5)}px`,
                "borderBottomWidth" : `${percentage(currentSize.width , 1)}px`,
                "textAlign" : "center",
                "borderRadius" : "50%",
                "boxShadow" : `0 0 ${percentage(currentSize.width , 2)}px black`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 2)}px black`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 2)}px black`,
            }}>

                <div style={{
                    "height" : `${percentage(currentSize.width , 20/8.5)}px`,
                }}></div>
                
                <div style={{
                    "width" : `${percentage(currentSize.width , 20)}px`,                    
                    "textALign" : "center",
                    "fontSize" : `${percentage(currentSize.width , 3)}px`,                                        
                }}>
                    BLUFFER
                </div>
            </div>

            { GameData.CurrentBluff !== null &&
                <motion.div animate={ ClaimPosition() }>
                    < CurrentBluff GameData = {GameData} />
                </motion.div>
            }
            
        </motion.div>
    )
    
}

            

export default Mover