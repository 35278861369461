import React , { useState } from 'react';
import socket from '../../../../../SocketConnectionManager';
import PlayingCardCreator from '../PlayingCardCreator';
import useResize from 'use-resize'; 
import TimeValue from './BidTimerValue';
import { motion }  from "framer-motion";
import SoundTypeOne from '../../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';

const Section = (Value) => {
    const [ CurrentBid , CurrentBidUpdate ] = useState(1);
    const [ SubmitBid , SubmitBidUpdate ] = useState(null);
    const ShadowColor = "#00f9ff";
    const OverlayShadowWidth = 10;
    const ShadowWidth = 10;
    const SoundStatus = Value.SoundStatus
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableData = Value.GameData;

    const Seat = TableData.MySeat;

    const AllSpades = TableData.MyCards.Spades ;
    const AllHearts = TableData.MyCards.Hearts ;
    const AllDiamonds = TableData.MyCards.Diamonds ;
    const AllClubs = TableData.MyCards.Clubs ;

    const BidFinder = () => {
        if(Seat === 1 && TableData.Bids.Player1 !== null){
            return TableData.Bids.Player1
        } else if(Seat === 2 && TableData.Bids.Player2 !== null){
            return TableData.Bids.Player2
        } else if(Seat === 3 && TableData.Bids.Player3 !== null){
            return TableData.Bids.Player3
        } else if(Seat === 4 && TableData.Bids.Player4 !== null){
            return TableData.Bids.Player4
        } else {
            return 0
        }
    }
    
    if(BidFinder() !== 0 && SubmitBid === null){
        SubmitBidUpdate(true)
        CurrentBidUpdate(BidFinder())
    }

    
    AllSpades.sort(( a, b) => ( a.Ranking > b.Ranking) ? -1 : 1)
    AllHearts.sort(( a, b) => ( a.Ranking > b.Ranking) ? -1 : 1)
    AllDiamonds.sort(( a, b) => ( a.Ranking > b.Ranking) ? -1 : 1)
    AllClubs.sort(( a, b) => ( a.Ranking > b.Ranking) ? -1 : 1)

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const ShadowProperty = `0 0 ${percentage(currentSize.width , OverlayShadowWidth)}px  ${ShadowColor}`;

    
    const BidSubmission = () => {
        SubmitBidUpdate(true)
        socket.emit('UserBidSubmit-CB', CurrentBid)
    }

    const BidMaker = (BidValue) => {
        if(BidValue.BidNumber === CurrentBid ){
            return (
                <motion.div                
                style={{
                    "height" : `${percentage(currentSize.width , 12)}px`,
                    "width" : `${percentage(currentSize.width , 12)}px`,
                    "borderRadius" : "50%",
                    "fontSize" : `${percentage(currentSize.width , 9)}px`,                                     
                    "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",
                    "textAlign" : "center",
                    "boxShadow" : ShadowProperty,
                    "WebkitBoxShadow" : ShadowProperty,
                    "MozBoxShadow" : ShadowProperty,
                    "borderStyle" : "solid",
                    "color" : "yellow",
                    "borderColor" : "yellow",
                    "borderWidth" : `${percentage(currentSize.width , 1.15)}px`,
                }}>
                    <div>{ BidValue.BidNumber }</div>
                </motion.div>
            )
        } else {
            return (
                <motion.div
                onTapStart={() => {
                    if(SubmitBid !== true){
                        if(SoundStatus === true){
                            playOne();
                        }
                        CurrentBidUpdate(BidValue.BidNumber) 
                    }                    
                }}
                style={{
                    "height" : `${percentage(currentSize.width , 12)}px`,
                    "width" : `${percentage(currentSize.width , 12)}px`,
                    "fontSize" : `${percentage(currentSize.width , 9)}px`,
                    "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",
                    "textAlign" : "center",
                    "boxShadow" : ShadowProperty,
                    "WebkitBoxShadow" : ShadowProperty,
                    "MozBoxShadow" : ShadowProperty,
                    "borderRadius" : "50%",
                    "borderStyle" : "solid",
                    "borderColor" : "black",
                    "borderWidth" : `${percentage(currentSize.width , 1.15)}px`,
                }}>
                    <div>{ BidValue.BidNumber }</div>
                </motion.div>
            )
        }
    }

    const TimerOver = (Value) => {
        if(Value() === "JABBU"){
            SubmitBidUpdate(true)
        }
    }

    return (
        <motion.div             
            initial={{ "bottom" : "50%" , opacity : 1}}
            animate={{ "bottom" : "65%" , opacity : 1}}
            style={{
                "position" : "absolute",
                "zIndex" : "25",
                "borderRadius" : "10%",
                "borderColor" : "black",
                "borderStyle" : "solid",
                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",
                "width" : `${percentage(currentSize.width , 95)}px`,
                "height" : `${percentage(currentSize.width , 30)}px`,
                "marginLeft" : `${percentage(currentSize.width , 1.5)}px`,
                "borderWidth" : `${percentage(currentSize.width , 1.15)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
            }}>

                
                <div style={{
                    "height" : `${percentage(currentSize.width , 3)}px`,
                }}></div>

                <div style={{
                    "display" : "flex",
                    "flexDirection" : "row",
                    "flexWrap" : "wrap",
                    "justifyContent" : "center",
                    "margin" : "0 auto",
                    "width" : `${percentage(currentSize.width , 95)}px`,
                }}>
                {AllSpades.map((Card) => 
                    <motion.div style={{
                        "height" : `${percentage(currentSize.width , 11)}px`,
                        "width" : `${percentage(currentSize.width , 12)}px`,
                        "marginBottom" : `${percentage(currentSize.width , 2)}px`,
                    }}
                    key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}

                {AllHearts.map((Card) => 
                    <motion.div style={{
                        "height" : `${percentage(currentSize.width , 11)}px`,
                        "width" : `${percentage(currentSize.width , 12)}px`,
                        "marginBottom" : `${percentage(currentSize.width , 2)}px`,
                    }}
                    key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}

                {AllClubs.map((Card) => 
                    <motion.div style={{
                        "height" : `${percentage(currentSize.width , 11)}px`,
                        "width" : `${percentage(currentSize.width , 12)}px`,
                        "marginBottom" : `${percentage(currentSize.width , 2)}px`,
                    }}
                    key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}

                {AllDiamonds.map((Card) => 
                    <motion.div style={{
                        "height" : `${percentage(currentSize.width , 11)}px`,
                        "width" : `${percentage(currentSize.width , 12)}px`,
                        "marginBottom" : `${percentage(currentSize.width , 2)}px`,
                    }}
                    key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}
                </div>

                <div style={{
                    "height" : `${percentage(currentSize.width , 3)}px`,
                }}></div>

        
                <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                <motion.div 
                style={{
                    "textAlign" : "center",
                    "color" : "yellow",
                    "fontSize" : `${percentage(currentSize.width , 4)}px`,
                }}>
                    SELECT YOUR BID
                </motion.div>

                <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>

                <motion.div 
                style={{
                    "textAlign" : "center",
                    "color" : "yellow",
                    "fontSize" : `${percentage(currentSize.width , 3)}px`,
                }}>
                    MINIMUM NUMBER OF HANDS THAT YOU'LL WIN
                </motion.div>

                <div style={{"height" : `${percentage(currentSize.width , 3)}px`,}}></div>


                <div style={{
                    "display" : "flex",
                    "height" : `${percentage(currentSize.width , 15)}px`,
                    "justifyContent" : "center"
                }}>
                    < BidMaker BidNumber = { 1 } />

                    <div style={{"width" : `${percentage(currentSize.width , 3)}px`,}}></div>

                    < BidMaker BidNumber = { 2 } />

                    <div style={{"width" : `${percentage(currentSize.width , 3)}px`,}}></div>

                    < BidMaker BidNumber = { 3 } />

                    <div style={{"width" : `${percentage(currentSize.width , 3)}px`,}}></div>

                    < BidMaker BidNumber = { 4 } />
                </div>

                <div style={{"height" : `${percentage(currentSize.width , 3)}px`,}}></div>

                <div style={{
                    "display" : "flex",
                    "height" : `${percentage(currentSize.width , 15)}px`,
                    "justifyContent" : "center",
                }}>
                    < BidMaker BidNumber = { 5 } />

                    <div style={{"width" : `${percentage(currentSize.width , 3)}px`,}}></div>

                    < BidMaker BidNumber = { 6 } />

                    <div style={{"width" : `${percentage(currentSize.width , 3)}px`,}}></div>

                    < BidMaker BidNumber = { 7 } />

                    <div style={{"width" : `${percentage(currentSize.width , 3)}px`,}}></div>

                    < BidMaker BidNumber = { 8 } />
                </div>

                <div style={{"height" : `${percentage(currentSize.width , 3)}px`,}}></div>

            
                { SubmitBid === null &&
                    <motion.div 
                    onTapStart={() => {
                        if(SoundStatus === true){
                            playOne();
                        }
                        BidSubmission() 
                    }}
                        className="BlurIt" style={{
                            "backgroundColor" : "black",
                            "fontSize" : `${percentage(currentSize.width , 3.75)}px`,
                            "width" : `${percentage(currentSize.width , 40)}px`,
                            "margin" : "0 auto",
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.85)}px`,
                            "color" : "yellow",
                            "borderStyle" : "solid",
                            "borderColor" : "#00d6ff",
                            "textAlign" : "center",
                        }}>
                            <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                            <div>CONFIRM BID</div>
                            <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>                           
                    </motion.div>
                }

                { SubmitBid === true &&
                    <motion.div 
                        className="BlurIt" style={{
                            "backgroundColor" : "black",
                            "fontSize" : `${percentage(currentSize.width , 3.75)}px`,
                            "width" : `${percentage(currentSize.width , 60)}px`,
                            "margin" : "0 auto",
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.85)}px`,
                            "color" : "yellow",
                            "borderStyle" : "solid",
                            "borderColor" : "#00d6ff",
                            "textAlign" : "center",
                        }}>
                            <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                            <div className="pulsate-fwd">FINALIZING BID ... </div>
                            <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>                           
                    </motion.div>
                }
            
                <div style={{"height" : `${percentage(currentSize.width , 6)}px`,}}></div>
            
                <motion.div 
                style={{
                    "textAlign" : "center",
                    "color" : "yellow",
                    "fontSize" : `${percentage(currentSize.width , 3)}px`,
                    "display" : "flex",
                    "justifyContent" : "center"
                }}>
                    < TimeValue IsOver={ TimerOver } TimerData={TableData.TurnTimer}/>
                </motion.div>

                <div style={{"height" : `${percentage(currentSize.width , 3)}px`,}}></div>


        </motion.div>)
}

export default Section