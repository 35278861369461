import React from 'react';
import ChangeModeToggle from './ChangeModeToggle'
import GameLogo from '../../Icons/BrandLogo.png'
import HomeIcon from '../../Icons/Home.png'
import Config from '../../GlobalFunctions/Config';

const Section = (Value) => {
   
    const Size = Value.SizeData ;
    const CurrentBuildVersion = Config.app_version ;
    

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const width = Size.width ;
    
    return (<div>
        <div style = {{     
        }}>
            <div className="BlurIt" style={{
                "width" : `${percentage(width , 100)}px`,
                'height' : `${percentage(width , 20)}px`,
                "backgroundColor" : "rgba(0,0,0,0.1)",
                "borderTopStyle" : "solid",
                "borderBottomStyle" : "solid",
                "borderColor" : "black",
                "borderWidth" : `${percentage(width , 0.35)}px`,
            }}>
                
                    <div style={{'height' : `${percentage(width , 0.5)}px`,}}></div>

                    <div style={{
                        "display" : "flex",
                    }}>

                        <div onClick={()=> window.location.replace("/?" +  CurrentBuildVersion)} style={{
                            'height' : `${percentage(width , 15)}px`,
                            'width' : `${percentage(width , 15)}px`,
                            'marginLeft' : `${percentage(width , 1.75)}px`,
                            'marginTop' : `${percentage(width , 1.75)}px`,
                            "borderRadius" : `${percentage(width , 1.5)}px`,
                            "boxShadow" : `0 0 ${percentage(width , 1.5)}px grey`,
                            "WebkitBoxShadow" : `0 0 ${percentage(width , 1.5)}px grey`,
                            "MozBoxShadow" : `0 0 ${percentage(width , 1.5)}px grey`,
                            "borderWidth" : `${percentage(width , 0.25)}px`,
                        }}>
                            <div style={{
                                "position" : "relative",
                                "top" : "50%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",
                                "textAlign" : "center"
                            }}>
                            < img alt="Logo" 
                                style={{
                                'height' : `${percentage(width , 10)}px`,
                                'width' : `${percentage(width , 10)}px`,
                                }}
                                src={ HomeIcon } />
                            </div>
                            
                        </div>

                        <div style={{
                            "fontFamily" : "HighlightText",
                            "color" : "#00f9ff",
                        }}>
                            <div style={{'height' : `${percentage(width , 2)}px`,}}></div>
                            
                            < img alt="Logo" 
                                style={{
                                'height' : `${percentage(width , 6)}px`,
                                "marginLeft" : `${percentage(width , 2)}px`,
                                'width' : `auto`,
                                }}
                                src={ GameLogo } />

                            <div style={{
                                "fontSize" : `${percentage(width , 8)}px`,
                                "marginLeft" : `${percentage(width , 4)}px`,
                            }}>CALL BREAK</div>
                        </div>

                         
                         <div style={{
                             "position" : "absolute",
                             "height" :  `${percentage(width , 3)}px`,
                             "width" :  `${percentage(width , 30)}px`,
                             "right" : "5%",
                             "top" : "10%"
                         }}>
                                < ChangeModeToggle SizeData={Value.SizeData}/>
                        </div>
                             
                         </div>

                        
                              
            </div>
        </div>

    </div>)
    
}

export default Section