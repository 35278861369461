import React from 'react';
import useResize from 'use-resize';
import { motion } from "framer-motion";
import LandingFooter from './LandingFooter';

const LoaderScreen = () => {
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const currentSize = SizeInfo();

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    return (<div style={{
        "height" : `${currentSize.height}px`,
        "width": `${currentSize.width}px`,
    }} className="BlurIt" onClick={()=> {}}>
        <div style={{
            "position" : "absolute",
            "top" : `${percentage(Dimensions.height , 50) - percentage(Dimensions.width , 5)}px`,
            "left": "50%",
            "transform" : "translate(-50%, -50%)"
        }}>
            <motion.div
                initial={{opacity : 0}}
                animate={{opacity: 1}}
                exit={{opacity : 0}}
            >
                <div className="ping" style = {{
                    "textAlign" : "center",
                    "fontSize" : `${percentage(Dimensions.width , 10)}px`,
                    "fontFamily" : "HighlightText",
                    "color" : "#a9a9a9"
                }}>
                    LOADING ...
                </div>
            </motion.div>
        </div>

        < LandingFooter />
    </div>)
}

export default LoaderScreen