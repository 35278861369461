import React , { useState } from 'react';
import useResize from 'use-resize';
import useSound from 'use-sound';
import { motion } from 'framer-motion';
import SoundOnIcon from '../../../../Icons/MusicToggle.png'
import SoundOffIcon from '../../../../Icons/MusicToggle.png'
import MusicOne from '../../../../Sound/BackgroundScoreDiff.mp3';
import MusicTwo from '../../../../Sound/BackgroundScore.mp3';

const Section = (Values) => {
    const [ SoundSwitch , SoundSwitchUpdate ] = useState(true)   
    const [ Swap , SwapUpdate ] = useState(2)
    const [ playOne , { stop } ] = useSound(MusicOne, { volume : 0.2  , interrupt : false});
    const [ playTwo , { pause } ] = useSound(MusicTwo, { volume : 0.5  , interrupt : false});
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Toggle = () => {
        if(Swap === 1){
            SwapUpdate(2)
        } else {
            SwapUpdate(1)
        }
    }

    const SoundOn = () => {  
        Toggle()
        SoundSwitchUpdate(true)
    }

    const SoundOff = () => {
        if(Swap === 1){
            stop() 
        } else {
            pause()
        }
        SoundSwitchUpdate(false)
    }

    
    
        return (
            <div>
            { SoundSwitch === true && Swap === 1 && playOne() }            
            { SoundSwitch === true && Swap === 2 && playTwo() }   

            { SoundSwitch === true &&
                <motion.div 

                style={{
                }}>
                    <motion.div                         
                        animate = {{
                            opacity : Values.OpacityValue,
                            "top" : `2%`,
                            'left' : "65%",  
                        }}
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 1.75)}px`,
                            "margin" : "0 auto",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",                            
                            'position' : "absolute", 
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "top" : `2%`,
                            "color" : "#00f9ff",
                            'left' : "75%",         
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25",                            
                        }}>                        
                                { Swap === 1 && "COLD" } 
                                { Swap === 2 && "WARM" }            
                    </motion.div>

                    

                </motion.div>
            
            }

            { SoundSwitch === true &&
                <motion.div 

                style={{
                }}>
                    <motion.div 
                        onTapStart={ SoundOff }                        
                        animate = {{
                            opacity : Values.OpacityValue
                        }}
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",                            
                            'position' : "absolute", 
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "top" : `2%`,
                            'left' : "75%",                            
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25",                            
                        }}>                        
                            < img alt="Avatar" 
                                style={{
                                'height' : `${percentage(currentSize.width , 7.5)}px`,
                                "width" : "auto",
                                }}
                                src={ SoundOnIcon } />
                    </motion.div>

                    

                </motion.div>
            
            }

            

            { SoundSwitch === false &&
                <motion.div 

                style={{
                }}>
                    <motion.div 
                        onTapStart={ SoundOn }                        
                        animate = {{
                            opacity : Values.OpacityValue
                        }}
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",                            
                            'position' : "absolute", 
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "top" : `2%`,
                            'left' : "75%",                            
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25",                            
                        }}>    
                            <motion.div
                                animate={{ opacity : 0.1}}
                            >
                            < img alt="Avatar" 
                                style={{
                                'height' : `${percentage(currentSize.width , 7.5)}px`,
                                "width" : "auto",
                                }}
                                src={ SoundOffIcon} />
                            </motion.div>                                                
                    </motion.div>

                </motion.div>
                }
            </div>
    )

}

export default Section