import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import LaunchGame from '../../Icons/LaunchGame.png'


const Section = (Value) => {
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const Size = Value.SizeData ;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function onTapStart() {

    }

    function onTapCancel() {

    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }    

    const width = Size.width ;
    
    return (<div style={{
        "position": "absolute",
        "top" : "40%",
        "left" : "50%",
        "transform" : "translate(-50%, -50%)",
    }}>

        <div style = {{
            "fontSize" : `${percentage(width , 7)}px`,
            "fontFamily" : "HighlightText",
        }}>
            <div style={{
                "width" : `${percentage(width , 100)}px`,
                "textAlign" : "center",                
            }}>
                <motion.div 
                initial={{ scale : 0.5 , opacity : 0.5 }}
                animate={{ scale : 1 , opacity : 1}} 
                transition={{ duration : 0.4}}
                onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>
                    
                        < img alt="Avatar" 
                            style={{
                            'height' : `auto`,
                            'width' : `${percentage(width , 50)}px`,
                            "borderStyle" : "solid",
                            "borderWidth" : `${percentage(width , 1.75)}px`,
                            "borderRadius" : `${percentage(width , 4)}px`,
                            "borderColor" : "#00d6ff",                            
                            "boxShadow" : `0 0 ${percentage(width , 5)}px #00d6ff`,
                            "WebkitBoxShadow" : `0 0 ${percentage(width , 5)}px #00d6ff`,
                            "MozBoxShadow" : `0 0 ${percentage(width , 5)}px #00d6ff`,
                            }}
                            src={ LaunchGame } />  
                    
                </motion.div>                
            </div>
        </div>

        { RedirectProperty === true &&
            <Redirect to={"/uuidonboard"} />
        }

    </div>)
    
}

export default Section