import React , { useState } from 'react';
import useResize from 'use-resize';  
import socket from '../../../SocketConnectionManager';
import Config from '../../../GlobalFunctions/Config';
import AccessPassTimer from './AccessPassTimer';
import { motion } from "framer-motion";
import CloseMatchMaker from './CloseMatchMaker'
import setTimeout from 'use-set-timeout'
import { Redirect } from 'react-router';
import GameTableEngine from './GameTableEngine';
import SoundTypeOne from '../../../Sound/PassSound.mp3';
import useSound from 'use-sound';

const Section = (Value) => {
    const Agnostic = Value.AgnosticValue ;
    const [ Searching , SearchingUpdate ] = useState(null);
    const [ MatchMakerProcess , MatchMakerProcessUpdate ] = useState(null)
    const [ TableData , TableDataUpdate ] = useState(null);
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ playOne ] = useSound(SoundTypeOne);
    const BackGroundGradient_1 = "linear-gradient(#00f9ff ,rgba(0,0,0,0.1), #00f9ff)"
    const BackGroundGradient_2 = "linear-gradient(rgba(0,0,0,0.9) ,rgba(0,0,0,0.9), rgba(0,0,0,0.9), rgba(0,0,0,0.9))"
    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_1)

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    if(MatchMakerProcess === null && Searching === null && Config.practiceModeDataCB !== null){
        MatchMakerProcessUpdate(true); 
        SearchingUpdate(true);
        socket.emit('MatchMakingRequest-CB' , Config.practiceModeDataCB._id)
    }


    socket.on('TableAccess-CB' , function(Data){
        SearchingUpdate(false);
        TableDataUpdate(Data);
    });


    const Launcher = () => {
        const [ Timer , TimerUpdate ] = useState(false)

        setTimeout (() => { TimerUpdate(true) }, 1000);

        return (<div style={{
                    "position" : "relative",
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",
                }}>

            { Timer === false &&
                <div style={{
                    "fontSize" : "2.55em",
                    "position" : "relative",
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",
                    "textAlign" : "center",
                    "color" : "#00f9ff",
                    "fontFamily" : "HighlightText",
                }}>
                    <div className="pulsate-fwd">LAUNCHING TABLE</div>
                </div>
            }

            { Timer === true &&
                <Redirect to={'/freetoplay/cb/gametable'} />
            }
            
        </div>)
    }
    


    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_2)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTap() {
        playOne()
        Config.practiceModeCBTableAccessPass = TableData
        RedirectPropertyUpdate(true);
    }
    


    return (<div style={{
        "height" : `${currentSize.height}px`,
        "width": `${currentSize.width}px`,
    }} className="BlurIt" onClick={()=> {}}>
                <div style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>

                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    <div className="TableBackgroundOverlay" style={{
                        "position" : "fixed",
                        "zIndex" : "20",
                        "height" :  `100%`,
                        "width" : `100%`,
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        < GameTableEngine GameData = {null} />
                    </div>
                </div> 

                { Searching === true && 
                    <div style={{
                        "position" : 'fixed',
                        "width" : `100%`,
                        "zIndex" : "21",
                        "top" : "80%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        <div className="pulsate-fwd" style={{
                            "margin" : "0 auto",
                            "width" : "70%",
                            "textAlign" : "center",
                            "fontFamily" : "HighlightText",
                            "fontSize" : "1.5em",
                            "color" : "#00f9ff",
                        }}>
                            <div>SEARCHING FOR</div>
                            <div style={{
                                "marginTop" : "5%",
                            }}>OPPONENTS</div>
                        </div>
                    </div>
                }


                { TableData !== null &&  RedirectProperty !== true &&
                <div style={{
                "position" : 'fixed',
                "zIndex" : "21",
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
                }}>
                <div className="scale-in-hor-center" style={{
                    "margin" : "0 auto",
                    "width" : "100%",
                    "textAlign" : "center",
                    "fontSize" : "1.5em",
                    "color" : "#00f9ff",
                }}>

                    <div style={{
                        "fontSize" : "0.55em",
                        "color" : "white"
                    }}>
                            < AccessPassTimer />
                    </div>

                    <div style={{'height' : `${percentage(currentSize.width , 10)}px`,}}></div>

                    <div style={{
                        "fontSize" : "0.55em",
                    }}>
                        OPPONENTS FOUND
                    </div>

                    <div style={{'height' : `${percentage(currentSize.width , 3)}px`,}}></div>

                    { TableData.Access === "Premium" &&
                        TableData.Joined === false &&
                        <div style={{
                            "borderRadius" : "30%",
                            "height" : `${percentage(currentSize.width , 10)}px`,
                            "width" : `${percentage(currentSize.width , 45)}px`,
                            "margin" : "0 auto",
                            "backgroundColor" : "black",
                            "boxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                        }}>
                            <motion.div>
                                <div style={{'height' : `${percentage(currentSize.width , 3)}px`}}></div>
                                <div style={{}}></div>
                                <div style={{'fontSize' : `${percentage(currentSize.width , 3.5)}px`}}>BOOT - 10 CHIPS </div>
                            </motion.div>
                        </div>
                    }

                    { TableData.Access === "FreeMode" &&
                        TableData.Joined === false && Agnostic === false &&
                        <div style={{
                            "borderRadius" : "30%",
                            "width" : `${percentage(currentSize.width , 60)}px`,
                            "margin" : "0 auto",
                            "backgroundColor" : "black",
                            "boxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                        }}>
                            <motion.div>
                                <div style={{'height' : `${percentage(currentSize.width , 3)}px`}}></div>
                                <div style={{'fontSize' : `${percentage(currentSize.width , 2.5)}px`,}}>YOUR CURRENT BALANCE IS LOW</div>
                                <div style={{'height' : `${percentage(currentSize.width , 3)}px`}}></div>
                                <div style={{'fontSize' : `${percentage(currentSize.width , 2.5)}px`,}}>YOU WILL BE LOANED 10 CHIPS TO PLAY</div>
                                <div style={{'height' : `${percentage(currentSize.width , 3)}px`}}></div>
                                <div style={{'fontSize' : `${percentage(currentSize.width , 3.5)}px`}}>BOOT - 10 CHIPS </div>
                                <div style={{'height' : `${percentage(currentSize.width , 3)}px`}}></div>
                            </motion.div>
                        </div>
                    }


                    { TableData.Joined === true &&
                        <div style={{
                            "borderRadius" : "30%",
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "width" : `${percentage(currentSize.width , 45)}px`,
                            "margin" : "0 auto",
                            "backgroundColor" : "black",
                            "boxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                        }}>
                            <motion.div>
                                <div style={{'height' : `${percentage(currentSize.width , 3)}px`}}></div>
                                <div style={{'fontSize' : `${percentage(currentSize.width , 3.5)}px`,}}>ACTIVE TABLE FOUND</div>
                                <div style={{}}></div>
                                <div style={{'fontSize' : `${percentage(currentSize.width , 3.5)}px`}}>JOIN BACK</div>
                            </motion.div>
                        </div>
                    }

                    <div style={{'height' : `${percentage(currentSize.width , 2)}px`,}}></div>

                    <div className="pulsate-fwd" style={{
                        "margin" : "0 auto",
                        "width" : `${percentage(currentSize.width , 32)}px`,
                        "textAlign" : "center",
                        "backgroundImage" : BackgroundColor ,
                        "backgroundColor" : "black",
                        "borderRadius" :"10%",
                        "borderWidth" : `${percentage(currentSize.width , 1)}px`,
                        "fontSize" : `${percentage(currentSize.width , 6)}px`,
                        "height" : `${percentage(currentSize.width , 9)}px`,
                        "fontFamily" : "HighlightText",
                        "boxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width  , 5)}px #00f9ff`,
                    }}>
                        <motion.div onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>
                            <div style={{'height' : `${percentage(currentSize.width , 1.25)}px`,}}></div>
                            <div className="TextAwesomeNess">JOIN TABLE</div>
                        </motion.div>
                    </div>

                    </div>
                </div>
            }

            {  RedirectProperty === true && 
                <div style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "21",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>
                    <div style={{
                        "fontSize" : "1.5em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        <div className="pulsate-fwd">
                           <div>CONNECTING TO TABLE</div>
                        </div>
                    </div>
                </div> 
            }

            { RedirectProperty === true && 
                < Launcher />
            }

            { Agnostic === false &&
                <div style={{
                    "position" : 'fixed',
                    "width" : `100%`,
                    "zIndex" : "25",
                    "top" : "7%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",
                }}>
                    < CloseMatchMaker />
                </div>
            }
            
        </div>                
    </div>)
}

export default Section