import React , { useState } from 'react';
import CardCountIcon from '../../../../../Icons/CardsCount.png';

const Section = (Values) => {
        const SeatNumber = Values.SeatNumber
        const BlockSize = Values.BlockSize
        const PlayerValues = Values.PlayerValues ;
        const [ BackgroundColor , BackgroundColorUpdate ] = useState("linear-gradient(rgba(0,0,0,0.9) ,rgba(0,0,0,0.7),rgba(0,0,0,0.8))");
        const BlockShadowColor = "#00f9ff";
        const [ BlockShadowWidth ] = useState(5);
        const [ ClassName , ClassNameUpdate ] = useState(null);
        

        const PlayerTurn = PlayerValues.Turn
        

        if(PlayerTurn === true && ClassName === null){
            ClassNameUpdate("pulsate-fwd")
            BackgroundColorUpdate("linear-gradient(rgba(0,249,255,0.1) ,rgba(0,249,255,0.2),rgba(0,249,255,0.3))");
        } else if(ClassName !== null && PlayerTurn !== true){
            ClassNameUpdate(null)
            BackgroundColorUpdate("linear-gradient(rgba(0,0,0,0.9) ,rgba(0,0,0,0.7),rgba(0,0,0,0.8))");
        }

        const width = BlockSize

        function percentage(num, per)
        {
                return (num/100)*per;
        }


        if(SeatNumber === 2 || SeatNumber === 3){
            return (
                    <div style={{
                        "width" : `${percentage(width , 40)}px`,
                        "height" : `${percentage(width , 40)}px`,
                        "borderWidth" : `${percentage(width , 0.35)}px`,
                        "borderRadius" : `${percentage(width , 1)}px`,
                        "position" : "absolute",
                        "backgroundImage" : BackgroundColor ,
                        "textAlign" : "left",
                        "top" : "66%",
                        "left" : "90%",
                        "transform" : "translate(-50%, -50%)",
                        "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                        "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                        "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,   
                    }}>

                        < img className={ClassName} alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 25)}px`,
                        'width' : `${percentage(width , 25)}px`,
                        }}
                        src={ CardCountIcon } />

                        <div className="TextAwesomeNess" style={{
                            "textAlign" : "right",
                            "color" : "#00f9ff",
                            "position" : "absolute",
                            "bottom" : "0",
                            "width" : "100%",
                            "fontSize" : `${percentage(width , 15)}px`,
                        }}>{PlayerValues.TotalCards}</div>


                    </div>
                )
        } else {
            return (<div style={{
                "width" : `${percentage(width , 40)}px`,
                "height" : `${percentage(width , 40)}px`,
                "borderWidth" : `${percentage(width , 0.35)}px`,
                "borderRadius" : `${percentage(width , 1)}px`,
                "position" : "absolute",
                "backgroundImage" : BackgroundColor ,
                "textAlign" : "right",
                "top" : "66%",
                "left" : "6%",
                "transform" : "translate(-50%, -50%)",
                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,   
            }}>

                < img className={ClassName} alt="Avatar" 
                style={{
                'height' : `${percentage(width , 25)}px`,
                'width' : `${percentage(width , 25)}px`,
                }}
                src={ CardCountIcon } />

                <div className="TextAwesomeNess" style={{
                    "textAlign" : "left",
                    "color" : "#00f9ff",
                    "position" : "absolute",
                    "bottom" : "0",
                    "width" : "100%",
                    "fontSize" : `${percentage(width , 15)}px`,
                }}>{PlayerValues.TotalCards}</div>


            </div>)
        }
}

export default Section