import React from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import TableHeightCalculator from "./TableHeightCalculator";
import TimerClock from '../../../../Icons/TimerClock.png'
import TimerOne from './TimerValueProvider/SeatOne';
import TimerTwo from './TimerValueProvider/SeatTwo';
import TimerThree from './TimerValueProvider/SeatThree';
import TimerFour from './TimerValueProvider/SeatFour';

const Mover = (Value) => {
    const GameData = Value.GameData ;
    const PlayerTimerValue = GameData.TurnTimer ;

    const currentSize = SizeInfo();

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }


    const SeatOneValues = PlayerData() ;
    const SeatTwoValues = PlayerTwo() ;
    const SeatThreeValues = PlayerThree() ;
    const SeatFourValues = PlayerFour() ;

    const TableHeight = TableHeightCalculator();

    const SmallTable = percentage(currentSize.width , 120)
    const MediumTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 20)
    const LongTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 45)
    const VeryLongTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 55)

    const Animater = () => {
        const Seat = PlayerTurn()

        if(TableHeight === MediumTable){
            if(Seat === 1){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "53%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 2){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "26%",
                        "left" : "4%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 3){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "4%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 4){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "26%",
                        "left" : "76%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            }    
        }

        if(TableHeight === SmallTable){
            if(Seat === 1){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "52%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 2){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "25%",
                        "left" : "4%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 3){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "2%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 4){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "25%",
                        "left" : "76%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            }    
        }

        if(TableHeight === LongTable){
            if(Seat === 1){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "55%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 2){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "26%",
                        "left" : "4%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 3){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "3%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 4){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "26%",
                        "left" : "76%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            }    
        }

        if(TableHeight === VeryLongTable){
            if(Seat === 1){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "55%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 2){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "27%",
                        "left" : "4%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 3){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "4%",
                        "left" : "40%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            } else if(Seat === 4){
                return(
                    {
                        'position' : "absolute", 
                        "zIndex" : "22",
                        "top" : "27%",
                        "left" : "76%",
                        scale : 1,
                        opacity : 1,
                    }
                )
            }    
        }
        

        return ({
            scale : 0,
            opacity : 0,
            left : percentage(currentSize.width , 40),
            top : "30%"
        })
        
    }

    const PlayerTurn = () => {
        if(GameData === null){
            return null
        } else if(SeatOneValues.Turn ===  true){
            return 1
        } else if(SeatTwoValues.Turn === true){
            return 2
        } else if(SeatThreeValues.Turn === true){
            return 3
        } else if(SeatFourValues.Turn === true){
            return 4
        }
    }
    
    return (
        <motion.div style={{
            "position" : "absolute",
            "zIndex" : "20",
        }}
        animate={Animater()}
        transition={{ duration : 0.5 }}
        >
            <div
            style={{                
               "width" : `${percentage(currentSize.width , 20)}px`,
               "height" : `${percentage(currentSize.width , 20/2.5)}px`,
               "backgroundImage" : "linear-gradient(rgba(0,0,0,0.7) ,rgba(0,0,0,0.9), rgba(0,0,0,0.9))",
               "borderBottomStyle" : "solid",
               "borderTopStyle" : "solid",
               "borderColor" : "#00d6ff",
               "color" : "#00d6ff",
               "borderTopWidth" : `${percentage(currentSize.width , 0.5)}px`,
               "borderBottomWidth" : `${percentage(currentSize.width , 1)}px`,
               "textAlign" : "center",
               "borderRadius" : "50%",
               "boxShadow" : `0 0 ${percentage(currentSize.width , 2)}px black`,
               "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 2)}px black`,
               "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 2)}px black`,

            }}>
                
            < img alt="Avatar" 
                    style={{
                    'height' : `auto`,
                    'width' : `${percentage(currentSize.width , 5)}px`,
                    "position" :"absolute",
                    "top" : "50%",
                    "left" : "30%",
                    "transform" : "translate(-50%, -50%)",
                    }}
                    src={ TimerClock } />

                <div style={{
                    "position" :"absolute",
                    "top" : "50%",
                    "fontSize" : `${percentage(currentSize.width , 3)}px`,
                    "right" : "10%",
                    "transform" : "translate(-50%, -50%)",
                }}>
                    { PlayerTurn() === 1 && < TimerOne SeatOneTimerValue = { PlayerTimerValue } /> }
                    { PlayerTurn() === 2 && < TimerTwo SeatTwoTimerValue = { PlayerTimerValue } /> }
                    { PlayerTurn() === 3 && < TimerThree SeatThreeTimerValue = { PlayerTimerValue } /> }
                    { PlayerTurn() === 4 && < TimerFour SeatFourTimerValue = { PlayerTimerValue } /> }                    
                </div>
            </div>
        </motion.div>
    )
    
}

            

export default Mover