import React , {useState } from 'react';
import { motion } from "framer-motion";
import Home from '../../Icons/Home.png'
import { Redirect } from 'react-router';

const Section = (Value) => {
    const Size = Value.SizeData ;
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false); 
    const [ BorderColor , BorderColorUpdate ] = useState("#00f9ff");

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;

    const pageVariants = {
        initial: {
            opacity: 1,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 1,
        },
    }

    function onTapStart() {
        BorderColorUpdate("black")
    }

    function onTapCancel() {
        BorderColorUpdate("#00f9ff")
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }
    
    return (
    
    <motion.div onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
    >

       
            <div style={{
                "position" : "absolute",
                "textAlign" : "center",
                "top" : `${0 - percentage(width , 17)}px`,
                'width' : `${percentage(width , 12)}px`,
                "left" : `${percentage(width , 50) - percentage(width , 9.5)}px`,
                "backgroundColor" : "black",
                "borderRadius" : "50%",
                "padding" : `${percentage(width , 2)}px`,
                "borderStyle" : "solid",
                "borderColor" : BorderColor,
                "height" : `${percentage(width , 12)}px`,
                "borderWidth" : `${percentage(width , 0.85)}px`,
                "color" : "#00f9ff",
            }}>
                
                    < img alt="Logo" 
                            style={{
                            'height' : `${percentage(width , 7)}px`,
                            'width' : `${percentage(width , 7)}px`,
                            }}
                            src={ Home } />
                    < div style={{'fontSize' : `${percentage(width , 10)/3.5}px`,}}>HOME</div>
                
            </div>


        { RedirectProperty === true && <Redirect to="/" />}
    </motion.div>
    )
    
}

export default Section