import { motion }  from "framer-motion";
import Heart from '../../../../Icons/Heart.png'
import Spade from '../../../../Icons/Spade.png'
import Diamond from '../../../../Icons/Diamond.png'
import Club from '../../../../Icons/Club.png'


const PlayingCardCreator = (Value) => {
    const width = Value.Dimensions.width ;
    const CardData = Value.CardData ;
    const CardSuit = CardData.Suit;
    var BackgroundColor = "white";
    var Opacity = 1 ;
    if(CardData.Background === "grey"){
        BackgroundColor = "grey";
        Opacity = 0.4
    }

    


    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const CardTopSeriesMention = (Value) => {
        return (
            <motion.div  style={{
                "fontSize" :  `${percentage(width , 3.5)}px`,
                "color" : Value.Color,
                "position" : "absolute",
                "fontFamily" : "CardText",
                "left" : "10%",
                "top" : "2%"
            }}>
                    {Value.Series}
            </motion.div>
        )
    }

    const CardBottomSeriesMention = (Value) => {
        var TopValue = "0%";
        var LeftValue = "0%";

        if(Value.Series === "10"){
            TopValue = "-10%";
            LeftValue = "40%";
        } else {
            TopValue = "-10%";
            LeftValue = "55%";
        } 

        return (
            <motion.div  style={{
                "fontSize" :  `${percentage(width , 7)}px`,
                "color" : Value.Color,
                "position" : "absolute",
                "fontFamily" : "CardText",
                "left" : LeftValue,
                "bottom" : TopValue
            }}>
                    {Value.Series}
            </motion.div>
        )
    }

    const CardTopHeartMention = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "left" : "5%",
                "top" : "22%"
            }}>
                < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 2.5)}px`,
                "width" : "auto",
                }}
                src={ Heart } />
            </motion.div>
        )
    }

    const CardTopSpadeMention = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "left" : "5%",
                "top" : "22%"
            }}>
                < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 2.5)}px`,
                "width" : "auto",
                }}
                src={ Spade } />
            </motion.div>
        )
    }

    const CardTopClubMention = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "left" : "5%",
                "top" : "22%"
            }}>
                < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 2.5)}px`,
                "width" : "auto",
                }}
                src={ Club } />
            </motion.div>
        )
    }

    const CardTopDiamondMention = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "left" : "5%",
                "top" : "22%"
            }}>
                < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 2.5)}px`,
                "width" : "auto",
                }}
                src={ Diamond } />
            </motion.div>
        )
    }

    const CardBottomHeartMention = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "top" : "5%",
                "left" : "45%"
            }}>
                < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 4)}px`,
                "width" : "auto",
                }}
                src={ Heart } />
            </motion.div>
        )
    }

    const CardBottomSpadeMention = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "top" : "5%",
                "left" : "45%"
            }}>
                < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 4)}px`,
                "width" : "auto",
                }}
                src={ Spade } />
            </motion.div>
        )
    }

    const CardBottomClubMention = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "top" : "5%",
                "left" : "45%"
            }}>
                < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 4)}px`,
                "width" : "auto",
                }}
                src={ Club } />
            </motion.div>
        )
    }

    const CardBottomDiamondMention = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "top" : "5%",
                "left" : "45%"
            }}>
                < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 4)}px`,
                "width" : "auto",
                }}
                src={ Diamond } />
            </motion.div>
        )
    }

    const Series = CardData.Series ;
    const Suit = CardSuit;

    const TextColor = () =>{
        if(Suit === "Spade" || Suit === "Club"){
            return("black")
        } else {
            return("red")
        }
    }

    return (<motion.div 
        animate={{ opacity : Opacity}}
        style={{
        "height" : `${percentage(width , 11)}px`,
        "width" : `${percentage(width , 9)}px`,
        "backgroundColor" : BackgroundColor,
        "margin" : "0 auto",
        "borderRadius" : "3%",
        "position" : "absolute"
    }}>

                < CardTopSeriesMention Color = { TextColor () } Series = { Series } />

                { Suit === "Spade" &&
                    < CardTopSpadeMention />
                }

                { Suit === "Club" &&
                    < CardTopClubMention />
                }

                { Suit === "Heart" &&
                    < CardTopHeartMention />
                }

                { Suit === "Diamond" &&
                    < CardTopDiamondMention />
                }
                
                < CardBottomSeriesMention Color = { TextColor () } Series = { Series } />

                { Suit === "Spade" &&
                    < CardBottomSpadeMention />
                }

                { Suit === "Club" &&
                    < CardBottomClubMention />
                }

                { Suit === "Heart" &&
                    < CardBottomHeartMention />
                }

                { Suit === "Diamond" &&
                    < CardBottomDiamondMention />
                }  

    </motion.div>
    )
    
}

export default PlayingCardCreator 