import React , { useState } from 'react';
import { Redirect } from 'react-router';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import BackIcon from '../../../Icons/Back.png'

const BackButton = () => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"

    const RedirectValue = "/freetoplay/cb/profile" ;
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);

    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);

    

    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
            BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    return (
        <div className="BlurIt" style={{
            "position" : "absolute",
            "bottom" :   "3%",
            "height" : "10%",
            "width" : "100%",
            "borderTopStyle" : "solid",
            "borderBottomStyle" : "solid",
            "borderColor" : 'black' ,
            "backgroundImage" : BackgroundColor
       }}>

           <div style={{
               "position" : "relative",
               "top" : "50%",
               "left": "50%",
               "transform" : "translate(-50%, -50%)",
           }}>

               <motion.div style={{
                   "textAlign" : "center",
                   "display" : "flex",
                   "justifyContent" : "center"
               }} onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>

               <div>
               < img alt="Logo" 
                   style={{
                   'height' : `${percentage(currentSize.width , 10)}px`,
                   'width' : `${percentage(currentSize.width , 10)}px`,
                   }}
                   src={ BackIcon } />
               </div>

               <div style={{
                   "fontFamily" : "HighlightText",
                   "color" : '#C0C0C0',
                   "fontSize" : `${percentage(currentSize.width , 10)}px`,
                   "marginLeft" : `${percentage(currentSize.width , 1)}px`,
               }}>
                   BACK
               </div>

               </motion.div>

               </div>
           
               { RedirectProperty === true &&
                   <Redirect to={RedirectValue} />
               }
           
       </div>
    )
}

export default BackButton