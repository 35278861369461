import React , { useState } from 'react';

const Bid = (Values) => {
    const BlockSize = Values.BlockSize
    const GameData = Values.TableData ;
    const Seat = Values.PlayerValues.Seat ;
    const [ BackgroundColor ] = useState("linear-gradient(rgba(0,0,0,0.1) ,rgba(0,0,0,0.8),rgba(0,0,0,0.8))");
    
    const ShadowColor = () => {
        if(Values.PlayerValues.IsHandWinner === true){
            return "#FDA80D"
        } else {
            return "#00f9ff"
        }
    }

    const BlockShadowColor = ShadowColor();
    
    
    const [ BlockShadowWidth  ] = useState(4);

    const BidFinder = () => {
        if(Seat === 1 && GameData.Bids.Player1 !== null){
            return GameData.Bids.Player1
        } else if(Seat === 2 && GameData.Bids.Player2 !== null){
            return GameData.Bids.Player2
        } else if(Seat === 3 && GameData.Bids.Player3 !== null){
            return GameData.Bids.Player3
        } else if(Seat === 4 && GameData.Bids.Player4 !== null){
            return GameData.Bids.Player4
        } else {
            return 0
        }
    }

    const HandsMadeFinder = () => {
        if(GameData.Round === 1){
            if(Seat === 1 && GameData.PlayerHands.Player1.R_One !== null){
                return GameData.PlayerHands.Player1.R_One 
            } else if(Seat === 2 && GameData.PlayerHands.Player2.R_One !== null){
                return GameData.PlayerHands.Player2.R_One 
            } else if(Seat === 3 && GameData.PlayerHands.Player3.R_One !== null){
                return GameData.PlayerHands.Player3.R_One 
            } else if(Seat === 4 && GameData.PlayerHands.Player4.R_One !== null){
                return GameData.PlayerHands.Player4.R_One 
            } else {
                return 0
            }
        } else if(GameData.Round === 2){
            if(Seat === 1 && GameData.PlayerHands.Player1.R_Two !== null){
                return GameData.PlayerHands.Player1.R_Two
            } else if(Seat === 2 && GameData.PlayerHands.Player2.R_Two !== null){
                return GameData.PlayerHands.Player2.R_Two 
            } else if(Seat === 3 && GameData.PlayerHands.Player3.R_Two !== null){
                return GameData.PlayerHands.Player3.R_Two 
            } else if(Seat === 4 && GameData.PlayerHands.Player4.R_Two !== null){
                return GameData.PlayerHands.Player4.R_Two
            } else {
                return 0
            }
        }
    }
    
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = BlockSize

    return (
        <div>
            <div style={{
                "position" : "absolute",
                "color" : "#00f9ff",
                "top" : "2%",
                "fontSize" : `${percentage(width , 13)}px`,
                "width" : `${percentage(width , 100)}px`,
                "backgroundImage" : BackgroundColor ,
                "textAlign" : "center",
                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,            
            }}>
                { GameData.IsBidding !== true && BidFinder() !== 0 && <div>BID -  { HandsMadeFinder() }/{ BidFinder() }</div> }
                { GameData.IsBidding === true && <div>BIDDING</div> }
                
            </div>
        </div>
        
    )
}

export default Bid