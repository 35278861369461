import React , { useState } from 'react';
import useResize from 'use-resize';
import useOrientationChange from "use-orientation-change";
import MatchesIcon from '../../../Icons/Matches.png';
import ChallengeIcon from '../../../Icons/Challenges.png';
import WonIcon from '../../../Icons/Won.png';
import { motion } from "framer-motion";
import Progress from 'react-progressbar';
import Config from '../../../GlobalFunctions/Config'

const Section = () => {
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

   
    const BackGroundGradient_1_step1 = "linear-gradient(rgba(232, 232,  232  ,0.4) ,rgba(0,0,0,0.1), rgba(0,0,0,0.2), rgba(0,0,0,0.3), rgba(0,0,0,0.4))"
   
    
    const BackGroundGradient_3_step1 = "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.4), rgba(0,0,0,0.3), rgba(0,0,0,0.2), rgba(232, 232,  232  ,0.4))"
    
    const [ BackgroundOne  ] = useState(BackGroundGradient_1_step1)
    const [ BackgroundTwo  ] = useState(BackGroundGradient_3_step1)

    const BackGroundGradient_1_step5 = "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.3), rgba(0,0,0,0.8))"

    

    const orientation = useOrientationChange()
    const currentSize = SizeInfo();

    const width = currentSize.width ;
    const height = currentSize.height ;

    const MatchesPlayedValue = Config.practiceModeData.MatchesPlayed;
    const MatchWonValue = Config.practiceModeData.MatchesWon;
    var MatchWonRatio =  0;

    if(MatchWonValue !== 0 && MatchesPlayedValue !== 0){
        MatchWonRatio = Math.round((MatchWonValue * 100)/MatchesPlayedValue)
    }

    const ChallengesPlayedValue = Config.practiceModeData.Challenges;
    const ChallengesWonValue = Config.practiceModeData.ChallengesWon;
    var ChallengesWonRatio =  0;

    if(ChallengesPlayedValue !== 0 && ChallengesWonValue !== 0){
        ChallengesWonRatio = Math.round((ChallengesWonValue * 100)/ChallengesPlayedValue)
    }

    
    var PlayerImpactRatio = 0 ;

    var TotalImapact = ChallengesWonRatio + MatchWonRatio ;

    if(TotalImapact !== 0) {
        PlayerImpactRatio = Math.round((TotalImapact * 100)/200)
    }

    
    

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const pageVariants = {
        initial: {
            opacity: 0.5,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0.5,
        },
        }

        var NavigationSwitchHeight = ((percentage(Dimensions.width , 24)/16) * 2) + (percentage(Dimensions.width , 24)/8)

        var PageStartingPosition = percentage(Dimensions.width , 32) + NavigationSwitchHeight ;
        var PageEndingPosition = percentage(Dimensions.width , 20) + percentage(Dimensions.height , 3);
        var PageHeight = percentage(Dimensions.height , 98) - (PageStartingPosition + PageEndingPosition);
        var AreaConsumed = percentage(Dimensions.height , 15) * 3
        var AreaLeft = PageHeight - AreaConsumed
    


    if(orientation === "landscape"){
        return (<div>

        </div>)
    } else {
          
        
        return (<div style={{
            "position" : "fixed",
            "marginTop" : `${PageStartingPosition}px`,
            "height" : `${PageHeight}px`,
            "width" : `${percentage(Dimensions.width , 100)}px`, 
        }}>

            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
            >
                <div style={{'height' : `${percentage(AreaLeft , 10)}px`}}></div>

                <div className="BlurIt" style={{
                    "display" : "flex",
                    "justifyContent" : "center",
                    "textAlign" : "center",
                    "fontSize" : `${percentage(width , 2.5)}px`,
                    "backgroundImage" : BackgroundOne ,
                    "paddingTop" : `${percentage(AreaLeft , 10)}px`,
                    "paddingBottom" : `${percentage(AreaLeft , 10)}px`,
                    "borderColor" : "black",
                    "borderStyle" : "solid",
                    "borderWidth" : `${percentage(width , 1)}px`,
                    "width" : `${percentage(width , 95)}px`,
                    "borderTopLeftRadius" :"10%",
                    "borderTopRightRadius" :"10%",
                    "margin" : "0 auto"
                }}>

                <div style={{
                    "width" : `${percentage(width , 30)}px`,
                }}>
                    <div>MATCHES</div> 
                    <div>PLAYED</div>

                    <div style={{"height" : `${percentage(height , 3)}px`,}}></div>

                    <div style={{
                        "display" : "flex" ,
                        "justifyContent" : "center"
                    }}>

                        < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 3)}px`,
                            'width' : `${percentage(width , 3)}px`,
                            }}
                        src={ MatchesIcon } /> 

                        <div style={{
                            "color" : "#00f9ff",
                            "marginLeft" : `${percentage(width , 1)}px`,
                        }}>{ MatchesPlayedValue }</div>

                     

                    </div>

                    
                </div>

                <div style={{
                    "width" : `${percentage(width , 30)}px`,
                }}>
                    <div>MATCHES</div>
                    <div>WON</div> 

                    <div style={{"height" : `${percentage(height , 3)}px`,}}></div>

                    <div style={{
                        "display" : "flex" ,
                        "justifyContent" : "center"
                    }}>

                        < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 3)}px`,
                            'width' : `${percentage(width , 3)}px`,
                            }}
                        src={ WonIcon } /> 

                        <div style={{
                            "color" : "#00f9ff",
                            "marginLeft" : `${percentage(width , 1)}px`,
                        }}>{ MatchWonValue }</div>

                     

                    </div>
                </div>


                <div style={{
                    "width" : `${percentage(width , 30)}px`,
                }}>
                    <div>WIN</div>
                    <div>RATIO</div>

                    <div style={{"height" : `${percentage(height , 2)}px`,}}></div>

                    <div style={{
                        "color" : "#00f9ff",
                    }}>{  MatchWonRatio } %</div>

                    <div style={{"height" : `${percentage(height , 0.25)}px`,}}></div>

                    <div style={{
                        "width" : `${percentage(width, 20)}px`,
                        "backgroundColor" : "black" ,
                        "borderStyle" : "solid",
                        "borderColor" : "#00f9ff",
                        "margin" : "0 auto",
                        "borderWidth" : `${percentage(width, 0.5)}px`,
                        "borderRadius" : `${percentage(width, 0.25)}px`,
                    }}>
                        <Progress color={"#00f9ff"} completed={ MatchWonRatio } />
                    </div>
                </div>
            </div> 

            <div style={{'height' : `${percentage(AreaLeft , 1)}px`}}></div>

            <div className="BlurIt" style={{
                    "display" : "flex",
                    "justifyContent" : "center",
                    "textAlign" : "center",
                    "fontSize" : `${percentage(width , 2.5)}px`,
                    "backgroundImage" : BackgroundTwo ,
                    "paddingTop" : `${percentage(AreaLeft , 10)}px`,
                    "paddingBottom" : `${percentage(AreaLeft , 10)}px`,
                    "borderColor" : "black",
                    "borderStyle" : "solid",
                    "borderWidth" : `${percentage(width , 1)}px`,
                    "borderBottomLeftRadius" :"10%",
                    "borderBottomRightRadius" :"10%",
                    "width" : `${percentage(width , 95)}px`,
                    "margin" : "0 auto"
                }}>

                <div style={{
                    "width" : `${percentage(width , 30)}px`,
                }}>
                    <div>TOTAL</div> 
                    <div>CHALLENGES</div>

                    <div style={{"height" : `${percentage(height , 3)}px`,}}></div>

                    <div style={{
                        "display" : "flex" ,
                        "justifyContent" : "center"
                    }}>

                        < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 4)}px`,
                            'width' : `${percentage(width , 4)}px`,
                            }}
                        src={ ChallengeIcon } /> 

                        <div style={{
                            "color" : "#00f9ff",
                            "marginLeft" : `${percentage(width , 1)}px`,
                        }}>{ ChallengesPlayedValue }</div>

                     

                    </div>

                    
                </div>

                <div style={{
                    "width" : `${percentage(width , 30)}px`,
                }}>
                    <div>CHALLENGES</div>
                    <div>WON</div> 

                    <div style={{"height" : `${percentage(height , 3)}px`,}}></div>

                    <div style={{
                        "display" : "flex" ,
                        "justifyContent" : "center"
                    }}>

                        < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 3)}px`,
                            'width' : `${percentage(width , 3)}px`,
                            }}
                        src={ WonIcon } /> 

                        <div style={{
                            "color" : "#00f9ff",
                            "marginLeft" : `${percentage(width , 1)}px`,
                        }}>{ ChallengesWonValue }</div>

                     

                    </div>
                </div>


                <div style={{
                    "width" : `${percentage(width , 30)}px`,
                }}>
                    <div>WIN</div>
                    <div>RATIO</div>

                    <div style={{"height" : `${percentage(height , 2)}px`,}}></div>

                    <div style={{
                        "color" : "#00f9ff",
                    }}>{  ChallengesWonRatio } %</div>

                    <div style={{"height" : `${percentage(height , 0.25)}px`,}}></div>

                    <div style={{
                        "width" : `${percentage(width, 20)}px`,
                        "backgroundColor" : "black" ,
                        "borderStyle" : "solid",
                        "borderColor" : "#00f9ff",
                        "margin" : "0 auto",
                        "borderWidth" : `${percentage(width, 0.5)}px`,
                        "borderRadius" : `${percentage(width, 0.25)}px`,
                    }}>
                        <Progress color={"#00f9ff"} completed={ChallengesWonRatio} />
                    </div>
                </div>
            </div>     

            <div style={{'height' : `${percentage(AreaLeft , 5)}px`}}></div>

            <div className="BlurIt" style={{
                    "textAlign" : "center",
                    "fontSize" : `${percentage(width , 2.5)}px`,
                    "backgroundImage" : BackGroundGradient_1_step5 ,
                    "backgroundColor" : "rgba(232, 232,  232  ,0.1)",
                    "paddingTop" : `${percentage(AreaLeft , 10)}px`,
                    "paddingBottom" : `${percentage(AreaLeft , 10)}px`,
                    "borderColor" : "black",
                    "borderStyle" : "solid",
                    "borderWidth" : `${percentage(width , 1)}px`,
                    "width" : `${percentage(width , 95)}px`,
                    "margin" : "0 auto"
                }}>

                    <div>PLAYER IMPACT</div>

                    <div style={{'height' :  `${percentage(height , 0.5)}px`,}}></div>

                    <div>{ TotalImapact } / 200</div>

                    <div style={{'height' :  `${percentage(height , 0.5)}px`,}}></div>

                    <div style={{
                        "width" : `${percentage(width, 50)}px`,
                        "backgroundColor" : "black" ,
                        "borderStyle" : "solid",
                        "borderColor" : "#00f9ff",
                        "margin" : "0 auto",
                        "borderWidth" : `${percentage(width, 0.5)}px`,
                        "borderRadius" : `${percentage(width, 0.25)}px`,
                    }}>
                        <Progress color={"#00f9ff"} completed={PlayerImpactRatio} />
                    </div>
            </div> 

                
            </motion.div>
                            
        </div>)
    }
}

export default Section