import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import Username from './Username'
import Config from '../../../GlobalFunctions/Config'

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"

    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);


    

    const Size = Value.SizeData ;
    const MatchesPlayedValue = Config.practiceModeData.MatchesPlayed;
    const MatchWonValue = Config.practiceModeData.MatchesWon;
    var MatchWonRatio =  0;

    if(MatchWonValue !== 0 && MatchesPlayedValue !== 0){
        MatchWonRatio = Math.round((MatchWonValue * 100)/MatchesPlayedValue)
    }
 
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
    const height = Size.height ;

    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }

    return (<div className="BlurIt" style={{
            "height" : `${percentage(height , 15)}px`,
            "width" : `${percentage(width , 70)}px`,
            "backgroundImage" : BackgroundColor ,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "borderWidth" : `${percentage(width , 0.35)}px`,
            "borderRadius" : `${percentage(width , 1)}px`,
    }}>

        <div style={{
            "position" : "relative",
            "top" : "-15%",
        }}>
            < Username SizeData = { Value.SizeData } />
        </div>

        <div style={{"height" : `${percentage(height , 1)}px`,}}></div>
        
        <div style={{
            "fontSize" : `${percentage(width , 3)}px`,
            "position" : "relative",
            "top" : "20%",
            "left": "50%",
            "transform" : "translate(-50%, -50%)"
        }}>

            <motion.div style={{
                "display" : "flex",
                "justifyContent" : "center",
                "textAlign" : "center"
            }} onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>

                <div style={{
                    "width" : `${percentage(width , 22)}px`,
                }}>
                    <div>MATCHES</div> 
                    <div>PLAYED</div>

                    <div style={{"height" : `${percentage(height , 1)}px`,}}></div>

                    <div style={{
                        "color" : "#00f9ff",
                    }}>{ MatchesPlayedValue }</div>
                </div>

                <div style={{
                    "width" : `${percentage(width , 22)}px`,
                }}>
                    <div>MATCHES</div>
                    <div>WON</div> 

                    <div style={{"height" : `${percentage(height , 1)}px`,}}></div>

                    <div style={{
                        "color" : "#00f9ff",
                    }}>{ MatchWonValue }</div>
                </div>


                <div style={{
                    "width" : `${percentage(width , 22)}px`,
                }}>
                    <div>WIN</div> 
                    <div>RATIO</div>

                    <div style={{"height" : `${percentage(height , 1)}px`,}}></div>


                    <div style={{
                        "color" : "#00f9ff",
                    }}>{  MatchWonRatio } %</div>
                </div>
            </motion.div> 
        </div> 

        

        { RedirectProperty === true &&
            <Redirect to="/freetoplay/profilestats" />
        }
        
    </div>)
}

export default Section