import { motion } from 'framer-motion';
import React from 'react';
import useOrientationChange from "use-orientation-change";
import useResize from 'use-resize';
import TutorialImage from '../../../../Icons/CallBreakRules.png'

const Section = () => {
    const orientation = useOrientationChange()

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    


    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else {
        return (
            <div style={{
                "height" : `${Dimensions.height}px`,
                "width": `${Dimensions.width}px`,
                "textAlign" : "center",
                "fontSize" : `${percentage( Dimensions.width, 3)}px`,
                "overflowY" : "scroll"
            }}>
               
                <motion.div>
                < img alt="Avatar" 
                        style={{
                        'height' : `auto`,
                        'width' : `${percentage(Dimensions.width , 100)}px`,
                        }}
                        src={ TutorialImage } />
                </motion.div>
                
            </div>
        )
    }
}

export default Section