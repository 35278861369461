import React , { useState } from 'react';

const Score = (Values) => {
    const BlockSize = Values.BlockSize
    const GameData = Values.TableData ;
    const Seat = Values.PlayerValues.Seat ;
    const [ BackgroundColor ] = useState("linear-gradient(rgba(0,0,0,0.9) ,rgba(0,0,0,0.8),rgba(0,0,0,0.8))");
    const [ BlockShadowWidth  ] = useState(4);

    const ShadowColor = () => {
        if(Values.PlayerValues.IsHandWinner === true){
            return "#FDA80D"
        } else {
            return "#00f9ff"
        }
    }

    const BlockShadowColor = ShadowColor();

    
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = BlockSize

    const ScoreFinder = () => {
        if(GameData.Round === 1){
                return 0
        } else if(GameData.Round === 2){
            if(Seat === 1 && GameData.ScoreBoard.Player1.R_One !== null){
                return GameData.ScoreBoard.Player1.R_One
            } else if(Seat === 2 && GameData.ScoreBoard.Player2.R_One !== null){
                return GameData.ScoreBoard.Player2.R_One 
            } else if(Seat === 3 && GameData.ScoreBoard.Player3.R_One !== null){
                return GameData.ScoreBoard.Player3.R_One 
            } else if(Seat === 4 && GameData.ScoreBoard.Player4.R_One !== null){
                return GameData.ScoreBoard.Player4.R_One
            } else {
                return 0
            }
        }
    }
    
    const ScoreColor = () =>{
        if(ScoreFinder() < 0){
            return("red")
        } else {
            return("#00f9ff")
        }
    }

    return (
        <div>
            <div style={{
                "position" : "absolute",
                "color" : "#00f9ff",
                "bottom" : "10%",
                "fontSize" : `${percentage(width , 13)}px`,
                "width" : `${percentage(width , 100)}px`,
                "backgroundImage" : BackgroundColor ,
                "textAlign" : "center",
                "display" : "flex",
                "justifyContent" : "center",
                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,            
            }}>
                <div>SCORE</div>
                <div style={{
                    "width" : `${percentage(width , 10)}px`,
                }}></div>
                <div style={{
                    "color" : ScoreColor()
                }}>{ ScoreFinder() } </div>
            </div>
        </div>
        
    )
}

export default Score