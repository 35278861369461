import React , { useState } from 'react';
import useResize from 'use-resize';  
import socket from '../../../SocketConnectionManager';
import Config from '../../../GlobalFunctions/Config' ;
import GameTableEngine from './GameTableEngine';
import CloseTable from '../ProfileComponents/GameTableAssets/CloseTable'
import IsAccess from '../../../GlobalFunctions/PracticeMode/IsGamerAccessCB'
import CardDistributionAnimation from './GameTableAssets/CardDistributionAnimation'
import StartingGame from './GameTableAssets/StartingGame'
import StartingRound from './GameTableAssets/StartingRound'
import StartingBids from './GameTableAssets/StartingBids'
import RoundEnded from './GameTableAssets/RoundEnded'
import PlayerCardControls from './GameTableAssets/PlayerCardControls'
import Timer from './GameTableAssets/Timer'
import { motion } from 'framer-motion';
import AdditionalControls from './AdditionalControls';
import Hand from './GameTableAssets/Hand';
import useSetTimeout from 'use-set-timeout';

const Section = () => {
    const [ Connected , ConnectedUpdate ] = useState(null);
    const [ isAgnostic , isAgnosticUpdate ] = useState(null);
    const [ isDisconnected , isDisconnectedUpdate ] = useState(null);
    const [ isEvicted , isEvictedUpdate ] = useState(null);
    const [ isTable , isTableUpdate ] = useState(null);
    const [ TableData , TableDataUpdate ] = useState(null);
    const [ MusicOn , MusicOnUpdate ] = useState(Config.DefaultCallbreakSound);

    if(Config.DefaultCallbreakSound === null){
        Config.DefaultCallbreakSound = true
        MusicOnUpdate(true)
    }

    if(isAgnostic === null){
        isAgnosticUpdate(true)

        if(Config.practiceModeDataCB !== null){
            isAgnosticUpdate(Config.practiceModeDataCB.isAgnostic)
        } else {
            
                IsAccess(function (AccessDataValue){
                    Config.practiceModeDataCB = AccessDataValue;
                    isAgnosticUpdate(Config.practiceModeDataCB.isAgnostic)
                })              
        }
        
    }


    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    if(Connected === null && Config.practiceModeCBTableAccessPass !== null){
        ConnectedUpdate(false);
        socket.emit('MatchMakingAccessRequest-CB', Config.practiceModeCBTableAccessPass);
    }

    socket.on('AccessGranted-CB' , function (){
        ConnectedUpdate(true);
        if(isAgnostic === null){
            isAgnosticUpdate(Config.practiceModeDataCB.isAgnostic)
        }
        socket.emit('UserConnectedToTable-CB' , Config.practiceModeDataCB._id);
    })
    
    socket.on('GameStateUpdate-CB', function (Data){
        isTableUpdate(true)
        TableDataUpdate(Data)

        if(isDisconnected === true){
            isDisconnectedUpdate(null);
        }

        
        if(isAgnostic === null){
            isAgnosticUpdate(Config.practiceModeDataCB.isAgnostic)
        }
    })

    socket.on('disconnect' , function (){
        if(isDisconnected === null){
            isDisconnectedUpdate(true);
        }
    })

    socket.on('Evicted-CB' , function (){
        if(isEvicted === null){
            isEvictedUpdate(true);
        }
    })

    socket.on('connect' , function (){
        if(isDisconnected === true){
            window.location.reload() 
        }
    })

    socket.on('onStopMusic' , function (){
        MusicOnUpdate(false)
    })

    socket.on('onStartMusic' , function (){
        MusicOnUpdate(true)
    })


    const RejoinTable = () => {
        IsAccess(function (){
            socket.emit('UserConnectedToTable-CB' , Config.practiceModeDataCB._id);
        })
    }

    const EvictionClick = () => {
        window.location.replace("/freetoplay/cb/profile")
    }

    if(Config.practiceModeDataCB === null && Connected === null){
        RejoinTable()
    }
    

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableConnector = () => {
        const [ Timer, TimerUpdate ] = useState(false)

        useSetTimeout(()=>{
            TimerUpdate(true)
        }, 3000)

        return (
            <div>
                { Timer === false &&
                    <div className='pulsate-fwd'>CONNECTING TO TABLE</div>
                }

                { Timer === true && isAgnostic === false &&
                    <div onClick={ ()=> window.location.reload() } style={{
                        "color" : "red"
                    }}>
                        <div>UNABLE TO CONNECT TO TABLE</div>
                        <div style={{
                            "marginTop" : "2%"
                        }}>CLICK HERE TO RECONNECT</div>
                    </div>
                }

                { Timer === true && isAgnostic === true &&
                    <div style={{
                        "color" : "red"
                    }}>
                        <div>YOU WERE EVICTED</div>
                        <div style={{
                            "marginTop" : "2%"
                        }}>FROM THE TABLE</div>
                    </div>
                }
            </div>
        )
    }


        return (<div style={{
            "height" : `${currentSize.height}px`,
            "width": `${currentSize.width}px`,
        }} className="BlurIt" onClick={()=> {}}>
                { isTable === null && isDisconnected === null && isEvicted === null &&

                <div>

                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    <div className="TableBackgroundOverlay" style={{
                        "position" : "fixed",
                        "zIndex" : "20",
                        "height" :  `100%`,
                        "width" : `100%`,
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        < GameTableEngine GameData = {null} />
                    </div>
                </div>

                

                <div style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "21",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>
                    <div style={{
                        "fontSize" : "1.5em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        < TableConnector />
                    </div>
                    { isAgnostic === false &&
                        < CloseTable />
                    }                    
                </div>   

                </div>
            }

            { isTable === true && TableData !== null && isDisconnected === null && isEvicted === null &&
                <motion.div 
                className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    { TableData !== null &&
                        <motion.div className="TableBackgroundOverlay" style={{
                            "position" : "fixed",
                            "zIndex" : "20",
                            "height" :  `100%`,
                            "width" : `100%`,
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                        }}>

                            { TableData.RoundEnded !== true &&
                                < GameTableEngine GameData = {TableData} isAgnostic = {isAgnostic} />
                            }
                        </motion.div>
                    }

                    

                    { TableData !== null &&
                        TableData.CardDistribution === null &&
                        TableData.BootDeduction === null &&
                        < StartingGame />
                    }

                    { TableData !== null &&
                        TableData.RoundStarting === true &&
                        < StartingRound GameData = {TableData} Music = { MusicOn } /> 
                    }

                    

                    { TableData !== null && TableData.IsBidding === false &&
                        < Timer GameData = {TableData}/> 
                    }

                    
                    { TableData !== null && TableData.IsBidding === false &&
                        < Hand GameData = {TableData}  Music = { MusicOn } /> 
                    }
                    
                    { TableData !== null  && TableData.IsBidding === true &&
                        < StartingBids GameData = { TableData } Music = { MusicOn } /> 
                    }
                    
                    { TableData !== null  && TableData.RoundEnded === true &&
                        < RoundEnded GameData = { TableData } Music = { MusicOn } isAgnostic = {isAgnostic} />
                    }

                    { TableData.CardDistribution === true && 
                        <motion.div style={{
                            'position' : "absolute",  
                            "zIndex" : "22",
                            "height" :  `100%`,
                            "width" : `100%`,
                        }}>
                            < CardDistributionAnimation  GameData = { TableData} Music = { MusicOn } /> 
                        </motion.div>
                    }

                    { TableData !== null &&
                        < PlayerCardControls GameData = { TableData } Music = { MusicOn } /> 
                    }

                    
                    < AdditionalControls TableData={ TableData } Music = { MusicOn } isAgnostic = {isAgnostic} />
                    
                </motion.div> 
            }


            { isDisconnected === true && isEvicted === null &&
                    <div className="TableBackground" style={{
                        "position" : "fixed",
                        "top" : "0%",
                        "left" : "0%",
                        "zIndex" : "20",
                        "height" :  `${percentage(currentSize.height , 100)}px`,
                        "width" : `${percentage(currentSize.width , 100)}px`,
                    }}>
                        <div style={{
                            "fontSize" : "1.55em",
                            "position" : "relative",
                            "top" : "50%",
                            "left": "50%",
                            "zIndex" : "22",
                            "transform" : "translate(-50%, -50%)",
                            "textAlign" : "center",
                            "color" : "#00f9ff",
                            "fontFamily" : "HighlightText",
                        }}>
                            <div>

                               <div style={{
                                   "marginTop" : "10%",
                                   "backgroundColor" : "black",
                                   "paddingTop" : "2%",
                                   "paddingBottom" : "2%"
                               }}>
                                   REJOINING......
                                </div>
                            </div>
                        </div>

                        <div className="TableBackgroundOverlay" style={{
                            "position" : "fixed",
                            "zIndex" : "21",
                            "height" :  `100%`,
                            "width" : `100%`,
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                            "backgroundColor" : "rgba(0,0,0,0.9)"
                        }}>
                            
                        </div>



                        { TableData !== null &&
                            <div className="TableBackgroundOverlay" style={{
                                "position" : "fixed",
                                "zIndex" : "20",
                                "height" :  `100%`,
                                "width" : `100%`,
                                "top" : "50%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",
                            }}>
                                < GameTableEngine GameData = {TableData} />
                            </div>
                        }
    
                    </div>  
            }

            { isDisconnected === null && isEvicted === true &&
                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>
                    <div style={{
                        "fontSize" : "1.55em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "zIndex" : "22",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        <div>
                           <div>YOU WERE EVICTED</div>
                               
                           <div>FROM THE TABLE</div>

                           <div onClick={()=> EvictionClick()} style={{
                               "marginTop" : "10%",
                               "backgroundColor" : "black",
                               "color" : "white",
                               "paddingTop" : "2%",
                               "paddingBottom" : "2%"
                           }}>
                               CLICK HERE TO GO BACK TO PROFILE
                            </div>
                        </div>
                    </div>

                    <div className="TableBackgroundOverlay" style={{
                        "position" : "fixed",
                        "zIndex" : "21",
                        "height" :  `100%`,
                        "width" : `100%`,
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                        "backgroundColor" : "rgba(0,0,0,0.9)"
                    }}>
                        
                    </div>


                    { TableData !== null &&
                        <div className="TableBackgroundOverlay" style={{
                            "position" : "fixed",
                            "zIndex" : "20",
                            "height" :  `100%`,
                            "width" : `100%`,
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                        }}>
                            < GameTableEngine GameData = {TableData} />
                        </div>
                    }
                    

                </div>  
            
            }

            { TableData === null && isDisconnected === true &&
                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>
                    <div style={{
                        "fontSize" : "1.55em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "zIndex" : "22",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        <div>
                           <div>YOU WERE DISCONNECTED</div>
                               
                           <div>FROM THE TABLE</div>
                
                           <div onClick={()=> window.location.reload()} style={{
                               "marginTop" : "10%",
                               "backgroundColor" : "black",
                               "color" : "white",
                               "paddingTop" : "2%",
                               "paddingBottom" : "2%"
                           }}>
                               CLICK HERE TO RECONNECT
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div style={{
                "position" : "absolute",                
            }}></div>
    </div>)
}

export default Section