import React  from 'react';
import HomeButton from './LandingMobileComponents/HomeButton'
import PoweredBy from './LandingMobileComponents/PoweredBy'
import useResize from 'use-resize';

const LandingFooter = () => {
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    

    const Structure = () => {
        const currentSize = SizeInfo();

        const Dimensions = {
            "height" : currentSize.height ,
            "width" : currentSize.width 
        }

        return (
            <div>
                <div style={{'height' : `${percentage(Dimensions.width , 2)}px`}}></div>
                < HomeButton SizeData = { Dimensions } />
                < PoweredBy SizeData = { Dimensions } />
                <div style={{'height' : `${percentage(Dimensions.width , 2)}px`}}></div>
            </div>
            
        )
    }

    return (
        <div style={{
            "position" : "absolute",
            "bottom" :   "0",
            "marginLeft" : "0",
            "marginRight" : "0",
            "left" : "0",
            "right" : "0",
            "backgroundColor" : "#0a0a0d"
        }}>
            < Structure />
        </div>
    )
}

export default LandingFooter