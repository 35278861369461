import Config from '../Config';


const NetworkCall = function (Result) {

    const URL = Config.url_api + "/PMG/CB/isGamerAccess";

    if(Config.practiceModeDataCB === null){

        fetch(URL , {
            method: 'GET',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
            },
            credentials : "include"
        }).then((response) => response.json())
        .then((json) => {
               Config.practiceModeDataCB = json
               return Result(Config.practiceModeDataCB)
            })
    } else {
        return Result(Config.practiceModeDataCB)
    }
}

export default NetworkCall