import React , { useState } from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import CardBackround from '../../../../Icons/CardBackground.jpg';
import useSetTimeout from "use-set-timeout";
import TableHeightCalculator from "./TableHeightCalculator";
import PlayerPositionCalculator from './PlayerPositionCalculator';
import EventScore from './SoundAssets/EventFx';
import GiveCardScore from './SoundAssets/GiveCard';

const Mover = (Value) => {
    const GameData = Value.GameData 
    const MusicOn  = Value.Music;
    const BlockShadowColor = "#00f9ff";
    const [ BlockShadowWidth  ] = useState(10);

    const currentSize = SizeInfo();

    const width = currentSize.width ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }


    const SeatOneValues = PlayerData() ;
    const SeatTwoValues = PlayerTwo() ;
    const SeatThreeValues = PlayerThree() ;
    const SeatFourValues = PlayerFour() ;

    const DealerSeat = () => {
        if(GameData === null){
            return 0
        } else if(SeatOneValues.IsDealer === true){
            return 1
        } else if(SeatTwoValues.IsDealer === true){
            return 2
        } else if(SeatThreeValues.IsDealer === true){
            return 3
        } else if(SeatFourValues.IsDealer === true){
            return 4
        } else {
            return 0
        }
        
    }

    const Dealer = DealerSeat();

    const TableHeight = TableHeightCalculator();
    const SeatOnePosition = PlayerPositionCalculator(1)
    const SeatTwoPosition = PlayerPositionCalculator(2)
    const SeatThreePosition = PlayerPositionCalculator(3)
    const SeatFourPosition = PlayerPositionCalculator(4)

    const [ Show , ShowUpdate ] = useState(null)
    const [ ShowDealer , ShowDealerUpdate ] = useState(true)
    const [ CardsDistributed , CardsDistributedUpdate ] = useState(false)

    

    useSetTimeout(()=>{ 
        ShowDealerUpdate(false)
    }, 6000)

    useSetTimeout(()=>{ ShowUpdate(true)}, 2000)

    useSetTimeout(()=>{ ShowUpdate(false)}, 6000)
        
    useSetTimeout(()=>{ CardsDistributedUpdate(true)}, 6000)
    
    const DealersPosition = () => {
        if(Dealer === SeatOneValues.Seat ){
            return SeatOnePosition
        } else if(Dealer === SeatTwoValues.Seat ){
            return SeatTwoPosition
        } else if(Dealer === SeatThreeValues.Seat ){
            return SeatThreePosition
        } else if(Dealer === SeatFourValues.Seat ){
            return SeatFourPosition
        } else {
            return ({

            })
        }
    }

    const GetDealerPosition = () => {
        var DealerSeatPosition = DealersPosition()
        if(Dealer === SeatOneValues.Seat ){
            if(DealerSeatPosition.top === "92%"){
                DealerSeatPosition.top = "84%"
            } else if(DealerSeatPosition.top === "95%"){
                DealerSeatPosition.top = "89%"
            } else if(DealerSeatPosition.top === "96%"){
                DealerSeatPosition.top = "88%"
            }
        } else if(Dealer === SeatTwoValues.Seat ){
            DealerSeatPosition.left = "20%"
        } else if(Dealer === SeatThreeValues.Seat ){
            if(DealerSeatPosition.top === "4%"){
                DealerSeatPosition.top = "12%"
            } else if(DealerSeatPosition.top === "2%"){
                DealerSeatPosition.top = "10%"
            } 
        } else if(Dealer === SeatFourValues.Seat ){
            DealerSeatPosition.left = "88%"
        } 

        return DealerSeatPosition
    }

    const CardTravelAnimation = () => {
        return (<motion.div
            initial={{ opacity : 0.1}}
            animate={{ opacity : 1}}
            transition={{ delay : Delay , duration : 0.5 , repeat : 13}}
        >
            < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 10)}px`,
                "borderRadius" : `${percentage(width , 1)}px`,
                'width' : `${percentage(width , 7)}px`,
                "borderColor" : "black",
                "borderStyle" : "solid",
                "borderWidth" : `${percentage(width , 0.35)}px`,
                "boxShadow" : `0 0 ${percentage(width , 3)}px ${"black"}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , 3)}px ${"black"}`,
                "MozBoxShadow" : `0 0 ${percentage(width , 3)}px ${"black"}`,
                }}
                src={ CardBackround } />
        </motion.div>)
    }

    const Delay = 0.2 ;
    
    return (
        <div>
            { GameData !== null &&
                <div style={{
                    "height" : `${TableHeight}px`,
                    "width" : `${percentage(currentSize.width , 75)}px`,
                    "margin" : "0 auto",
                    "borderTopLeftRadius" : "35%",
                    "borderTopRightRadius" : "35%",
                    "borderBottomLeftRadius" : "25%",
                    "borderBottomRightRadius" : "25%",
                    'position' : "absolute",  
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",          
                }}>

                    { ShowDealer === true &&
                        <motion.div 
                        style={{
                                'position' : "absolute",                             
                                "transform" : "translate(-50%, -50%)",
                                "zIndex" : "50",
                                "fontSize" : `${percentage(width , 2)}px`,
                                "width" : `${percentage(width , 20)}px`,
                                "background" : "rgba(0,0,0,0.1)",
                                "color" : "#00f9ff",
                                "textAlign" : "center",
                                "borderTopLeftRadius" : "50%",
                                "borderTopRightRadius" : "50%",
                                "borderBottomLeftRadius" : "50%",
                                "borderBottomRightRadius" : "50%",
                                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                            }}
                                initial={{
                                    y : percentage( TableHeight , 50),
                                    x : percentage(width , 0)
                                }}
                                animate={{
                                    y : percentage( TableHeight , 27),
                                    x : percentage(width , 27.5)
                                 }}>
                                <div style={{
                                    "height" :  `${percentage(width , 2)}px`,
                                }}></div>
                                <div className="pulsate-fwd">DISTRIBUTING</div>
                                <div className="pulsate-fwd">CARDS</div>
                                <div style={{
                                    "height" :  `${percentage(width , 2)}px`,
                                }}></div>

                                { MusicOn === true && < EventScore Status={ MusicOn } /> }
                        </motion.div>
                    }

                    { CardsDistributed === true &&
                        <motion.div 
                        style={{
                                'position' : "absolute",                                 
                                "transform" : "translate(-50%, -50%)",
                                "zIndex" : "50",
                                "fontSize" : `${percentage(width , 2)}px`,
                                "width" : `${percentage(width , 20)}px`,
                                "background" : "rgba(0,0,0,0.1)",
                                "color" : "#00f9ff",
                                "textAlign" : "center",
                                "borderTopLeftRadius" : "50%",
                                "borderTopRightRadius" : "50%",
                                "borderBottomLeftRadius" : "50%",
                                "borderBottomRightRadius" : "50%",
                                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                            }}
                                initial={{
                                    y : percentage( TableHeight , 27),
                                    x : percentage(width , 27.5),
                                    rotate : 180
                                }}
                                animate={{
                                    y : percentage( TableHeight , 27),
                                    x : percentage(width , 27.5),
                                    rotate : 0
                                }}>
                                <div style={{
                                    "height" :  `${percentage(width , 2)}px`,
                                }}></div>
                                <div className="pulsate-fwd">CARDS</div>
                                <div className="pulsate-fwd">DISTRIBUTED</div>
                                <div style={{
                                    "height" :  `${percentage(width , 2)}px`,
                                }}></div>

                                { MusicOn === true && < EventScore Status={ MusicOn } /> }
                        </motion.div>
                    }
    
                    { ShowDealer === true &&
                        <motion.div 
                        initial={{
                            'position' : "absolute",                             
                            "top" : "31%",
                            "left" : "50%",
                            "transform" : "translate(-50%, -50%)",
                            "zIndex" : "50"
                        }}
                        animate={GetDealerPosition()}
                        transition={{ duration : 0.5 }}
                        >
                            <motion.div className="BlurIt"
                            initial={{ scale : 0.40}}
                            animate={{ scale : 0.20}}
                            style={{
                                "fontSize" : `${percentage(width , 11)}px`,
                                "width" : `${percentage(width , 70)}px`,
                                "color" : "#00f9ff",
                                "textAlign" : "center",
                                "background" : "rgba(0,0,0,0.1)",
                                "borderTopLeftRadius" : "50%",
                                "borderTopRightRadius" : "50%",
                                "borderBottomLeftRadius" : "50%",
                                "borderBottomRightRadius" : "50%",
                                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                            }}>
                               <div style={{
                                    "height" :  `${percentage(width , 11)}px`,
                                }}></div>
                                <div>DEALER</div>
                                <div style={{
                                    "height" :  `${percentage(width , 11)}px`,
                                }}></div>

                                { MusicOn === true && < EventScore Status={ MusicOn } /> }
                            </motion.div>
                        </motion.div>
                    }
                    
                    { Show === true &&
                        <motion.div>

                            <motion.div 
                                initial={ GetDealerPosition() }
                                animate={ SeatOnePosition }
                                transition={{ delay : Delay + 0.1, duration : 0.4 , repeat : Infinity}}
                            >
                                < CardTravelAnimation />
                            </motion.div>

                            <motion.div 
                                initial={GetDealerPosition()}
                                animate={ SeatTwoPosition }
                                transition={{ delay : Delay + 0.1 , duration : 0.4 , repeat : Infinity}}
                            >
                                < CardTravelAnimation />
                            </motion.div>

                            <motion.div 
                                initial={GetDealerPosition()}
                                animate={ SeatThreePosition }
                                transition={{ delay : Delay + 0.2 , duration : 0.4 , repeat : Infinity}}
                            >
                                < CardTravelAnimation />
                            </motion.div>

                            <motion.div 
                                initial={GetDealerPosition()}
                                animate={ SeatFourPosition }
                                transition={{ delay : Delay + 0.3, duration : 0.4 , repeat : Infinity}}
                            >
                                < CardTravelAnimation />
                            </motion.div>

                            { MusicOn === true && < GiveCardScore Status={ Show } /> }
                        </motion.div>
                    }
                
    
                </div>
            }
        </div>
        
    )
    
}

            

export default Mover