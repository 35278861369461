import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LandingMobile from './MobileComponents/LandingMobile';
import UUIDonBoard from './MobileComponents/UUIDonBoard';
import Home from './MobileComponents/Home';

import F2PProfileBluff from './MobileComponents/FreeToPlayBluff/Profile';
import F2PProfileStatsBluff from './MobileComponents/FreeToPlayBluff/ProfileStats';
import F2PBalanceBluff from './MobileComponents/FreeToPlayBluff/Balance';
import F2PSettingsBluff from './MobileComponents/FreeToPlayBluff/Settings';
import F2PLevelBluff from './MobileComponents/FreeToPlayBluff/Level';
import F2PRewardBluff from './MobileComponents/FreeToPlayBluff/Rewards';
import F2PMatchMakerBluff from './MobileComponents/FreeToPlayBluff/MatchMaker';
import F2PGameTableLauncherBluff from './MobileComponents/FreeToPlayBluff/GameTableLauncher';
import F2PMatchMakerBluffPlayAgain from './MobileComponents/FreeToPlayBluff/MatchMakerPlayAgain';

import F2PMatchMakerBluffAgnostic from './MobileComponents/FreeToPlayBluff/MatchMakerAgnostic';

import F2PProfileCB from './MobileComponents/FreeToPlayCallBreak/Profile';
import F2PProfileStatsCB from './MobileComponents/FreeToPlayCallBreak/ProfileStats';
import F2PBalanceCB from './MobileComponents/FreeToPlayCallBreak/Balance';
import F2PSettingsCB from './MobileComponents/FreeToPlayCallBreak/Settings';
import F2PLevelCB from './MobileComponents/FreeToPlayCallBreak/Level';
import F2PRewardCB from './MobileComponents/FreeToPlayCallBreak/Rewards';
import F2PMatchMakerCB from './MobileComponents/FreeToPlayCallBreak/MatchMaker';
import F2PMatchMakerCBPlayAgain from './MobileComponents/FreeToPlayCallBreak/MatchMakerPlayAgain';
import F2PGameTableLauncherCB from './MobileComponents/FreeToPlayCallBreak/GameTableLauncher';

import F2PMatchMakerCBAgnostic from './MobileComponents/FreeToPlayCallBreak/MatchMakerAgnostic';

import Config from './GlobalFunctions/Config';

// LogoutWrapper component
const LogoutWrapper = ({ children }) => {
  const [loggedOut, setLoggedOut] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // Send logout request and wait for completion before rendering children
    fetch('/api/PMG/logout', { method: 'POST', credentials: 'include' })
      .then((response) => {
        if (response.ok) {
          setLoggedOut(true); // Proceed to render children after logout
        } else {
          console.error("Logout failed");
          history.push('/'); // Redirect to home if logout fails
        }
      })
      .catch((error) => {
        console.error("An error occurred during logout:", error);
        history.push('/'); // Redirect on error
      });
  }, [history]);

  // Show a loading state while logging out
  if (!loggedOut) return <div>Logging out...</div>;

  // Render the child component once logout is complete
  return children;
};

const Content = () => {
  const location = useLocation();
  const CurrentBuildVersion = Config.app_version;

  const NoMatchPage = () => {
    return (
      <div onClick={() => window.location.replace("/?" + CurrentBuildVersion)}>
        <div style={{
          position: "fixed",
          height: "100%",
          width: "100%",
          top: "0px",
          left: "0px",
          backgroundImage: "linear-gradient(rgba(0, 0, 0 ,0.8), rgba(0,0,0,0.9), rgba(0,0,0,0.9), rgba(0,0,0,0.9), rgba(0, 0, 0 ,0.8))",
          zIndex: "1000",
          overflowY: "none",
          overflowX: "none",
          fontFamily: "HighlightText",
          fontSize: "1.5em",
          color: "#00f9ff",
        }}>
          <div style={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}>
            <div style={{
              margin: "0 auto",
              width: "70%",
              textAlign: "center"
            }}>
              <div>404</div>
              <div style={{
                marginTop: "2%",
              }}>NOT FOUND</div>

              <div style={{
                marginTop: "25%",
              }}>
                <div>CLICK HERE</div>
                <div>TO GO BACK TO</div>
                <div>HOME PAGE</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={Home} />
        <Route exact path="/landingmobile" component={LandingMobile} />
        <Route exact path="/uuidonboard" component={UUIDonBoard} />

        <Route exact path="/freetoplay/profile" component={F2PProfileBluff} />
        <Route exact path="/freetoplay/profilestats" component={F2PProfileStatsBluff} />
        <Route exact path="/freetoplay/settings" component={F2PSettingsBluff} />
        <Route exact path="/freetoplay/balance" component={F2PBalanceBluff} />
        <Route exact path="/freetoplay/level" component={F2PLevelBluff} />
        <Route exact path="/freetoplay/rewards" component={F2PRewardBluff} />
        <Route exact path="/freetoplay/matchmaker" component={F2PMatchMakerBluff} />
        <Route exact path="/freetoplay/matchmaker_playagain" component={F2PMatchMakerBluffPlayAgain} />
        <Route exact path="/freetoplay/gametable" component={F2PGameTableLauncherBluff} />

        {/* Routes with logout before rendering */}
        <Route exact path="/freetoplay/bluff/matchmaker/agnostic">
          <LogoutWrapper>
            <F2PMatchMakerBluffAgnostic />
          </LogoutWrapper>
        </Route>

        <Route exact path="/freetoplay/cb/matchmaker/agnostic">
          <LogoutWrapper>
            <F2PMatchMakerCBAgnostic />
          </LogoutWrapper>
        </Route>

        <Route exact path="/freetoplay/cb/profile" component={F2PProfileCB} />
        <Route exact path="/freetoplay/cb/profilestats" component={F2PProfileStatsCB} />
        <Route exact path="/freetoplay/cb/settings" component={F2PSettingsCB} />
        <Route exact path="/freetoplay/cb/balance" component={F2PBalanceCB} />
        <Route exact path="/freetoplay/cb/level" component={F2PLevelCB} />
        <Route exact path="/freetoplay/cb/rewards" component={F2PRewardCB} />
        <Route exact path="/freetoplay/cb/matchmaker" component={F2PMatchMakerCB} />
        <Route exact path="/freetoplay/cb/matchmaker_playagain" component={F2PMatchMakerCBPlayAgain} />
        <Route exact path="/freetoplay/cb/gametable" component={F2PGameTableLauncherCB} />

        <Route component={NoMatchPage} /> {/* Fallback 404 route */}
      </Switch>
    </AnimatePresence>
  );
};

export default Content;