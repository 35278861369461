import React from 'react';
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import TableHeightCalculator from './TableHeightCalculator';
import TableCardPileDisplay from './TableCardPileDisplayBox';
import PlayerOneHandCard from './HandAssets/PlayerOneHandCard';
import PlayerTwoHandCard from './HandAssets/PlayerTwoHandCard';
import PlayerThreeHandCard from './HandAssets/PlayerThreeHandCard';
import PlayerFourHandCard from './HandAssets/PlayerFourHandCard';
import PlayingCardCreator from './CardControlElements/PlayingCardCreator';

const CurrentBluff = (Values) => {
        const SeatSize = 20
        const GameData = Values.GameData;
        const Music = Values.Music;
        const ShadowWidth = 4;
        const ShadowColor = "black";

        function SizeInfo() {
            const size = useResize()
            
            return size;
        }

        const currentSize = SizeInfo();
        const TableHeight = TableHeightCalculator();

        function percentage(num, per)
        {
            return (num/100)*per;
        }

        const ClaimProvider = (Value) => {
            if(Value === "A"){
                return ("ACE")
            } else if(Value === "K"){
                return ("KING")
            } else if(Value === "Q"){
                return ("QUEEN")
            } else if(Value === "J"){
                return ("JACK")
            } else if(Value === "10"){
                return ("TEN")
            } else if(Value === "9"){
                return ("NINE")
            } else {
                return ("WTF")
            }
        }

        const Dimensions = {
            "height" : currentSize.height ,
            "width" : currentSize.width 
        }

        return (
        <div style={{
            "height" : "100%",
            "width" : "100%",
            "position": "absolute",
        }}>
            <motion.div
                animate={{ x: 0 , y: 0}}
                style={{
                    "position" : "absolute",
                    "top" : `${percentage(TableHeight , 52)}px`,
                    "left" : `${percentage(currentSize.width , 13)}px`,
                    "width" : `${percentage(currentSize.width , 75)}px`,
                    "textAlign" : "center",
                    "color" : "grey",
                    "fontSize" : `${percentage(currentSize.width , 10)}px`,
            }}>
                    <div style={{                    
                        "width" : `${percentage(currentSize.width , SeatSize) * 2.5}px`,
                        "height" : `${percentage(currentSize.width , SeatSize/1.5)}px`,                                        
                        "margin" : "0 auto"
                    }}>                    

                    { GameData.CurrentBluff.Bluffer.Seat === 1 && < TableCardPileDisplay GameData={GameData}/> }
                    { GameData.CurrentBluff.Bluffer.Seat === 2 && < TableCardPileDisplay GameData={GameData}/> }
                    { GameData.CurrentBluff.Bluffer.Seat === 3 && < TableCardPileDisplay GameData={GameData}/> }
                    { GameData.CurrentBluff.Bluffer.Seat === 4 && < TableCardPileDisplay GameData={GameData}/> }

                    </div>
            </motion.div>

            <motion.div 
            initial={{ opacity : 0 }}
            animate={{ opacity : 1 }}
            transition={{ duration : 0.5 }}
            style={{
                "position" : "absolute",
                "top" : "10%",
                "left" : "3%",                
                "borderRadius" : "10%",
                "width" : `${percentage(currentSize.width , 30)}px`,
                "height" : `${percentage(currentSize.width , 20)}px`,                
            }}>
                

                    <motion.div style={{
                        "position" : "absolute",
                        "left" : "50%",
                        "top" : "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        

                        <motion.div 
                        style={{
                            "margin" : "0 auto",
                            "display" : "flex",
                            "justifyContent" : "center"
                        }}>
                            <motion.div style={{
                                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                            }}>
                                < PlayingCardCreator CardData={{
                                    Suit : "Spade",
                                    Series : GameData.CurrentBluff.Claim
                                }} Dimensions = { Dimensions } />
                            </motion.div>

                            <motion.div>

                                <motion.div animate={{
                                    "height" : `${percentage(currentSize.width , 1)}px`,
                                }}></motion.div>

                                <div style={{   
                                    "fontSize" : `${percentage(currentSize.width , 2.5)}px`,       
                                    "width" : `${percentage(currentSize.width , 15)}px`,   
                                    "backgroundColor" : "rgba(0,0,0,0.5)",  
                                    "paddingLeft" : `${percentage(currentSize.width , 1.15)}px`,
                                    "borderTopRightRadius" : "10%",
                                    "borderBottomRightRadius" : "10%",                                    
                                    "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                }}>{ ClaimProvider(GameData.CurrentBluff.Claim) }</div>

                                <motion.div animate={{
                                    "height" : `${percentage(currentSize.width , 2)}px`,
                                }}></motion.div>

                                <div style={{
                                    "fontSize" : `${percentage(currentSize.width , 2)}px`,
                                    "width" : `${percentage(currentSize.width , 15)}px`,
                                    "backgroundColor" : "rgba(0,0,0,0.5)",
                                    "paddingLeft" : `${percentage(currentSize.width , 1.15)}px`,
                                    "borderTopRightRadius" : "10%",
                                    "borderBottomRightRadius" : "10%",
                                    "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                }}>SERIES RUNNING</div>

                            </motion.div>

                            
                        </motion.div>
                        

                        
                        
                    </motion.div>
                
            </motion.div>

            
            { GameData.CurrentBluff.Bluffer.Seat === 1 && < PlayerOneHandCard  Music = { Music } />  }
            { GameData.CurrentBluff.Bluffer.Seat === 2 && < PlayerTwoHandCard Music = { Music } />  }
            { GameData.CurrentBluff.Bluffer.Seat === 3 && < PlayerThreeHandCard Music = { Music } />  }
            { GameData.CurrentBluff.Bluffer.Seat === 4 && < PlayerFourHandCard Music = { Music } />  }

        </div>
    )
}

export default CurrentBluff