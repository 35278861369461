import React from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import Icon from '../../../../Icons/ChallengeWinner.png';

const Mover = () => {
    const currentSize = SizeInfo();

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    return (
        <motion.div >        
            <motion.div
                style={{
                        "position" : "absolute",
                        "width" : `${percentage(currentSize.width , 12)}px`,
                        "height" : `${percentage(currentSize.width , 12)}px`,
                        "borderWidth" : `${percentage(currentSize.width , 1)}px`,
                        "borderRadius" : "50%",
                        "borderStyle" : "solid",
                        "borderColor" : "#FDA80D",
                        "textAlign" : "center",
                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                }}>
                < img alt="Avatar" 
                    style={{
                    'height' : `auto`,
                    "marginTop" : `${percentage(currentSize.width , 1)}px`,                    
                    'width' : `${percentage(currentSize.width , 10)}px`,                    
                    }}
                src={ Icon } />
            </motion.div>    
            
        </motion.div>
    )
    
}

            

export default Mover