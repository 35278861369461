import React from "react";
import { motion }  from "framer-motion";
import BootCollectionAnimation from './BootAssets/BootCollectionAnimation';
import PrizePool from './BootAssets/PrizePool';

const Mover = (Value) => {
    const GameData = Value.GameData ;
    const ActiveStateMaker = Value.ActiveStateMaker ;
    const SoundStatus = Value.SoundStatus ; 

    if(GameData.BootDeduction === true){
        return(
            <motion.div style={{
                'position' : "absolute",  
                "zIndex" : "22",
                "height" :  `100%`,
                "width" : `100%`,
            }}>
                < BootCollectionAnimation SoundStatus = { SoundStatus } GameData = { GameData } />
            </motion.div>
        )
    } else if(GameData.BootDeduction === false && GameData.IsBidding === false){
        return(
            <motion.div style={{
                'position' : "absolute",  
                "zIndex" : "20",
                "height" :  `100%`,
                "width" : `100%`,
            }}>
                < PrizePool SoundStatus = { SoundStatus } GameData = { GameData } OpacityValue={Value.OpacityValue}  ActiveStateMaker = { ActiveStateMaker } /> 
            </motion.div>
        )
    } else {
        return (<div></div>)
    }
    
}

            

export default Mover