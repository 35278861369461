import React  from 'react';
import useResize from 'use-resize';  
import ChangeMode from '../ChangeMode';
import ProfileNavigationBar from '../ProfileNavigationBar';
import InfoScreen from '../ProfileFilterComponents/Rewards'
import BackButton from '../ProfileFilterComponents/BackButton'

const Section = () => {

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    return (<div style={{
                "height" : `${Dimensions.height}px`,
                "width": `${Dimensions.width}px`,
            }} className="BlurIt">
                <div style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>

                    <div style={{
                        "position" : "fixed",
                        "top" : "3%",
                        "left" : "0%",
                        "zIndex" : "50"
                    }}>
                        < ChangeMode SizeData = { Dimensions } />
                    </div>

                    < ProfileNavigationBar SizeData = { Dimensions } />

                    < InfoScreen />

                    < BackButton />
                </div>                
    </div>)
}

export default Section