import React , { useState } from 'react';
import { motion } from 'framer-motion';
import useResize from 'use-resize';  
import socket from '../../../SocketConnectionManager';
import Config from '../../../GlobalFunctions/Config' ;
import GameTableEngine from './GameTableEngine';
import IsAccess from '../../../GlobalFunctions/PracticeMode/IsGamerAccess'
import Timer from './GameTableAssets/Timer'
import Bluffer from './GameTableAssets/Bluffer'
import Challenger from './GameTableAssets/Challenger'
import TableCardPile from './GameTableAssets/TableCardPile'
import CurrentChallengeInfoBar from './GameTableAssets/CurrentChallengeInfoBar';
import PlayerCardControls from './GameTableAssets/PlayerCardControls';
import AdditionalControls from './GameTableAssets/AdditionalControls';
import StartingRound from './GameTableAssets/StartingRound';
import BootCollectionAnimation from './GameTableAssets/BootCollectionAnimation'
import CardDistributionAnimation from './GameTableAssets/CardDistributionAnimation'

const Section = () => {
    const [ MusicOn , MusicOnUpdate ] = useState(Config.DefaultBluffSound);
    const [ isAgnostic , isAgnosticUpdate ] = useState(null);
    const [ Connected , ConnectedUpdate ] = useState(null);
    const [ isDisconnected , isDisconnectedUpdate ] = useState(null);
    const [ isEvicted , isEvictedUpdate ] = useState(null);
    const [ isTable , isTableUpdate ] = useState(null);
    const [ TableData , TableDataUpdate ] = useState(null);

    if(Config.DefaultBluffSound === null){
        Config.DefaultBluffSound = true
        MusicOnUpdate(true)
    }

    if(isAgnostic === null){
        isAgnosticUpdate(true)

        if(Config.practiceModeData !== null){
            isAgnosticUpdate(Config.practiceModeData.isAgnostic)
        } else {
            
                IsAccess(function (AccessDataValue){
                    Config.practiceModeData = AccessDataValue;
                    isAgnosticUpdate(Config.practiceModeData.isAgnostic)
                })              
        }
        
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    if(Connected === null && Config.practiceModeTableAccessPass !== null){
        ConnectedUpdate(false);
        socket.emit('MatchMakingAccessRequest', Config.practiceModeTableAccessPass);
    }

    socket.on('AccessGranted' , function (){
        ConnectedUpdate(true);
        if(isAgnostic === null){
            isAgnosticUpdate(Config.practiceModeData.isAgnostic)
        }
        socket.emit('UserConnectedToTable' , Config.practiceModeData._id);
    })
    
    socket.on('GameStateUpdate', function (Data){
        isTableUpdate(true)
        TableDataUpdate(Data)
        if(isDisconnected === true){
            isDisconnectedUpdate(null);
        }

        if(isAgnostic === null){
            isAgnosticUpdate(Config.practiceModeData.isAgnostic)
        }
    })

    socket.on('disconnect' , function (){
        if(isDisconnected === null){
            isDisconnectedUpdate(true);
        }
    })

    socket.on('Evicted' , function (){
        if(isEvicted === null){
            isEvictedUpdate(true);
        }
    })

    socket.on('onStopMusic' , function (){
        MusicOnUpdate(false)
    })

    socket.on('onStartMusic' , function (){
        MusicOnUpdate(true)
    })

    const RejoinTable = () => {
        IsAccess(function (){
            socket.emit('UserConnectedToTable' , Config.practiceModeData._id);
        })
    }

    const EvictionClick = () => {
        window.location.replace("/freetoplay/profile")
    }

    if(Config.practiceModeData === null && Connected === null){
        RejoinTable()
    }
    

    function percentage(num, per)
    {
        return (num/100)*per;
    }


        return (<div style={{
            "height" : `${currentSize.height}px`,
            "width": `${currentSize.width}px`,
        }} className="BlurIt" onClick={()=> {}}>
                { isTable === null && isDisconnected === null && isEvicted === null &&

                <div>

                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    <div className="TableBackgroundOverlay" style={{
                        "position" : "fixed",
                        "zIndex" : "20",
                        "height" :  `100%`,
                        "width" : `100%`,
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        < GameTableEngine GameData = {null} />
                    </div>
                </div> 

                <div style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "21",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>
                    <div style={{
                        "fontSize" : "1.5em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        <div className="pulsate-fwd">
                           <div>CONNECTING TO TABLE</div>
                        </div>
                    </div>
                </div>   

                </div>
            }

            { isTable === true && TableData !== null && isDisconnected === null && isEvicted === null &&
                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    { TableData !== null &&
                        <div className="TableBackgroundOverlay" style={{
                            "position" : "fixed",
                            "zIndex" : "20",
                            "height" :  `100%`,
                            "width" : `100%`,
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                        }}>
                            < GameTableEngine GameData = {TableData} isAgnostic = {isAgnostic} />
                        </div>
                    }
                   
                </div> 
            }

            { isTable === true && TableData !== null && isDisconnected === null && isEvicted === null &&
                <motion.div 
                className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    
                    
                    { TableData !== null &&
                        < Timer GameData = {TableData}  Music = { MusicOn } /> 
                    }

                    { TableData !== null &&
                        < Bluffer GameData = {TableData}  Music = { MusicOn } /> 
                    }

                    { TableData !== null &&
                        < Challenger GameData = {TableData}  Music = { MusicOn } /> 
                    }

                    { TableData !== null && TableData.CurrentBluff !== null &&
                        < TableCardPile GameData = {TableData}  Music = { MusicOn } /> 
                    }

                    { TableData !== null && TableData.CurrentChallenge !== null &&
                        < CurrentChallengeInfoBar GameData = {TableData} Music = { MusicOn } /> 
                    }

                    { TableData !== null && TableData.DeductBoot === null &&
                        < StartingRound  Music = { MusicOn } /> 
                    }

                    { TableData !== null && TableData.DeductBoot === true && TableData.CardDistribution === null &&
                        < BootCollectionAnimation  Music = { MusicOn } /> 
                    }

                    { TableData !== null && TableData.DeductBoot === false && TableData.CardDistribution === true &&
                        < CardDistributionAnimation GameData={ TableData } Music = { MusicOn } /> 
                    }

                </motion.div>
            }

            { TableData !== null && <PlayerCardControls GameData = {TableData} isAgnostic = { isAgnostic } /> }
            
            < AdditionalControls TableData={ TableData } Music = { MusicOn } isAgnostic = { isAgnostic } /> 

            { isDisconnected === true && isEvicted === null &&
                    <div className="TableBackground" style={{
                        "position" : "fixed",
                        "top" : "0%",
                        "left" : "0%",
                        "zIndex" : "200",
                        "height" :  `${percentage(currentSize.height , 100)}px`,
                        "width" : `${percentage(currentSize.width , 100)}px`,
                    }}>
                        <div style={{
                            "fontSize" : "1.55em",
                            "position" : "relative",
                            "top" : "50%",
                            "left": "50%",
                            "zIndex" : "22",
                            "transform" : "translate(-50%, -50%)",
                            "textAlign" : "center",
                            "color" : "#00f9ff",
                            "fontFamily" : "HighlightText",
                        }}>
                            <div>
                               <div>YOU WERE DISCONNECTED</div>
                                   
                               <div>FROM THE TABLE</div>

                               <div onClick={()=> window.location.reload()} style={{
                                   "marginTop" : "10%",
                                   "backgroundColor" : "black",
                                   "color" : "white",
                                   "paddingTop" : "2%",
                                   "paddingBottom" : "2%"
                               }}>
                                   CLICK HERE TO REJOIN
                                </div>
                            </div>
                        </div>

                        <div className="TableBackgroundOverlay" style={{
                            "position" : "fixed",
                            "zIndex" : "21",
                            "height" :  `100%`,
                            "width" : `100%`,
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                            "backgroundColor" : "rgba(0,0,0,0.9)"
                        }}>
                            
                        </div>



                        { TableData !== null &&
                            <div className="TableBackgroundOverlay" style={{
                                "position" : "fixed",
                                "zIndex" : "20",
                                "height" :  `100%`,
                                "width" : `100%`,
                                "top" : "50%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",
                            }}>
                                < GameTableEngine GameData = {TableData} />
                            </div>
                        }
    
                    </div>  
            }

            { isDisconnected === null && isEvicted === true &&
                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>
                    <div style={{
                        "fontSize" : "1.55em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "zIndex" : "22",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        <div>
                           <div>YOU WERE EVICTED</div>
                               
                           <div>FROM THE TABLE</div>

                           { isAgnostic === false &&
                           
                                <div onClick={()=> EvictionClick()} style={{
                                    "marginTop" : "10%",
                                    "backgroundColor" : "black",
                                    "color" : "white",
                                    "paddingTop" : "2%",
                                    "paddingBottom" : "2%"
                                    }}>
                                    CLICK HERE TO GO BACK TO PROFILE
                                </div>
                           }

                        </div>
                    </div>

                    <div className="TableBackgroundOverlay" style={{
                        "position" : "fixed",
                        "zIndex" : "21",
                        "height" :  `100%`,
                        "width" : `100%`,
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                        "backgroundColor" : "rgba(0,0,0,0.9)"
                    }}>
                        
                    </div>


                    { TableData !== null &&
                        <div className="TableBackgroundOverlay" style={{
                            "position" : "fixed",
                            "zIndex" : "20",
                            "height" :  `100%`,
                            "width" : `100%`,
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                        }}>
                            < GameTableEngine GameData = {TableData} />
                        </div>
                    }
                    

                </div>  
            
            }

            { TableData === null && isDisconnected === true &&
                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "200",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>
                    <div style={{
                        "fontSize" : "1.55em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "zIndex" : "22",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        <div>
                           <div>YOU WERE DISCONNECTED</div>
                               
                           <div>FROM THE TABLE</div>

                           <div onClick={()=> window.location.reload()} style={{
                               "marginTop" : "10%",
                               "backgroundColor" : "black",
                               "color" : "white",
                               "paddingTop" : "2%",
                               "paddingBottom" : "2%"
                           }}>
                               CLICK HERE TO RECONNECT
                            </div>
                        </div>
                    </div>
                </div>
            }

            
    </div>)
}

export default Section