const PositionCalculator = (PlayerSeat) => {


    if(PlayerSeat === 1){
        return(
            {
                'position' : "absolute", 
                "zIndex" : "22",
                "top" : "65%",
                "left" : "50%",
                "transform" : "translate(-50%, -50%)",
            }
        )
    } else if(PlayerSeat === 2){
        return (
            {
                'position' : "absolute", 
                "zIndex" : "22",
                "top" : "30%",
                "left" : "2%",
                "transform" : "translate(-50%, -50%)",
            }
        )
    } else if(PlayerSeat === 3){
        return(
            {
                'position' : "absolute", 
                "zIndex" : "22",
                "top" : "2%",
                "left" : "50%",
                "transform" : "translate(-50%, -50%)",
            }
        )
    } else if(PlayerSeat === 4){
        return (
            {
                'position' : "absolute", 
                "zIndex" : "22",
                "top" : "30%",
                "left" : "99%",
                "transform" : "translate(-50%, -50%)",
            }
        )
    } else {
       return ({})
    }
    
}

export default PositionCalculator