import Config from '../Config';

const NetworkCall = function (Result) {

    const URL = Config.url_api + "/PMG/CreateFreeAgnosticAccount";

    const details = {
        'uuid' : "somethingNice" ,
        'aunikey' : "Madness"
    };

    var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
    formBody = formBody.join("&");

    fetch(URL , {
        method: 'POST',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
        },
        body: formBody,
        credentials : "include"
    }).then((response) => response.json())
    .then((json) => {
        return Result(json)
    })
}

export default NetworkCall