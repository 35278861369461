module.exports = {
    'url_api' : '/api',
 //   'url_api' : 'http://192.168.0.101:5001/api',
    'app_version' : "v3",
    'currentOrientation' : null,
    'practiceModeAccess' : null,
    'practiceModeAgnosticAccess' : null,
    'practiceModeData' : null,
    'practiceModeTableAccessPass' : null,
    'practiceModeCurrentBluffer' : null,
    'practiceModeCBTableAccessPass' : null,
    'practiceModeDataCB' : null,
    'practiceModeData325' : null,
    'practiceModeData78' : null,
    'HomeButtonAccess' : null,
    'DefaultCallbreakSound' : null,
    'DefaultBluffSound' : null,
    'AgnosticCallbreak' : null,
    'AgnosticAccessPoint' : null,
};