import React , { useState } from 'react';
import { motion } from "framer-motion";

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"

    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);
    const Size = Value.SizeData ;

    


    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        Value.TutorialCallback(true)
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
    const height = Size.height ;

    return (
    <div>
        <div  style={{
            "height" : `${percentage(height , 15)}px`,
            "width" : `${percentage(width , 48)}px`,
        }}>
            <div className="BlurIt" style={{
                "fontSize" : `${percentage(width , 8.5)}px`,
                "width" : `${percentage(width , 40)}px`,                
                "textAlign" : "center",
                "backgroundImage" : BackgroundColor ,
                "borderRadius" :"10%",
                "position" : "relative",
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
                "color" : "#00f9ff",
                "borderColor" : "black",
                "borderStyle" : "solid",
                "borderWidth" : `${percentage(width , 0.5)}px`,
                "fontFamily" : "HighlightText"
            }}>
                <div style={{"height" : `${percentage(width , 3)}px`}}></div>
                <motion.div onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>
                    <div className="TextAwesomeNess">TUTORIAL</div>
                </motion.div>
                <div style={{"height" : `${percentage(width , 3)}px`}}></div>
            </div>
        </div>
            
    </div>)
}

export default Section