import React , { useState } from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import TableHeightCalculator from "./TableHeightCalculator";
import EventScore from './SoundAssets/HandWon';

const Mover = (Value) => {
    const BlockShadowColor = "#00f9ff";
    const [ BlockShadowWidth  ] = useState(10);
    const MusicOn  = Value.Music;

    const currentSize = SizeInfo();

    const width = currentSize.width ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const TableHeight = TableHeightCalculator();
    
    return (
        <div style={{
            'position' : "absolute",  
            "zIndex" : "22",
            "height" :  `100%`,
            "width" : `100%`,
        }}>
                 <div style={{
                    "height" : `${TableHeight}px`,
                    "width" : `${percentage(currentSize.width , 75)}px`,
                    "margin" : "0 auto",
                    "borderTopLeftRadius" : "35%",
                    "borderTopRightRadius" : "35%",
                    "borderBottomLeftRadius" : "25%",
                    "borderBottomRightRadius" : "25%",
                    'position' : "absolute",  
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",          
                }}>
                        <motion.div 
                        style={{
                            'position' : "absolute",                             
                            "transform" : "translate(-50%, -50%)",
                            "zIndex" : "50",
                            "fontSize" : `${percentage(width , 2)}px`,
                            "width" : `${percentage(width , 20)}px`,
                            "background" : "rgba(0,0,0,0.1)",
                            "color" : "#00f9ff",
                            "textAlign" : "center",
                            "borderTopLeftRadius" : "50%",
                            "borderTopRightRadius" : "50%",
                            "borderBottomLeftRadius" : "50%",
                            "borderBottomRightRadius" : "50%",
                            "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                            "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                            "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                        }}
                        initial={{
                            y : percentage( TableHeight , 50),
                            x : percentage(width , 27.5)
                         }}
                        animate={{
                           y : percentage( TableHeight , 27),
                           x : percentage(width , 27.5)
                        }}>
                            <div style={{
                                "height" :  `${percentage(width , 2)}px`,
                            }}></div>
                            <div className="pulsate-fwd">FINALIZING</div>
                            <div className="pulsate-fwd">BIDS</div>
                            <div style={{
                                "height" :  `${percentage(width , 2)}px`,
                            }}></div>

                            { MusicOn === true && < EventScore Status={ MusicOn } /> }
                        </motion.div>
                </div>
        </div>
        
    )
    
}

            

export default Mover