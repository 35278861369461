import React , { useState } from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import useSetTimeout from "use-set-timeout";
import TableHeightCalculator from "./TableHeightCalculator";
import PlayerPositionCalculator from './PlayerPositionCalculator';
import ChipIcon from '../../../../Icons/Chips.png';
import EventScore from './SoundAssets/EventFx';
import BootCollectionScore from './SoundAssets/BootCollection';

const Mover = (Value) => {
    const GameData = Value.GameData 
    const BlockShadowColor = "#00f9ff";
    const MusicOn  = Value.Music;
    const [ BlockShadowWidth  ] = useState(10);

    const currentSize = SizeInfo();

    const width = currentSize.width ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const BootTravelAnimation = () => {
        return (<motion.div
            initial={{ opacity : 0.1}}
            animate={{ opacity : 1}}
        >
            < img alt="Avatar" 
                style={{
                'height' : `${percentage(width , 10)}px`,
                "borderRadius" : `${percentage(width , 50)}px`,
                'width' : `${percentage(width , 10)}px`,
                "borderColor" : "black",
                "borderStyle" : "solid",
                "borderWidth" : `${percentage(width , 0.35)}px`
                }}
                src={ ChipIcon } />
        </motion.div>)
    }

   

    const TableHeight = TableHeightCalculator();
    const SeatOnePosition = PlayerPositionCalculator(1)
    const SeatTwoPosition = PlayerPositionCalculator(2)
    const SeatThreePosition = PlayerPositionCalculator(3)
    const SeatFourPosition = PlayerPositionCalculator(4)

    const [ Show , ShowUpdate ] = useState(null)
    const [ ShowDealer ] = useState(true)
    const [ ShowBootCollected , ShowBootCollectedUpdate ] = useState(null)
    const [ CollectingBoot , CollectingBootUpdate ] = useState(true)

    useSetTimeout(() => {        
        ShowBootCollectedUpdate(true)
    }, 3000);

    useSetTimeout(()=>{ CollectingBootUpdate(false)}, 2000)

    useSetTimeout(()=>{ ShowUpdate(true)}, 2000)
    
    const TableCenter = () => {
        return (
            {
                'position' : "absolute",                 
                "top" : "31%",
                "left" : "50%",
                "transform" : "translate(-50%, -50%)",
                "zIndex" : "50"
            }
        )
    }
    
    return (
        <div>
            { GameData !== null &&
                <div style={{
                    "height" : `${TableHeight}px`,
                    "width" : `${percentage(currentSize.width , 75)}px`,
                    "margin" : "0 auto",
                    "borderTopLeftRadius" : "35%",
                    "borderTopRightRadius" : "35%",
                    "borderBottomLeftRadius" : "25%",
                    "borderBottomRightRadius" : "25%",
                    'position' : "absolute",  
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",          
                }}>

                    { ShowDealer === true && CollectingBoot === true &&
                        <motion.div 
                            style={{
                                'position' : "absolute",                                 
                                "transform" : "translate(-50%, -50%)",
                                "zIndex" : "50",
                                "fontSize" : `${percentage(width , 2)}px`,
                                "width" : `${percentage(width , 20)}px`,
                                "background" : "rgba(0,0,0,0.1)",
                                "color" : "#00f9ff",
                                "textAlign" : "center",
                                "borderTopLeftRadius" : "50%",
                                "borderTopRightRadius" : "50%",
                                "borderBottomLeftRadius" : "50%",
                                "borderBottomRightRadius" : "50%",
                                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                            }}
                                initial={{
                                y : percentage( TableHeight , 50),
                                x : percentage(width , 0)
                                }}
                                animate={{
                                y : percentage( TableHeight , 27),
                                x : percentage(width , 27.5)
                                }}>
                                <div style={{
                                    "height" :  `${percentage(width , 2)}px`,
                                }}></div>
                                <div className="pulsate-fwd">COLLECTING</div>
                                <div className="pulsate-fwd">BOOT</div>
                                <div style={{
                                    "height" :  `${percentage(width , 2)}px`,
                                }}></div>
                                { MusicOn === true && < EventScore Status={ CollectingBoot } /> }
                            </motion.div>
                    }

                       
                    

                    { ShowBootCollected === true && Show === true && 
                        <motion.div 
                            style={{
                                'position' : "absolute",                                 
                                "transform" : "translate(-50%, -50%)",
                                "zIndex" : "50",
                                "fontSize" : `${percentage(width , 2)}px`,
                                "width" : `${percentage(width , 20)}px`,
                                "background" : "rgba(0,0,0,0.1)",
                                "color" : "#00f9ff",
                                "textAlign" : "center",
                                "borderTopLeftRadius" : "50%",
                                "borderTopRightRadius" : "50%",
                                "borderBottomLeftRadius" : "50%",
                                "borderBottomRightRadius" : "50%",
                                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                            }}
                                initial={{
                                    y : percentage( TableHeight , 27),
                                    x : percentage(width , 27.5),
                                    rotate : 180
                                }}
                                animate={{
                                    y : percentage( TableHeight , 27),
                                    x : percentage(width , 27.5),
                                    rotate : 0
                                }}>
                                <div style={{
                                    "height" :  `${percentage(width , 2)}px`,
                                }}></div>
                                <div className="pulsate-fwd">BOOT</div>
                                <div className="pulsate-fwd">COLLECTED</div>
                                <div style={{
                                    "height" :  `${percentage(width , 2)}px`,
                                }}></div>
                                { MusicOn === true && < EventScore Status={ ShowBootCollected } /> }
                            </motion.div>
                    }                    
                    
                    { Show === true && ShowBootCollected !== true &&
                        <motion.div
                            animate={{ opacity : 0}}
                            transition={{ delay : 0.4}}
                        >
                            { MusicOn === true && < BootCollectionScore Status={ Show } /> }
                            <motion.div 
                                style={ SeatOnePosition }
                                animate={ TableCenter()}
                                transition={{ duration :  1}}
                            >
                                < BootTravelAnimation />
                            </motion.div>

                            <motion.div 
                                initial={ SeatTwoPosition }
                                animate={ TableCenter()}
                                transition={{ duration :  1}}
                            >
                               < BootTravelAnimation />
                            </motion.div>

                            <motion.div 
                                initial={ SeatThreePosition }
                                animate={ TableCenter()}
                                transition={{ duration :  1}}
                            >
                                < BootTravelAnimation />
                            </motion.div>

                            <motion.div 
                                initial={ SeatFourPosition }
                                animate={ TableCenter()}
                                transition={{ duration :  1}}
                            >
                                < BootTravelAnimation />
                            </motion.div>

            
                        </motion.div>
                    }

                    
                
    
                </div>
            }
        </div>
        
    )
    
}

            

export default Mover