import Config from '../Config';

const NetworkCall = function (Data, Result) {

    const URL = Config.url_api + "/PMG/UpdateAgnosticData/Itap";

    const details = {
        'PlayerOne' : Data.PlayerOne.Winner,
        'PlayerTwo' : Data.PlayerTwo.Winner,
        'PlayerThree' : Data.PlayerThree.Winner,
        'PlayerFour' : Data.PlayerFour.Winner
    };

    var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
    formBody = formBody.join("&");

    fetch(URL , {
        method: 'POST',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
        },
        body: formBody,
        credentials : "include"
    }).then((response) => response.json())
    .then((json) => {
        return Result(json)
    })
}

export default NetworkCall