import React , { useState } from 'react';
import { motion }  from "framer-motion";
import useResize from 'use-resize'; 
import UserName from './OpponentBlockElements/UserName'
import ItapUserName from './OpponentBlockElements/ItapUserName'
import Avatar from './OpponentBlockElements/Avatar'
import Bid from './OpponentBlockElements/Bid'
import Score from './OpponentBlockElements/Score'
import Dealer from './OpponentBlockElements/Dealer'
import ITapper from './OpponentBlockElements/iTapper'
import useSetInterval from 'use-set-interval';

const Section = (Values) => {
    const PlayerValues = Values.PlayerValues;
    const isAgnostic = Values.isAgnostic ;    
    const SeatNumber = Values.Seat; 
    const GameData = Values.GameData ;
    const [ ShadowWidth ] = useState(4);
    const ShadowColor = "black";
    const BackgroundColor = "radial-gradient(rgba(0, 0, 0, 0.9) , rgba(0, 0, 0, 0.9) , rgba(0, 0, 0, 0.9))";
    const BackgroundColorFalse = "radial-gradient(rgba(0, 0, 0, 0.1) , rgba(0, 0, 0, 0.9) , rgba(0, 0, 0, 0.1))";
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const [ SeatSize ] = useState(20);

    const TurnStyle = () => {
        if(PlayerValues === null){
            return null
        } else if(PlayerValues.Turn === true){
            var StyleData = {
                scale : 1,
                rotateX : 0,    
                rotateY : 0       
            }
            
            if(Values.Seat === 1){
                
            } else if(Values.Seat === 2){
                StyleData.rotateX = -15;
                StyleData.rotateY = 10;
            } else if(Values.Seat === 3){
                StyleData.rotateX = -10;
                StyleData.rotateY = 10;
            } else if(Values.Seat === 4){
                StyleData.rotateX = 15;
                StyleData.rotateY = 10;
            }

            return StyleData
        } else {
            return ({
                scale : 1,
            })
        }
    }

    const StyleProvider = () => {
        if(PlayerValues.Turn !== true){
            return ({
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
            })
        } else if(PlayerValues.Turn === true){
            
            var StyleData = {
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
            }
            return StyleData ;
        }
        
    }

    const TurnTrue = () =>{
        const [ heightManager , heightManagerUpdate ] = useState(0);
        const MaxHeight = percentage(currentSize.width , SeatSize);
        useSetInterval(()=>{ 
            if(heightManager <= 100){
                heightManagerUpdate( heightManager +  1)
            } 
        }, 100) 

        return (
            <motion.div style={{
                "position" : "absolute",
                "width" : `${MaxHeight}px`,
                "height" : `${MaxHeight}px`,
                "borderWidth" : `${percentage(currentSize.width , 0.75)}px`,
                "top" : "50%",
                "left" : "50%",
                "transform" : "translate(-50%, -50%)",
                "borderRadius" : "50%",
            }}>
                <motion.div 
                    animate={{ 
                        height : `${percentage(MaxHeight , heightManager)}px`,
                        width : `${MaxHeight}px`,
                        backgroundColor : "rgba(0,0,0,0.9)",
                        "position" : "absolute", 
                        "zIndex" : "10",
                        "boxShadow" : `0 0 ${percentage(MaxHeight , 1)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(MaxHeight , 1)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(MaxHeight , 1)}px black`,
                    }}
                ></motion.div>

                <motion.div style={{
                        "position" : "absolute",
                        "width" : `${percentage(MaxHeight, 90)}px`,
                        "height" : `${percentage(MaxHeight, 90)}px`,
                        "borderWidth" : `${percentage(currentSize.width , 0.7)}px`,
                        "borderRadius" : "50%",
                        "borderStyle" : "solid",
                        "borderColor" : "#00f9ff",
                        "top" : "50%",
                        "left" : "50%",
                        "transform" : "translate(-50%, -50%)",
                        "boxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "WebkitBoxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "MozBoxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "zIndex" : "9"
                }}>
                    
                </motion.div>
            </motion.div>
        )
    }

    const BlockStyle = () => {
        if (PlayerValues == null){
            return ({
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
            })
        } else if( PlayerValues.Turn !== true){
            return ({
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "backgroundImage" : BackgroundColor ,
                "borderRadius" : `${percentage(currentSize.width , 5)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`, 
            })
        } else if( PlayerValues.Turn === true){
            return ({
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "borderRadius" : `${percentage(currentSize.width , 5)}px`,
                "backgroundImage" : BackgroundColorFalse ,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`, 
            })
        } else {
            return ({
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "borderRadius" : `${percentage(currentSize.width , 5)}px`,
                "backgroundImage" : BackgroundColorFalse ,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`, 
            })
        }
    }

    
    return (<motion.div style={ BlockStyle() }>

        
        { PlayerValues !== null  && PlayerValues.IsHandWinner !== true && (SeatNumber === 2 || SeatNumber === 3 || SeatNumber === 4) &&
            <motion.div 
            animate={ TurnStyle() }
            transition={{duration : 0.6 }}
            style={ StyleProvider() }>
                <div>                    
                    { PlayerValues.Turn === true &&
                        < TurnTrue />
                    }

                    < UserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} />

                    < Avatar PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                    

                    < Bid TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    < Score TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    { PlayerValues.IsDealer === true && GameData.CardDistribution !== true &&
                        < Dealer Seat = {Values.Seat} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  
                    }
                    
                </div>
            </motion.div>
        }

        
        { PlayerValues !== null  && PlayerValues.IsHandWinner === true && (SeatNumber === 2 || SeatNumber === 3 || SeatNumber === 4) &&
            <motion.div 
            animate={{ scale : 1.15 }}
            transition={{duration : 0.6 }}
            style={{
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "borderWidth" : `${percentage(currentSize.width , 1)}px`,
                "borderRadius" : "50%",
                "borderStyle" : "solid",
                "borderColor" : "#FDA80D",
                "boxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
            }}>
                <div>
                    < UserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} />

                    < Avatar PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                    

                    < Bid TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    < Score TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    { PlayerValues.IsDealer === true && GameData.CardDistribution !== true &&
                        < Dealer Seat = {Values.Seat} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  
                    }
                    
                </div>
            </motion.div>
        }

        { PlayerValues !== null  && PlayerValues.IsHandWinner !== true && SeatNumber === 1 && isAgnostic === false &&
            <motion.div 
            animate={ TurnStyle() }
            transition={{duration : 0.6 }}
            style={ StyleProvider() }>
                <div>                    
                    { PlayerValues.Turn === true &&
                        < TurnTrue />
                    }

                    < UserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} />

                    < Avatar PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                    

                    < Bid TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    < Score TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    { PlayerValues.IsDealer === true && GameData.CardDistribution !== true &&
                        < Dealer Seat = {Values.Seat} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  
                    }
                    
                </div>
            </motion.div>
        }

        { PlayerValues !== null  && PlayerValues.IsHandWinner === true && SeatNumber === 1 && isAgnostic === false &&
            <motion.div 
            animate={{ scale : 1.15 }}
            transition={{duration : 0.6 }}
            style={{
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "borderWidth" : `${percentage(currentSize.width , 1)}px`,
                "borderRadius" : "50%",
                "borderStyle" : "solid",
                "borderColor" : "#FDA80D",
                "boxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
            }}>
                <div>
                    < UserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} />

                    < Avatar PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                    

                    < Bid TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    < Score TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    { PlayerValues.IsDealer === true && GameData.CardDistribution !== true &&
                        < Dealer Seat = {Values.Seat} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  
                    }
                    
                </div>
            </motion.div>
        }


        { PlayerValues !== null  && PlayerValues.IsHandWinner !== true && SeatNumber === 1 && isAgnostic === true &&
            <motion.div 
            animate={ TurnStyle() }
            transition={{duration : 0.6 }}
            style={ StyleProvider() }>
                <div>                    
                    { PlayerValues.Turn === true &&
                        < TurnTrue />
                    }

                    < ItapUserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} />

                    < ITapper PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/> 

                    < Bid TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    < Score TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    { PlayerValues.IsDealer === true && GameData.CardDistribution !== true &&
                        < Dealer Seat = {Values.Seat} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  
                    }
                    
                </div>
            </motion.div>
        }

        { PlayerValues !== null  && PlayerValues.IsHandWinner === true && SeatNumber === 1 && isAgnostic === true &&
            <motion.div 
            animate={{ scale : 1.15 }}
            transition={{duration : 0.6 }}
            style={{
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "borderWidth" : `${percentage(currentSize.width , 1)}px`,
                "borderRadius" : "50%",
                "borderStyle" : "solid",
                "borderColor" : "#FDA80D",
                "boxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1)}px #FDA80D`,
            }}>
                <div>
                    < ItapUserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} />

                    < ITapper PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/> 

                    < Bid TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    < Score TableData = {GameData} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  

                    { PlayerValues.IsDealer === true && GameData.CardDistribution !== true &&
                        < Dealer Seat = {Values.Seat} PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/>                                  
                    }
                    
                </div>
            </motion.div>
        }
        


    </motion.div>)
    
    

}

export default Section