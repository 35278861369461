import useResize from 'use-resize';

const TableHeightCalculator = () => {
    const currentSize = SizeInfo();

    var TableHeight = 0 ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    if(currentSize.height > percentage(currentSize.width , 120)){
        var Difference = currentSize.height - percentage(currentSize.width , 120)

        if(Difference > 350){
            TableHeight = percentage(currentSize.width , 120) + percentage(currentSize.width , 55)
        } else if(Difference > 300 ){
            TableHeight = percentage(currentSize.width , 120) + percentage(currentSize.width , 45)
        } else if(Difference > percentage(currentSize.width , 40)){
            TableHeight = percentage(currentSize.width , 120) + percentage(currentSize.width , 20)
        } else {
            TableHeight = percentage(currentSize.width , 120)
        }
    } else {
        TableHeight = percentage(currentSize.width , 120)
    }
    
    return TableHeight

}

export default TableHeightCalculator
