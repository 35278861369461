import React from 'react';
import Profile from './ProfileNavigationBarComponents/Profile'
import Balance from './ProfileNavigationBarComponents/Balance'
import Level from './ProfileNavigationBarComponents/Level'
import Rewards from './ProfileNavigationBarComponents/Rewards'

const Section = (Value) => {
    const Size = Value.SizeData ;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    

    const width = Size.width ;
    
    return (<div className="BlurIt" style={{
        "position" : "fixed",
        "top" : `${percentage(width , 30)}px`,
        "left" : `${percentage(width , 0.5)}px`,
        "display" : "flex",
        "justifyContent" : "center",
    }}>
        < Profile SizeData = { Size }/>
        <div style={{'width' : `${percentage(width , 1)}px`}}></div>
        < Balance SizeData = { Size }/>
        <div style={{'width' : `${percentage(width , 1)}px`}}></div>
        < Level SizeData = { Size }/>
        <div style={{'width' : `${percentage(width , 1)}px`}}></div>
        < Rewards SizeData = { Size }/>
    </div>)
    
}

export default Section