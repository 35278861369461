import React , { useState } from 'react';
import socket from '../../../../../SocketConnectionManager';
import TimerClock from '../../../../../Icons/TimerClock.png'
import FingerMover from "./FingerMover";
import useSetInterval from 'use-set-interval';
import ArrayList from 'arraylist';
import CardStackProvider from './CardStackProvider';
import SelectedCardsProvider from './SelectedCardsProvider';
import BluffHideCards from './BluffHideCards';
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import TableHeightCalculator from "../TableHeightCalculator";
import SoundTypeOne from '../../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';

const Section = (Value) => {
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    const SoundStatus = Value.Music ; 
    const GameData = Value.TableData ;
    const ZeroCards =  GameData.ZeroCards ;
    const CurrentBluff = GameData.CurrentBluff ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableHeight = TableHeightCalculator();
    const TableWidth = percentage(currentSize.width , 75);
    
    var AcesValue  = new ArrayList();
    var KingsValue = new ArrayList();
    var QueensValue = new ArrayList();
    var JacksValue = new ArrayList();
    var TensValue = new ArrayList();
    var NinesValue = new ArrayList();

    UpdateCardCount ()

    function UpdateCardCount () {
        AcesValue.add(GameData.MyCards.Aces)
        KingsValue.add(GameData.MyCards.Kings)
        QueensValue.add(GameData.MyCards.Queens)
        JacksValue.add(GameData.MyCards.Jacks)
        TensValue.add(GameData.MyCards.Tens)
        NinesValue.add(GameData.MyCards.Nines)
    }

    const [ Submitted , SubmittedUpdate ] = useState(false)
    const [ SelectBluff , SelectBluffUpdate ] = useState(false);
    const [ SlotOneValue , SlotOneValueUpdate ] = useState(null);
    const [ SlotTwoValue , SlotTwoValueUpdate ] = useState(null);
    const [ SlotThreeValue , SlotThreeValueUpdate ] = useState(null);
    const [ SlotFourValue , SlotFourValueUpdate ] = useState(null);
    const [ SlotFiveValue , SlotFiveValueUpdate ] = useState(null);
    const [ SlotSixValue , SlotSixValueUpdate ] = useState(null);
    const [ AllAces , AllAcesUpdate ]  = useState(AcesValue);
    const [ AllKings , AllKingsUpdate ]  = useState(KingsValue);
    const [ AllQueens , AllQueensUpdate ]  = useState(QueensValue);
    const [ AllJacks , AllJacksUpdate ]  = useState(JacksValue);
    const [ AllTens , AllTensUpdate ]  = useState(TensValue);
    const [ AllNines , AllNinesUpdate ]  = useState(NinesValue);
    const [ PlayAsValue , PlayAsValueUpdate ] = useState(null);

   

    const [ SelectBluffColor , SelectBluffColorUpdate ] = useState({
        "backgroundColor" : "black",
        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
        "width" : `${percentage(currentSize.width , 75/3)}px`,
        "margin" : "0 auto",
        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
        "borderStyle" : "solid",
        "borderColor" : "#00d6ff",
        "color" : "#00d6ff",
        "textAlign" : "center"
    })

    const [ BluffCancelColor , BluffCancelColorUpdate ] = useState({
        "backgroundColor" : "black",
        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
        "width" : `${percentage(currentSize.width , 75/3)}px`,
        "margin" : "0 auto",
        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
        "borderStyle" : "solid",
        "borderColor" : "#00d6ff",
        "color" : "#00d6ff",
        "textAlign" : "center"
    })

    const CardSelection = (Series) => {
        if(SlotOneValue === null || SlotTwoValue === null || 
            SlotThreeValue === null || SlotFourValue === null ||
            SlotFiveValue === null || SlotSixValue === null){
            var ExistingValues ;
            if(Series === "A"){
                ExistingValues = AllAces ;
                AcesValue = new ArrayList();
    
                if(ExistingValues.length > 1){
                    EmptySlotUpdate(ExistingValues[0]);
                    ExistingValues.remove(0);
                    AcesValue.add(ExistingValues);
                    AllAcesUpdate(AcesValue);                
                } else {
                    EmptySlotUpdate(ExistingValues[0]);
                    AllAcesUpdate(AcesValue);
                }
            } else if(Series === "K"){
                ExistingValues = AllKings ;
                KingsValue = new ArrayList();
    
                if(ExistingValues.length > 1){
                    EmptySlotUpdate(ExistingValues[0]);
                    ExistingValues.remove(0);
                    KingsValue.add(ExistingValues);
                    AllKingsUpdate(KingsValue);                
                } else {
                    EmptySlotUpdate(ExistingValues[0]);
                    AllKingsUpdate(KingsValue);
                }
            } else if(Series === "Q"){
                ExistingValues = AllQueens ;
                QueensValue = new ArrayList();
    
                if(ExistingValues.length > 1){
                    EmptySlotUpdate(ExistingValues[0]);
                    ExistingValues.remove(0);
                    QueensValue.add(ExistingValues);
                    AllQueensUpdate(QueensValue);                
                } else {
                    EmptySlotUpdate(ExistingValues[0]);
                    AllQueensUpdate(QueensValue);
                }
            } else if(Series === "J"){
                ExistingValues = AllJacks ;
                JacksValue = new ArrayList();
    
                if(ExistingValues.length > 1){
                    EmptySlotUpdate(ExistingValues[0]);
                    ExistingValues.remove(0);
                    JacksValue.add(ExistingValues);
                    AllJacksUpdate(JacksValue);                
                } else {
                    EmptySlotUpdate(ExistingValues[0]);
                    AllJacksUpdate(JacksValue);     
                }
            } else if(Series === "10"){
                ExistingValues = AllTens ;
                TensValue = new ArrayList();
    
                if(ExistingValues.length > 1){
                    EmptySlotUpdate(ExistingValues[0]);
                    ExistingValues.remove(0);
                    TensValue.add(ExistingValues);
                    AllTensUpdate(TensValue);                
                } else {
                    EmptySlotUpdate(ExistingValues[0]);
                    AllTensUpdate(TensValue);         
                }
            } else if(Series === "9"){
                ExistingValues = AllNines ;
                NinesValue = new ArrayList();
    
                if(ExistingValues.length > 1){
                    EmptySlotUpdate(ExistingValues[0]);
                    ExistingValues.remove(0);
                    NinesValue.add(ExistingValues);
                    AllNinesUpdate(NinesValue);                
                } else {
                    EmptySlotUpdate(ExistingValues[0]);
                    AllNinesUpdate(NinesValue);                 
                }
            }
    
        }
    
            function EmptySlotUpdate(Card){
                if(SlotOneValue === null){
                    SlotOneValueUpdate(Card);
                } else if(SlotTwoValue === null){
                    SlotTwoValueUpdate(Card);
                } else if(SlotThreeValue === null){
                    SlotThreeValueUpdate(Card);
                } else if(SlotFourValue === null){
                    SlotFourValueUpdate(Card);
                } else if(SlotFiveValue === null){
                    SlotFiveValueUpdate(Card);
                } else if(SlotSixValue === null){
                    SlotSixValueUpdate(Card);
                }
            }
    }

    const DeSelectEmptySlot = (SlotNumber) => {
        var DeSelectedCard ;

        if(SlotNumber === 1){
            DeSelectedCard = SlotOneValue
            SlotOneValueUpdate(null);
        } else if(SlotNumber === 2){
            DeSelectedCard = SlotTwoValue
            SlotTwoValueUpdate(null);
        } else if(SlotNumber === 3){
            DeSelectedCard = SlotThreeValue
            SlotThreeValueUpdate(null);
        } else if(SlotNumber === 4){
            DeSelectedCard = SlotFourValue
            SlotFourValueUpdate(null);
        } else if(SlotNumber === 5){
            DeSelectedCard = SlotFiveValue
            SlotFiveValueUpdate(null);
        } else if(SlotNumber === 6){
            DeSelectedCard = SlotSixValue
            SlotSixValueUpdate(null);
        }

        var CurrentValues = new ArrayList ()
        if(DeSelectedCard.Value === "A"){
            CurrentValues.add(AllAces);
            AcesValue = new ArrayList ();
            AcesValue.add(DeSelectedCard);
            AcesValue.add(CurrentValues);
            AllAcesUpdate(AcesValue);
        } else if(DeSelectedCard.Value === "K"){
            CurrentValues.add(AllKings);
            KingsValue = new ArrayList ();
            KingsValue.add(DeSelectedCard);
            KingsValue.add(CurrentValues);
            AllKingsUpdate(KingsValue);
        } else if(DeSelectedCard.Value === "Q"){
            CurrentValues.add(AllQueens);
            QueensValue = new ArrayList ();
            QueensValue.add(DeSelectedCard);
            QueensValue.add(CurrentValues);
            AllQueensUpdate(QueensValue);
        } else if(DeSelectedCard.Value === "J"){
            CurrentValues.add(AllJacks);
            JacksValue = new ArrayList ();
            JacksValue.add(DeSelectedCard);
            JacksValue.add(CurrentValues);
            AllJacksUpdate(JacksValue);
        } else if(DeSelectedCard.Value === "10"){
            CurrentValues.add(AllTens);
            TensValue = new ArrayList ();
            TensValue.add(DeSelectedCard);
            TensValue.add(CurrentValues);
            AllTensUpdate(TensValue);
        } else if(DeSelectedCard.Value === "9"){
            CurrentValues.add(AllNines);
            NinesValue = new ArrayList ();
            NinesValue.add(DeSelectedCard);
            NinesValue.add(CurrentValues);
            AllNinesUpdate(NinesValue);
        }
    }

    const PlayBluff = () => {
        var BluffData = null;
        var CardsPlayed = new ArrayList ();
    
        if(SlotOneValue !== null){
            CardsPlayed.add(SlotOneValue);
        } 
    
        if(SlotTwoValue !== null){
            CardsPlayed.add(SlotTwoValue);
        } 
    
        if(SlotThreeValue !== null){
            CardsPlayed.add(SlotThreeValue);
        } 
        
        if(SlotFourValue !== null){
            CardsPlayed.add(SlotFourValue);
        } 
    
        if(SlotFiveValue !== null){
            CardsPlayed.add(SlotFiveValue);
        } 
    
        if(SlotSixValue !== null){
            CardsPlayed.add(SlotSixValue);
        } 
    
        BluffData = {
            "Cards" : CardsPlayed ,
            "Claim" : PlayAsValue
        }
        
        
        SlotOneValueUpdate(null);
        SlotTwoValueUpdate(null);
        SlotThreeValueUpdate(null);
        SlotFourValueUpdate(null);
        SlotFiveValueUpdate(null);
        SlotSixValueUpdate(null);

        SubmittedUpdate(true);
        if(SoundStatus === true){
            playOne()
        }   

        socket.emit('BluffAction' , BluffData)


    }

    const CreatePlayBluff = (Series) => {
        var BluffData = null;
        var CardsPlayed = new ArrayList ();
    
        if(SlotOneValue !== null){
            CardsPlayed.add(SlotOneValue);
        } 
    
        if(SlotTwoValue !== null){
            CardsPlayed.add(SlotTwoValue);
        } 
    
        if(SlotThreeValue !== null){
            CardsPlayed.add(SlotThreeValue);
        } 
        
        if(SlotFourValue !== null){
            CardsPlayed.add(SlotFourValue);
        } 
    
        if(SlotFiveValue !== null){
            CardsPlayed.add(SlotFiveValue);
        } 
    
        if(SlotSixValue !== null){
            CardsPlayed.add(SlotSixValue);
        } 
    
        BluffData = {
            "Cards" : CardsPlayed ,
            "Claim" : Series
        }
        
        
        SlotOneValueUpdate(null);
        SlotTwoValueUpdate(null);
        SlotThreeValueUpdate(null);
        SlotFourValueUpdate(null);
        SlotFiveValueUpdate(null);
        SlotSixValueUpdate(null);

        
        SubmittedUpdate(true);

        if(SoundStatus === true){
            playOne()
        }   

        socket.emit('BluffAction' , BluffData)
    }


    const Confirmation = () => {
        PlayAsValueUpdate(GameData.CurrentBluff.Claim);
        PlayBluff();
    }


    const [ SelectPassColor , SelectPassColorUpdate ] = useState({
        "backgroundColor" : "black",
        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
        "width" : `${percentage(currentSize.width , 75/3)}px`,
        "margin" : "0 auto",
        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
        "borderStyle" : "solid",
        "borderColor" : "#39FF14",
        "color" : "#39FF14",
        "textAlign" : "center"
    })

    const [ SelectChallengeColor , SelectChallengeColorUpdate ] = useState({
        "backgroundColor" : "black",
        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
        "width" : `${percentage(currentSize.width , 75/3)}px`,
        "margin" : "0 auto",
        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
        "borderStyle" : "solid",
        "borderColor" : "#F72119",
        "color" : "#F72119",
        "textAlign" : "center"
    })

    const CancelStart = () => {
        BluffCancelColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const CancelCancel = () => {
        BluffCancelColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const CancelDone = () => {
        BluffCancelColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
        if(SoundStatus === true){
            playOne()
        }   
        SelectBluffUpdate(false)
    }

    const SelectBluffStart = () => {
        SelectBluffColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const SelectBluffCancel = () => {
        SelectBluffColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const SelectBluffDone = () => {
        SelectBluffColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
        if(SoundStatus === true){
            playOne()
        }   

        SelectBluffUpdate(true) ;
    }

    const SelectChallengeStart = () => {
        SelectChallengeColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const SelectChallengeCancel = () => {
        SelectChallengeColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#F72119",
            "color" : "#F72119",
            "textAlign" : "center"
        })
    }

    const SelectChallengeDone = () => {
        SelectChallengeColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })

        if(SoundStatus === true){
            playOne()
        }   
        SubmittedUpdate(true);
        PlayChallenge();
    }



    const SelectPassStart = () => {
        SelectPassColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
    }

    const SelectPassCancel = () => {
        SelectPassColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#39FF14",
            "color" : "#39FF14",
            "textAlign" : "center"
        })
    }

    const SelectPassDone = () => {
        SelectPassColorUpdate({
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "width" : `${percentage(currentSize.width , 75/3)}px`,
            "margin" : "0 auto",
            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "#00d6ff",
            "color" : "#00d6ff",
            "textAlign" : "center"
        })
        if(SoundStatus === true){
            playOne()
        }   
        SubmittedUpdate(true);
        PlayPass();
    }

    const PlayChallenge = () => {
        SubmittedUpdate(true);
        socket.emit('ChallengeAction');
    }

    const PlayPass = () => {
        SubmittedUpdate(true);
        socket.emit('PassAction');
    }

    var TimerValueProvider = Value.PlayerValue.TimerValue ;

    if(TimerValueProvider <= 0){
        TimerValueProvider = 20
    }

    const [ PlayerTimerValue , PlayerTimerValueUpdate ] = useState(TimerValueProvider)
    
    useSetInterval (()=>{ 
        PlayerTimerValueUpdate(PlayerTimerValue - 1)
    }, 1000)

    


    if(SelectBluff === true && 
        ZeroCards === false &&
        Submitted === false){
            return (
                <div style={{
                    "position" : "absolute",
                    "height" : `${TableHeight}px`,
                    "width" : `${TableWidth}px`,
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",
                }}>
                    <motion.div 
                        initial={{x : "0%", y : "0%" }}
                        style={{
                        "position" : "absolute",
                        "zIndex" : "50",
                        "bottom" : "0"
                    }}>

                        
                        <motion.div style={{
                            "position" : "absolute",
                            "zIndex" : "52",
                            "bottom" : `${percentage(currentSize.width , 0)}px`,
                        }}>
                            <motion.div 
                                style={{
                                    "marginLeft" :`${percentage(currentSize.width , 27)}px`,
                                    "width" : `${percentage(currentSize.width , 20)}px`,
                                }}>

                                    <div className="pulsate-fwd" style={{                
                                    "width" : `${percentage(currentSize.width , 20)}px`,
                                    "height" : `${percentage(currentSize.width , 20/2.5)}px`,
                                    "backgroundImage" : "linear-gradient(rgba(0,0,0,0.7) ,rgba(0,0,0,0.9), rgba(0,0,0,0.9))",
                                    "borderBottomStyle" : "solid",
                                    "borderTopStyle" : "solid",
                                    "borderColor" : "#191919",
                                    "color" : "#00d6ff",
                                    "borderTopWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                    "borderBottomWidth" : `${percentage(currentSize.width , 1)}px`,
                                    "textAlign" : "center",
                                    "borderRadius" : "50%",
                                    "boxShadow" : `0 0 ${percentage(currentSize.width , 4)}px black`,
                                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 4)}px black`,
                                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 4)}px black`,
                                    }}>
                                        
                                    < img alt="Avatar" 
                                            style={{
                                            'height' : `auto`,
                                            'width' : `${percentage(currentSize.width , 20 - 15)}px`,
                                            "position" :"absolute",
                                            "top" : "50%",
                                            "left" : "30%",
                                            "transform" : "translate(-50%, -50%)",
                                            }}
                                            src={ TimerClock } />

                                        <div style={{
                                            "position" :"absolute",
                                            "top" : "50%",
                                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                                            "right" : "10%",
                                            "transform" : "translate(-50%, -50%)",
                                        }}>

                                                { PlayerTimerValue }  
                                            
                                        
                                        </div>
                                    </div>
                                </motion.div>
                        </motion.div>
                        
                        <div>

                        <FingerMover SlotOneValue = {SlotOneValue} 
                                            SlotTwoValue = {SlotTwoValue} 
                                            SlotThreeValue = {SlotThreeValue} 
                                            SlotFourValue = {SlotFourValue} 
                                            SlotFiveValue = {SlotFiveValue} 
                                            SlotSixValue = {SlotSixValue} />

                        <motion.div>
                            < CardStackProvider AllAces = { AllAces } AllKings = {AllKings}
                                        AllJacks = { AllJacks } AllQueens = {AllQueens }
                                        AllNines = { AllNines } AllTens = { AllTens }/>
                        </motion.div>

                        </div>

                        <motion.div style={{
                            "position" : "absolute",
                            "height": `${percentage(currentSize.width , 20)}px`,
                            "width" : `${percentage(currentSize.width , 75)}px`,
                            "bottom" : `${percentage(currentSize.width , 19.5)}px`,
                            "zIndex" : "40",
                            "display" : "flex"
                            }}
                            >

                            <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                            <motion.div style={{
                                "height": `${percentage(currentSize.width , 20)}px`,
                                "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                            }}
                            onClick={()=>{
                                if(AllAces.length > 0){
                                    CardSelection("A")
                                }
                            }}
                            />
                            
                            <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>
                            
                            <motion.div style={{
                                "height": `${percentage(currentSize.width , 20)}px`,
                                "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                            }}
                            onClick={()=>{
                                if(AllKings.length > 0){
                                    CardSelection("K")
                                }
                            }}
                            />

                            <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                            <motion.div style={{
                                "height": `${percentage(currentSize.width , 20)}px`,
                                "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                            }}
                            onClick={()=>{
                                if(AllQueens.length > 0){
                                    CardSelection("Q")
                                }
                            }}
                            />

                            <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                            
                            <motion.div style={{
                                "height": `${percentage(currentSize.width , 20)}px`,
                                "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                            }}
                            onClick={()=>{
                                if(AllJacks.length > 0){
                                    CardSelection("J")
                                }
                            }}
                            />

                            <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                            <motion.div style={{
                                "height": `${percentage(currentSize.width , 20)}px`,
                                "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                            }}
                            onClick={()=>{
                                if(AllTens.length > 0){
                                    CardSelection("10")
                                }
                            }}
                            />

                            <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                            <motion.div style={{
                                "height": `${percentage(currentSize.width , 20)}px`,
                                "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                            }}
                            onClick={()=>{
                                if(AllNines.length > 0){
                                    CardSelection("9")
                                }
                            }}
                            />

                        </motion.div> 

                        { (SlotOneValue !== null ||
                            SlotTwoValue !== null ||
                            SlotThreeValue !== null ||
                            SlotFourValue !== null ||
                            SlotFiveValue !== null ||
                            SlotSixValue !== null) && 

                            <div style={{
                                "position" : "absolute",
                                "bottom" : `${percentage(currentSize.width , 75)}px`,
                                "height": `${percentage(currentSize.width , 15)}px`,
                                "zIndex" : "10",
                            }}>
                                    <motion.div style={{
                                        "position" : "absolute",
                                        "height": `${percentage(currentSize.width , 15)}px`,
                                        "width" : `${percentage(currentSize.width , 75)}px`,
                                        "bottom" : "-100%",
                                        "zIndex" : "12",
                                    }}
                                        className="scale-in-hor-center">
                                        < SelectedCardsProvider 
                                            SlotOneValue = {SlotOneValue} 
                                            SlotTwoValue = {SlotTwoValue} 
                                            SlotThreeValue = {SlotThreeValue} 
                                            SlotFourValue = {SlotFourValue} 
                                            SlotFiveValue = {SlotFiveValue} 
                                            SlotSixValue = {SlotSixValue} 
                                        />
                                    </motion.div>

                                    <motion.div style={{
                                        "position" : "absolute",
                                        "height": `${percentage(currentSize.width , 15)}px`,
                                        "width" : `${percentage(currentSize.width , 75)}px`,
                                        "bottom" :  `${percentage(currentSize.width , -25)}px`,
                                        "zIndex" : "12",
                                        "display" : "flex"
                                    }}
                                    >
                                         <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                                        <motion.div style={{
                                            "height": `${percentage(currentSize.width , 15)}px`,
                                            "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                                        }}
                                        onClick={()=> {
                                            if(SlotOneValue !== null){
                                                DeSelectEmptySlot(1)
                                            }
                                        }}
                                        />

                                        <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                                        <motion.div style={{
                                            "height": `${percentage(currentSize.width , 15)}px`,
                                            "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                                        }}
                                        onClick={()=> {
                                            if(SlotTwoValue !== null){
                                                DeSelectEmptySlot(2)
                                            }
                                        }}
                                        />

                                        <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                                        <motion.div style={{
                                            "height": `${percentage(currentSize.width , 15)}px`,
                                            "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                                        }}
                                        onClick={()=> {
                                            if(SlotThreeValue !== null){
                                                DeSelectEmptySlot(3)
                                            }
                                        }}
                                        />

                                        <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                                        <motion.div style={{
                                            "height": `${percentage(currentSize.width , 15)}px`,
                                            "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                                        }}
                                        onClick={()=> {
                                            if(SlotFourValue !== null){
                                                DeSelectEmptySlot(4)
                                            }
                                        }}
                                        />

                                        <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                                        <motion.div style={{
                                            "height": `${percentage(currentSize.width , 15)}px`,
                                            "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                                        }}
                                        onClick={()=> {
                                            if(SlotFiveValue !== null){
                                                DeSelectEmptySlot(5)
                                            }
                                        }}
                                        />

                                        <div style={{"width" :  `${percentage(currentSize.width , 2)}px`,}}></div>

                                        <motion.div style={{
                                            "height": `${percentage(currentSize.width , 15)}px`,
                                            "width" : `${percentage(currentSize.width , 75)/6.75}px`,
                                        }}
                                        onClick={()=> {
                                            if(SlotSixValue !== null){
                                                DeSelectEmptySlot(6)
                                            }
                                        }}
                                        />

                                    </motion.div>
                            </div>
                        }

                        { (SlotOneValue !== null ||
                            SlotTwoValue !== null ||
                            SlotThreeValue !== null ||
                            SlotFourValue !== null ||
                            SlotFiveValue !== null ||
                            SlotSixValue !== null) && 
                            CurrentBluff !== null &&

                            <div className="scale-in-hor-center">

                        <motion.div initial={{ x : percentage(currentSize.width , -1) , opacity : 0}}
                        animate={{ opacity : 1}}
                        transition={{ duration : 0.7}}
                            style={{
                            "position" : "absolute",
                            "bottom" : `${percentage(currentSize.width , 76)}px`,
                            "zIndex" : "10",
                            "width" : `${percentage(currentSize.width , 76)}px`,
                            "height" : `${percentage(currentSize.width , 30)}px`,
                            "backgroundColor" : "#191919",
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderStyle" : "solid",
                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                            "borderColor" : "black",
                            }}>
                                <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                                <div style={{
                                    "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                                    "textAlign" : "center"
                                }}>CHOOSE SERIES TO PLAY SELECTED CARDS</div> 

                                <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                                
                                <motion.div style={{
                                    "width" : `${percentage(currentSize.width , 30)}px`,
                                    }}
                                    onClick={()=> Confirmation()}
                                    initial={{ x:"80%", scale: 1}}
                                    >
                                    <div style={{
                                        "backgroundColor" : "rgba(0,0,0,0.5)",
                                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                                        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "borderStyle" : "solid",
                                        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                        "borderColor" : "black",
                                        "color" : "#00d6ff",
                                    }}>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>PLAY AS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                        
                                        { GameData.CurrentBluff.Claim === "A" &&
                                            <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>
                                                ACES
                                            </div>
                                        }

                                        { GameData.CurrentBluff.Claim === "K" &&
                                            <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>
                                                KINGS
                                            </div>
                                        }

                                        { GameData.CurrentBluff.Claim === "Q" &&
                                            <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>
                                                QUEENS
                                            </div>
                                        }

                                        { GameData.CurrentBluff.Claim === "J" &&
                                            <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>
                                                JACKS
                                            </div>
                                        }

                                        { GameData.CurrentBluff.Claim === "10" &&
                                            <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>
                                                TENS
                                            </div>
                                        }

                                        { GameData.CurrentBluff.Claim === "9" &&
                                            <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>
                                                NINES
                                            </div>
                                        }

                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                    </div>


                                    <div style={{
                                            "position" : "absolute",
                                            "bottom" : "5%",
                                            "left" : "75%",
                                            "borderRadius" : "50%",
                                            "backgroundColor" : "black",
                                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                                            "height" : `${percentage(currentSize.width , 7)}px`,
                                            "width" : `${percentage(currentSize.width , 7)}px`,
                                            "borderStyle" : "solid" ,
                                            "borderColor" : "#00d6ff",
                                            "color" : "#00d6ff",
                                            "textAlign" : "center",
                                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`
                                        }}>
                                            <motion.div
                                                initial={{x : 1 , y : 7}}
                                            >

                                            { GameData.CurrentBluff.Claim === "A" &&
                                                <div>
                                                    A
                                                </div>
                                            }

                                            { GameData.CurrentBluff.Claim === "K" &&
                                                <div>
                                                    K
                                                </div>
                                            }

                                            { GameData.CurrentBluff.Claim === "Q" &&
                                                <div>
                                                    Q
                                                </div>
                                            }

                                            { GameData.CurrentBluff.Claim === "J" &&
                                                <div>
                                                    J
                                                </div>
                                            }

                                            { GameData.CurrentBluff.Claim === "10" &&
                                                <div>
                                                    10
                                                </div>
                                            }

                                            { GameData.CurrentBluff.Claim === "9" &&
                                                <div>
                                                    9
                                                </div>
                                            }

                                            </motion.div>
                                        </div>
                                    
                                </motion.div>
                                
                            
                            
                            
                            </motion.div>
                            </div>
                        }

                        { (SlotOneValue !== null ||
                            SlotTwoValue !== null ||
                            SlotThreeValue !== null ||
                            SlotFourValue !== null ||
                            SlotFiveValue !== null ||
                            SlotSixValue !== null) && 
                            CurrentBluff === null &&

                            <div className="scale-in-hor-center">
                                    
                            <motion.div initial={{ x : percentage(currentSize.width , -7.5), opacity : 0}}
                            animate={{ opacity : 1}}
                            transition={{ duration : 0.7}}
                            style={{
                            "position" : "absolute",
                            "bottom" : `${percentage(currentSize.width , 76)}px`,
                            "zIndex" : "29",
                            "width" : `${percentage(currentSize.width , 90)}px`,
                            "height" : `${percentage(currentSize.width , 40)}px`,
                            "backgroundColor" : "#191919",
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderStyle" : "solid",
                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                            "borderColor" : "black",
                            }}>
                            <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                            <div style={{
                                "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                                "textAlign" : "center"
                            }}>
                                CHOOSE SERIES TO PLAY YOUR SELECTED CARDS
                            </div> 


                            <div style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div style={{
                                    "width" : `${percentage(currentSize.width , 30)}px`,
                                    }}
                                    onClick={()=> CreatePlayBluff("A")}
                                    initial={{ opacity : 1 ,x: 0, y: 0, scale:0.75}}
                                    >
                                    <div style={{
                                        "backgroundColor" : "rgba(0,0,0,0.5)",
                                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                                        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "borderStyle" : "solid",
                                        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                        "borderColor" : "black",
                                        "color" : "#00d6ff",
                                    }}>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>PLAY AS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>ACES</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>

                                        <div style={{
                                            "position" : "absolute",
                                            "bottom" : "5%",
                                            "left" : "75%",
                                            "borderRadius" : "50%",
                                            "backgroundColor" : "black",
                                            "height" : `${percentage(currentSize.width , 7)}px`,
                                            "width" : `${percentage(currentSize.width , 7)}px`,
                                            "borderStyle" : "solid" ,
                                            "borderColor" : "#00d6ff",
                                            "textAlign" : "center",
                                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`
                                        }}>
                                            <motion.div
                                                initial={{x : 1 , y : 7}}
                                            >A</motion.div>
                                        </div>
                                    </div>
                                    
                                </motion.div>

                                <motion.div style={{
                                    "width" : `${percentage(currentSize.width , 30)}px`,
                                    }}
                                    onClick={()=> CreatePlayBluff("K")}
                                    initial={{ opacity : 1 ,x: 0, y: 0, scale:0.75}}
                                    >
                                    <div style={{
                                        "backgroundColor" : "rgba(0,0,0,0.5)",
                                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                                        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "borderStyle" : "solid",
                                        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                        "borderColor" : "black",
                                        "color" : "#00d6ff",
                                    }}>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>PLAY AS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>KINGS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>

                                        <div style={{
                                            "position" : "absolute",
                                            "bottom" : "5%",
                                            "left" : "75%",
                                            "borderRadius" : "50%",
                                            "backgroundColor" : "black",
                                            "height" : `${percentage(currentSize.width , 7)}px`,
                                            "width" : `${percentage(currentSize.width , 7)}px`,
                                            "borderStyle" : "solid" ,
                                            "borderColor" : "#00d6ff",
                                            "textAlign" : "center",
                                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`
                                        }}>
                                            <motion.div
                                                initial={{x : 1 , y : 7}}
                                            >K</motion.div>
                                        </div>
                                    </div>
                                    
                                </motion.div>


                                <motion.div style={{
                                    "width" : `${percentage(currentSize.width , 30)}px`,
                                    }}
                                    onClick={()=> CreatePlayBluff("Q")}
                                    initial={{ opacity : 1 ,x: 0, y: 0, scale:0.75}}
                                    >
                                    <div style={{
                                        "backgroundColor" : "rgba(0,0,0,0.5)",
                                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                                        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "borderStyle" : "solid",
                                        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                        "borderColor" : "black",
                                        "color" : "#00d6ff",
                                    }}>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>PLAY AS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>QUEENS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>

                                        <div style={{
                                            "position" : "absolute",
                                            "bottom" : "5%",
                                            "left" : "75%",
                                            "borderRadius" : "50%",
                                            "backgroundColor" : "black",
                                            "height" : `${percentage(currentSize.width , 7)}px`,
                                            "width" : `${percentage(currentSize.width , 7)}px`,
                                            "borderStyle" : "solid" ,
                                            "borderColor" : "#00d6ff",
                                            "textAlign" : "center",
                                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`
                                        }}>
                                            <motion.div
                                                initial={{x : 1 , y : 7}}
                                            >Q</motion.div>
                                        </div>
                                    </div>
                                    
                                </motion.div>
                            </div>

                            <div style={{
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div style={{
                                    "width" : `${percentage(currentSize.width , 30)}px`,
                                    }}
                                    onClick={()=> CreatePlayBluff("J")}
                                    initial={{ opacity : 1 ,x: 0, y: 0, scale:0.75}}
                                    >
                                    <div style={{
                                        "backgroundColor" : "rgba(0,0,0,0.5)",
                                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                                        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "borderStyle" : "solid",
                                        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                        "borderColor" : "black",
                                        "color" : "#00d6ff",
                                    }}>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>PLAY AS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>JACKS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>

                                        <div style={{
                                            "position" : "absolute",
                                            "bottom" : "5%",
                                            "left" : "75%",
                                            "borderRadius" : "50%",
                                            "backgroundColor" : "black",
                                            "height" : `${percentage(currentSize.width , 7)}px`,
                                            "width" : `${percentage(currentSize.width , 7)}px`,
                                            "borderStyle" : "solid" ,
                                            "borderColor" : "#00d6ff",
                                            "textAlign" : "center",
                                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`
                                        }}>
                                            <motion.div
                                                initial={{x : 1 , y : 7}}
                                            >J</motion.div>
                                        </div>
                                    </div>
                                    
                                </motion.div>

                                <motion.div style={{
                                    "width" : `${percentage(currentSize.width , 30)}px`,
                                    }}
                                    onClick={()=> CreatePlayBluff("10")}
                                    initial={{ opacity : 1 ,x: 0, y: 0, scale:0.75}}
                                    >
                                    <div style={{
                                        "backgroundColor" : "rgba(0,0,0,0.5)",
                                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                                        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "borderStyle" : "solid",
                                        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                        "borderColor" : "black",
                                        "color" : "#00d6ff",
                                    }}>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>PLAY AS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>TENS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>

                                        <div style={{
                                            "position" : "absolute",
                                            "bottom" : "5%",
                                            "left" : "75%",
                                            "borderRadius" : "50%",
                                            "backgroundColor" : "black",
                                            "height" : `${percentage(currentSize.width , 7)}px`,
                                            "width" : `${percentage(currentSize.width , 7)}px`,
                                            "borderStyle" : "solid" ,
                                            "borderColor" : "#00d6ff",
                                            "textAlign" : "center",
                                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`
                                        }}>
                                            <motion.div
                                                initial={{x : 1 , y : 7}}
                                            >10</motion.div>
                                        </div>
                                    </div>
                                    
                                </motion.div>

                                <motion.div style={{
                                    "width" : `${percentage(currentSize.width , 30)}px`,
                                    }}
                                    onClick={()=> CreatePlayBluff("9")}
                                    initial={{ opacity : 1 ,x: 0, y: 0, scale:0.75}}
                                    >
                                    <div style={{
                                        "backgroundColor" : "rgba(0,0,0,0.5)",
                                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                                        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                        "borderStyle" : "solid",
                                        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                        "borderColor" : "black",
                                        "color" : "#00d6ff",
                                    }}>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>PLAY AS</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                        <div style={{"marginLeft" : `${percentage(currentSize.width , 3.5)}px`,}}>NINES</div>
                                        <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>

                                        <div style={{
                                            "position" : "absolute",
                                            "bottom" : "5%",
                                            "left" : "75%",
                                            "borderRadius" : "50%",
                                            "backgroundColor" : "black",
                                            "height" : `${percentage(currentSize.width , 7)}px`,
                                            "width" : `${percentage(currentSize.width , 7)}px`,
                                            "borderStyle" : "solid" ,
                                            "borderColor" : "#00d6ff",
                                            "textAlign" : "center",
                                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`
                                        }}>
                                            <motion.div
                                                initial={{x : 1 , y : 7}}
                                            >9</motion.div>
                                        </div>
                                    </div>
                                    
                                </motion.div>
                            </div>
                                
                            </motion.div>

                        </div>
                        }

                        


                        <motion.div 
                            initial={{ opacity : 1, x: 0, y: 0 , scale : 1 }}
                            style={{
                                "position" : "absolute",
                                "width" : `${percentage(currentSize.width , 75)}px`,
                                "zIndex" : "60"
                            }}>
                                <motion.div 
                                onTapStart={ CancelStart }
                                onTapCancel={ CancelCancel }
                                onTap={ CancelDone }
                                style={BluffCancelColor}>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                    <div>CANCEL</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>                                
                                </motion.div>
                        </motion.div>
                    </motion.div>
                </div>
            )
    } else if(Submitted === false &&
        SelectBluff === false &&
        ZeroCards === true 
    ){
        return ( <div style={{
                        "position" : "absolute",
                        "height" : `${TableHeight}px`,
                        "width" : `${TableWidth}px`,
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        <div style={{
                            "position" : "absolute",
                            "bottom" : "0%",
                            "left" : "-15%"
                        }}>

                            <motion.div 
                            style={{
                                "backgroundColor" : "black",
                                "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                                "width" : `${percentage(currentSize.width , 75/2.5)}px`,
                                "margin" : "0 auto",
                                "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                                "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                                "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                                "borderStyle" : "solid",
                                "borderColor" : "#00d6ff",
                                "color" : "#00d6ff",
                                "textAlign" : "center"
                            }}>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                <div>CURRENT BLUFFER</div>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                <div>HAS ZERO CARDS</div>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                <div>CHALLENGE IS THE</div>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                <div>ONLY OPTION</div>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                <div>AVAILABLE</div>
                                <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                            </motion.div>
                            
                        </div>

                        <div style={{
                            "position" : "absolute",
                            "bottom" : "0%",
                            "right" : "0%"
                        }}>
                            <div>
                                <motion.div style={SelectChallengeColor}
                                    onTapStart={ SelectChallengeStart }
                                    onTapCancel={ SelectChallengeCancel }
                                    onTap={ SelectChallengeDone }
                                    initial={{x: "40%", y: "0%" }}
                                >
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                    <div>CHALLENGE</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
        )
    } else if(Submitted === false &&
        SelectBluff === false &&
        ZeroCards === false &&
        Submitted === false &&
        CurrentBluff !== null 
    ){
        return (
            <div style={{
                "position" : "absolute",
                "height" : `${TableHeight}px`,
                "width" : `${TableWidth}px`,
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
            }}>
                <div style={{
                    "position" : "absolute",
                    "bottom" : "0%",
                    "left" : "0%"
                }}>
                    <motion.div 
                        initial={{x: "-40%" , y: "25%" , scale : 1}}>
                            < BluffHideCards GameData = { GameData } />
                    </motion.div>
                    
                    <motion.div initial={{x: "-40%", y: "0%" }}
                        style={{
                            "width" : `${percentage(currentSize.width , 75/3)}px`,
                        }}>
                            <motion.div 
                                onTapStart={ SelectBluffStart }
                                onTapCancel={ SelectBluffCancel }
                                onTap={ SelectBluffDone }
                                style={SelectBluffColor}>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                    <div>PLAY BLUFF</div>
                                    <div style={{
                                        "fontSize" : `${percentage(currentSize.width , 1.75)}px`,
                                    }}>CLICK TO MAXIMIZE</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>                                
                            </motion.div>
                    </motion.div>
                </div>

                <div style={{
                    "position" : "absolute",
                    "bottom" : "0%",
                    "right" : "0%"
                }}>
                    <div>
                        <motion.div style={SelectChallengeColor}
                            onTapStart={ SelectChallengeStart }
                            onTapCancel={ SelectChallengeCancel }
                            onTap={ SelectChallengeDone }
                            initial={{x: "40%", y: "-50%" }}
                        >
                            <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                            <div>CHALLENGE</div>
                            <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                        </motion.div>
                    </div>

                    <div >
                        <motion.div style={SelectPassColor}
                            onTapStart={ SelectPassStart }
                            onTapCancel={ SelectPassCancel }
                            onTap={ SelectPassDone }
                            initial={{x: "40%", y: "0%" }}
                        >
                            <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                            <div>PASS</div>
                            <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                        </motion.div>
                    </div>
                </div>
            </div>
        )
    } else if(Submitted === false &&
        SelectBluff === false &&
        ZeroCards === false &&
        Submitted === false &&
        CurrentBluff === null 
    ){
        return (
            <div style={{
                "position" : "absolute",
                "height" : `${TableHeight}px`,
                "width" : `${TableWidth}px`,
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
            }}>
                <div style={{
                    "position" : "absolute",
                    "bottom" : "0%",
                    "left" : "0%"
                }}>
                    <motion.div 
                        initial={{x: "-40%" , y: "25%" , scale : 1}}>
                            < BluffHideCards GameData = { GameData } />
                    </motion.div>
                    
                    <motion.div initial={{x: "-40%", y: "0%" }}
                        style={{
                            "width" : `${percentage(currentSize.width , 75/3)}px`,
                        }}>
                            <motion.div 
                                onTapStart={ SelectBluffStart }
                                onTapCancel={ SelectBluffCancel }
                                onTap={ SelectBluffDone }
                                style={SelectBluffColor}>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                                    <div>PLAY BLUFF</div>
                                    <div style={{
                                        "fontSize" : `${percentage(currentSize.width , 1.75)}px`,
                                    }}>CLICK TO MAXIMIZE</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>                                
                            </motion.div>
                    </motion.div>
                </div>

                <div style={{
                    "position" : "absolute",
                    "bottom" : "0%",
                    "right" : "0%"
                }}>
                    <div >
                        <motion.div style={SelectPassColor}
                            onTapStart={ SelectPassStart }
                            onTapCancel={ SelectPassCancel }
                            onTap={ SelectPassDone }
                            initial={{x: "40%", y: "0%" }}
                        >
                            <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                            <div>PASS</div>
                            <div style={{"height" : `${percentage(currentSize.width , 1.5)}px`,}}></div>
                        </motion.div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<div>

        </div>)
    }
}

export default Section