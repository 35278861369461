import React , { useState } from "react";
import { motion }  from "framer-motion";
import ArrayList from 'arraylist';
import useResize from 'use-resize';
import PlayingCardCreator from './PlayingCardCreator';

const Section = (Value) => {
    const GameData = Value.GameData;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const currentSize = SizeInfo();
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    var AcesValue  = new ArrayList();
    var KingsValue = new ArrayList();
    var QueensValue = new ArrayList();
    var JacksValue = new ArrayList();
    var TensValue = new ArrayList();
    var NinesValue = new ArrayList();
    
    UpdateCardCount ()

    function UpdateCardCount () {
        AcesValue.add(GameData.MyCards.Aces)
        KingsValue.add(GameData.MyCards.Kings)
        QueensValue.add(GameData.MyCards.Queens)
        JacksValue.add(GameData.MyCards.Jacks)
        TensValue.add(GameData.MyCards.Tens)
        NinesValue.add(GameData.MyCards.Nines)
    }

    const [ AllAces ]  = useState(AcesValue);
    const [ AllKings ]  = useState(KingsValue);
    const [ AllQueens ]  = useState(QueensValue);
    const [ AllJacks ]  = useState(JacksValue);
    const [ AllTens ]  = useState(TensValue);
    const [ AllNines ]  = useState(NinesValue);
    
    const AcesProvider = () => {
        return (
            <div>
                {AllAces.map((Card) => 
                    <motion.div
                    initial={{ opacity : 1 , scale :1, x: 4 , y: -30 - (AllAces.indexOf(Card)* 2)}}
                    style={{
                        "position" : "absolute",
                        "zIndex" : `${ 20 - AllAces.indexOf(Card)}`
                    }} key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}
            </div>
        )
    }

    const KingsProvider = () => {
        return (
            <div>
                {AllKings.map((Card) => 
                    <motion.div 
                    initial={{ opacity : 1 , scale :1, x: 5 , y: -30 - (AllKings.indexOf(Card)* 2)}}
                    style={{
                        "position" : "absolute",
                        "zIndex" : `${ 20 - AllKings.indexOf(Card)}`
                    }} key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )} 
            </div>
        )
    }

    const QueensProvider = () => {
        return (
            <div>
                {AllQueens.map((Card) => 
                    <motion.div 
                    initial={{ opacity : 1 , scale :1, x: 5 , y: -30 - (AllQueens.indexOf(Card)* 2)}}
                    style={{
                        "position" : "absolute",
                        "zIndex" : `${ 20 - AllQueens.indexOf(Card)}`
                    }} key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}
            </div>
        )
    }

    const JacksProvider = () => {
        return (
            <div>
                {AllJacks.map((Card) => 
                    <motion.div 
                    initial={{ opacity : 1 , scale :1, x: 5 , y: -30 - (AllJacks.indexOf(Card)* 2)}}
                    style={{
                        "position" : "absolute",
                        "zIndex" : `${ 20 - AllJacks.indexOf(Card)}`
                    }} key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}
            </div>
        )
    }

    const TensProvider = () => {
        return (
            <div>
                {AllTens.map((Card) => 
                    <motion.div 
                    initial={{ opacity : 1 , scale :1, x: 5 , y: -30 - (AllTens.indexOf(Card)* 2)}}
                    style={{
                        "position" : "absolute",
                        "zIndex" : `${ 20 - AllTens.indexOf(Card)}`
                    }} key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}
            </div>
        )
    }

    const NinesProvider = () => {
        return (
            <div>
                {AllNines.map((Card) => 
                    <motion.div 
                    initial={{ opacity : 1 , scale :1, x: 5 , y: -30 - (AllNines.indexOf(Card)* 2)}}
                    style={{
                        "position" : "absolute",
                        "zIndex" : `${ 20 - AllNines.indexOf(Card)}`
                    }} key={Card.Index}>
                            < PlayingCardCreator CardData={{
                            Suit : Card.Suit,
                            Series : Card.Value
                        }} Dimensions = { Dimensions } />
                    </motion.div> 
                )}
            </div>
        )
    }


    return (
            <motion.div
                initial={{ opacity : 1, x: 0, y: 0 }}
                >
                
                <div style={{
                    "width" : `${percentage(currentSize.width , 25)}px`,
                    "height" : `${percentage(currentSize.width , 20)}px`,
                    "backgroundColor" : "#191919",
                    "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                    "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "borderStyle" : "solid",
                    "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                    "borderColor" : "black",
                }}>

            
            
    
                    <motion.div initial={{x : "-33%", y: "150%",scale : 0.30}}
                        style={{
                        "display" : "flex",
                        "position" : "absolute",
                        "bottom" : `${percentage(currentSize.width , 12)}px`,
                        "fontSize" : `${percentage(currentSize.width , 2)}px`,
                        "color" : "#00d6ff",
                        }}>

                    <motion.div style={{
                        "width" : `${percentage(currentSize.width , 75/6)}px`,
                        "textAlign" : "center"
                    }}>
                        <div>{AllAces.length}</div>
                        <div>ACE(S)</div>
                    </motion.div>

                    <motion.div style={{
                        "width" : `${percentage(currentSize.width , 75/6)}px`,
                        "textAlign" : "center"
                    }}>
                        <div>{AllKings.length}</div>
                        <div>KING(S)</div>
                    </motion.div>

                    <motion.div style={{
                        "width" : `${percentage(currentSize.width , 75/6)}px`,
                        "textAlign" : "center"
                    }}>
                        <div>{AllQueens.length}</div>
                        <div>QUEEN(S)</div>
                    </motion.div>

                    <motion.div style={{
                        "width" : `${percentage(currentSize.width , 75/6)}px`,
                        "textAlign" : "center"
                    }}>
                        <div>{AllJacks.length}</div>
                        <div>JACK(S)</div>
                    </motion.div>

                    <motion.div style={{
                        "width" : `${percentage(currentSize.width , 75/6)}px`,
                        "textAlign" : "center"
                    }}>
                        <div>{AllTens.length}</div>
                        <div>TEN(S)</div>
                    </motion.div>

                    <motion.div style={{
                        "width" : `${percentage(currentSize.width , 75/6)}px`,
                        "textAlign" : "center"
                    }}>
                        <div>{AllNines.length}</div>
                        <div>NINE(S)</div>
                    </motion.div>


                    </motion.div>

                    <motion.div initial={{x : "-33%", y: "240%",scale : 0.30}}
                        style={{
                        "display" : "flex",
                        "color" : "#00d6ff",
                        "position" : "absolute",
                        "bottom" : `${percentage(currentSize.width , 20)}px`,
                    }}>

                        <motion.div style={{
                            "width" : `${percentage(currentSize.width , 75/6)}px`,
                            "height": `${percentage(currentSize.width , 5)}px`,
                        }}>
                            < AcesProvider />
                        </motion.div>

                        <motion.div style={{
                            "width" : `${percentage(currentSize.width , 75/6)}px`,
                            "height": `${percentage(currentSize.width , 5)}px`,
                        }}>
                            < KingsProvider />
                        </motion.div>

                        <motion.div style={{
                            "width" : `${percentage(currentSize.width , 75/6)}px`,
                            "height": `${percentage(currentSize.width , 5)}px`,
                        }}>
                            < QueensProvider />
                        </motion.div>

                        <motion.div style={{
                            "width" : `${percentage(currentSize.width , 75/6)}px`,
                            "height": `${percentage(currentSize.width , 5)}px`,
                        }}>
                            < JacksProvider />
                        </motion.div>

                        <motion.div style={{
                            "width" : `${percentage(currentSize.width , 75/6)}px`,
                            "height": `${percentage(currentSize.width , 5)}px`,
                        }}>
                            < TensProvider />
                        </motion.div>

                        <motion.div style={{
                            "width" : `${percentage(currentSize.width , 75/6)}px`,
                            "height": `${percentage(currentSize.width , 5)}px`,
                        }}>
                            < NinesProvider />
                        </motion.div>


                    </motion.div>

            

                </div>

                </motion.div>

    )
}

export default Section