import React , { useState } from 'react';
import useOrientationChange from "use-orientation-change";
import IsAccess from '../../GlobalFunctions/PracticeMode/IsGamerAccess'
import socket from '../../SocketConnectionManager';
import Rendering from './ProfileComponents/GameTableLauncher';
import Analytics from '../../GlobalFunctions/Analytics';

const Section = () => {
    const orientation = useOrientationChange()
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null);

    if(IsAccessData === null){
        IsAccess(function (AccessDataValue){
            IsAccessDataUpdate(AccessDataValue)
            Analytics("Game-Table-Launched")
        })
    }

    socket.on('connect' , function (){

    })

    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else {
        return (
            <div>
                < Rendering />
            </div>
        )
    }
}

export default Section