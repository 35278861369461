import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import {  useLocation} from 'react-router-dom'

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(232, 232,  232 , 0.4) ,rgba(0,0,0,0.1), rgba(232, 232,  232, 0.2))"
    const BackGroundGradient_8 = "linear-gradient(rgba(0, 0, 0 ,0.1) ,rgba(0,0,0,0.1), rgba(0, 0, 0 ,0.1))"
   
    const location = useLocation();
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ ButtonColor , ButtonColorUpdate ] = useState(BackGroundGradient_8);
    const [ ButtonFontColor ] = useState("#00f9ff")
    const [ ShadowWidth ] = useState(1);
    const [ ShadowColor ] = useState("#00f9ff");
    
    const ButtonValue = "REWARDS";
    const RedirectValue = "/freetoplay/cb/rewards" ;
    const Size = Value.SizeData ;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function onTapStart() {
        ButtonColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        ButtonColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }
    

    const width = Size.width ;
    
    return ( <div className="BlurIt">
        { location.pathname === RedirectValue &&
            <div style={{
                "width" : `${percentage(width , 24)}px`,
                "textAlign" : "center",
                "backgroundImage" : BackGroundGradient_1,
                "fontSize" : `${percentage(width , 24)/8}px`,
                "borderTopStyle" : "solid",
                "borderBottomStyle" : "solid",
                "borderWidth" : `${percentage(width , 24)/80}px`,
                "borderColor" : "#00f9ff",
                "color" : ButtonFontColor,
                "boxShadow" : `0 0 ${percentage(width , ShadowWidth * 3)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , ShadowWidth * 3)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , ShadowWidth * 3)}px ${ShadowColor}`,
            }}>
                <div style={{"height" : `${percentage(width , 24)/16}px`,}}></div>
                { ButtonValue }
                <div style={{"height" : `${percentage(width , 24)/16}px`,}}></div>
            </div>
        }

        { location.pathname !== RedirectValue &&
            <div style={{
                "width" : `${percentage(width , 24)}px`,
                "textAlign" : "center",
                "backgroundImage" : ButtonColor,
                "fontSize" : `${percentage(width , 24)/8}px`,
                "borderTopStyle" : "solid",
                "borderBottomStyle" : "solid",
                "borderWidth" : `${percentage(width , 24)/80}px`,
                "borderColor" : "#00f9ff",
                "color" : ButtonFontColor,
                "boxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
            }}>
                <motion.div onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>
                <div style={{"height" : `${percentage(width , 24)/16}px`,}}></div>
                { ButtonValue }
                <div style={{"height" : `${percentage(width , 24)/16}px`,}}></div>
                </motion.div>
            </div>
        }

        { RedirectProperty === true &&
            <Redirect to={RedirectValue} />
        }

    </div>)
    
}

export default Section