import React , { useState } from 'react';
import useSetInterval from 'use-set-interval';
import { motion }  from "framer-motion";

    const TimeValue = (Values) => {
        const CallBack = Values.IsOver ;
        const [ Time , TimeUpdate ] = useState(Values.TimerData)

        useSetInterval(()=>{
            TimeUpdate(Time - 1)
            if(Time <= 0){
                CallBack( function (){
                    return "JABBU"
                })
            }
        }, 1000)

        if(Time >= 0){
            return <motion.div 
            initial={{ opacity : 1}}
            >
                BIDDING WILL CLOSE IN { Time } SECOND(S)
            </motion.div>
        } else {
            return <div></div>
        }
        
    }

    export default TimeValue