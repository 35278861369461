import React , { useState } from 'react';
import useResize from 'use-resize';  
import OnSoundToggle from './OnSoundToggle';
import OnMusicToggle from './OnMusicToggle';
import LeaveMatch from './LeaveMatch';
import { motion } from 'framer-motion';
import TableHeightCalculator from "./TableHeightCalculator";
import Tutorial from './Tutorial'

const Section = (Value) => {
    const TableData  = Value.TableData;
    const isAgnostic = Value.isAgnostic ;
    const [ ActiveState , ActiveStateUpdate ] = useState("JABBU");
    const SoundStatus = Value.Music;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    
    const TableHeight = TableHeightCalculator();

    

    const ActiveStateMaker = (ViewValue) => {
        ActiveStateUpdate(ViewValue)
    }

    const OpacityValue = () => {
        if(ActiveState === "JABBU"){
            return 1
        } else {
            return 1
        }
    }   
    
    return (<div style={{
        "height" : `${currentSize.height}px`,
        "width": `${currentSize.width}px`,
    }}>
        {  TableData !== null &&
            <div className="TableBackground" style={{
                "position" : "fixed",
                "top" : "2%",
                "left" : "0%",
                "zIndex" : "40",
                "width" : `100%`,
            }}>
               
               { TableData.DeductBoot === false && TableData.CardDistribution === false && TableData.CurrentChallenge === null && isAgnostic === false &&
                    < LeaveMatch SoundStatus = { SoundStatus } GameData = {TableData} OpacityValue={OpacityValue()} ActiveStateMaker = { ActiveStateMaker } />         
               }

                { isAgnostic === true && TableData.Winner !== true &&
                    < Tutorial SoundStatus = { SoundStatus } GameData = {TableData} OpacityValue={OpacityValue()} ActiveStateMaker = { ActiveStateMaker } />         
                }
                                
                < OnSoundToggle GameData = {TableData} SoundStatus = {SoundStatus} /> 

                < OnMusicToggle GameData = {TableData}  />   

                {   TableData !== null &&
                    TableData.CurrentBluff === null && TableData.CurrentChallenge === null &&
                    ( TableData.CardDistribution === false || TableData.BootDeduction === false ) &&
                    TableData.Winner === true &&
                        <motion.div>
                                 <motion.div 
                                    animate={{ opacity : 0.2}}
                                    style={{
                                        "position" : "absolute",
                                        "top" : `${percentage(TableHeight , 60)}px`,
                                        "width" : `${percentage(currentSize.width , 75)}px`,
                                        "textAlign" : "center",
                                        "left": "50%",
                                        "transform" : "translate(-50%, -50%)",
                                        "fontFamily" : "CardText",
                                        "color" : "grey",
                                        "fontSize" : `${percentage(currentSize.width , 10)}px`,
                                    }}>
                                        <div style={{
                                            "fontSize" : `${percentage(currentSize.width , 5)}px`,
                                        }}>MATCH ENDED</div>
                                 </motion.div>                                 
                        </motion.div>
                        
                }
              
            </div> 
        }
    </div>)
}

export default Section