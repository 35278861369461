import React from 'react';
import Music from '../../../../../Sound/CardsDistributed.mp3';
import useSound from 'use-sound';

const Section = (Value) => {
    const ToPlay = Value.Status;
    const [ play , { stop }] = useSound(Music);

    return (
        <div>
            { ToPlay === true && play() }
            { ToPlay === false && stop() }
        </div>
    )
    
}

export default Section