import React , { useState } from 'react';
import useOrientationChange from "use-orientation-change";
import IsAccess from '../../GlobalFunctions/PracticeMode/IsGamerAccess'
import socket from '../../SocketConnectionManager';
import Rendering from './ProfileComponents/MatchMaker'
import MatchMakerDisconnected from './ProfileComponents/MatchMakerDisconnected'
import GetAgnosticAccessPoint from '../../GlobalFunctions/PracticeMode/GetAgnosticAccessPoint'
import Analytics from '../../GlobalFunctions/Analytics';
import { motion } from 'framer-motion';
import useSetTimeout from 'use-set-timeout';
import Config from '../../GlobalFunctions/Config';

const Section = () => {
    const orientation = useOrientationChange()
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null);
    const [ IsDisconnected , IsDisconnectedUpdate ] = useState(false);
    const [ isAgnostic , isAgnosticUpdate ] = useState(null);

    if(IsAccessData === null){
        IsAccess(function (AccessDataValue){            
            if(isAgnostic === null){
                isAgnosticUpdate(AccessDataValue.isAgnostic)
            }
            IsAccessDataUpdate(AccessDataValue)
            Analytics("Game-MatchMaker-Accessed")
        })
    }

    socket.on('MatchmakerCloseAccess' , function (){
        IsDisconnectedUpdate(true)
    })

    socket.on('connect' , function (){
        IsDisconnectedUpdate(true)
    })

    socket.on('disconnect' , function(){
        IsDisconnectedUpdate(true)
    })

    const TransferBackToAgnostic = () => {
        useSetTimeout(()=>{
            if(Config.AgnosticAccessPoint === null){
                GetAgnosticAccessPoint("bluff" , function (Data){
                    Config.AgnosticAccessPoint = Data
                    window.location.replace(Config.AgnosticAccessPoint)
                })
            } else {
                window.location.replace(Config.AgnosticAccessPoint)
            }
        }, 1500)

        return (<motion.div  style={{
            "position" : 'fixed',
            "width" : `100%`,
            "zIndex" : "25",
            "top" : "50%",
            "left": "0%",
            "transform" : "translate(-50%, -50%)",
            "textAlign" : "center"
        }}
        className='pulsate-fwd'>
            RECONNECTING TO MATCHMAKER
        </motion.div>)
    }

    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else {
        return (
            <div>
               { IsDisconnected === false && < Rendering AgnosticValue = { isAgnostic } /> }

               { IsDisconnected === true && isAgnostic === false && < MatchMakerDisconnected /> }

               { IsDisconnected === true && isAgnostic === true && <TransferBackToAgnostic /> }
            </div>
        )
    }
}

export default Section