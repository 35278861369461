import React  from 'react';
import useResize from 'use-resize';
import useOrientationChange from "use-orientation-change";
import { motion } from "framer-motion";
import BrandLogo from '../Icons/BrandLogo.png'
import FacebookLogo from '../Icons/Facebook.png'
import InstagramLogo from '../Icons/Instagram.png'
import TwitterLogo from '../Icons/Twitter.png'
import Analytics from '../GlobalFunctions/Analytics';
import BluffButton from './HomeComponents/BluffButton' ;
import CallBreakButton from './HomeComponents/CallBreakButton'
import MoreGamesComingSoon from '../Icons/MoreGamesComingSoon.png';


const Home = () => {
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const orientation = useOrientationChange()
    const currentSize = SizeInfo();

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    
    Analytics("Landing-Page-Visit")

    const Header = () => {
        return (
            <motion.div style={{
                "position" : "absolute",
                "width" : `${percentage(Dimensions.width , 100)}px`,
                "height" : `${percentage(Dimensions.width , 15)}px`,
                "top" : "2%",
            }}>
                 <motion.div                    
                    style={{
                        "position" : "absolute",
                        "left" : "2%"
                    }}
                 >
                    < img alt="Avatar" 
                        style={{
                        'height' : `auto`,
                        'width' : `${percentage(Dimensions.width , 25)}px`,
                        }}
                        src={ BrandLogo } />  
                </motion.div>

                <motion.div 
                    style={{
                        "color" : "#FDA80D",
                        'fontSize' : `${percentage(Dimensions.width , 2.5)}px`,
                        "position" : "absolute",
                        "left" : "2.5%",
                        "top" : "80%"
                    }}>
                        <div>MADE IN INDIA FOR THE WORLD</div>
                </motion.div>

                <motion.div 
                    style={{
                        "color" : "#FDA80D",
                        'width' : `${percentage(Dimensions.width , 25)}px`,
                        'fontSize' : `${percentage(Dimensions.width , 2.5)}px`,
                        "textAlign" : "center",
                        "position" : "absolute",
                        "right" : "2%",
                        "top" : "20%"
                }}>
                    <div>MULTIPLAYER</div>
                    <div>FREE TO PLAY</div>
                    <div>PLATFORM</div>
                </motion.div>


            </motion.div>
        )
    }


    const SocialHandles = () => {
        return (<motion.div
            style={{
                "position" : "absolute",
                "bottom" : "0%",
                "width" : `${percentage(Dimensions.width , 100)}px`,
                "height" : `${percentage(Dimensions.width , 25)}px`,
            }}
        >
            <motion.div style={{
                "textAlign" : "center",
                "width" : `${percentage(Dimensions.width , 100)}px`,
                "position" : "absolute",
                "top" : "0%",
                'fontSize' : `${percentage(Dimensions.width ,  3.5)}px`,
            }}>
                FOLLOW US ON
            </motion.div>

            <motion.div style={{
                "width" : `${percentage(Dimensions.width , 100)}px`,
                "position" : "absolute",
                "top" : "25%",
                "display" : "flex",
                "justifyContent" : "center"
            }}>
                
                <motion.div
                    initial = {{ scale : 0.75 , x : -10}}
					onTap={()=>{
						window.open("https://www.facebook.com/juvia.games" , "_blank")
					}}
                >
                    < img alt="Avatar" 
                        style={{
                        'height' : `auto`,
                        'width' : `${percentage(Dimensions.width , 12)}px`,
                        }}
                        src={ FacebookLogo } />
                </motion.div>

                <motion.div
					onTap={()=>{
						window.open("https://www.instagram.com/gamesjuvia" , "_blank")
					}}
				>
                    < img alt="Avatar" 
                        style={{
                        'height' : `auto`,
                        'width' : `${percentage(Dimensions.width , 12)}px`,
                        }}
                        src={ InstagramLogo } />
                </motion.div>

                <motion.div
                initial = {{  x : 10}}
				onTap={()=>{
						window.open("https://twitter.com/JuviaGames" , "_blank")
					}}
                >
                    < img alt="Avatar" 
                        style={{
                        'height' : `auto`,
                        'width' : `${percentage(Dimensions.width , 12)}px`,
                        }}
                        src={ TwitterLogo } />
                </motion.div>

            </motion.div>
        </motion.div>)
    }

    
    

    if(orientation === "landscape"){
        return (<div>

        </div>)
    } else {
          
        
        return (<motion.div             
            className="HomePageBackground"
                    style={{
                        "position" : "absolute",
                        "width" : `${percentage(Dimensions.width , 100)}px`,
                        "height" : `${percentage(Dimensions.height , 100)}px`,
                    }}
                >
                <motion.div 
                style={{
                    "position" : "absolute",
                    "width" : `${percentage(Dimensions.width , 100)}px`,
                    "height" : `${percentage(Dimensions.height , 100)}px`,
                }}
                className="BlurIt">

                < Header />

                <motion.div 
                initial={{ opacity : 0 }}
                animate={{ opacity : 1 }}   
                style={{
                    "position" : "absolute",
                    "width" : `${percentage(Dimensions.width , 100)}px`,
                    "top" : "48%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",
                }}>

                    <div style={{
                        "display" : "flex",
                        "justifyContent" : "center",
                        "width" : `${percentage(Dimensions.width , 100)}px`,
                    }}>
                    < BluffButton />
                        <div style={{"width" : `${percentage(Dimensions.width , 5)}px`,}}></div>
                    < CallBreakButton />
                    </div>      


                    <div style={{"height" : `${percentage(Dimensions.width , 5)}px` }} />


                    <motion.div
                        animate={{ opacity : 0.4 }}                        
                        style={{
                            "textAlign" : "center",
                        }}                      
                    >
                        < img alt="Avatar" 
                            style={{
                            'height' : `auto`,
                            'width' : `${percentage(Dimensions.width , 87)}px`,
                            "borderStyle" : "solid",
                            "borderWidth" : `${percentage(Dimensions.width , 0.75)}px`,
                            "borderRadius" : `${percentage(Dimensions.width , 4)}px`,
                            "borderColor" : "black",                            
                            "boxShadow" : `0 0 ${percentage(Dimensions.width , 2)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 2)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 2)}px black`,
                            }}
                            src={ MoreGamesComingSoon } />
                    </motion.div>              

                </motion.div>




                < SocialHandles />

                </motion.div>
                
                
        </motion.div>)
    }
}

export default Home