import React , { useState } from 'react';
import useResize from 'use-resize';  
import BootManager from './GameTableAssets/BootManager'
import PlayerScoreBoard from './GameTableAssets/PlayerScoreBoard';
import OnSoundToggle from './GameTableAssets/OnSoundToggle';
import OnMusicToggle from './GameTableAssets/OnMusicToggle';
import LeaveMatch from './GameTableAssets/LeaveMatch';
import socket from '../../../SocketConnectionManager';
import SoundTypeOne from '../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';
import MatchEndedImage from '../../../Icons/MatchEnded.png' ;
import RoundEndedImage from '../../../Icons/RoundEnded.png' ;
import PlayAgainImage from '../../../Icons/PlayAgain.png' ;
import QuitGameImage from '../../../Icons/ExitGame.png' ;
import { motion } from 'framer-motion';
import Tutorial from './GameTableAssets/Tutorial'
import Config from '../../../GlobalFunctions/Config';
import GetAgnosticAccessPoint from '../../../GlobalFunctions/PracticeMode/GetAgnosticAccessPoint'
import AgnosticEnd from '../../../GlobalFunctions/PracticeMode/AgnosticEnd'

const Section = (Value) => {
    const TableData  = Value.TableData;
    const isAgnostic = Value.isAgnostic ;
    const [ AgnosticUpdater , AgnosticUpdaterUpdate ] = useState(null)
    const [ ActiveState , ActiveStateUpdate ] = useState("JABBU");
    const SoundStatus = Value.Music;
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    const EmitSignal =  "LeaveGameNow-CB";
    const [ OverlayShadowWidth ] = useState(5);
    const ShadowColor = "black";
    
    const OnExit = () => {
        if(SoundStatus === true){
            playOne()
        }        
        socket.emit(EmitSignal)
        window.location.replace('/freetoplay/cb/profile')
    }

    const OnPlayAgain = () => {
        if(SoundStatus === true){
            playOne()
        }        
        socket.emit(EmitSignal)
        window.location.replace('/freetoplay/cb/matchmaker_playagain')
    }

    const OnPlayAgainAgnostic = () => {
        if(SoundStatus === true){
            playOne()
        }        
        GetAgnosticAccessPoint("cb" , function (Data){
            socket.emit(EmitSignal)
            Config.AgnosticAccessPoint = Data
            window.location.replace(Config.AgnosticAccessPoint)
        })
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();
    const ShadowProperty = `0 0 ${percentage(currentSize.width , OverlayShadowWidth)}px  ${ShadowColor}`;
    const ShadowPropertyPlay = `0 0 ${percentage(currentSize.width , OverlayShadowWidth)}px  #fda80d`;
    const ShadowPropertyQuit = `0 0 ${percentage(currentSize.width , OverlayShadowWidth)}px  #ff0000`;
    const [ zIndexBeauty , zIndexBeautyUpdate ] = useState(50)

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const ActiveStateMaker = (ViewValue) => {
        ActiveStateUpdate(ViewValue)
    }

    const OpacityValue = () => {
        if(ActiveState === "JABBU"){
            if(zIndexBeauty === 500){
                zIndexBeautyUpdate(50)
            }
            
            return 1
        } else {
            if(ActiveState === "TUTORIAL"){                
                if(zIndexBeauty === 50){
                    zIndexBeautyUpdate(500)
                }
                
            } else {
                if(zIndexBeauty === 500){
                    zIndexBeautyUpdate(50)
                }
                
                return 0.1
            }
            
        }
    }   

    if(TableData.RoundEnded === true && TableData.Round === 2 && isAgnostic === true && AgnosticUpdater === null){
        AgnosticUpdaterUpdate(false)
        var UpdateData = {
            PlayerOne : {
                Winner : isWinner(1)
            },
            PlayerTwo : {
                Winner : isWinner(2)
            },
            PlayerThree : {
                Winner : isWinner(3)
            },
            PlayerFour : {
                Winner : isWinner(4)
            },
        }

        function isWinner(PlayerValue){            
            if(TableData.RankValues[0].item.Seat === PlayerValue){
                return true
            } else {
                return false
            }         
        }

        AgnosticEnd(UpdateData, function (Data){
            socket.emit(EmitSignal)
        })
    }
    
    return (<div style={{
        "height" : `${currentSize.height}px`,
        "width": `${currentSize.width}px`,
    }}>

          

        {  TableData !== null &&
            <div className="TableBackground" style={{
                "position" : "fixed",
                "top" : "0%",
                "left" : "0%",
                "zIndex" : zIndexBeauty ,
                "height" :  `100%`,
                "width" : `100%`,
            }}>
               
                { TableData.BootDeduction === false && 
                    TableData.IsBidding === false  &&
                    TableData.RoundEnded !== true &&

                    < PlayerScoreBoard SoundStatus = { SoundStatus } GameData = {TableData} OpacityValue={OpacityValue()} ActiveStateMaker = { ActiveStateMaker } /> 
                }

                { TableData.BootDeduction === false && 
                    TableData.IsBidding === false  &&
                    TableData.RoundEnded !== true &&
                    isAgnostic === false &&
                    < LeaveMatch SoundStatus = { SoundStatus } GameData = {TableData} OpacityValue={OpacityValue()} ActiveStateMaker = { ActiveStateMaker } /> 
                }

                { isAgnostic === true && TableData.Winner !== true && TableData.RoundEnded !== true &&
                    < Tutorial SoundStatus = { SoundStatus } GameData = {TableData} OpacityValue={OpacityValue()} ActiveStateMaker = { ActiveStateMaker } />         
                }

                { TableData.RoundEnded === true && TableData.Round === 2 && isAgnostic !== true &&           
                    <motion.div
                        initial={{ opacity : 0}}
                        animate={{ opacity : 1}}
                        transition={{ delay : 4.2}}
                        style={{
                            "position" : "absolute",
                            "bottom" : "2%",
                            "display" : "flex",
                            "justifyContent" : "center",
                            "width" : "100%",
                            "height" : `${percentage(currentSize.width , 17)}px`,                            
                        }}
                    >

                        <motion.div 
                            onTapStart={ OnExit }
                            animate={{}}
                            transition={{ delay : 4.3}}
                            style={{
                            }}>

                            <motion.div>
                                < img 
                                style={{
                                    "width" : `${percentage(currentSize.width , 17)}px`,
                                    "height" : `auto`,
                                    "backgroundColor" : "rgba(0,0,0,0.8)",
                                    "padding" : `${percentage(currentSize.width , 2)}px`,
                                    "paddingRight" : `${percentage(currentSize.width , 5)}px`,
                                    "paddingLeft" : `${percentage(currentSize.width , 5)}px`,
                                    "borderWidth" : `${percentage(currentSize.width , 0.55)}px`, 
                                    "borderRadius" : "15%",
                                    "borderStyle" : "solid",
                                    "borderColor" : "#ff0000",
                                    "boxShadow" : ShadowPropertyQuit,
                                    "WebkitBoxShadow" : ShadowPropertyQuit,
                                    "MozBoxShadow" : ShadowPropertyQuit,  
                                }}
                                alt="Avatar" src={ QuitGameImage } />
                            </motion.div> 

                        </motion.div>

                        <motion.div animate={{ width : `${percentage(currentSize.width , 6)}px`,}}>

                        </motion.div>


                        <motion.div 
                        animate={{}}
                        onTapStart={ OnPlayAgain }
                        transition={{ delay : 4.3}}
                        style={{                     
                        }}>
                            
                            <motion.div style={{
                            }}>
                                < img 
                                style={{
                                    "width" : `${percentage(currentSize.width , 17)}px`,
                                    "height" : `auto`,                                    
                                    "backgroundColor" : "rgba(0,0,0,0.8)",
                                    "padding" : `${percentage(currentSize.width , 2)}px`,
                                    "paddingRight" : `${percentage(currentSize.width , 5)}px`,
                                    "paddingLeft" : `${percentage(currentSize.width , 5)}px`,
                                    "borderWidth" : `${percentage(currentSize.width , 0.55)}px`, 
                                    "borderStyle" : "solid",
                                    "borderRadius" : "15%",
                                    "borderColor" : "#fda80d",
                                    "boxShadow" : ShadowPropertyPlay,
                                    "WebkitBoxShadow" : ShadowPropertyPlay,
                                    "MozBoxShadow" : ShadowPropertyPlay,  
                                }}
                                alt="Avatar" src={ PlayAgainImage } />
                            </motion.div>  

                        </motion.div>

                        
                    </motion.div>
                }

                { TableData.RoundEnded === true && TableData.Round === 2 && isAgnostic === true &&           
                    <motion.div
                        initial={{ opacity : 0}}
                        animate={{ opacity : 1}}
                        transition={{ delay : 4.2}}
                        style={{
                            "position" : "absolute",
                            "bottom" : "2%",
                            "display" : "flex",
                            "justifyContent" : "center",
                            "width" : "100%",
                            "height" : `${percentage(currentSize.width , 17)}px`,                            
                        }}
                    >

                        <motion.div 
                        animate={{}}
                        onTapStart={ OnPlayAgainAgnostic }
                        transition={{ delay : 4.3}}
                        style={{                     
                        }}>
                            
                            <motion.div style={{
                            }}>
                                < img 
                                style={{
                                    "width" : `${percentage(currentSize.width , 17)}px`,
                                    "height" : `auto`,                                    
                                    "backgroundColor" : "rgba(0,0,0,0.8)",
                                    "padding" : `${percentage(currentSize.width , 2)}px`,
                                    "paddingRight" : `${percentage(currentSize.width , 5)}px`,
                                    "paddingLeft" : `${percentage(currentSize.width , 5)}px`,
                                    "borderWidth" : `${percentage(currentSize.width , 0.55)}px`, 
                                    "borderStyle" : "solid",
                                    "borderRadius" : "15%",
                                    "borderColor" : "#fda80d",
                                    "boxShadow" : ShadowPropertyPlay,
                                    "WebkitBoxShadow" : ShadowPropertyPlay,
                                    "MozBoxShadow" : ShadowPropertyPlay,  
                                }}
                                alt="Avatar" src={ PlayAgainImage } />
                            </motion.div>  

                        </motion.div>

                        
                    </motion.div>
                }

                { TableData.RoundEnded === true && TableData.Round === 1 &&                
                    <motion.div
                        initial={{ opacity : 0 , x : -100}}
                        animate={{ opacity : 1, x : 0}}
                        transition={{ delay : 4.2}}
                        style={{
                            "position" : "absolute",
                            "top" : "5%",
                            "zIndex" : "53",
                            "height" :  `${percentage(currentSize.width , 15)}px`,
                            "width" : `${percentage(currentSize.width , 60)}px`,                            
                        }}>
                        
                        
                        <motion.div style={{
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "position" : "absolute",
                            }}>
                            < img 
                            style={{
                                "width" : `${percentage(currentSize.width , 45)}px`,
                                "height" : `auto`,
                                "position" : "absolute",
                                "backgroundColor" : "rgba(0,0,0,0.8)",
                                "padding" : `${percentage(currentSize.width , 1)}px`,
                                "borderWidth" : `${percentage(currentSize.width , 0.55)}px`, 
                                "borderStyle" : "solid",
                                "borderColor" : "black",
                                "boxShadow" : ShadowProperty,
                                "WebkitBoxShadow" : ShadowProperty,
                                "MozBoxShadow" : ShadowProperty,  
                            }}
                            alt="Avatar" src={ RoundEndedImage } />
                        </motion.div>                      

                    </motion.div>
                }

                { TableData.RoundEnded === true && TableData.Round === 2 &&                
                    <motion.div
                        initial={{ opacity : 0 , x : -100}}
                        animate={{ opacity : 1, x : 0}}
                        transition={{ delay : 4.2}}
                        style={{
                            "position" : "absolute",
                            "top" : "1%",
                            "zIndex" : "53",
                            "height" :  `${percentage(currentSize.width , 15)}px`,
                            "width" : `${percentage(currentSize.width , 60)}px`,                            
                        }}>
                        
                        
                        <motion.div style={{
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "position" : "absolute",
                            }}>
                            < img 
                            style={{
                                "width" : `${percentage(currentSize.width , 45)}px`,
                                "height" : `auto`,
                                "position" : "absolute",
                                "backgroundColor" : "rgba(0,0,0,0.8)",
                                "padding" : `${percentage(currentSize.width , 1)}px`,
                                "borderWidth" : `${percentage(currentSize.width , 0.55)}px`, 
                                "borderStyle" : "solid",
                                "borderColor" : "black",
                                "boxShadow" : ShadowProperty,
                                "WebkitBoxShadow" : ShadowProperty,
                                "MozBoxShadow" : ShadowProperty,  
                            }}
                            alt="Avatar" src={ MatchEndedImage } />
                        </motion.div>                      

                    </motion.div>
                }
                
                < OnSoundToggle GameData = {TableData} SoundStatus = {SoundStatus} /> 

                < OnMusicToggle GameData = {TableData}  /> 



                { TableData !== null  && TableData.RoundEnded !== true &&
                    < BootManager SoundStatus = { SoundStatus } GameData = { TableData } OpacityValue={OpacityValue()} ActiveStateMaker = { ActiveStateMaker } /> 
                }

                
                
            </div> 
        }


    </div>)
}

export default Section