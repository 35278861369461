import React , { useState } from 'react';
import useResize from 'use-resize';
import { motion } from 'framer-motion';
import ScoreboardIcon from '../../../../Icons/ScoreBoard.png'
import SoundTypeOne from '../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';

const Section = (Values) => {
    const [ ViewState , ViewStateUpdate ] = useState(false)
    const ScoreBoardValues = Values.GameData.ScoreBoardDisplay ;
    const ActiveStateMaker = Values.ActiveStateMaker ;
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Selection = () => {
        ViewStateUpdate(true)
        playOne();
        ActiveStateMaker( function (){
            return "Scoreboard"
        })
    }

    const SelectionCancel = () => {
        ViewStateUpdate(false)
        playOne();
        ActiveStateMaker( function (){
            return "JABBU"
        })
    }

    
    
        return (
            <div>
            { ViewState === false &&
                <motion.div 

                style={{
                }}>
                    <motion.div 
                        onTapStart={ Selection }                        
                        animate = {{
                            opacity : Values.OpacityValue
                        }}
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",                            
                            'position' : "absolute", 
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "top" : `10%`,
                            'left' : "88%",                            
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25",                            
                        }}>                        
                            < img alt="Avatar" 
                                style={{
                                'height' : `${percentage(currentSize.width , 7.5)}px`,
                                "width" : "auto",
                                }}
                                src={ ScoreboardIcon} />
                    </motion.div>

                </motion.div>
            
            }

            { ViewState === true &&
                <motion.div 

                style={{
                    'position' : "absolute",  
                    "zIndex" : "22",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    <motion.div 
                        onTapStart={ SelectionCancel }
                        animate={{ rotate : 20}}
                        style={{
                            "backgroundColor" : "black",
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "margin" : "0 auto",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",                            
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "#fe68c3",
                            "color" : "#fe68c3",
                            "textAlign" : "center",
                            "position" : "absolute",
                            "top" : `10%`,
                            'left' : "73%",
                            "zIndex" : "20"
                        }}>
                            <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                            <div>CLOSE</div>  
                            <div>SCOREBOARD</div>
                            <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>                           
                    </motion.div>



                    <motion.div 
                        initial={{ x : 50 , y : 20, zIndex : 1}}
                        style={{
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                            "top" : `10%`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "width" : `${percentage(currentSize.width , 75)}px`,
                            "margin" : "0 auto",
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "#fe68c3",
                            "color" : "#fe68c3",
                            "textAlign" : "center",
                            "position" : "absolute"
                        }}>
                            <div style={{
                                "display" : "flex"
                            }}>
                                <div style={{
                                    "width" : `${percentage(currentSize.width , 25)}px`,
                                    "textAlign" : "left",
                                    "marginLeft" : `${percentage(currentSize.width , 2)}px`,
                                }}>
                                    <div style={{"height" : `${percentage(currentSize.width , 10)}px`,}}></div>

                                    <div>{ ScoreBoardValues.PlayerOneName }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerTwoName }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerThreeName }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerFourName }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 2.75)}px`,}}></div>
                                    
                                </div>

                                <div style={{
                                    "width" : `${percentage(currentSize.width , 15)}px`,
                                    "textAlign" : "center",
                                }}>
                                    <div style={{"height" : `${percentage(currentSize.width , 10)}px`,}}>
                                        <div
                                            style={{
                                                "paddingTop" : `${percentage(currentSize.width , 5)}px`,
                                            }}
                                        >R1</div>
                                    </div>


                                    <div>{ ScoreBoardValues.PlayerOneR1Score }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerTwoR1Score }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerThreeR1Score }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerFourR1Score }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 2.75)}px`,}}></div>
                                    
                                </div>

                                <div style={{
                                    "width" : `${percentage(currentSize.width , 15)}px`,
                                    "textAlign" : "center",
                                }}>
                                    <div style={{"height" : `${percentage(currentSize.width , 10)}px`,}}>
                                        <div
                                            style={{
                                                "paddingTop" : `${percentage(currentSize.width , 5)}px`,
                                            }}
                                        >R2</div>
                                    </div>


                                    <div>{ ScoreBoardValues.PlayerOneR2Score }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerTwoR2Score }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerThreeR2Score }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerFourR2Score }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 2.75)}px`,}}></div>
                                    
                                </div>

                                <div style={{
                                    "width" : `${percentage(currentSize.width , 15)}px`,
                                    "textAlign" : "center",
                                }}>
                                    <div style={{"height" : `${percentage(currentSize.width , 10)}px`,}}>
                                        <div
                                            style={{
                                                "paddingTop" : `${percentage(currentSize.width , 5)}px`,
                                            }}
                                        >TOTAL</div>
                                    </div>


                                    <div>{ ScoreBoardValues.PlayerOneScore }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerTwoScore }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerThreeScore }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                    <div>{ ScoreBoardValues.PlayerFourScore }</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 2.75)}px`,}}></div>
                                    
                                </div>
                            </div>  

                            <div style={{"height" : `${percentage(currentSize.width , 5)}px`,}}></div>

                            <div style={{
                                "display" : "flex",                                
                            }}>
                                <div style={{ 
                                    "width" : `${percentage(currentSize.width , 6)}px`,
                                    "textAlign" : "center",
                                }}>
                                   <div>*</div> 
                                   <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                   <div>*</div>
                                   <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                   <div>*</div> 
                                </div>
                                <div style={{ 
                                    "width" : `${percentage(currentSize.width , 60)}px`,
                                    "textAlign" : "left",
                                }}>
                                    <div>THIS IS A LIVE SCOREBOARD</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                    <div>SCORES CHANGE WITH EACH HAND RESULT</div>
                                    <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                    <div>SCORES WILL BE FINALIZED ON ROUND END</div>
                                </div>
                                
                            </div>     

                            <div style={{"height" : `${percentage(currentSize.width , 5)}px`,}}></div>

                        </motion.div>
                </motion.div>
                }
            </div>
    )

}

export default Section