import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import RewardIcon from '../../../Icons/Reward.png';
import Progress from 'react-progressbar';
import Config from '../../../GlobalFunctions/Config'

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"

    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);

    const Size = Value.SizeData ;

    const RewardWins = Config.practiceModeDataCB.RewardWins;
    const RewardMaxWins = Config.practiceModeDataCB.RewardMaxWins;

    const RewardText = () => {
        if(RewardWins === RewardMaxWins){
            return (<div>
                <div style={{
                    "fontSize" :  `${percentage(width , 2.7)}px`,
                    "color" : "#00f9ff",
                }}>
                    <div>REWARD</div>
                    <div>UNLOCKED</div>
                </div>
            </div>)
        } else {
            return (<div>
                <div style={{"display" : "flex" , "justifyContent" : "center"}}>YOU NEED 
                <div style={{
                    'paddingLeft' :  `${percentage(width , 0.8)}px`,
                    'paddingRight' :  `${percentage(width , 0.8)}px`,
                    "color" : "#00f9ff",
                }}>{ RewardMaxWins - RewardWins }</div> 
                WINS</div> 
                <div>TO UNLOCK REWARD</div>
            </div>)
        }
    }

    const ClassAnimation = () => {
        if(RewardWins === RewardMaxWins){
            return ("pulsate-fwd")
        } else {
            return ("")
        }
    }

    const IconSize = () => {
        if(RewardWins === RewardMaxWins){
            return 12
        } else {
            return 8
        }
    }

    var LevelCompletion = (RewardWins * 100) / RewardMaxWins
    LevelCompletion = Math.round(LevelCompletion);

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
    const height = Size.height ;

    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }

    return (<div className="BlurIt" style={{
            "height" : `${percentage(height , 15)}px`,
            "width" : `${percentage(width , 30)}px`,
            "textAlign" : "center",
            "backgroundImage" : BackgroundColor,
            "fontSize" : `${percentage(width , 2.5)}px`,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "borderWidth" : `${percentage(width , 0.35)}px`,
            "borderRadius" : `${percentage(width , 1)}px`,
    }}>

        <div style={{
            "position" : "relative",
            "top" : "50%",
            "left": "50%",
            "transform" : "translate(-50%, -50%)"
        }}>

            <motion.div  onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>

            
            < img className={ClassAnimation()} alt="Avatar" 
                    style={{
                    'height' : `${percentage(width , IconSize())}px`,
                    'width' : `${percentage(width , IconSize())}px`,
                    }}
                    src={ RewardIcon } />  

            <div style={{"height" : `${percentage(height , 0.5)}px`,}}></div>

            <div style={{}}> { RewardText() } </div>

            <div style={{"height" : `${percentage(height , 0.5)}px`,}}></div>

            { RewardWins !== RewardMaxWins &&
                <div style={{
                    "width" : `${percentage(width, 25)}px`,
                    "backgroundColor" : "black" ,
                    "borderStyle" : "solid",
                    "borderColor" : "#00f9ff",
                    "margin" : "0 auto",
                    "borderWidth" : `${percentage(width, 0.5)}px`,
                    "borderRadius" : `${percentage(width, 1)}px`,
                }}>
                    <Progress color={"#00f9ff"} completed={LevelCompletion} />
                </div>
            }

            </motion.div>

            { RedirectProperty === true &&
                <Redirect to="/freetoplay/cb/rewards" />
            }

        </div>

    </div>)
}

export default Section