import React , { useState } from 'react';
import useResize from 'use-resize'; 
import OpponentBlock from './OpponentBlock';
import { motion } from 'framer-motion';

import PlayerPositionCalculator from './PlayerPositionCalculator';
import TableHeightCalculator from "./TableHeightCalculator";


const Section = (Data) => {
    const [ ShadowWidth ] = useState(4);
    const [ OverlayShadowWidth ] = useState(10);
    const ShadowColor = "grey";
    const ShadowColorTable = "#00f9ff";
    const GameData = Data.GameData ;
    const isAgnostic = Data.isAgnostic ;

    const SeatOnePosition = PlayerPositionCalculator(1)
    const SeatTwoPosition = PlayerPositionCalculator(2)
    const SeatThreePosition = PlayerPositionCalculator(3)
    const SeatFourPosition = PlayerPositionCalculator(4)

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const ShadowProperty = `0 0 ${percentage(currentSize.width , OverlayShadowWidth)}px  ${ShadowColor}`;
    const TableShadow = `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColorTable}`;

    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }

    

    const TableHeight = TableHeightCalculator();

    const RoundValue = () =>{
        if(GameData !== null){
            if(GameData.Round === 1){ return "ONE" }
            if(GameData.Round === 2){ return "TWO" }
            return("")
        }
    }

    return (<motion.div 
    initial={{ opacity : 0.5}}
    animate={{
        opacity : 1
    }}
    transition={{ duration : 0.5 }}
    style={{
                "height" : `${TableHeight}px`,
                "width" : `${percentage(currentSize.width , 75)}px`,
                "margin" : "0 auto",
                "borderTopLeftRadius" : "35%",
                "borderTopRightRadius" : "35%",
                "borderBottomLeftRadius" : "25%",
                "borderBottomRightRadius" : "25%",                
            }}>

                <div style={{
                    "position" : "absolute",
                    "transform" : "translate(-50%, -50%)",
                    "top" : "50%",
                    "left": "50%",
                }}>
                    <div style={{
                        "height" : `${percentage(TableHeight , 100)}px`,
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "backgroundColor" : "grey",
                        "backgroundImage" : "radial-gradient(rgba(0, 0, 0 , 0.1) , rgba(0, 0, 0 , 0.5) , rgba(0, 0, 0 , 0.99))",
                        "margin" : "0 auto",
                        "borderTopLeftRadius" : "35%",
                        "borderTopRightRadius" : "35%",
                        "borderBottomLeftRadius" : "35%",
                        "borderBottomRightRadius" : "35%",
                        "borderStyle" : "solid",
                        "borderColor" : "black",
                        "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                        "boxShadow" : ShadowProperty,
                        "WebkitBoxShadow" : ShadowProperty,
                        "MozBoxShadow" : ShadowProperty,
                        "position" : "absolute",
                        "zIndex" : "21",
                        "top" : "32%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>

                    </div>

                    <div style={{
                        "height" : `${percentage(TableHeight , 95)}px`,
                        "width" : `${percentage(currentSize.width , 70)}px`,
                        "backgroundColor" : "rgba(0,0,0,0.1)",
                        "backgroundImage" : "linear-gradient(rgba(0, 0, 0 , 0.1) , rgba(0, 0, 0 , 0.6) , rgba(0, 0, 0 , 0.6))",
                        "borderStyle" : "solid",
                        "borderTopLeftRadius" : "35%",
                        "borderTopRightRadius" : "35%",
                        "borderBottomLeftRadius" : "35%",
                        "borderBottomRightRadius" : "35%",
                        "borderColor" : ShadowColorTable,
                        "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                        "boxShadow" : TableShadow ,
                        "WebkitBoxShadow" : TableShadow ,
                        "MozBoxShadow" : TableShadow ,
                        "position" : "absolute",
                        "zIndex" : "21",
                        "top" : "32%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>

                    </div>
                </div>         

                <div style={{
                    "height" : `${TableHeight}px`,
                    "width" : `${percentage(currentSize.width , 75)}px`,
                    "position" : "absolute",
                }}>

                { GameData !== null &&
                    GameData.IsBidding !== true &&
                      < motion.div style={ SeatOnePosition }>
                        < OpponentBlock Seat={1} PlayerValues = { PlayerData() } GameData = {GameData} isAgnostic = {isAgnostic} />
                    </motion.div>
                }
            
                < motion.div style={ SeatTwoPosition }>
                    < OpponentBlock Seat={2} PlayerValues = { PlayerTwo() } GameData = {GameData} isAgnostic = {isAgnostic} />
                </motion.div>

                < motion.div style={ SeatThreePosition }>
                    < OpponentBlock Seat={3} PlayerValues = { PlayerThree() } GameData = {GameData} isAgnostic = {isAgnostic} />
                </motion.div>
                

                < motion.div style={ SeatFourPosition }>
                    < OpponentBlock Seat={4} PlayerValues = { PlayerFour() } GameData = {GameData} isAgnostic = {isAgnostic} />
                </motion.div>

                {   GameData !== null &&
                        <motion.div 
                        animate={{ opacity : 0.2}}
                        style={{
                            "position" : "absolute",
                            "top" : `${percentage(TableHeight , 47)}px`,
                            "width" : `${percentage(currentSize.width , 75)}px`,
                            "textAlign" : "center",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",
                            "fontFamily" : "CardText",
                            "color" : "grey",
                            "fontSize" : `${percentage(currentSize.width , 5)}px`,
                        }}>
                            <div>CALLBREAK {"<>"} ROUND { RoundValue() }</div>
                        </motion.div>
                }



                
                
                </div>
                
            </motion.div>)

}

export default Section