import React from 'react';
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import PlayingCardCreator from '../PlayingCardCreator';

const Section = (Value) => {
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const GameData = Value.GameData;
    const HandWinner = GameData.HandWinner ;

    const Player = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1 && HandWinner === null){
            return GameData.HandCards.Player4 ;
        } else if(GameData.MySeat === 1 && HandWinner !== null){
            return HandWinner.HandData.HandData.Player4
        } else {
            return null
        }
    }

    const PlayerData = Player ();

    const StyleValue = {
        "height" : `${percentage(currentSize.width , 11)}px`,
        "width" : `${percentage(currentSize.width , 9)}px`,
        "backgroundColor" : "rgba(0,0,0,0.2)",
        "borderRadius" : `${percentage(currentSize.width , 0.75)}px`,
        "position" : "absolute",
        "top" : "20%",
        "left" : "87%"
    }

    const FinalStyleValue = {
        "height" : `${percentage(currentSize.width , 11)}px`,
        "width" : `${percentage(currentSize.width , 9)}px`,
        "position" : "absolute",
        "top" : "20%",
        "left" : "87%",
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }


    return(
        <div>
            { PlayerData === null &&
                <motion.div 
                    style={ StyleValue }>
                </motion.div>
            }

            { PlayerData !== null &&
                <motion.div className="seat-four-card-played" style={ FinalStyleValue }>
                        < PlayingCardCreator CardData={{
                            Suit : PlayerData.Suit,
                            Series : PlayerData.Value
                        }} Dimensions = { Dimensions } />
                </motion.div>
            }
        </div>
    )

}

export default Section