import React , { useState } from "react";
import { motion }  from "framer-motion";
import ArrayList from 'arraylist';
import useResize from 'use-resize';
import CardStackProvider from './CardStackProvider'
import FingerMover from "./FingerMover";

const Section = (Value) => {
    const GameData = Value.GameData;
    var AcesValue  = new ArrayList();
    var KingsValue = new ArrayList();
    var QueensValue = new ArrayList();
    var JacksValue = new ArrayList();
    var TensValue = new ArrayList();
    var NinesValue = new ArrayList();
    
    UpdateCardCount ()

    function UpdateCardCount () {
        AcesValue.add(GameData.MyCards.Aces)
        KingsValue.add(GameData.MyCards.Kings)
        QueensValue.add(GameData.MyCards.Queens)
        JacksValue.add(GameData.MyCards.Jacks)
        TensValue.add(GameData.MyCards.Tens)
        NinesValue.add(GameData.MyCards.Nines)
    }

    const [ AllAces ]  = useState(AcesValue);
    const [ AllKings ]  = useState(KingsValue);
    const [ AllQueens ]  = useState(QueensValue);
    const [ AllJacks ]  = useState(JacksValue);
    const [ AllTens ]  = useState(TensValue);
    const [ AllNines ]  = useState(NinesValue);

    
    const currentSize = SizeInfo();

    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    

    return (
            <motion.div style={{
            }}>

                <motion.div 
                    style={{
                        "position" : "absolute",
                        "zIndex" : "26",
                        "textAlign" : "center",
                        "color" : "white",
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                        "bottom" : "2%"
                    }}
                        animate={{x: 0 , y: 0}}>
                    <div>YOU CAN SELECT CARDS</div>
                    <div>WHEN IT"S YOUR TURN</div>
                </motion.div>

                <motion.div style={{
                   "position" : "absolute",
                   "zIndex" : "26",
                   "textAlign" : "center",
                   "color" : "white",
                   "width" : `${percentage(currentSize.width , 75)}px`,
                   "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                   "top" : "2%"
                }}>
                <FingerMover SlotOneValue = {null} 
                                    SlotTwoValue = {null} 
                                    SlotThreeValue = {null} 
                                    SlotFourValue = {null} 
                                    SlotFiveValue = {null} 
                                    SlotSixValue = {null} />
                </motion.div>
                
                <motion.div 
                    style={{
                        "position" : "absolute",
                        "zIndex" : "25",
                        "textAlign" : "center",
                        "color" : "#00d6ff",
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                        "bottom" : "-5%"
                    }}>
                < CardStackProvider AllAces = { AllAces } AllKings = {AllKings}
                            AllJacks = { AllJacks } AllQueens = {AllQueens }
                            AllNines = { AllNines } AllTens = { AllTens }/>
                </motion.div>

                </motion.div>

    )
}

export default Section