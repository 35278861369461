import React  from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import PlayingCardCreator from './PlayingCardCreator';

const Section = (Value) => {
    const SlotOneValue = Value.SlotOneValue ;
    const SlotTwoValue = Value.SlotTwoValue ;
    const SlotThreeValue = Value.SlotThreeValue ;
    const SlotFourValue = Value.SlotFourValue ;
    const SlotFiveValue = Value.SlotFiveValue ;
    const SlotSixValue = Value.SlotSixValue ;

    const currentSize = SizeInfo();
    const BackgroundColor = "radial-gradient(rgba(0, 0, 0, 0.1) , rgba(0, 0, 0, 0.1) , rgba(0, 0, 0, 0.1))";

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    
    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }


    return (
        <div>
            <motion.div 
                    style={{
                        "position" : "absolute",
                        "zIndex" : "30",
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "height" : `${percentage(currentSize.width , 25)}px`,
                        "backgroundColor" : "#191919",
                        "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                        "borderStyle" : "solid",
                        "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                        "borderColor" : "black",
                    }}>
                        <div style={{
                             "width" : `${percentage(currentSize.width , 75)}px`,
                             "position" : "absolute",
                             "top" : `${percentage(currentSize.width , -6)}px`,
                        }}>
                        <motion.div 
                        className="BlurIt" style={{
                            "backgroundColor" : "#191919",
                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                            "width" : `${percentage(currentSize.width , 30)}px`,
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderStyle" : "solid",
                            "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                            "borderColor" : "black",
                            "zIndex" : "22",
                            "textAlign" : "center",
                            "margin" : "0 auto"
                        }}>
                            <div style={{"height" : `${percentage(currentSize.width , 3)}px`,}}></div>
                            SELECTED CARDS
                            <div style={{"height" : `${percentage(currentSize.width , 3)}px`,}}></div>
                        </motion.div>
                        </div>

                        <div style={{
                             "width" : `${percentage(currentSize.width , 75)}px`,
                             "position" : "absolute",
                             "top" : `${percentage(currentSize.width , 3)}px`,
                        }}>

                        <motion.div 
                            style={{
                                "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                                "width" : `${percentage(currentSize.width , 75)}px`,
                                "textAlign" : "center"
                            }}>
                                <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                TAP ON SLOT(S) TO REMOVE THE CARD
                                <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                        </motion.div>

                        </div>

                        <div style={{
                             "width" : `${percentage(currentSize.width , 75)}px`,
                             "position" : "absolute",
                             "top" : `${percentage(currentSize.width , 9)}px`,
                        }}>

                        <motion.div 
                            style={{
                                "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                                "width" : `${percentage(currentSize.width , 75)}px`,
                                "display" : "flex"
                            }}>
                            
                            <div style={{"width" :  `${percentage(currentSize.width , 1.25)}px`,}}></div>
                            
                            <motion.div    
                                style={{
                                "width" : `${percentage(currentSize.width , 10)}px`,
                                "height" : `${percentage(currentSize.width , 12)}px`,
                                "backgroundColor" : "rgba(0,0,0,0.3)",
                                "backgroundImage" : BackgroundColor ,
                                "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                                "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            }}>
                                
                                { SlotOneValue !== null && SlotOneValue.Value  === "A" &&
                                    <motion.div className="slide-one-to-one"
                                    initial={{x : 0 , y : 0, scale : 1}}
                                    >
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotOneValue.Suit,
                                                        Series : SlotOneValue.Value 
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }

                                { SlotOneValue !== null && SlotOneValue.Value  === "K" &&
                                    <motion.div className="slide-two-to-one"
                                    initial={{x : 0 , y : 0, scale : 1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotOneValue.Suit,
                                                        Series : SlotOneValue !== null && SlotOneValue.Value 
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }


                                { SlotOneValue !== null && SlotOneValue.Value  === "Q" &&
                                    <motion.div className="slide-three-to-one"
                                    initial={{x : 0 , y : 0, scale : 1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotOneValue.Suit,
                                                        Series : SlotOneValue !== null && SlotOneValue.Value 
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }


                                { SlotOneValue !== null && SlotOneValue.Value  === "J" &&
                                    <motion.div className="slide-four-to-one"
                                    initial={{x : 0 , y : 0, scale : 1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotOneValue.Suit,
                                                        Series : SlotOneValue !== null && SlotOneValue.Value 
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }

                                { SlotOneValue !== null && SlotOneValue.Value  === "10" &&
                                    <motion.div className="slide-five-to-one"
                                    initial={{x : 0 , y : 0, scale : 1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotOneValue.Suit,
                                                        Series : SlotOneValue !== null && SlotOneValue.Value 
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }

                                { SlotOneValue !== null && SlotOneValue.Value  === "9" &&
                                    <motion.div className="slide-six-to-one"
                                    initial={{x : 0 , y : 0, scale :1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotOneValue.Suit,
                                                        Series : SlotOneValue !== null && SlotOneValue.Value 
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }
                            </motion.div>

                            <div style={{"width" :  `${percentage(currentSize.width , 2.35)}px`,}}></div>

                        <motion.div 
                        style={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "height" : `${percentage(currentSize.width , 12)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.3)",
                            "backgroundImage" : BackgroundColor ,
                            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`
                        }}>
                            { SlotTwoValue !== null && SlotTwoValue.Value  === "A" &&
                                <motion.div className="slide-minus-one-to-one"
                                initial={{x : 0 , y : 0, scale : 1}}>
                                    < PlayingCardCreator CardData={{
                                                    Suit : SlotTwoValue.Suit,
                                                    Series : SlotTwoValue.Value 
                                                }} Dimensions = { Dimensions } />
                                </motion.div>
                            }

                            { SlotTwoValue !== null && SlotTwoValue.Value  === "K" &&
                                <motion.div className="slide-one-to-one"
                                initial={{x : 0 , y : 0, scale :  1}}>
                                    < PlayingCardCreator CardData={{
                                                    Suit : SlotTwoValue.Suit,
                                                    Series : SlotTwoValue.Value 
                                                }} Dimensions = { Dimensions } />
                                </motion.div>
                            }


                            { SlotTwoValue !== null && SlotTwoValue.Value  === "Q" &&
                                <motion.div className="slide-two-to-one"
                                initial={{x : 0 , y : 0, scale :  1}}>
                                    < PlayingCardCreator CardData={{
                                                    Suit : SlotTwoValue.Suit,
                                                    Series : SlotTwoValue.Value 
                                                }} Dimensions = { Dimensions } />
                                </motion.div>
                            }


                            { SlotTwoValue !== null && SlotTwoValue.Value  === "J" &&
                                <motion.div className="slide-three-to-one"
                                initial={{x : 0 , y : 0, scale :  1}}>
                                    < PlayingCardCreator CardData={{
                                                    Suit : SlotTwoValue.Suit,
                                                    Series : SlotTwoValue.Value 
                                                }} Dimensions = { Dimensions } />
                                </motion.div>
                            }

                            { SlotTwoValue !== null && SlotTwoValue.Value  === "10" &&
                                <motion.div className="slide-four-to-one"
                                initial={{x : 0 , y : 0, scale :  1}}>
                                    < PlayingCardCreator CardData={{
                                                    Suit : SlotTwoValue.Suit,
                                                    Series : SlotTwoValue.Value 
                                                }} Dimensions = { Dimensions } />
                                </motion.div>
                            }

                            { SlotTwoValue !== null && SlotTwoValue.Value  === "9" &&
                                <motion.div className="slide-five-to-one"
                                initial={{x : 0 , y : 0, scale :  1}}>
                                    < PlayingCardCreator CardData={{
                                                    Suit : SlotTwoValue.Suit,
                                                    Series : SlotTwoValue.Value 
                                                }} Dimensions = { Dimensions } />
                                </motion.div>
                            }
                        </motion.div>

                            <div style={{"width" :  `${percentage(currentSize.width , 2.5)}px`,}}></div>
                        
                            <motion.div 
                                style={{
                                    "width" : `${percentage(currentSize.width , 10)}px`,
                                    "height" : `${percentage(currentSize.width , 12)}px`,
                                    "backgroundColor" : "rgba(0,0,0,0.3)",
                                    "backgroundImage" : BackgroundColor ,
                                    "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                                    "borderRadius" : `${percentage(currentSize.width , 2.5)}px`
                                }}>
                                { SlotThreeValue !== null && SlotThreeValue.Value === "A" &&
                                    <motion.div className="slide-minus-two-to-one"
                                    initial={{x : 0 , y : 0, scale :  1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotThreeValue.Suit,
                                                        Series : SlotThreeValue.Value
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }

                                { SlotThreeValue !== null && SlotThreeValue.Value === "K" &&
                                    <motion.div className="slide-minus-one-to-one"
                                    initial={{x : 0 , y : 0, scale :  1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotThreeValue.Suit,
                                                        Series : SlotThreeValue.Value
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }


                                { SlotThreeValue !== null && SlotThreeValue.Value === "Q" &&
                                    <motion.div className="slide-one-to-one"
                                    initial={{x : 0 , y : 0, scale :  1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotThreeValue.Suit,
                                                        Series : SlotThreeValue.Value
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }


                                { SlotThreeValue !== null && SlotThreeValue.Value === "J" &&
                                    <motion.div className="slide-two-to-one"
                                    initial={{x : 0 , y : 0, scale :  1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotThreeValue.Suit,
                                                        Series : SlotThreeValue.Value
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }

                                { SlotThreeValue !== null && SlotThreeValue.Value === "10" &&
                                    <motion.div className="slide-three-to-one"
                                    initial={{x : 0 , y : 0, scale :  1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotThreeValue.Suit,
                                                        Series : SlotThreeValue.Value
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }

                                { SlotThreeValue !== null && SlotThreeValue.Value === "9" &&
                                    <motion.div className="slide-four-to-one"
                                    initial={{x : 0 , y : 0, scale :  1}}>
                                        < PlayingCardCreator CardData={{
                                                        Suit : SlotThreeValue.Suit,
                                                        Series : SlotThreeValue.Value
                                                    }} Dimensions = { Dimensions } />
                                    </motion.div>
                                }
                            </motion.div>

                            <div style={{"width" :  `${percentage(currentSize.width , 2.5)}px`,}}></div>


                                <motion.div 
                                style={{
                                    "width" : `${percentage(currentSize.width , 10)}px`,
                                    "height" : `${percentage(currentSize.width , 12)}px`,
                                    "backgroundColor" : "rgba(0,0,0,0.3)",
                                    "backgroundImage" : BackgroundColor ,
                                    "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                                    "borderRadius" : `${percentage(currentSize.width , 2.5)}px`
                                }}>
                                    { SlotFourValue !== null && SlotFourValue.Value === "A" &&
                                        <motion.div className="slide-minus-three-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFourValue.Suit,
                                                            Series : SlotFourValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotFourValue !== null && SlotFourValue.Value === "K" &&
                                        <motion.div className="slide-minus-two-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFourValue.Suit,
                                                            Series : SlotFourValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }


                                    { SlotFourValue !== null && SlotFourValue.Value === "Q" &&
                                        <motion.div className="slide-minus-one-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFourValue.Suit,
                                                            Series : SlotFourValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }


                                    { SlotFourValue !== null && SlotFourValue.Value === "J" &&
                                        <motion.div className="slide-one-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFourValue.Suit,
                                                            Series : SlotFourValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotFourValue !== null && SlotFourValue.Value === "10" &&
                                        <motion.div className="slide-two-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFourValue.Suit,
                                                            Series : SlotFourValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotFourValue !== null && SlotFourValue.Value === "9" &&
                                        <motion.div className="slide-three-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFourValue.Suit,
                                                            Series : SlotFourValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }
                                </motion.div>

                                <div style={{"width" :  `${percentage(currentSize.width , 2.5)}px`,}}></div>

                                <motion.div 
                                style={{
                                    "width" : `${percentage(currentSize.width , 10)}px`,
                                    "height" : `${percentage(currentSize.width , 12)}px`,
                                    "backgroundColor" : "rgba(0,0,0,0.3)",
                                    "backgroundImage" : BackgroundColor ,
                                    "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                                    "borderRadius" : `${percentage(currentSize.width , 2.5)}px`
                                }}>
                                    { SlotFiveValue !== null && SlotFiveValue.Value === "A" &&
                                        <motion.div className="slide-minus-four-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFiveValue.Suit,
                                                            Series : SlotFiveValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotFiveValue !== null && SlotFiveValue.Value === "K" &&
                                        <motion.div className="slide-minus-three-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFiveValue.Suit,
                                                            Series : SlotFiveValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }


                                    { SlotFiveValue !== null && SlotFiveValue.Value === "Q" &&
                                        <motion.div className="slide-minus-two-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFiveValue.Suit,
                                                            Series : SlotFiveValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }


                                    { SlotFiveValue !== null && SlotFiveValue.Value === "J" &&
                                        <motion.div className="slide-minus-one-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFiveValue.Suit,
                                                            Series : SlotFiveValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotFiveValue !== null && SlotFiveValue.Value === "10" &&
                                        <motion.div className="slide-one-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFiveValue.Suit,
                                                            Series : SlotFiveValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotFiveValue !== null && SlotFiveValue.Value === "9" &&
                                        <motion.div className="slide-two-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotFiveValue.Suit,
                                                            Series : SlotFiveValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }
                                </motion.div>

                                <div style={{"width" :  `${percentage(currentSize.width , 2.5)}px`,}}></div>

                                <motion.div 
                                style={{
                                    "width" : `${percentage(currentSize.width , 10)}px`,
                                    "height" : `${percentage(currentSize.width , 12)}px`,
                                    "backgroundColor" : "rgba(0,0,0,0.3)",
                                    "backgroundImage" : BackgroundColor ,
                                    "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                                    "borderRadius" : `${percentage(currentSize.width , 2.5)}px`
                                }}>
                                    { SlotSixValue !== null && SlotSixValue.Value === "A" &&
                                        <motion.div className="slide-minus-five-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotSixValue.Suit,
                                                            Series : SlotSixValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotSixValue !== null && SlotSixValue.Value === "K" &&
                                        <motion.div className="slide-minus-four-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotSixValue.Suit,
                                                            Series : SlotSixValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }


                                    { SlotSixValue !== null && SlotSixValue.Value === "Q" &&
                                        <motion.div className="slide-minus-three-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotSixValue.Suit,
                                                            Series : SlotSixValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }


                                    { SlotSixValue !== null && SlotSixValue.Value === "J" &&
                                        <motion.div className="slide-minus-two-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotSixValue.Suit,
                                                            Series : SlotSixValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotSixValue !== null && SlotSixValue.Value === "10" &&
                                        <motion.div className="slide-minus-one-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotSixValue.Suit,
                                                            Series : SlotSixValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }

                                    { SlotSixValue !== null && SlotSixValue.Value === "9" &&
                                        <motion.div className="slide-one-to-one"
                                        initial={{x : 0 , y : 0, scale :  1}}>
                                            < PlayingCardCreator CardData={{
                                                            Suit : SlotSixValue.Suit,
                                                            Series : SlotSixValue.Value
                                                        }} Dimensions = { Dimensions } />
                                        </motion.div>
                                    }
                                </motion.div>

                        </motion.div>

                        </div>
                </motion.div>
        </div>
    )
}

export default Section