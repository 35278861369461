import React  from 'react';
import useResize from 'use-resize'; 
import PlayerPositionCalculator from '../PlayerPositionCalculator';
import { motion }  from "framer-motion";
import InfoImage from '../../../../../Icons/SpadesAreTrumps.png'

const Section = () => {
    const ShadowWidth = 5;
    const ShadowColor = "grey";
    const SeatOnePosition = PlayerPositionCalculator(1)

    

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const TrayPositionProvider = () => {
        if(SeatOnePosition.top === "65%"){
            return("56%")            
        } else if(SeatOnePosition.top === "95%"){
            return("55%")
        } else if(SeatOnePosition.top === "96%"){
            return("55%")
        }  

        return("")
    }
        
    const TrayStyle = () => {
        return (
            {
                "position" : "absolute",
                "zIndex" : "100",
                "top" :  TrayPositionProvider() ,
                "borderRadius" : "10%",
                "borderColor" : "black",
                "borderStyle" : "solid",
                "fontFamily" : "CardText",
                "textAlign" : "center",
                "backgroundColor" : "black",
                "fontSize" : `${percentage(currentSize.width , 5)}px`,
                "width" : `${percentage(currentSize.width , 95)}px`,
                "marginLeft" : `${percentage(currentSize.width , 1.5)}px`,
                "borderWidth" : `${percentage(currentSize.width , 1.15)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
             }
        )
    }
    
    

    return (
    <motion.div 
        style={TrayStyle()}>
            
            <motion.div style={{
                            }}>
                < img 
                style={{
                    "width" : `${percentage(currentSize.width , 60)}px`,                    
                }}
                alt="Avatar" src={ InfoImage } />
            </motion.div>  

    </motion.div>
    )
}

export default Section