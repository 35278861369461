import React , { useState } from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import BackIcon from '../../../../Icons/Back.png'

const BackButton = () => {
    const [ ButtonSize , ButtonSizeUpdate ] = useState(10)

    

    function onTapStart() {
        ButtonSizeUpdate(6)
    }

    function onTapCancel() {
        ButtonSizeUpdate(10) 
    }

    function onTap() {
        window.location.replace('/freetoplay/cb/profile')
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    return (
        <div style={{
            "position" : "absolute",
            "top" :   "3%",
            "height" : "7%",
            "width" : "100%",
            "zIndex" : "25"
       }}>

           <div style={{
               "position" : "relative",
               "top" : "50%",
               "left": "50%",
               "transform" : "translate(-50%, -50%)",
           }}>

               <motion.div style={{
                   "width" : `${percentage(currentSize.width , 10)}px`,
                   "marginLeft" : `${percentage(currentSize.width , 6)}px`,
               }} onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>

               <div>
               < img alt="Logo" 
                   style={{
                   'height' : `${percentage(currentSize.width , ButtonSize)}px`,
                   'width' : `${percentage(currentSize.width , ButtonSize)}px`,
                   }}
                   src={ BackIcon } />
               </div>


               </motion.div>

               </div>
           
           
       </div>
    )
}

export default BackButton