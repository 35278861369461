import React , { useState } from "react";
import useSetInterval from "use-set-interval";

const NextRoundStarter = () => {
    const [ Time , TimeUpdate ] = useState(5)

    useSetInterval(()=>{
        TimeUpdate(Time - 1)
    }, 1000)

    return (<div className="pulsate-fwd">IN { Time } SECOND(S)</div>)
}

export default NextRoundStarter