import React from 'react';
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import CardStack from '../../../../Icons/CardStack.png';
import CardBackground from '../../../../Icons/CardBackground.jpg';

const CardPile = (Values) => {
    const SeatSize = 20
    const GameData = Values.GameData;
    
    const ShadowWidth = 4;
    const ShadowColor = "black";

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();
    
    function percentage(num, per)
        {
            return (num/100)*per;
        }

    const ValueOfCenter = percentage(currentSize.width , 27)

    const NumberOfCardsBluffed = GameData.CurrentBluff.NumberOfCardsBluffed ;

    const CardBackgroundCreator = () => {
        return (
            <div>
                < img alt="Avatar" style={{
                    'width' : `${percentage(currentSize.width , 5)}px`,
                    'height' : `auto`,
                    "paddingLeft" : `${percentage(currentSize.width , 1)}px`,
                    "paddingRight" : `${percentage(currentSize.width , 1)}px`,
                    "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px black`,
                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px black`,
                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px black`,
                    "borderRadius" : `${percentage(currentSize.width , 1)}px`,
                }}
                src={ CardBackground } />
            </div>
        )
    }

    return (
        <div>
                <motion.div  
                    initial={{ 
                        "left" : `${ValueOfCenter}px`, 
                        opacity : 1 }}
                    animate={{
                        "left" : `${ValueOfCenter}px`,                        
                        opacity : 0
                    }} 
                    transition={{ delay : 0.4}}                      
                    style={{
                        "position" : "absolute",
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "width" : `${percentage(currentSize.width , SeatSize)}px`,
                        "height" : `${percentage(currentSize.width , SeatSize/1.75)}px`,
                        "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                        "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                        "borderColor" : "black",
                        "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                        "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                        "borderStyle" : "solid",
                    }}>


                        <div style={{
                            "color" : "#00d6ff",
                            "backgroundColor" : "black",
                            "borderTopLeftRadius" : `${percentage(currentSize.width , 2)}px`,
                            "borderTopRightRadius" : `${percentage(currentSize.width , 2)}px`,
                            "textAlign" : "center"
                        }}>
                            <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                            CARD PILE
                            <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                        </div>

                        < img alt="Avatar" 
                                    style={{
                                        'height' : `${percentage(currentSize.width , 3.5)}px`,
                                        'width' : `auto`,
                                        "position" : "absolute",
                                        "top" : "60%",
                                        "left": "50%",
                                        "zIndex" : "25",
                                        "transform" : "translate(-50%, -50%)",
                                        }}
                                        src={ CardStack} />

                        
                            <div style={{
                                "position" : "absolute",
                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                "borderRadius" : "50%",
                                "color" : "#00d6ff",
                                "height" : `${percentage(currentSize.width , 6)}px`,
                                "width" : `${percentage(currentSize.width , 6)}px`,
                                "fontSize" : `${percentage(currentSize.width , 3)}px`,
                                "textAlign": "center",
                                "top" : "100%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",
                                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                            }}> 
                                <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                { GameData.TableCards - GameData.CurrentBluff.NumberOfCardsBluffed}
                                <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                            </div>
            </motion.div>

                <motion.div  
                    initial={{ opacity : 0 , 
                        "left" : `${ValueOfCenter}px`, 
                    }}
                    animate={{                      
                        scale : 1,
                        opacity : 1
                    }} 
                    transition={{ delay : 0.4}}                      
                    style={{
                        "position" : "absolute",
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "width" : `${percentage(currentSize.width , SeatSize)}px`,
                        "height" : `${percentage(currentSize.width , SeatSize/1.75)}px`,
                        "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                        "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                        "borderColor" : "black",
                        "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                        "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                        "borderStyle" : "solid",
                    }}>

                        <motion.div className='pulsate-fwd'
                        initial={{ opacity : 0.6 , x : 0, y : 0}}
                        animate={{ opacity : 0 , x : 0, y : 0}}
                        transition={{ delay : 0.4 , duration : 0.5}}
                        style={{
                            "position" : "absolute",
                            "width" : `${percentage(currentSize.width , SeatSize) * 1.65}px`,
                            "height" : `${percentage(currentSize.width , SeatSize) * 1.65}px`,                            
                            "borderRadius" : "50%",
                            "borderWidth" : `${percentage(currentSize.width , 2)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "#00d6ff",
                            "top" : "-100%",
                            "left" : "-43%",
                        }}>
                        </motion.div>

                        <motion.div className='pulsate-fwd'
                        initial={{ opacity : 0.5 , x : 0, y : 0}}
                        animate={{ opacity : 0 , x : 0, y : 0}}
                        transition={{ delay : 0.4 , duration : 0.5}}
                        style={{
                            "position" : "absolute",
                            "width" : `${percentage(currentSize.width , SeatSize) * 1.45}px`,
                            "height" : `${percentage(currentSize.width , SeatSize) * 1.45}px`,                            
                            "borderRadius" : "50%",
                            "borderWidth" : `${percentage(currentSize.width , 1)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "#00d6ff",
                            "top" : "-71%",
                            "left" : "-28%",
                        }}>
                        </motion.div>


                        <div style={{
                            "color" : "#00d6ff",
                            "backgroundColor" : "black",
                            "borderTopLeftRadius" : `${percentage(currentSize.width , 2)}px`,
                            "borderTopRightRadius" : `${percentage(currentSize.width , 2)}px`,
                            "textAlign" : "center"
                        }}>
                            <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                            CARD PILE
                            <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                        </div>

                        <motion.div 
                        initial={{
                            "position" : "absolute",
                            "top" : "60%",
                            "left": "10%",
                            "zIndex" : "25",
                            "transform" : "translate(-50%, -50%)",                            
                        }}
                        animate={{                            
                            "position" : "absolute",
                            "top" : "60%",
                            "left": "50%",
                            "zIndex" : "25",
                            "transform" : "translate(-50%, -50%)",
                        }}
                        transition={{ duration : 0.8}}
                        >
                            < img alt="Avatar" style={{
                                'height' : `${percentage(currentSize.width , 3.5)}px`,
                                'width' : `auto`,
                            }}
                            src={ CardStack} />
                        </motion.div>
                        

                        
                            <div style={{
                                "position" : "absolute",
                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                "borderRadius" : "50%",
                                "color" : "#00d6ff",
                                "height" : `${percentage(currentSize.width , 6)}px`,
                                "width" : `${percentage(currentSize.width , 6)}px`,
                                "fontSize" : `${percentage(currentSize.width , 3)}px`,
                                "textAlign": "center",
                                "top" : "100%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",
                                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                            }}> 
                                <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                { GameData.TableCards }
                                <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                            </div>

                            <motion.div 
                                style={{
                                    "position" : "absolute",
                                    "width" : `${percentage(currentSize.width , SeatSize) * 2}px`,
                                }}
                                initial={{ y : 0 , opacity : 0 }}
                                animate={{ y : 40 , opacity : 1 }}
                                transition={{ delay : 0.8 }}
                                >
                                    <div 
                                        style={{
                                            "position" : "absolute",
                                            "width" : `${percentage(currentSize.width , SeatSize) * 2}px`,
                                            "height" : `${percentage(currentSize.width , SeatSize)/2}px`,  
                                            "textAlign" : "center",
                                            "left" : "-25%",
                                            "top" : "130%",                                    
                                        }}>
                                        <div style={{
                                            "position" : "absolute",            
                                            "width" : `${percentage(currentSize.width , SeatSize) * 1.25}px`, 
                                            "top" : "50%" ,
                                            "left" : "50%",
                                            "transform" : "translate(-50%, -50%)", 
                                            "color" : "#00d6ff",
                                            "borderRadius" : "15%",
                                            "zIndex" : "10",
                                            "backgroundColor" : "rgba(0,0,0,0.8)",
                                            "paddingTop" : `${percentage(currentSize.width , 0.2)}px`,
                                            "paddingBottom" : `${percentage(currentSize.width , 0.2)}px`,
                                            "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                        }}>BLUFFER'S CARDS</div>

                                        
                                            <div style={{
                                                "display" : "flex",
                                                "justifyContent" : "center",
                                                "width" : `${percentage(currentSize.width , SeatSize) * 2.2}px`,
                                                "position" : "absolute",                                                        
                                                "top" : "50%" ,
                                                "left" : "50%",
                                                "transform" : "translate(-50%, -50%)",   
                                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                                "paddingTop" : `${percentage(currentSize.width , 1)}px`,
                                                "paddingBottom" : `${percentage(currentSize.width , 1)}px`,
                                                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                "borderColor" : "black",
                                                "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                                                "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                                                "borderStyle" : "solid",
                                            }}>
                                                { (NumberOfCardsBluffed > 0 || NumberOfCardsBluffed === 1 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 1 || NumberOfCardsBluffed === 2 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 2 || NumberOfCardsBluffed === 3 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 3 || NumberOfCardsBluffed === 4 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 4 || NumberOfCardsBluffed === 5 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 5 || NumberOfCardsBluffed === 6 ) &&
                                                    < CardBackgroundCreator />
                                                }
                                            </div>
                                    </div>
                            </motion.div>            
            </motion.div>
        </div>
        
    )
}

export default CardPile