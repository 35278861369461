import React , { useState } from 'react';
import useResize from 'use-resize';
import { motion } from 'framer-motion';
import QuitIcon from '../../../../Icons/Quit.png'
import socket from '../../../../SocketConnectionManager';
import SoundTypeOne from '../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';

const Section = (Values) => {
    const [ ViewState , ViewStateUpdate ] = useState(false)
    const ActiveStateMaker = Values.ActiveStateMaker ;
    const SoundStatus = Values.SoundStatus ; 
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const EmitSignal =  "LeaveGameNow-CB";

    const OnExit = () => {
        if(SoundStatus === true){
            playOne()
        }        
        socket.emit(EmitSignal)
    }

    socket.on('EvictedFromTable' , function (){
        window.location.replace('/freetoplay/cb/profile')
    })

    const Selection = () => {
        ViewStateUpdate(true)
        if(SoundStatus === true){
            playOne()
        }
        ActiveStateMaker( function (){
            return "QUIT"
        })
    }

    const SelectionCancel = () => {
        ViewStateUpdate(false)
        if(SoundStatus === true){
            playOne()
        }
        ActiveStateMaker( function (){
            return "JABBU"
        })
    }

    
    
        return (
            <div>
            { ViewState === false &&
                <motion.div 
                animate = {{
                    opacity : Values.OpacityValue
                }}>
                    <motion.div 
                        onTapStart={ Selection }                        
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            'position' : "absolute", 
                            "top" : `2%`,
                            'left' : "2%",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",    
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25"
                        }}> < img alt="Avatar" 
                                style={{
                                'width' : `${percentage(currentSize.width , 7.5)}px`,
                                "height" : "auto",
                                }}
                                src={ QuitIcon} />
                    </motion.div>

                </motion.div>
            
            }

            { ViewState === true &&
                <motion.div 

                style={{
                    'position' : "absolute",  
                    "zIndex" : "50",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    <motion.div 
                        onTapStart={ SelectionCancel }
                        animate={{ rotate : -20}}
                        initial={{
                            "width" : `${percentage(currentSize.width , 25)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            'position' : "absolute", 
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "top" : `2%`,
                            'left' : "2%",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",    
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "#fe68c3",
                            "color" : "#fe68c3",
                            "textAlign" : "center", 
                            "zIndex" : "25"                           
                        }}>
                            <div style={{
                                "height" :  `${percentage(currentSize.width , 2)}px`,
                            }}></div>
                            CLOSE
                            <div style={{
                                "height" :  `${percentage(currentSize.width , 2)}px`,
                            }}></div>   
                    </motion.div>

                    <motion.div 
                        initial={{ x : 50 , y : 20}}
                        style={{                            
                            "top" : `2%`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "width" : `${percentage(currentSize.width , 75)}px`,
                            "height" : `${percentage(currentSize.width , 30)}px`,
                            "margin" : "0 auto",
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                            "borderStyle" : "solid",
                            "borderColor" : "#fe68c3",
                            "color" : "#fe68c3",
                            "position" : "absolute",
                            "zIndex" : "24"
                        }}>
                            
                        <motion.div 
                        style={{
                            "position" : "absolute",
                            "top" : "15%",
                            "left" : "5%"
                        }}>
                            ARE YOU SURE YOU WANT TO LEAVE THE GAME?
                        </motion.div>

                        <motion.div 
                        style={{
                            "position" : "absolute",
                            "top" : "35%",
                            "left" : "5%"
                        }}>
                            <div>YOU'LL LOSE ALL YOUR BUY-IN AND</div>
                            <div>TABLE BOOT CHIPS</div>
                        </motion.div>


                        <motion.div style={{
                            "position" : "absolute",
                            "top" : "70%",
                            "width" : "100%",
                            "display" : "flex",
                            "justifyContent" : "center",
                            "textAlign" : "center"
                        }}>
                            <motion.div 
                                onTapStart={ OnExit }
                                style={{
                                    "width" : `${percentage(currentSize.width , 15)}px`,
                                    "height" : `${percentage(currentSize.width , 5)}px`,
                                    "backgroundColor" : "#fe68c3",
                                    "color" : "black",
                                }}>
                                <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                <div>YES</div>
                                <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                            </motion.div>

                            <motion.div style={{
                                "width" : `${percentage(currentSize.width , 5)}px`,
                            }}></motion.div>

                            <motion.div 
                                onTapStart={ SelectionCancel }
                            style={{
                                "width" : `${percentage(currentSize.width , 15)}px`,
                                "height" : `${percentage(currentSize.width , 5)}px`,
                                "backgroundColor" : "#fe68c3",
                                "color" : "black",                            
                            }}>
                                <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                                <div>NO</div>
                                <div style={{"height" : `${percentage(currentSize.width , 0.75)}px`,}}></div>
                            </motion.div>

                        </motion.div>

                        

                        </motion.div>
                </motion.div>
                }
            </div>
    )

}

export default Section