import React from 'react';
import useSetTimeout from 'use-set-timeout';
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import Image from './Icons/Disconnected.png' ;

const DisconnectionScreen = () => {
    useSetTimeout(()=>{
        // window.location.reload()
    }, 100)
    
    const currentSize = SizeInfo();

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    


    return (
        <div className="BlurIt" style={{
            "position" : "fixed",
            "height" : "100%",
            "width" : "100%",
            "top" : "0px",
            "left" : "0px",
            "backgroundColor" : "rgba(0,0,0,0.9)",
            "zIndex" : "1000",
            "overflowY" : "none",
            "overflowX" : "none",
            "fontFamily" : "HighlightText",
            "color" : "#00f9ff",
        }}>
                <div style={{
                    "position" : "relative",
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",
                }}>
                    <motion.div style={{
                    }} 
                    initial={{ opacity : 0.1}}
                    animate={{ opacity : 1}}
                    transition={{ duration: 0.5 , delay : 0.8 }}                    
                    >
                        < img 
                        style={{
                            "width" : `${percentage(currentSize.width , 15)}px`,
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "position" : "absolute",
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",          
                        }}
                        alt="Avatar" src={ Image } />
                    </motion.div>

                    <motion.div 
                    initial={{ y : -60 , x: 0, opacity: 0.1}}
                    animate={{ opacity : 1}}
                    transition={{ duration: 0.5 , delay : 0.8 }}
                    style={{
                            "width" : `${percentage(currentSize.width , 100)}px`,
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "textAlign" : "center",
                            "position" : "absolute",
                            "top" : "30%",
                            "left": "0%",
                            "transform" : "translate(-50%, -50%)",          
                        }}>
                        NO INTERNET FOUND
                    </motion.div>

                    <motion.div   
                    initial={{ opacity : 0.1,  y : 70 , x: 0}}
                    animate={{ opacity : 1}}
                    transition={{ duration: 0.5 , delay : 0.8 }}
                    style={{
                            "width" : `${percentage(currentSize.width , 100)}px`,
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "textAlign" : "center",
                            "position" : "absolute",
                            "top" : "30%",
                            "left": "0%",
                            "transform" : "translate(-50%, -50%)",          
                        }}>
                        <div className='pulsate-fwd'>RECONNECTING....</div>
                    </motion.div>

                    <motion.div   
                    onTap={()=>window.location.reload() } 
                    initial={{ y : 450 , x: 0 , opacity : 0}}                 
                    animate={{ y : 150 , x: 0, opacity : 1}}
                    transition={{ duration: 0.5 , delay : 3 }}
                    style={{
                            "width" : `${percentage(currentSize.width , 70)}px`,
                            "fontSize" : `${percentage(currentSize.width , 10.75)}px`,
                            "backgroundColor" : "#00f9ff",
                            "color" : "black",
                            "textAlign" : "center",
                            "position" : "absolute",
                            "top" : "30%",
                            "left": "15%",
                            "transform" : "translate(-50%, -50%)", 
                            "borderRadius" : "5%"         
                        }}>
                        <div>TAP TO</div>
                        <div>CONNECT MANUALLY</div>
                    </motion.div>
                </div>
        </div>
    )
}

export default DisconnectionScreen