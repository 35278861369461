import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import Config from '../../../GlobalFunctions/Config'

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"

    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);

    


    const Size = Value.SizeData ;
    const UsernameValue = Config.practiceModeDataCB.UsernameDisplay

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
    const height = Size.height ;

    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }

    

    return (<motion.div 
        onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}
        style={{
            "height" : `${percentage(height , 5)}px`,
            "width" : `${percentage(width , 50)}px`,
            "textAlign" : "center",
            "fontSize" : `${percentage(width , 25)/8}px`,
            "borderRadius" : `${percentage(width , 1)}px`,
            "backgroundColor" : "black",
            "backgroundImage" : BackgroundColor ,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "borderWidth" : `${percentage(width , 0.35)}px`,
            "margin" : "0 auto"
    }}>
       <div style={{
            "position" : "relative",
            "top" : "50%",
            "left": "50%",
            "transform" : "translate(-50%, -50%)",
            "color" : "#00f9ff",
       }}>
           <motion.div>
                { UsernameValue } 
           </motion.div>         
        </div>     
             
        { RedirectProperty === true &&
            <Redirect to="/freetoplay/cb/profilestats" />
        }
    </motion.div>)
}

export default Section