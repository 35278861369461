import React , { useState } from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import PrizeIcon from '../../../../../Icons/PrizePool.png';
import SoundTypeOne from '../../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';

const Mover = (Values) => {
    const [ ViewState , ViewStateUpdate ] = useState(false)
    const PrizePoolData = Values.GameData.PrizePoolDisplay ;
    const HandWinner = Values.GameData.HandWinner ;
    const ActiveStateMaker = Values.ActiveStateMaker ;
    const [ BlockShadowWidth  ] = useState(10);
    const SoundStatus = Values.SoundStatus; 
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});

    const currentSize = SizeInfo();

    const width = currentSize.width ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Selection = () => {
        ViewStateUpdate(true)
        if(SoundStatus === true){
            playOne()
        }
        ActiveStateMaker( function (){
            return "PRIZE"
        })
    }

    const SelectionCancel = () => {
        ViewStateUpdate(false)
        if(SoundStatus === true){
            playOne()
        }
        ActiveStateMaker( function (){
            return "JABBU"
        })
    }

    return (
        
        <div>
            { ViewState === false && 
                <motion.div 
                    onTapStart={ Selection }
                    animate = {{
                        opacity : Values.OpacityValue
                    }}
                    style={{ 
                        "width" : `${percentage(currentSize.width , 10)}px`,
                        "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                        "margin" : "0 auto",
                        'position' : "absolute", 
                        "top" : `10%`,
                        'left' : "2%",
                        "borderTopLeftRadius" : "10%",
                        "borderTopRightRadius" : "10%",
                        "borderBottomLeftRadius" : "10%",
                        "borderBottomRightRadius" : "10%",
                        "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                        "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                        "borderStyle" : "solid",
                        "borderColor" : "black",
                        "textAlign" : "center",
                        "zIndex" : "25"
                    }}>
                    < img alt="Avatar" 
                                style={{
                                'width' : `${percentage(currentSize.width , 7.5)}px`,
                                "height" : "auto",
                                }}
                                src={ PrizeIcon } />
                </motion.div>
            }

            { ViewState === true &&
                <motion.div                 
                style={{
                    'position' : "absolute",  
                    "zIndex" : "51",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                   <motion.div
                   animate={{ rotate : -20}}
                    onTapStart={ SelectionCancel }
                    style={{
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",
                        'position' : "absolute", 
                        "zIndex" : "25",
                        "top" : `10%`,
                        "left" : "2%",
                        "fontSize" : `${percentage(width , 2.5)}px`,
                        "width" : `${percentage(width , 25)}px`,
                        "color" : "#fe68c3",
                        "borderColor" : "#fe68c3",
                        "borderStyle" : "solid",
                        "borderWidth" :  `${percentage(width , 0.45)}px`,
                        "textAlign" : "center",
                        "borderTopLeftRadius" : "10%",
                        "borderTopRightRadius" : "10%",
                        "borderBottomLeftRadius" : "10%",
                        "borderBottomRightRadius" : "10%",
                        "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth - 5)}px ${"black"}`,
                        "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth - 5)}px ${"black"}`,
                        "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth - 5)}px ${"black"}`,
                    }}>
                    <div style={{
                        "height" :  `${percentage(width , 2)}px`,
                    }}></div>
                    CLOSE
                    <div style={{
                        "height" :  `${percentage(width , 2)}px`,
                    }}></div>   
                </motion.div>

                    <motion.div 
                        initial={{ x : 35 , y : 25, zIndex : 1}}
                        style={{
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                            "top" : `10%`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "width" : `${percentage(currentSize.width , 55)}px`,
                            "height" : `${percentage(currentSize.width , 55)}px`,
                            "margin" : "0 auto",
                            "borderRadius" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "#fe68c3",
                            "color" : "#fe68c3",
                            "textAlign" : "center",
                            "position" : "absolute"
                        }}>
                            
                            <motion.div
                                initial={{
                                    x : 40,
                                    y : 20
                                }}
                                style={{
                                    "textAlign" : "left",
                                    "position" : "absolute"
                                }}
                            >FIRST POSITION - {PrizePoolData.First} CHIPS</motion.div>
                            
                            <motion.div                            
                                initial={{
                                    x : 40,
                                    y : 40
                                }}
                                style={{
                                    "textAlign" : "left",
                                    "position" : "absolute"
                                }}
                            >SECOND POSITION - {PrizePoolData.Second} CHIPS</motion.div>

                            <motion.div                            
                                initial={{
                                    x : 40,
                                    y : 70
                                }}
                                style={{
                                    "textAlign" : "left",
                                    "position" : "absolute"
                                }}
                            >LIVE STANDING</motion.div>

                            { HandWinner === null && 
                            <div>
                                {PrizePoolData.Standings.map((Rank , i) => 
                                    <motion.div 
                                    initial={{
                                        x : 40,
                                        y : 100,
                                        "textAlign" : "left",
                                    }}
                                    transition={{ duration : 0.5 }}
                                    key={i}>
                                        
                                        <motion.div>POSITION  { Rank.rank } - { Rank.item.PlayerDetail.Username }</motion.div>

                                    </motion.div>
                                )}
                            </div>
                            }

                            { HandWinner !== null && 
                            <div>                                
                                <motion.div 
                                initial={{
                                    x : 40,
                                    y : 100,
                                    "textAlign" : "left",
                                }}
                                transition={{ duration : 0.5 }}
                                >
                                    
                                    <motion.div className="pulsate-fwd">UPDATING STANDINGS</motion.div>

                                </motion.div>
                            </div>
                            }
                            

                            <motion.div                            
                                initial={{
                                    x : 10,
                                    y : 130
                                }}
                                style={{
                                    "textAlign" : "left",
                                    "position" : "absolute",
                                    "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                                    "width" : `${percentage(currentSize.width , 45)}px`,
                                }}
                            >POSITION CHANGES WITH EACH HAND RESULT</motion.div>


                        </motion.div>
                </motion.div>
                }

        </div>
    )
    
}

            

export default Mover