import React from 'react';
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import Image from './Icons/LandScape.png' ;

const DisconnectionScreen = (CallBack) => {
    const CallBacker = CallBack.CallBackValue ;
    const currentSize = SizeInfo();

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    CallBacker( function (){
        return "YUP"
    })

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    

    return (
        <div className="BlurIt" style={{
            "position" : "fixed",
            "height" : "100%",
            "width" : "100%",
            "top" : "0px",
            "left" : "0px",
            "backgroundColor" : "rgba(0,0,0,0.9)",
            "zIndex" : "1000",
            "overflowY" : "none",
            "overflowX" : "none",
            "fontFamily" : "HighlightText",
            "color" : "#00f9ff",
        }}>
                <div style={{
                    "position" : "relative",
                    "top" : "50%",
                    "left": "50%",
                    "transform" : "translate(-50%, -50%)",
                }}>
                    <motion.div style={{
                    }} animate={{
                        rotate : 90
                    }}
                     transition={{ duration : 0.5, delay : 0.5}}
                    >
                        < img 
                        style={{
                            "width" : `${percentage(currentSize.width , 15)}px`,
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "position" : "absolute",
                            "top" : "50%",
                            "left": "50%",
                            "transform" : "translate(-50%, -50%)",          
                        }}
                        alt="Avatar" src={ Image } />
                    </motion.div>

                    <motion.div 
                    animate={{ y : -80 , x: 0}}
                    style={{
                            "width" : `${percentage(currentSize.width , 100)}px`,
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "textAlign" : "center",
                            "position" : "absolute",
                            "top" : "30%",
                            "left": "0%",
                            "transform" : "translate(-50%, -50%)",          
                        }}>
                        LANDSCAPE MODE IS NOT SUPPORTED
                    </motion.div>

                    <motion.div                     
                    animate={{ y : 70 , x: 0}}
                    style={{
                            "width" : `${percentage(currentSize.width , 100)}px`,
                            "height" : `${percentage(currentSize.width , 15)}px`,
                            "textAlign" : "center",
                            "position" : "absolute",
                            "top" : "30%",
                            "left": "0%",
                            "transform" : "translate(-50%, -50%)",          
                        }}>
                        <div>SWITCH TO PORTRAIT MODE</div>
                    </motion.div>
                </div>
        </div>
    )
}

export default DisconnectionScreen