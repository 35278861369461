import React  from 'react';
import MainButton from './NavigationBarComponents/MainButton';
import SettingsButton from './NavigationBarComponents/SettingsButton';

const Section = (Value) => {
    const Size = Value.SizeData ;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    

    const width = Size.width ;
    
    return (<div>
        <div style = {{     
        }}>
            <div style={{
                "width" : `${percentage(width , 100)}px`,
                "margin" : "0 auto",
                'height' : `${percentage(width , 20)}px`,
            }}>
                
                    <div style={{'height' : `${percentage(width , 0.5)}px`,}}></div>

                    <div className="BlurIt" style={{
                        "display" : "flex",
                        "justifyContent" : "center"                        
                    }}>

                        < MainButton SizeData = { Size } />

                        <div style={{'width' : `${percentage(width , 2)}px`}}></div>

                        < SettingsButton SizeData = { Size } />

                        
                    </div>
                    
                              
            </div>
        </div>

    </div>)
    
}

export default Section