import React , { useState } from 'react';
import useResize from 'use-resize';  
import GameTableEngine from './GameTableEngine';

const Section = () => {
    const [ Searching ] = useState(true);
    const [ RedirectProperty ] = useState(false);

    

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    


    function percentage(num, per)
    {
        return (num/100)*per;
    }

    return (<div style={{
        "height" : `${currentSize.height}px`,
        "width": `${currentSize.width}px`,
    }} className="BlurIt" onClick={()=> {}}>
                <div style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>

                <div className="TableBackground" style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "20",
                    "height" :  `100%`,
                    "width" : `100%`,
                }}>
                    <div className="TableBackgroundOverlay" style={{
                        "position" : "fixed",
                        "zIndex" : "20",
                        "height" :  `100%`,
                        "width" : `100%`,
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        < GameTableEngine GameData = {null} />
                    </div>
                </div> 

                { Searching === true && 
                    <div style={{
                        "position" : 'fixed',
                        "width" : `100%`,
                        "zIndex" : "21",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        <div className="pulsate-fwd" style={{
                            "margin" : "0 auto",
                            "width" : "70%",
                            "textAlign" : "center",
                            "fontFamily" : "HighlightText",
                            "fontSize" : "1.5em",
                            "color" : "#00f9ff",
                        }}>
                            <div>SEARCHING</div>
                            <div style={{
                                "marginTop" : "5%",
                            }}>FOR</div>
                            <div style={{
                                "marginTop" : "5%",
                            }}>OPPONENTS</div>
                        </div>
                    </div>
                }


             

            {  RedirectProperty === true && 
                <div style={{
                    "position" : "fixed",
                    "top" : "0%",
                    "left" : "0%",
                    "zIndex" : "21",
                    "height" :  `${percentage(currentSize.height , 100)}px`,
                    "width" : `${percentage(currentSize.width , 100)}px`,
                }}>
                    <div style={{
                        "fontSize" : "1.5em",
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                        "textAlign" : "center",
                        "color" : "#00f9ff",
                        "fontFamily" : "HighlightText",
                    }}>
                        <div className="pulsate-fwd">
                           <div>CONNECTING TO TABLE</div>
                        </div>
                    </div>
                </div> 
            }


        </div>                
    </div>)
}

export default Section