import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import ChipIcon from '../../../Icons/Chips.png';
import Config from '../../../GlobalFunctions/Config';

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"

    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);


   
    
    const Size = Value.SizeData ;

    const WalletBalance = Config.practiceModeData.FreeModeWallet.Balance ;


    const WalletText = () => {
        
            return (<div>
                <div style={{
                    "display" : "flex",
                    "justifyContent" : "center"
                }}>
                    <div style={{
                        'paddingRight' :  `${percentage(width , 0.8)}px`,
                        "color" : "#00f9ff",
                        "fontSize" : `${percentage(width , 4)}px`,
                        "marginTop" : `${percentage(width , 0.5)}px`,
                    }}>{ WalletBalance } </div>
                    <div>
                        < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 4)}px`,
                            'width' : `${percentage(width , 4)}px`,
                            "marginTop" : `${percentage(width , 1)}px`,
                            "marginLeft" :  `${percentage(width , 1)}px`,
                            }}
                        src={ ChipIcon } />  
                    </div> 
                </div>
            </div>)
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
    const height = Size.height ;

    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }

    return (<div className="BlurIt" style={{
            "height" : `${percentage(height , 15)}px`,
            "width" : `${percentage(width , 30)}px`,
            "backgroundImage" : BackgroundColor,
            "fontSize" : `${percentage(width , 2.5)}px`,
            "textAlign" : "center",
            "borderStyle" : "solid",
            "borderColor" : "black",
            "borderWidth" : `${percentage(width , 0.35)}px`,
            "borderRadius" : `${percentage(width , 1)}px`,
    }}>


            <div style={{
                "position" : "relative",
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)"
            }}>
                <motion.div  onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>

                <div>YOU HAVE</div>

                <div style={{"height" : `${percentage(height , 0.5)}px`,}}></div>

                <div>
                    { WalletText() }
                </div>

                <div style={{"height" : `${percentage(height , 0.5)}px`,}}></div>

                <div>BALANCE</div>

                </motion.div>

            </div>
        
            { RedirectProperty === true &&
                <Redirect to="/freetoplay/balance" />
            }
              
    </div>)
}

export default Section