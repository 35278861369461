import React , { useState } from 'react';
import useResize from 'use-resize';
import { motion } from 'framer-motion';
import QuitIcon from '../../../../Icons/Tutorial.png'
import SoundTypeOne from '../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';
import Tutorial1 from '../TutorialPages/PageOne';

const Section = (Values) => {
    const [ ViewState , ViewStateUpdate ] = useState(false)    
    const SoundStatus = Values.SoundStatus ; 
    const ActiveStateMaker = Values.ActiveStateMaker ;
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }



    const Selection = () => {
        ViewStateUpdate(true)
        if(SoundStatus === true){
            playOne()
        }

        ActiveStateMaker( function (){
            return "TUTORIAL"
        })
    }

    
    
    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const CloseTutorial = () => {
        ViewStateUpdate(false)

        ActiveStateMaker( function (){
            return "JABBU"
        })
    }


    
    if(ViewState === false){
        return (
            <motion.div 
                animate = {{
                    opacity : Values.OpacityValue
                }}>
                    <motion.div 
                        onTapStart={ Selection }                        
                        initial={{
                            "width" : `${percentage(currentSize.width , 10)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                            "margin" : "0 auto",
                            'position' : "absolute", 
                            "top" : `2%`,
                            'left' : "2%",
                            "borderTopLeftRadius" : "10%",
                            "borderTopRightRadius" : "10%",
                            "borderBottomLeftRadius" : "10%",
                            "borderBottomRightRadius" : "10%",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",    
                            "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                            "borderWidth" : `${percentage(currentSize.width , 0.55)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "black",
                            "textAlign" : "center",
                            "zIndex" : "25"
                        }}> < img alt="Avatar" 
                                style={{
                                'width' : `${percentage(currentSize.width , 7.5)}px`,
                                "height" : "auto",
                                }}
                                src={ QuitIcon} />
                    </motion.div>

            </motion.div>
            
        )
    } else {
        return (

                    <motion.div 
                    style={{
                        "position" : "absolute",
                        "width" : `100%`,                
                        "height" : `100%`,
                        "left" : `0`, 
                        "top" : `0`,  
                        "zIndex" : "200"            
                    }}>

                         <motion.div 
                         animate={{ rotate : 45 , x: 35 , y : 5 }}
                         style={{
                            "width" : `${percentage(Dimensions.width , 30)}px`,
                            "height" : `${percentage(Dimensions.width , 10)}px`,
                            "fontSize" : `${percentage(Dimensions.width , 30)/8}px`,                            
                            "textAlign" : "center",
                            "color" : "red",
                        }} onTap={ CloseTutorial }>
                             < img alt="Avatar" 
                                style={{
                                'width' : `${percentage(currentSize.width , 7.5)}px`,
                                "height" : "auto",
                                }}
                                src={ QuitIcon} />

                                <div>CLOSE</div>
                        </motion.div>

                        <motion.div initial={{
                            opacity : 0.3
                        }}
                        animate={{
                            opacity : 1
                        }}
                        transition={{
                            duration : 0.5
                        }}
                        >
                            < Tutorial1 />
                        </motion.div>
                    

                    </motion.div>
                
        )
    }

}

export default Section