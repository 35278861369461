import React  from 'react';

const Section = (Value) => {
    const Size = Value.SizeData ;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
     
    return (<div>
        <div style = {{
            "textAlign" : "center",
            "fontSize" : `${percentage(width , 2)}px`,
        }}>
           <div>JuViA[.]GAMES</div>
        </div>
    </div>)
}

export default Section