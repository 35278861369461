import React , { useState } from 'react';
import Avatar1 from '../../../../../Icons/Avatars/Avatar-M1.png';
import Avatar2 from '../../../../../Icons/Avatars/Avatar-M2.png'; 
import Avatar3 from '../../../../../Icons/Avatars/Avatar-M3.png'; 
import Avatar4 from '../../../../../Icons/Avatars/Avatar-M4.png'; 
import Avatar5 from '../../../../../Icons/Avatars/Avatar-M5.png'; 
import Avatar6 from '../../../../../Icons/Avatars/Avatar-M6.png'; 
import Avatar7 from '../../../../../Icons/Avatars/Avatar-M7.png'; 
import Avatar8 from '../../../../../Icons/Avatars/Avatar-M8.png'; 
import Avatar9 from '../../../../../Icons/Avatars/Avatar-M9.png'; 
import Avatar10 from '../../../../../Icons/Avatars/Avatar-M10.png'; 
import Avatar11 from '../../../../../Icons/Avatars/Avatar-M11.png'; 
import Avatar12 from '../../../../../Icons/Avatars/Avatar-M12.png'; 
import Avatar13 from '../../../../../Icons/Avatars/Avatar-M13.png'; 
import Avatar14 from '../../../../../Icons/Avatars/Avatar-M14.png'; 
import Avatar30 from '../../../../../Icons/Avatars/Avatar-M15.png';
import Avatar15 from '../../../../../Icons/Avatars/Avatar-W1.png'; 
import Avatar16 from '../../../../../Icons/Avatars/Avatar-W2.png'; 
import Avatar17 from '../../../../../Icons/Avatars/Avatar-W3.png'; 
import Avatar18 from '../../../../../Icons/Avatars/Avatar-W4.png'; 
import Avatar19 from '../../../../../Icons/Avatars/Avatar-W5.png'; 
import Avatar20 from '../../../../../Icons/Avatars/Avatar-W6.png'; 
import Avatar21 from '../../../../../Icons/Avatars/Avatar-W7.png'; 
import Avatar22 from '../../../../../Icons/Avatars/Avatar-W8.png'; 
import Avatar23 from '../../../../../Icons/Avatars/Avatar-W9.png'; 
import Avatar24 from '../../../../../Icons/Avatars/Avatar-W10.png'; 
import Avatar25 from '../../../../../Icons/Avatars/Avatar-W11.png'; 
import Avatar26 from '../../../../../Icons/Avatars/Avatar-W12.png'; 
import Avatar27 from '../../../../../Icons/Avatars/Avatar-W13.png'; 
import Avatar28 from '../../../../../Icons/Avatars/Avatar-W14.png'; 
import Avatar29 from '../../../../../Icons/Avatars/Avatar-W15.png'; 

const Section = (Value) => {
        const BlockSize = Value.BlockSize

        const BlockShadowColor = "#00f9ff";
        const [ BlockShadowWidth ] = useState(2);

    
        
        const AvatarNumber = Value.PlayerValues.UserAvatar ;


        function percentage(num, per)
        {
                return (num/100)*per;
        }

        const width = BlockSize
        const AvatarSize = 70;
        

        return (<div style={{
                "height" : `${percentage(width , AvatarSize+1.5)}px`,
                "width" : `${percentage(width , AvatarSize+1.5)}px`,
                "textAlign" : "center",
                "borderWidth" : `${percentage(width , 0.35)}px`,
                "position" : "relative",
                "top" : "0%",
                "left" : "50%",
                "transform" : "translate(-50%, 20%)",
                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "borderRadius" : "50%"
        }}>
                <div style={{"height" : `${percentage(width , 0.5)}px`,}}></div>

                { AvatarNumber === 1 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar1 } />
                }

                { AvatarNumber === 2 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar2 } />
                }

                { AvatarNumber === 3 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar3 } />
                }

                { AvatarNumber === 4 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar4 } />
                }

                { AvatarNumber === 5 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar5 } />
                }

                { AvatarNumber === 6 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar6 } />
                }

                { AvatarNumber === 7 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar7 } />
                }

                { AvatarNumber === 8 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar8 } />
                }

                { AvatarNumber === 9 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar9 } />
                }

                { AvatarNumber === 10 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar10 } />
                }

                { AvatarNumber === 11 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar11 } />
                }

                { AvatarNumber === 12 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar12 } />
                }

                { AvatarNumber === 13 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar13 } />
                }

                { AvatarNumber === 14 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar14 } />
                }

                { AvatarNumber === 15 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar15 } />
                }

                { AvatarNumber === 16 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar16 } />
                }

                { AvatarNumber === 17 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar17 } />
                }

                { AvatarNumber === 18 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar18 } />
                }

                { AvatarNumber === 19 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar19 } />
                }

                { AvatarNumber === 20 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar20 } />
                }

                { AvatarNumber === 21 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar21 } />
                }

                { AvatarNumber === 22 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar22 } />
                }

                { AvatarNumber === 23 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar23 } />
                }

                { AvatarNumber === 24 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar24 } />
                }

                { AvatarNumber === 25 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar25 } />
                }

                { AvatarNumber === 26 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar26 } />
                }

                { AvatarNumber === 27 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar27 } />
                }

                { AvatarNumber === 28 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar28 } />
                }

                { AvatarNumber === 29 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar29 } />
                }

                { AvatarNumber === 30 && < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , AvatarSize)}px`,
                        'width' : `${percentage(width , AvatarSize)}px`,
                        }}
                        src={ Avatar30 } />
                }

        </div>)
}

export default Section