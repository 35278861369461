import React , { useState } from 'react';
import { motion }  from "framer-motion";
import useResize from 'use-resize'; 
import UserName from './OpponentBlockElements/UserName'
import Avatar from './OpponentBlockElements/Avatar'
import ITapper from './OpponentBlockElements/iTapper'
import MyCardsButton from './OpponentBlockElements/MyCardsButton'
import EmoteHandler from './EmoteHandler'
import PlayerEvictedLogo from '../../../../Icons/Evicted.png'
import WinnerLogo from '../../../../Icons/Winner.png'

const Section = (Values) => {
    const PlayerValues = Values.PlayerValues;
    const isAgnostic = Values.isAgnostic ;
    const TableData = Values.GameData;
    const SeatNumber = Values.Seat; 
    const [ ShadowWidth ] = useState(4);
    const ShadowColor = "black";
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const [ SeatSize ] = useState(20);

    const TurnStyle = () => {
        if(PlayerValues === null){
            return null
        } else if(PlayerValues.Turn === true){
            var StyleData = {
                scale : 1,
                rotateX : 0,    
                rotateY : 0       
            }
            
            if(Values.Seat === 1){
                
            } else if(Values.Seat === 2){
                StyleData.rotateX = -15;
                StyleData.rotateY = 10;
            } else if(Values.Seat === 3){
                StyleData.rotateX = -10;
                StyleData.rotateY = 10;
            } else if(Values.Seat === 4){
                StyleData.rotateX = 15;
                StyleData.rotateY = 10;
            }

            return StyleData
        } else {
            return ({
                scale : 1,
            })
        }
    }

    const StyleProvider = () => {
        if(PlayerValues.Turn !== true){
            return ({
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
            })
        } else if(PlayerValues.Turn === true){
            
            var StyleData = {
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
            }
            return StyleData ;
        }
        
    }

    const TurnTrue = () =>{
        const MaxHeight = percentage(currentSize.width , SeatSize);

        return (
            <motion.div style={{
                "position" : "absolute",
                "width" : `${MaxHeight}px`,
                "height" : `${MaxHeight}px`,
                "borderWidth" : `${percentage(currentSize.width , 0.75)}px`,
                "top" : "50%",
                "left" : "50%",
                "transform" : "translate(-50%, -50%)",
                "borderRadius" : "50%",
            }}>

                <motion.div 
                animate={{ opacity : 0.3 }}
                transition={{ duration : 1 , repeat : Infinity }}
                style={{
                        "position" : "absolute",
                        "width" : `${percentage(MaxHeight, 90)}px`,
                        "height" : `${percentage(MaxHeight, 90)}px`,
                        "borderWidth" : `${percentage(currentSize.width , 0.7)}px`,
                        "borderRadius" : "50%",
                        "borderStyle" : "solid",
                        "borderColor" : "#00f9ff",
                        "top" : "50%",
                        "left" : "50%",
                        "transform" : "translate(-50%, -50%)",
                        "boxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "WebkitBoxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "MozBoxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "zIndex" : "9",
                        "backgroundColor" : "black"
                }}>
                    
                </motion.div>
            </motion.div>
        )
    }

    const WinnerTrue = () =>{
        const MaxHeight = percentage(currentSize.width , SeatSize);

        return (
            <motion.div style={{
                "position" : "absolute",
                "width" : `${MaxHeight}px`,
                "height" : `${MaxHeight}px`,
                "borderWidth" : `${percentage(currentSize.width , 0.75)}px`,
                "top" : "50%",
                "left" : "50%",
                "transform" : "translate(-50%, -50%)",
                "borderRadius" : "50%",
            }}>

                <motion.div 
                animate={{ opacity : 0.3 }}
                transition={{ duration : 2 , repeat : Infinity }}
                style={{
                        "position" : "absolute",
                        "width" : `${percentage(MaxHeight, 90)}px`,
                        "height" : `${percentage(MaxHeight, 90)}px`,
                        "borderWidth" : `${percentage(currentSize.width , 1)}px`,
                        "borderRadius" : "50%",
                        "borderStyle" : "solid",
                        "borderColor" : "#FDA80D",
                        "top" : "50%",
                        "left" : "50%",
                        "transform" : "translate(-50%, -50%)",
                        "boxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "WebkitBoxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "MozBoxShadow" : `0 0 ${percentage(MaxHeight , 1)}px #00f9ff`,
                        "zIndex" : "9",
                        "backgroundColor" : "black"
                }}>
                    
                </motion.div>
            </motion.div>
        )
    }

    const Winner = () =>{
        const MaxHeight = percentage(currentSize.width , SeatSize);
        var position = {
            "top" : "0%",
            "left" : "0%"
        }
        if(PlayerValues.Seat === 4){
            position.top = "-30%";
            position.left = "-50%"
        } else if(PlayerValues.Seat === 2){
            position.top = "-30%";
            position.left = "50%"
        } else if(PlayerValues.Seat === 3){
            position.top = "-10%";
            position.left = "-50%"
        } else if(PlayerValues.Seat === 1){
            position.top = "-10%";
            position.left = "70%"
        }

        return (
            <motion.div 
            initial={{ scale : 0 }}
            animate={{ scale : 1 }}
            transition={{ delay : 0.5 , duration : 1 }}
            style={{
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , 18)}px`,
                "height" : `${percentage(currentSize.width , 18)}px`,
                "borderWidth" : `${percentage(currentSize.width , 0.75)}px`,
                "top" : position.top,
                "left" : position.left,
                "transform" : "translate(-50%, -50%)",
                "borderRadius" : "50%",
                "zIndex" : "10",
                "backgroundColor" : "rgba(0,0,0,0.7)" ,
                "boxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
                "WebkitBoxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
                "MozBoxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
            }}>
                <motion.div 
                    animate={{ scale : 1 }}
                    transition={{ delay : 0.2 , duration : 0.7 }}>
                        <motion.div 
                            initial={{ scale : 0 }}
                            animate={{ scale : 1 , y : 5}}
                            transition={{ delay : 0.5 , duration : 1 }}> 
                                < img alt="Avatar" 
                                    style={{
                                    'width' : `${percentage(currentSize.width , 14)}px`,
                                    "height" : "auto",
                                    }}
                                src={ WinnerLogo } />

                                { isAgnostic === false &&
                                    <motion.div style={{
                                        "fontSize" : `${percentage(currentSize.width , 3)}px`,
                                        "backgroundColor" : "black",
                                        "paddingTop" : `${percentage(currentSize.width , 0.75)}px`,
                                        "paddingBottom" : `${percentage(currentSize.width , 0.75)}px`,
                                        "boxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
                                        "MozBoxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
                                        "borderRadius" : "15%",
                                        "color" : "#FDA80D"
                                    }}>WON 40 CHIPS</motion.div>
                                }

                                { isAgnostic === true &&
                                    <motion.div style={{
                                        "fontSize" : `${percentage(currentSize.width , 3)}px`,
                                        "backgroundColor" : "black",
                                        "paddingTop" : `${percentage(currentSize.width , 0.75)}px`,
                                        "paddingBottom" : `${percentage(currentSize.width , 0.75)}px`,
                                        "boxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
                                        "WebkitBoxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
                                        "MozBoxShadow" : `0 0 ${percentage(MaxHeight , 20)}px #00f9ff`,
                                        "borderRadius" : "15%",
                                        "color" : "#FDA80D"
                                    }}>WON 25 CHIPS</motion.div>
                                }
                                
                        </motion.div>                    
                </motion.div>                
            </motion.div>
        )
    }

    const PlayerEvicted = () =>{
        return (
            <motion.div 
            style={{
                "position" : "absolute",
                "width" : `${percentage(currentSize.width , 20)}px`,
                "height" : `${percentage(currentSize.width , 20)}px`,
                "borderWidth" : `${percentage(currentSize.width , 0.75)}px`,
                "top" : "50%",
                "left" : "50%",
                "transform" : "translate(-50%, -50%)",
                "borderRadius" : "50%",
                "zIndex" : "10"
            }}>
                <motion.div 
                    animate={{ scale : 1 }}
                    transition={{ delay : 0.2 , duration : 0.7 }}>
                        <motion.div> 
                                < img alt="Avatar" 
                                    style={{
                                    'width' : `${percentage(currentSize.width , 20)}px`,
                                    "height" : "auto",
                                    }}
                                src={ PlayerEvictedLogo } />
                        </motion.div>                    
                </motion.div>                
            </motion.div>
        )
    }

    const BlockStyle = () => {
        if (PlayerValues == null){
            return ({
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
            })
        } else if( PlayerValues.Turn !== true){
            return ({
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "backgroundColor": "rgba(0,0,0,0.3)",
                "borderRadius" : `${percentage(currentSize.width , 5)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`, 
            })
        } else if( PlayerValues.Turn === true){
            return ({
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "borderRadius" : `${percentage(currentSize.width , 5)}px`,
                "backgroundColor": "rgba(0,0,0,0.3)",
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`, 
            })
        } else {
            return ({
                "width" : `${percentage(currentSize.width , SeatSize)}px`,
                "height" : `${percentage(currentSize.width , SeatSize)}px`,
                "borderRadius" : `${percentage(currentSize.width , 5)}px`,
                "backgroundColor": "rgba(0,0,0,0.3)",
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`, 
            })
        }
    }
    
    return (<motion.div style={ BlockStyle() }>

        { PlayerValues !== null  && PlayerValues.IsHandWinner !== true && (SeatNumber === 2 || SeatNumber === 3 || SeatNumber === 4) &&
            <motion.div 
            animate={ TurnStyle() }
            transition={{duration : 0.6 }}
            style={ StyleProvider() }>
                <div>                    
                    { PlayerValues.Turn === true && < TurnTrue /> }

                    { PlayerValues.Winner === true && < WinnerTrue /> }

                    { PlayerValues.RemainingLife === 0 && < PlayerEvicted /> }

                    { PlayerValues.Winner === true && < Winner /> }

                    < UserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} />

                    < Avatar PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/> 

                    < MyCardsButton PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} SeatNumber={SeatNumber}/>                   
                    

                    { TableData !== null &&
                        < EmoteHandler Seat = { PlayerValues.Seat } PlayerValues = { PlayerValues } />
                    }
                </div>
            </motion.div>
        }

        { SeatNumber === 1 && isAgnostic === false &&
            <motion.div 
            animate={ TurnStyle() }
            transition={{duration : 0.6 }}
            style={ StyleProvider() }>
                <div>                    
                    { PlayerValues.Turn === true && < TurnTrue /> }

                    { PlayerValues.Winner === true && < WinnerTrue /> }

                    { PlayerValues.RemainingLife === 0 && < PlayerEvicted /> }

                    { PlayerValues.Winner === true && < Winner /> }

                    < UserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} />

                    < Avatar PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/> 

                    < MyCardsButton PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} SeatNumber={SeatNumber}/>                   
                    

                    { TableData !== null &&
                        < EmoteHandler Seat = { PlayerValues.Seat } PlayerValues = { PlayerValues } />
                    }
                </div>
            </motion.div>
        }

        { SeatNumber === 1 && isAgnostic === true &&
            <motion.div 
            animate={ TurnStyle() }
            transition={{duration : 0.6 }}
            style={ StyleProvider() }>
                <div>                    
                    { PlayerValues.Turn === true && < TurnTrue /> }

                    { PlayerValues.Winner === true && < WinnerTrue /> }

                    { PlayerValues.RemainingLife === 0 && < PlayerEvicted /> }

                    { PlayerValues.Winner === true && < Winner /> }

                    < ITapper PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)}/> 
                   
                    < MyCardsButton PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} SeatNumber={SeatNumber}/>                   
                    
                    < UserName PlayerValues = {PlayerValues} BlockSize={percentage(currentSize.width , SeatSize)} isAgnostic={true} />

                    { TableData !== null &&
                        < EmoteHandler Seat = { PlayerValues.Seat } PlayerValues = { PlayerValues } />
                    }
                </div>
            </motion.div>
        }


    </motion.div>)
    
    

}

export default Section