import React from 'react';
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import CardBackground from '../../../../../Icons/CardBackground.jpg'
import EventScore from '../SoundAssets/HandPlayed';

const Section = (Value) => {
    const MusicOn = Value.Music;

    const SeatPosition = {
        top: "80%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        zIndex: "22"
    }  

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const currentSize = SizeInfo();

    return(
        <motion.div animate={{
            opacity : 0
        }}
        transition={{ delay : 0.4}}
        >
            <motion.div initial={ SeatPosition }
                animate={{
                    "left" : "50%",
                    "top" : "48%",
                }}
                transition={{ duration : 0.4}}
            >
                    < img alt="Avatar" 
                    style={{
                    'height' : `auto`,
                    "marginTop" : `${percentage(currentSize.width , 1)}px`,                    
                    'width' : `${percentage(currentSize.width , 10)}px`,                    
                    }}
                src={ CardBackground } />
            </motion.div>   
            { MusicOn === true && < EventScore Status={ true } /> }         
        </motion.div>
            
    )
}

export default Section