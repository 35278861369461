import { motion }  from "framer-motion";
import Finger from '../../../../../Icons/Finger.png';
import useResize from 'use-resize';

const FingerMover = (Value) => {
    const SlotOneValue = Value.SlotOneValue;
    const SlotTwoValue = Value.SlotTwoValue;
    const SlotThreeValue = Value.SlotThreeValue;
    const SlotFourValue = Value.SlotFourValue;
    const SlotFiveValue = Value.SlotFiveValue;
    const SlotSixValue = Value.SlotSixValue;


    const currentSize = SizeInfo();
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    return(
        <div>
            { SlotOneValue === null &&
                    SlotTwoValue === null &&
                    SlotThreeValue === null &&
                    SlotFourValue === null &&
                    SlotFiveValue === null &&
                    SlotSixValue === null &&
                

            <motion.div 
                style={{
                    "position" : "absolute",
                    "width" : `${percentage(currentSize.width , 75)}px`,
                    "top" : `${percentage(currentSize.width , -5)}px`,
                }}
            >


                <motion.div style={{
                    "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                    "height" : `${percentage(currentSize.width , 10)}px`,
                    "width" : `${percentage(currentSize.width , 50)}px`,
                    "backgroundColor" : "#222222",
                    "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                    "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "borderStyle" : "solid",
                    "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                    "borderColor" : "black",
                    "textAlign" : "center",
                    "margin" : "0 auto"
                }}>
                    <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                    <div>TAP ON CARD(S) TO SELECT</div>
                    <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                    <div>YOU CAN SELECT UPTO SIX CARDS</div>
                </motion.div>


                <motion.div 
                style={{
                    "borderRadius" : "50%",
                    'width' : `${percentage(currentSize.width , 10)}px`,
                    'height' : `${percentage(currentSize.width , 10)}px`,
                    "textAlign" : "center",
                    "backgroundColor" : "rgba(0,0,0,0.3)",
                    "boxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 1.5)}px black`,
                    "margin" : "0 auto"
                }} 
                >

                    <motion.div 
                        initial = {{ rotate : 180}}>
                        < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(currentSize.width , 5)}px`,
                            "width" : "auto",
                            }}
                            src={ Finger } />
                    </motion.div>

                </motion.div>

            </motion.div>
            }

            

            {  (SlotOneValue !== null ||
                    SlotTwoValue !== null ||
                    SlotThreeValue !== null ||
                    SlotFourValue !== null ||
                    SlotFiveValue !== null ||
                    SlotSixValue !== null) &&
                
            <motion.div 
                style={{
                    "position" : "absolute",
                    "width" : `${percentage(currentSize.width , 75)}px`,
                    "zIndex" : "30",
                }}
            >
                <motion.div  style={{
                    "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                    "height" : `${percentage(currentSize.width , 10)}px`,
                    "width" : `${percentage(currentSize.width , 50)}px`,
                    "margin" : "0 auto",
                    "textAlign" : "center"
                }}>
                    <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                    <div>TAP ON CARD(S) TO SELECT</div>
                    <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                    <div>YOU CAN SELECT UPTO SIX CARDS</div>
                </motion.div>
            </motion.div>
            }
        </div>
    )
}

export default FingerMover