import React , { useState , useEffect } from 'react';
import useResize from 'use-resize';  
import socket from '../../../SocketConnectionManager';

const AccessPassTimer =  () => {
    const [timeLeft, setTimeLeft] = useState(20);

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    

    useEffect(() => {
    if (!timeLeft) {
        socket.emit('CloseMatchMaker')
        return;
    }

    const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
    }, 2000);

    return () => clearInterval(intervalId);
    }, [timeLeft]);

    
    return (<div>
                <div>MATCH MAKER WILL CLOSE IN</div> 
                <div style={{'height' : `${percentage(currentSize.width , 1)}px`,}}></div>
                <div>{ timeLeft } Second(s)</div>
            </div>);
};

export default AccessPassTimer