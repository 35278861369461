import React, { Component } from 'react';
import './Body.css';
import Body from './Body';
 
class App extends Component {
  render() {
    return (
      <div className="App">
        < Body />
      </div>
    );
  }
}
 
export default App;