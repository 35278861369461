import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"

    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);
    const Size = Value.SizeData ;
    const [ ShadowWidth ] = useState(4);
    const [ ShadowColor ] = useState("#00f9ff");

    
    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }


    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
    const height = Size.height ;

    return (<div  style={{
            "height" : `${percentage(height , 15)}px`,
            "width" : `${percentage(width , 60)}px`,
            "margin" : "0 auto"
    }}>
            <div className="BlurIt" style={{
                "fontSize" : `${percentage(width , 4.5)}px`,
                "width" : `${percentage(width , 60)}px`,                
                "textAlign" : "center",
                "backgroundImage" : BackgroundColor ,
                "borderRadius" :"10%",
                "position" : "relative",
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",
                "color" : "#00f9ff",
                "borderStyle" : "solid",
                "borderColor" : "black",
                "borderWidth" : `${percentage(width , 0.5)}px`,
                "boxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
            }}>
                
                <motion.div onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>
                    <div style={{"height" : `${percentage(width , 2)}px`}}></div>

                    <div style={{
                        "fontFamily" : "HighlightText",
                    }}>RE-JOIN TABLE</div>

                    <div style={{"height" : `${percentage(width , 3)}px`}}></div>

                    <div style={{
                        "fontSize" : `${percentage(width , 2.5)}px`,
                        "color" : "white",
                    }}>IF YOU DON'T JOIN BACK</div>
                    <div style={{
                        "fontSize" : `${percentage(width , 2.5)}px`,
                        "color" : "white",
                    }}>YOU WILL BE EVICTED FROM THE TABLE</div>

                    <div style={{"height" : `${percentage(width , 2)}px`}}></div>
                </motion.div>
               
            </div>

            {   RedirectProperty === true &&
                < Redirect to={'/freetoplay/matchmaker'} />
            }
    </div>)
}

export default Section