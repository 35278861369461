import React , { useState } from 'react' ;
import { motion } from "framer-motion";
import LevelIcon from '../../Icons/Badge.png';

const ChangeModeToggle = (Value) => {
    const [ ShowAlert , ShowAlertUpdate ] = useState(false);
    const [ ShadowWidth ] = useState(4);
    const [ ShadowColor ] = useState("#00f9ff");
    const Size = Value.SizeData ;

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const onTapStart = () => {

    }

    const onTapCancel = () => {
        
    }

    const onTap = () => {
        if(ShowAlert === false){
            ShowAlertUpdate(true)
        } else {
            ShowAlertUpdate(false)
        }
        
    }

    
    const width = Size.width ;
    const height = Size.height ;

    return (
        <div>

            <div style={{
                "width" : `${percentage(width , 30)}px`,
                "top" : `${percentage(width , 20)}px`,
            }}>
                
                    <motion.div 
                        onTapStart={onTapStart}
                        onTapCancel={onTapCancel}
                        onTap={onTap}
                    style={{
                        "position" : "relative",
                        "marginTop" : `${percentage(width , 1.5)}px`,  
                        "height" :   `${percentage(width , 12.75)}px`,  
                        "width" :   `${percentage(width , 30)}px`, 
                        "backgroundColor" :  "rgba(0,0,0,0.2)",
                        "color" : "#00f9ff",
                        "borderStyle" : "solid",
                        "borderWidth" : `${percentage(width , 0.2)}px`, 
                        "borderRadius" : "20%",
                        "boxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
                        "WebkitBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
                        "MozBoxShadow" : `0 0 ${percentage(width , ShadowWidth)}px ${ShadowColor}`,
                        "textAlign" : "center"
                    }}>

                        <div style={{"height" : `${percentage(width , 0.5)}px`}}></div>

                        <div style={{
                            "fontSize" : `${percentage(width , 30)/10}px`, 
                        }}>COMPETITIVE</div>

                        <div style={{
                            "fontSize" : `${percentage(width , 30)/10}px`, 
                        }}>LEADERBOARDS</div>

                        <div style={{"height" : `${percentage(width , 1)}px`}}></div>

                        <div style={{
                            "fontSize" : `${percentage(width , 30)/10}px`, 
                        }}>COMING SOON</div>

                        <div style={{"height" : `${percentage(width , 0.5)}px`}}></div>
                            

                    </motion.div>
            </div>

            { ShowAlert === true &&
                <motion.div 
                onTap={onTap}
                style={{
                    "position" : "fixed" ,
                    "height" :   `${percentage(height , 100)}px`,  
                    "width" :   `${percentage(width , 100)}px`, 
                    "backgroundColor" : "rgba(0,0,0,0.8)",
                    "left" : `0`, 
                    "top" : `0`, 
                }}>
                <motion.div className="BlurIt"
                    initial={{ x : -100 , y : -600 , scale: 2 , rotate: 360}} 
                    animate={{ x : 0 , y : 0 , scale: 1 , rotate: 0}} 
                    style={{
                    "position" : "fixed",
                    "backgroundColor" : "rgba(0,0,0,0.5)",
                    "height" :   `${percentage(width , 80)}px`,  
                    "width" :   `${percentage(width , 80)}px`, 
                    "left" : `${percentage(width , 10)}px`, 
                    "top" : `${percentage(height , 20)}px`, 
                    "boxShadow" : `0 0 ${percentage(width , 13)}px ${ShadowColor}`,
                    "WebkitBoxShadow" : `0 0 ${percentage(width , 13)}px ${ShadowColor}`,
                    "MozBoxShadow" : `0 0 ${percentage(width , 13)}px ${ShadowColor}`,
                    "borderRadius" : `${percentage(width , 5)}px`, 
                    "zIndex" : "50"
                }}>
                    <motion.div
                        initial={{scale : 0.25 }}
                        animate={{ x : 0 , y : 0 , scale : 1}} 
                        transition={{ duration : 0.8}}
                        style={{
                            "textAlign" : "center"
                        }}
                    >
                        < img alt="Avatar" 
                            style={{
                            'height' : `${percentage(width , 40)}px`,
                            'width' : `${percentage(width , 40)}px`,
                            "marginTop" : `${percentage(width , 2)}px`,
                            }}
                            src={ LevelIcon } />  
                    </motion.div>

                    <motion.div style={{
                        "fontFamily" : "HighlightText",
                        "textAlign" : "center",
                        "fontSize" : `${percentage(width , 10)}px`,
                        "color" : ShadowColor
                    }}>
                        KEEP LEVELING UP!
                    </motion.div>

                    <motion.div 
                    initial={{ x : 0 , y : 20 , scale : 0.25}}
                    animate={{ x : 0 , y : 20 , scale : 1}} 
                    transition={{ delay : 0.3}}
                    style={{
                        "fontFamily" : "HighlightText",
                        "textAlign" : "center",
                        "fontSize" : `${percentage(width , 8)}px`,
                        "color" : ShadowColor
                    }}>
                        COMPETITIVE LEADERBOARDS
                    </motion.div>

                    <motion.div 
                    initial={{ x : 0 , y : 20 , scale : 0.25}}
                    animate={{ x : 0 , y : 20 , scale : 1}} 
                    transition={{ delay : 0.3}}
                    style={{
                        "fontFamily" : "HighlightText",
                        "textAlign" : "center",
                        "fontSize" : `${percentage(width , 8)}px`,
                        "color" : ShadowColor
                    }}>
                        COMING SOON!
                    </motion.div>
                </motion.div>
                </motion.div>
            }
            

        </div>
        
    )
}

export default ChangeModeToggle