import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import LevelIcon from '../../../Icons/Badge.png';
import Config from '../../../GlobalFunctions/Config'

const Section = (Value) => {
    const BackGroundGradient_1 = "linear-gradient(rgba(0, 0,  0 ,0.1) ,rgba(0,0,0,0.2), rgba(232, 232,  232 ,0.1))"
    const BackGroundGradient_8 = "linear-gradient(rgba(232, 232,  232 ,0.1) ,rgba(0,0,0,0.1), rgba(232, 232,  232 ,0.1))"

    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ BackgroundColor , BackgroundColorUpdate ] = useState(BackGroundGradient_8);

    const Size = Value.SizeData ;

    const LevelValue = Config.practiceModeData.CurrentLevel;

    const LevelPoints = Config.practiceModeData.CurrentLevelPointsEarned;
    const CurrentLevelMaxPoints = Config.practiceModeData.CurrentLevelMaxPoints;

    function onTapStart() {
        BackgroundColorUpdate(BackGroundGradient_1)
    }

    function onTapCancel() {
        BackgroundColorUpdate(BackGroundGradient_8)
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }


    const LevelText = () => {
        
            return (<div>
                <div>YOU NEED</div>

                <div style={{
                    "display" : "flex",
                    "justifyContent" : "center"
                }}>
                    <div style={{
                        'paddingRight' :  `${percentage(width , 0.8)}px`,
                        "color" : "#00f9ff",
                    }}>{ CurrentLevelMaxPoints - LevelPoints } </div>
                    <div>PTS</div> 
                </div>

                <div>TO LEVEL UP</div>
            </div>)
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = Size.width ;
    const height = Size.height ;

    return (<div className="BlurIt" style={{
            "height" : `${percentage(height , 15)}px`,
            "width" : `${percentage(width , 30)}px`,
            "backgroundImage" : BackgroundColor ,
            "borderStyle" : "solid",
            "borderColor" : "black",
            "borderWidth" : `${percentage(width , 0.35)}px`,
            "borderRadius" : `${percentage(width , 1)}px`,
    }}>
            <motion.div style={{
                "display" : "flex",
                "justifyContent" : "center",
                "textAlign" : "center",
                "position" : "relative",
                "top" : "50%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)"
            }} onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>


            <div>
            <div style={{
                "display" : "flex"
            }}>
                < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 8)}px`,
                        'width' : `${percentage(width , 8)}px`,
                        "marginTop" : `${percentage(width , 2)}px`,
                        }}
                        src={ LevelIcon } />  

                        <div style={{
                            "top" : `${percentage(width , 0.85)}px`,
                            "width" : `${percentage(width , 10)}px`,
                            "textAlign" : "center",
                            "color" : "black",
                            "fontSize" :  `${percentage(width , 4)}px`,
                            "marginTop" : `${percentage(width , 2)}px`,
                            "marginLeft" :  `${percentage(width , 2.55)}px`,                            
                            "backgroundColor" : "#00f9ff",
                            "borderRadius" : "50%"
                        }}>
                            <div style={{
                                "marginTop" : `${percentage(width , 1.5)}px`,
                            }}>
                                { LevelValue }
                            </div>
                        </div>
            </div>

            <div style={{
                "height" :  `${percentage(height , 0.85)}px`,
            }}></div>

            <div style={{
                "fontSize" : `${percentage(width , 2.5)}px`,
                "textAlign" : "center",
            }}>
                { LevelText() }
            </div>

            
        </div>

            </motion.div>
        
            { RedirectProperty === true &&
                <Redirect to="/freetoplay/level" />
            }
    </div>)
}

export default Section