import React , { useState } from 'react';
import socket from '../../../../../SocketConnectionManager';
import { motion }  from "framer-motion";
import useResize from 'use-resize'; 

const Section = (Value) => {
    const ShadowWidth = 2;
    const [ DisplayOn , DisplayOnUpdate ] = useState(false)
    const PlayerValue = Value.PlayerValue ;
    const TableData = Value.GameData;

    const AllSpades = TableData.MyCards.Spades ;
    const AllHearts = TableData.MyCards.Hearts ;
    const AllDiamonds = TableData.MyCards.Diamonds ;
    const AllClubs = TableData.MyCards.Clubs ;
    const TotalCards = AllSpades.length +  AllHearts.length + AllDiamonds.length + AllClubs.length

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    socket.on('OnTimeOutDetected' , function (SeatNumber){
        if(SeatNumber === PlayerValue.Seat){
            if(DisplayOn === false){
                DisplayOnUpdate(true)
            }
        }
    })

    socket.on('OnTimeOutStop' , function (){        
        DisplayOnUpdate(false)
    })

    return (<div>
        { DisplayOn === true && TotalCards > 7 &&
            <motion.div 
            initial={{
                "position" : "absolute",
                "top" : "0%",
                "right" : "0%",
                "width" : `${percentage(currentSize.width , 30)}px`,
                "textAlign" : "center",
                "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "backgroundColor" : "rgba(0,0,0,0.5)",
                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                "color" : "#FDA80D",  
            }}
            animate={{
                "position" : "absolute",
                "top" : "-50%",
                "right" : "0%",
                "width" : `${percentage(currentSize.width , 30)}px`,
                "textAlign" : "center",
                "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "backgroundColor" : "rgba(0,0,0,0.5)",
                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                "color" : "#FDA80D",  
            }}>  
                <div style={{
                    "height" : `${percentage(currentSize.width , 2)}px`,
                }}></div>
                YOU MISSED YOUR TURN             
                <div style={{
                    "height" : `${percentage(currentSize.width , 2)}px`,
                }}></div>         
            </motion.div>
        }
    
        { DisplayOn === true && TotalCards <= 7 &&
            <motion.div 
            initial={{
                "position" : "absolute",
                "top" : "0%",
                "right" : "0%",
                "width" : `${percentage(currentSize.width , 30)}px`,
                "textAlign" : "center",
                "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "backgroundColor" : "rgba(0,0,0,0.5)",
                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                "color" : "#FDA80D",  
            }}
            animate={{
                "position" : "absolute",
                "top" : "-90%",
                "right" : "0%",
                "width" : `${percentage(currentSize.width , 30)}px`,
                "textAlign" : "center",
                "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                "backgroundColor" : "rgba(0,0,0,0.5)",
                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                "color" : "#FDA80D",  
            }}>  
                <div style={{
                    "height" : `${percentage(currentSize.width , 2)}px`,
                }}></div>
                YOU MISSED YOUR TURN        
                <div style={{
                    "height" : `${percentage(currentSize.width , 2)}px`,
                }}></div>         
            </motion.div>
        }
    
    </div>)
}

export default Section
