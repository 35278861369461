import Config from '../Config';


const NetworkCall = function (Result) {

    const URL = Config.url_api + "/PMG/IsAccessAgnostic";

    if(Config.practiceModeAgnosticAccess === null){
        
        fetch(URL , {
            method: 'GET',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
            },
            credentials : "include"
        }).then((response) => response.json())
        .then((json) => {
               Config.practiceModeAgnosticAccess = json
               return Result(Config.practiceModeAgnosticAccess)
            })
    } else {
        return Result(Config.practiceModeAgnosticAccess)
    }
}

export default NetworkCall