import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion  }  from "framer-motion";
import Config from '../../../GlobalFunctions/Config'
import ChangeUsername from '../../../GlobalFunctions/PracticeMode/ChangeUsername';
import UpdateGamerData from '../../../GlobalFunctions/PracticeMode/UpdateGamerData';


const Section = () => {
    const [ InfoText , InfoTextUpdate ] = useState("CHANGE YOUR EXISTING USERNAME")
    const [ ExistingUsername , ExistingUsernameUpdate ] = useState(Config.practiceModeDataCB.UsernameDisplay)
    const [ ErrorFeild , ErrorFeildUpdate ] = useState("");
    const [ value, setValue ] = useState("");
    

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const [ ButtonStyle , ButtonStyleUpdate ] = useState ({
        "position" : "absolute" ,
        "left" : "50%",
        "transform" : "translate(-50%, -50%)",
        "width" : `${percentage(currentSize.width , 25)}px`,
        "backgroundColor" : "black",
        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
        "textAlign" : "center",
        "borderStyle" : "solid",
        "borderColor" : "#00f9ff",
        "color" : "#00f9ff",
        "borderWidth" : `${percentage(currentSize.width , 0.35)}px`,
        "borderRadius" : `${percentage(currentSize.width , 1)}px`,
    })

    const onSubmitStart = () => {
        ButtonStyleUpdate({
            "position" : "absolute" ,
            "left" : "50%",
            "transform" : "translate(-50%, -50%)",
            "width" : `${percentage(currentSize.width , 25)}px`,
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "textAlign" : "center",
            "borderStyle" : "solid",
            "borderColor" : "black",
            "color" : "#00f9ff",
            "borderWidth" : `${percentage(currentSize.width , 0.35)}px`,
            "borderRadius" : `${percentage(currentSize.width , 1)}px`,
        })
    }

    const onSubmitCancel = () => {
        ButtonStyleUpdate({
            "position" : "absolute" ,
            "left" : "50%",
            "transform" : "translate(-50%, -50%)",
            "width" : `${percentage(currentSize.width , 25)}px`,
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "textAlign" : "center",
            "borderStyle" : "solid",
            "borderColor" : "#00f9ff",
            "color" : "#00f9ff",
            "borderWidth" : `${percentage(currentSize.width , 0.35)}px`,
            "borderRadius" : `${percentage(currentSize.width , 1)}px`,
        })
    }



    const onSubmit = () => {
        ButtonStyleUpdate({
            "position" : "absolute" ,
            "left" : "50%",
            "transform" : "translate(-50%, -50%)",
            "width" : `${percentage(currentSize.width , 25)}px`,
            "backgroundColor" : "black",
            "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
            "textAlign" : "center",
            "borderStyle" : "solid",
            "borderColor" : "#00f9ff",
            "color" : "#00f9ff",
            "borderWidth" : `${percentage(currentSize.width , 0.35)}px`,
            "borderRadius" : `${percentage(currentSize.width , 1)}px`,
        })

        var regexp = /^[\w-_]*$/

        if(value.search(regexp) === -1){
            ErrorFeildUpdate("ONLY ALPHABETS , NUMBERS , _ , - ALLOWED")
        } else if(value.length > 15 ){
            ErrorFeildUpdate("SHOULD BE LESS THAN 16 CHARACTERS")
        } else if(value.length < 5 ){
            ErrorFeildUpdate("SHOULD BE MORE THAN 4 CHARACTERS")
        } else {
            InfoTextUpdate("YOUR UPDATED USERNAME");
            ExistingUsernameUpdate(value)

            ChangeUsername(value , function(Update){
                if(Update){
                    UpdateGamerData(function (){
                    })
                }
            })
        }
    }


    return (<div>
              <motion.div 
              initial={{ x: 0, y: -100 }}
              animate={{ x: 0 , y: 0}} >

                  <div className="BlurIt" style={{
                      "backgroundImage" : "linear-gradient(rgba(0,0,0,0.1) ,rgba(0,0,0,0.1), rgba(0,0,0,0.1))",
                      "boxShadow" : `0 0 ${percentage(currentSize.width , 3)}px black`,
                      "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px black`,
                      "MozBoxShadow" : `0 0 ${percentage(currentSize.width , 3)}px black`,
                      "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                      "borderStyle" : "solid",
                      "borderColor" : "black",
                      "width" : `${percentage(currentSize.width , 100)}px`,
                  }}>

                      <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                      <div style={{
                          "textAlign" : "center",
                          "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                          "color" : "#00f9ff"
                      }}>{ InfoText }</div>

                      <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                      <div style={{
                          "textAlign" : "center"
                      }}>{ ExistingUsername }</div>

                      <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>


                        <motion.div 
                        initial={{x : 60 , y: 0}}
                        animate={{x : 60,  y: 0}} style={{
                            "width" :  `${percentage(currentSize.width , 70)}px`,
                        }}>
                            <input onClick={()=> ErrorFeildUpdate("")} value={value}
                                onChange={ (e) => setValue(e.target.value.replace(/\s/g, "")) }
                                style={{
                                    "height" :  `${percentage(currentSize.width , 10)}px`,
                                    "fontSize" : `${percentage(currentSize.width , 4)}px`,
                                    "marginLeft" : `${percentage(currentSize.width , 3)}px`,
                                    "width" :  `${percentage(currentSize.width , 60)}px`,
                                    "backgroundColor" : "black",
                                    "color" : "white",
                                    "borderColor" : "#00f9ff"
                                }}/>
                        </motion.div>

                    <motion.div 
                    style={{
                        "width" :  `${percentage(currentSize.width , 70)}px`,
                    }}
                    initial={{x : 75}}>
                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                        <div style={{
                            "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                        }}>* USERNAME SHOULD BE LESS THAN 16 CHARACTERS</div>
                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                        <div style={{
                            "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                        }}>* USERNAME SHOULD BE MORE THAN 4 CHARACTERS</div>
                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                        <div style={{
                            "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                        }}>* ONLY ALPHABETS , NUMBERS , _ , - ALLOWED</div>
                    </motion.div>

                    <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                    
                    <motion.div 
                    style={{
                        "width" :  `${percentage(currentSize.width , 100)}px`,
                        "height" :  `${percentage(currentSize.width , 8)}px`,
                        "fontSize" : `${percentage(currentSize.width , 3.5)}px`,
                        "color" : "#00f9ff",
                        "textAlign" : "center"
                    }}
                    initial={{x : 0}}>
                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                        { ErrorFeild }
                    </motion.div>

                    <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>

                    <motion.div
                       onTapStart={ onSubmitStart }
                       onTapCancel={ onSubmitCancel }
                       onTap={ onSubmit }
                       style={ButtonStyle}>
                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                                SUBMIT
                        <div style={{"height" : `${percentage(currentSize.width , 2)}px`,}}></div>
                    </motion.div>



                  </div>

              </motion.div>
    </div>)
}

export default Section