import React , { useState } from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import TableHeightCalculator from "./TableHeightCalculator";
import PlayerPositionCalculator from './PlayerPositionCalculator';
import OpponentBlock from './OpponentBlock';
import useSetTimeout from "use-set-timeout";
import HandWonScore from './SoundAssets/HandWon';
import PlayerInfoBlockRoundOne from './RoundEndedAssets/PlayerInfoBlockRoundOne';
import PlayerInfoBlockRoundTwo from './RoundEndedAssets/PlayerInfoBlockRoundTwo';
import NextRoundStarter from "./RoundEndedAssets/NextRoundStarter";

const Mover = (Data) => {
    const [ ShadowWidth ] = useState(4);
    const isAgnostic = Data.isAgnostic ;
    const [ OverlayShadowWidth ] = useState(10);
    const [ ShowOpponentBlock , ShowOpponentBlockUpdate ] = useState(true)
    const [ AllDone , AllDoneUpdate ] = useState(false)
    const [ PlaceOpponentsBack , PlaceOpponentsBackUpdate ] = useState(false)
    const MusicOn  = Data.Music;
    const ShadowColor = "grey";
    const ShadowColorTable = "#00f9ff";
    const GameData = Data.GameData ;
    const Ranks = GameData.RankValues ;
    const currentSize = SizeInfo();
    const [ PlayFirstMusic , PlayFirstMusicUpdate ] = useState(false);
    const [ HideEndMessage , HideEndMessageUpdate ] = useState(null);


    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }
    const TableHeight = TableHeightCalculator();

    const ShadowProperty = `0 0 ${percentage(currentSize.width , OverlayShadowWidth)}px  ${ShadowColor}`;
    const TableShadow = `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColorTable}`;

    const SeatOnePosition = PlayerPositionCalculator(1)
    const SeatTwoPosition = PlayerPositionCalculator(2)
    const SeatThreePosition = PlayerPositionCalculator(3)
    const SeatFourPosition = PlayerPositionCalculator(4)


    const OpponentBlockMover = () => {
        return ({
            duration : 0.5,
            delay : 0.3
        })
    }

    const OpponentBlockMoverBack = () => {
        return ({
            duration : 0.5,
            delay : 0.1
        })
    }


    const RankPosition = (Value) => {
        if(Value === 1){
            return ({
                "height" : `${percentage(currentSize.width , 10)}px`,
                "width" : `${percentage(currentSize.width , 90)}px`,
                "marginBottom" : `${percentage(currentSize.width , 2)}px`,                            
                "position" : "absolute",
                "top" : "25%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",          
            })
        } else if(Value === 2){
            return ({
                "height" : `${percentage(currentSize.width , 10)}px`,
                "width" : `${percentage(currentSize.width , 90)}px`,
                "marginBottom" : `${percentage(currentSize.width , 2)}px`,                            
                "position" : "absolute",
                "top" : "40%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",          
            })
        } else if(Value === 3){
            return ({
                "height" : `${percentage(currentSize.width , 10)}px`,
                "width" : `${percentage(currentSize.width , 90)}px`,
                "marginBottom" : `${percentage(currentSize.width , 2)}px`,                            
                "position" : "absolute",
                "top" : "55%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",          
            })
        } else {
            return ({
                "height" : `${percentage(currentSize.width , 10)}px`,
                "width" : `${percentage(currentSize.width , 90)}px`,
                "marginBottom" : `${percentage(currentSize.width , 2)}px`,                            
                "position" : "absolute",
                "top" : "70%",
                "left": "50%",
                "transform" : "translate(-50%, -50%)",          
            })
        }
        
    }

    

    const TableCenter = () => {
        return({
            "top" : "50%",
            "left": "50%",
            "transform" : "translate(-50%, -50%)",          
        })
    } 

    useSetTimeout(() => {
        ShowOpponentBlockUpdate(false)
    }, 2100)

    useSetTimeout(() => {
        if(GameData.Round === 1){
         ShowOpponentBlockUpdate(null)
         AllDoneUpdate(true)
        }
    }, 8100)

    useSetTimeout(() => {
        if(GameData.Round === 1){
         PlaceOpponentsBackUpdate(true)
        }
    }, 8500)

    useSetTimeout(() => {
        if(GameData.Round === 1){
          AllDoneUpdate(false)
        }
    }, 8600)

    useSetTimeout(() => {
            PlayFirstMusicUpdate(true)
    }, 500)

    useSetTimeout(() => {
        HideEndMessageUpdate(true)
    }, 4000)
    
    return (
        <div style={{
            'position' : "absolute",  
            "zIndex" : "49",
            "height" :  `100%`,
            "width" : `100%`,
        }}>
                <div style={{
                    "position" : "absolute",
                    "transform" : "translate(-50%, -50%)",
                    "top" : "50%",
                    "left": "50%",
                }}>
                    <div style={{
                        "height" : `${percentage(TableHeight , 100)}px`,
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "backgroundColor" : "grey",
                        "backgroundImage" : "radial-gradient(rgba(0, 0, 0 , 0.1) , rgba(0, 0, 0 , 0.5) , rgba(0, 0, 0 , 0.99))",
                        "margin" : "0 auto",
                        "borderTopLeftRadius" : "35%",
                        "borderTopRightRadius" : "35%",
                        "borderBottomLeftRadius" : "35%",
                        "borderBottomRightRadius" : "35%",
                        "borderStyle" : "solid",
                        "borderColor" : "black",
                        "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                        "boxShadow" : ShadowProperty,
                        "WebkitBoxShadow" : ShadowProperty,
                        "MozBoxShadow" : ShadowProperty,
                        "position" : "absolute",
                        "zIndex" : "21",
                        "top" : "32%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>

                    </div>

                    <div style={{
                        "height" : `${percentage(TableHeight , 95)}px`,
                        "width" : `${percentage(currentSize.width , 70)}px`,
                        "backgroundColor" : "rgba(0,0,0,0.1)",
                        "backgroundImage" : "linear-gradient(rgba(0, 0, 0 , 0.1) , rgba(0, 0, 0 , 0.6) , rgba(0, 0, 0 , 0.6))",
                        "borderStyle" : "solid",
                        "borderTopLeftRadius" : "35%",
                        "borderTopRightRadius" : "35%",
                        "borderBottomLeftRadius" : "35%",
                        "borderBottomRightRadius" : "35%",
                        "borderColor" : ShadowColorTable,
                        "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                        "boxShadow" : TableShadow ,
                        "WebkitBoxShadow" : TableShadow ,
                        "MozBoxShadow" : TableShadow ,
                        "position" : "absolute",
                        "zIndex" : "21",
                        "top" : "32%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>

                    </div>
                </div>
                                
                 { ShowOpponentBlock === true &&
                    <motion.div                 
                    style={{
                        "height" : `${TableHeight}px`,
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "position" : "absolute",
                        "transform" : "translate(-50%, -50%)",
                        "top" : "50%",
                        "left": "50%",
                    }}>

                        { MusicOn === true && < HandWonScore Status={ PlayFirstMusic }/> }
                        
                        < motion.div 
                            animate={ TableCenter () }
                            transition={ OpponentBlockMover() }
                            initial={ SeatOnePosition }>
                            < OpponentBlock Seat={1} PlayerValues = { PlayerData() } GameData = {GameData} isAgnostic = {isAgnostic} />
                        </motion.div>
                        
                    
                        < motion.div 
                            animate={ TableCenter () }
                            transition={ OpponentBlockMover() }
                            initial={ SeatTwoPosition }>
                            < OpponentBlock Seat={2} PlayerValues = { PlayerTwo() } GameData = {GameData} isAgnostic = {isAgnostic} />
                        </motion.div>

                        < motion.div 
                            animate={ TableCenter () }
                            transition={ OpponentBlockMover() }
                            initial={ SeatThreePosition }>
                                < OpponentBlock Seat={3} PlayerValues = { PlayerThree() } GameData = {GameData} isAgnostic = {isAgnostic} />
                        </motion.div>
                        

                        < motion.div 
                            animate={ TableCenter () }
                            transition={ OpponentBlockMover() }
                            initial={ SeatFourPosition }>
                            < OpponentBlock Seat={4} PlayerValues = { PlayerFour() } GameData = {GameData} isAgnostic = {isAgnostic} />
                        </motion.div>
                    </motion.div>
                }

                

                { ShowOpponentBlock === false && GameData.Round === 1 &&
                    <motion.div 
                        initial={{ scale : 1,  x : 0, top: "50%"}}
                        animate={{ scale : 1 , x : 0, top: "85%"}}
                        transition={{ duration : 0.5}}
                        style={{
                            "position" : "absolute",
                            "display" : "flex",
                            "justifyContent" : "center",
                            "width" : `${percentage(currentSize.width , 100 )}px`,
                    }}>

                        { MusicOn === true && < HandWonScore Status={ PlayFirstMusic }/> }
                        
                        <motion.div 
                        className="BlurIt"
                            style={{
                                "width" : `${percentage(currentSize.width , 30 )}px`,
                                "borderTopLeftRadius" : "15%",
                                "borderBottomLeftRadius" : "15%",
                                "borderWidth" : `${percentage(currentSize.width , 0.15 )}px`,
                                "borderStyle" : "solid",
                                "borderColor" : "#00f9ff",
                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                "textAlign" : "center",
                                "fontSize" : `${percentage(currentSize.width , 3 )}px`,
                                "color" : "#00f9ff",
                        }}>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                            <div>ROUND</div>
                            <div style={{height :`${percentage(currentSize.width , 1 )}px`,}}></div>
                            <div>ENDED</div>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                        </motion.div>

                        <motion.div 
                            initial={{ "width" : `${percentage(currentSize.width , 0.1 )}px`, }}
                            animate={{ "width" : `${percentage(currentSize.width , 2 )}px`, }}
                            transition={{ duration : 0.5, delay: 0.6}}

                        ></motion.div>

                        <motion.div 
                        className="BlurIt"
                            style={{
                                "width" : `${percentage(currentSize.width , 35 )}px`,
                                "borderTopRightRadius" : "15%",
                                "borderBottomRightRadius" : "15%",                                
                                "borderWidth" : `${percentage(currentSize.width , 0.15 )}px`,
                                "borderStyle" : "solid",
                                "borderColor" : "#00f9ff",
                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                "textAlign" : "center",
                                "fontSize" : `${percentage(currentSize.width , 2.5 )}px`,
                                "color" : "#00f9ff"
                        }}>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                            <div>NEW ROUND WILL START</div>
                            <div style={{height :`${percentage(currentSize.width , 1 )}px`,}}></div>
                            < NextRoundStarter />
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                        </motion.div>

                    </motion.div>
                }

                { ShowOpponentBlock === false && GameData.Round === 2 && HideEndMessage === null &&
                    <motion.div 
                        initial={{ scale : 2.5,  x : 0, top: "50%"}}
                        animate={{ scale : 1 , x : 0, top: "85%"}}
                        transition={{ duration : 0.5}}
                        style={{
                            "position" : "absolute",
                            "display" : "flex",
                            "justifyContent" : "center",
                            "width" : `${percentage(currentSize.width , 100 )}px`,                            
                            "zIndex" : "50"
                    }}>

                        { MusicOn === true && < HandWonScore Status={ PlayFirstMusic }/> }
                        
                        <motion.div                         
                            style={{
                                "width" : `${percentage(currentSize.width , 30 )}px`,
                                "borderRadius" : "15%",
                                "borderWidth" : `${percentage(currentSize.width , 0.15 )}px`,
                                "borderStyle" : "solid",
                                "borderColor" : "#00f9ff",
                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",
                                "textAlign" : "center",
                                "fontSize" : `${percentage(currentSize.width , 3 )}px`,
                                "color" : "#00f9ff",
                        }}>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                            <div>MATCH</div>
                            <div style={{height :`${percentage(currentSize.width , 1 )}px`,}}></div>
                            <div>ENDED</div>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                        </motion.div>

                    </motion.div>
                }


                { AllDone === true && GameData.Round === 1 &&
                    <motion.div 
                        animate={{ scale : 1,  x : 0, top: "50%", opacity : 0}}
                        initial={{ scale : 1 , x : 0, top: "85%"}}
                        transition={{ duration : 0.6}}
                        style={{
                            "position" : "absolute",
                            "display" : "flex",
                            "justifyContent" : "center",
                            "width" : `${percentage(currentSize.width , 100 )}px`,
                    }}>
                        
                        <motion.div 
                        className="BlurIt"
                            style={{
                                "width" : `${percentage(currentSize.width , 30 )}px`,
                                "borderTopLeftRadius" : "15%",
                                "borderBottomLeftRadius" : "15%",
                                "borderWidth" : `${percentage(currentSize.width , 0.15 )}px`,
                                "borderStyle" : "solid",
                                "borderColor" : "#00f9ff",
                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                "textAlign" : "center",
                                "fontSize" : `${percentage(currentSize.width , 3 )}px`,
                                "color" : "#00f9ff",
                        }}>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                            <div>ROUND</div>
                            <div style={{height :`${percentage(currentSize.width , 1 )}px`,}}></div>
                            <div>ENDED</div>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                        </motion.div>

                        <motion.div 
                            initial={{ "width" : `${percentage(currentSize.width , 0.1 )}px`, }}
                            animate={{ "width" : `${percentage(currentSize.width , 2 )}px`, }}
                            transition={{ duration : 0.5, delay: 0.6}}

                        ></motion.div>

                        <motion.div 
                        className="BlurIt"
                            style={{
                                "width" : `${percentage(currentSize.width , 35 )}px`,
                                "borderTopRightRadius" : "15%",
                                "borderBottomRightRadius" : "15%",                                
                                "borderWidth" : `${percentage(currentSize.width , 0.15 )}px`,
                                "borderStyle" : "solid",
                                "borderColor" : "#00f9ff",
                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                "textAlign" : "center",
                                "fontSize" : `${percentage(currentSize.width , 2.5 )}px`,
                                "color" : "#00f9ff"
                        }}>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                            <div>STARTING</div>
                            <div style={{height :`${percentage(currentSize.width , 1 )}px`,}}></div>
                            <div className="pulsate-fwd">NEXT ROUND</div>
                            <div style={{height :`${percentage(currentSize.width , 3 )}px`,}}></div>
                        </motion.div>

                    </motion.div>
                }

                { ShowOpponentBlock === false && GameData.Round === 1 &&

                    <motion.div
                    initial={{ opacity : 0 }}
                    animate={{ opacity : 1 }}
                    transition={{ duration : 0.5 , delay : 0.8}}
                    >


                    {Ranks.map((Rank, i) => 
                        <motion.div 
                        animate={ RankPosition(Rank.ArrayPosition) }
                        initial={ RankPosition(4) }
                        transition={{ duration : 0.5 , delay : 1}}
                        key={i}>
                            
                            { MusicOn === true && < HandWonScore Status={ PlayFirstMusic }/> }

                            < PlayerInfoBlockRoundOne Rank = { Rank } Agnostic = { isAgnostic } />

                        </motion.div>
                    )}
                    </motion.div>
                }

                { ShowOpponentBlock === false && GameData.Round === 2 &&

                <motion.div
                initial={{ opacity : 0 }}
                animate={{ opacity : 1 }}
                transition={{ duration : 0.5 , delay : 0.8}}
                >

                { MusicOn === true && < HandWonScore Status={ PlayFirstMusic }/> }

                {Ranks.map((Rank , i) => 
                    <motion.div 
                    animate={ RankPosition(Rank.ArrayPosition) }
                    initial={ RankPosition(4) }
                    transition={{ duration : 0.5 , delay : 1}}
                    key={i}>
                        
                        { MusicOn === true && < HandWonScore Status={ PlayFirstMusic }/> }

                        < PlayerInfoBlockRoundTwo Rank = { Rank } Agnostic = { isAgnostic } />

                    </motion.div>
                )}

                </motion.div>

                }


                { PlaceOpponentsBack === true &&
                    <motion.div                 
                    style={{
                        "height" : `${TableHeight}px`,
                        "width" : `${percentage(currentSize.width , 75)}px`,
                        "position" : "absolute",
                        "transform" : "translate(-50%, -50%)",
                        "top" : "50%",
                        "left": "50%",
                    }}>
                        < motion.div 
                            initial={ TableCenter () }
                            transition={ OpponentBlockMoverBack() }
                            animate={ SeatOnePosition }>
                            < OpponentBlock Seat={1} PlayerValues = { PlayerData() } GameData = {GameData} isAgnostic = {isAgnostic} />
                        </motion.div>
                        
                    
                        < motion.div 
                            initial={ TableCenter () }
                            transition={ OpponentBlockMoverBack() }
                            animate={ SeatTwoPosition }>
                            < OpponentBlock Seat={2} PlayerValues = { PlayerTwo() } GameData = {GameData} isAgnostic = {isAgnostic} />
                        </motion.div>

                        < motion.div 
                            initial={ TableCenter () }
                            transition={ OpponentBlockMoverBack() }
                            animate={ SeatThreePosition }>
                                < OpponentBlock Seat={3} PlayerValues = { PlayerThree() } GameData = {GameData} isAgnostic = {isAgnostic} />
                        </motion.div>
                        

                        < motion.div 
                            initial={ TableCenter () }
                            transition={ OpponentBlockMoverBack() }
                            animate={ SeatFourPosition }>
                            < OpponentBlock Seat={4} PlayerValues = { PlayerFour() } GameData = {GameData} isAgnostic = {isAgnostic} />
                        </motion.div>
                    </motion.div>
                }


               
                
                
        </div>
        
    )
    
}

            

export default Mover