import React , { useState } from 'react';
import useResize from 'use-resize';
import { motion } from "framer-motion";
import NavigationBar from '../NavigationBar';
import ChangeMode from '../ChangeMode';
import SettingsNavigationBar from './SettingsNavigationBar'
import Username from './Username'
import Avatar from './Avatar'


const Settings = () => {
    const [ SelectedValue , SelectedValueUpdate ] = useState("Username")
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }


    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const pageVariants = {
        initial: {
            opacity: 0.5,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0.5,
        },
    }

    var NavigationSwitchHeight = ((percentage(Dimensions.width , 24)/16) * 2) + (percentage(Dimensions.width , 24)/8)

    var PageStartingPosition = percentage(Dimensions.width , 32) + NavigationSwitchHeight ;
    var PageEndingPosition = percentage(Dimensions.width , 20) + percentage(Dimensions.height , 3);
    var PageHeight = percentage(Dimensions.height , 98) - (PageStartingPosition + PageEndingPosition);
    var AreaConsumed = percentage(Dimensions.height , 15) * 3
    var AreaLeft = PageHeight - AreaConsumed

    function HandleCallback(Value){
        SelectedValueUpdate(Value);
    }
        
    return (<div style={{
        "height" : `${Dimensions.height}px`,
        "width": `${Dimensions.width}px`,
    }} className="BlurIt">
                <div style={{
                    "position" : "fixed",
                    "top" : "3%",
                    "left" : "0%",
                    "zIndex" : "10"
                }}>
                    < ChangeMode SizeData = { Dimensions } />
                </div>

                < SettingsNavigationBar SizeData = { Dimensions } ParentCallback = { HandleCallback } SelectedValue={SelectedValue}/>
                
                <div style={{
                    "position" : "fixed",
                    "marginTop" : `${PageStartingPosition}px`,
                    "height" : `${PageHeight}px`,
                    "left": "50%",
                    "transform" : "translate(-50%, -0%)",
                }}>

                    

                    <div style={{'height' : `${percentage(AreaLeft , 20)}px`}}></div>

                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                    >

                    { SelectedValue === "Username" &&
                        < Username />
                    }

                    { SelectedValue === "Avatar" &&
                        < Avatar />
                    }

                    </motion.div>

                </div>

                { currentSize.height - currentSize.width > 150 &&
                    <div style={{
                        "position" : "fixed",
                        "bottom" :   "3%",
                        "left" : "0%",
                        "zIndex" : "10"
                    }}>
                        < NavigationBar SizeData = { Dimensions } />
                    </div>
                }

                
    </div>)
}

export default Settings