import React from 'react';
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import CardStack from '../../../../Icons/CardStack.png';
import CardBackground from '../../../../Icons/CardBackground.jpg';
import ChallengeWinner from './ChallengeWinner'
import ChallengeLoser from './ChallengeLoser'
import TableHeightCalculator from './TableHeightCalculator';
import PlayingCardCreator from './CardControlElements/PlayingCardCreator';
import Correct from '../../../../Icons/Correct.png'
import Wrong from '../../../../Icons/Wrong.png'

const CardPile = (Values) => {
    const SeatSize = 20
    const GameData = Values.GameData;

    const TableHeight = TableHeightCalculator();
    
    const ShadowWidth = 4;
    const ShadowColor = "black";

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();
    
    function percentage(num, per)
        {
            return (num/100)*per;
        }

    const ValueOfCenter = percentage(currentSize.width , 14.5)
    
    const SmallTable = percentage(currentSize.width , 120)
    const MediumTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 20)
    const LongTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 45)
    const VeryLongTable = percentage(currentSize.width , 120) + percentage(currentSize.width , 55)

    const NumberOfCardsBluffed = GameData.CurrentChallenge.BluffData.NumberOfCardsBluffed ;

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const CardBackgroundCreator = () => {
        return (
            <div>
                < img alt="Avatar" style={{
                    'width' : `${percentage(currentSize.width , 5)}px`,
                    'height' : `auto`,
                    "paddingLeft" : `${percentage(currentSize.width , 1)}px`,
                    "paddingRight" : `${percentage(currentSize.width , 1)}px`,
                    "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px black`,
                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px black`,
                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px black`,
                    "borderRadius" : `${percentage(currentSize.width , 1)}px`,
                }}
                src={ CardBackground } />
            </div>
        )
    }

    const PlayerOneWinnerPosition = () => {
        if(TableHeight === VeryLongTable){
            return ({
                "left" : `60%`, 
                "top" : "77%",
                "position" : "absolute",
                opacity : 1
            })
        } else if(TableHeight === SmallTable || TableHeight === MediumTable || TableHeight === LongTable){
            return ({
                "left" : `60%`, 
                "top" : "72%",
                "position" : "absolute",
                opacity : 1
            })
        }
        
    }

    const PlayerTwoWinnerPosition = () => {
        return ({
            "left" : `24%`, 
            "top" : "28%",
            "position" : "absolute",
            opacity : 1
        })
    }

    const PlayerThreeWinnerPosition = () => {
        return ({
            "left" : `63%`, 
            "top" : "10%",
            "position" : "absolute",
            opacity : 1
        })
    }

    const PlayerFourWinnerPosition = () => {
        return ({
            "left" : `62%`, 
            "top" : "28%",
            "position" : "absolute",
            opacity : 1
        })
    }
    
    const PlayerOneLoserPosition = () => {
        return ({
            "left" : `65%`, 
            "top" : "78%",
            "position" : "absolute",
            opacity : 1
        })
    }

    const PlayerTwoLoserPosition = () => {
        return ({
            "left" : `29%`, 
            "top" : "33%",
            "position" : "absolute",
            opacity : 1
        })
    }

    const PlayerThreeLoserPosition = () => {
        return ({
            "left" : `65%`, 
            "top" : "12%",
            "position" : "absolute",
            opacity : 1
        })
    }

    const PlayerFourLoserPosition = () => {
        return ({
            "left" : `51%`, 
            "top" : "33%",
            "position" : "absolute",
            opacity : 1
        })
    }

    const LoserPositionFinder = () => {
        const LoserSeat = GameData.CurrentChallenge.BluffData.Loser ;

        if(LoserSeat === 1){
            return PlayerOneLoserPosition()
        } if(LoserSeat === 2){
            return PlayerTwoLoserPosition()
        } if(LoserSeat === 3){
            return PlayerThreeLoserPosition()
        } if(LoserSeat === 4){
            return PlayerFourLoserPosition()
        } 

        return null
    }

    const WinnerPositionFinder = () => {
        const WinnerSeat = GameData.CurrentChallenge.BluffData.Winner ;

        if(WinnerSeat === 1){
            return PlayerOneWinnerPosition()
        } if(WinnerSeat === 2){
            return PlayerTwoWinnerPosition()
        } if(WinnerSeat === 3){
            return PlayerThreeWinnerPosition()
        } if(WinnerSeat === 4){
            return PlayerFourWinnerPosition()
        } 

        return null
    }

    

    return (
        <div style={{
            "position" : "absolute",
            "backgroundColor" : "red"
        }}>

            <motion.div 
            style={{
                "position" : "fixed",
                "height" : "100%",
                "width" : "100%",
                "top" : "0%",
                "left" : "0%",
            }}>
                <motion.div initial={{
                    "left" : `48%`, 
                    "top" : "43%",
                    "position" : "absolute",
                    opacity : 0
                }} 
                transition={{ delay : 4 , duration : 0.5}}
                animate={ WinnerPositionFinder() }>
                    < ChallengeWinner />        
                </motion.div>
            </motion.div>
            
            
            <motion.div style={{
                "position" : "fixed",
                "height" : "100%",
                "width" : "100%",
                "top" : "0%",
                "left" : "0%",                
            }}>
                <motion.div initial={{
                    "left" : `48%`, 
                    "top" : "43%",
                    "position" : "absolute",
                    opacity : 0
                }} 
                transition={{ delay : 4 , duration : 0.5}}
                animate={ LoserPositionFinder() }>
                    < ChallengeLoser GameData={GameData} />
                </motion.div>
            </motion.div>
            
                
                <motion.div  
                    initial={{                      
                        scale : 1,
                        opacity : 1,
                        "left" : `${ValueOfCenter}px`, 
                    }}                    
                    style={{
                        "position" : "absolute",
                        "width" : `${percentage(currentSize.width , SeatSize)}px`,
                        "height" : `${percentage(currentSize.width , SeatSize/1.75)}px`,
                        "fontSize" : `${percentage(currentSize.width , 2.5)}px`,                        
                    }}>

                        <motion.div className='pulsate-fwd'
                        initial={{ opacity : 0.6 , x : 0, y : 0}}
                        animate={{ opacity : 0 , x : 0, y : 0}}
                        transition={{ delay : 0.4 , duration : 0.5}}
                        style={{
                            "position" : "absolute",
                            "width" : `${percentage(currentSize.width , SeatSize) * 1.65}px`,
                            "height" : `${percentage(currentSize.width , SeatSize) * 1.65}px`,                            
                            "borderRadius" : "50%",
                            "borderWidth" : `${percentage(currentSize.width , 2)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "#F72119",
                            "top" : "-100%",
                            "left" : "-43%",
                        }}>
                        </motion.div>

                        <motion.div className='pulsate-fwd'
                        initial={{ opacity : 0.5 , x : 0, y : 0}}
                        animate={{ opacity : 0 , x : 0, y : 0}}
                        transition={{ delay : 0.4 , duration : 0.5}}
                        style={{
                            "position" : "absolute",
                            "width" : `${percentage(currentSize.width , SeatSize) * 1.45}px`,
                            "height" : `${percentage(currentSize.width , SeatSize) * 1.45}px`,                            
                            "borderRadius" : "50%",
                            "borderWidth" : `${percentage(currentSize.width , 1)}px`,
                            "borderStyle" : "solid",
                            "borderColor" : "#F72119",
                            "top" : "-71%",
                            "left" : "-28%",
                        }}>
                        </motion.div>

                        <motion.div animate={{
                            opacity : 0
                        }}
                        transition={{ delay : 4}}
                        >
                            <motion.div style={{
                                "position" : "absolute",
                                "fontSize" : `${percentage(currentSize.width , 3)}px`,                            
                                "color" : "white",
                            }}
                                animate={{ y : -50 }}
                            >CLAIM CHALLENGED</motion.div>
                        </motion.div>

                        <motion.div 
                        animate={{ opacity : 0}}
                        transition={{ delay : 4}}
                        style={{
                            "position" : "absolute",
                            "backgroundColor" : "rgba(0,0,0,0.5)",
                            "width" : `${percentage(currentSize.width , SeatSize)}px`,
                            "height" : `${percentage(currentSize.width , SeatSize/1.75)}px`,
                            "fontSize" : `${percentage(currentSize.width , 2.5)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                            "borderColor" : "black",
                            "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                            "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                            "borderStyle" : "solid",
                        }}>

                        </motion.div>
                        

                        <motion.div 
                        animate={{ opacity : 0}}
                        transition={{ delay : 4}}
                        style={{
                            "color" : "#00d6ff",
                            "backgroundColor" : "black",
                            "borderTopLeftRadius" : `${percentage(currentSize.width , 2)}px`,
                            "borderTopRightRadius" : `${percentage(currentSize.width , 2)}px`,
                            "textAlign" : "center"
                        }}>
                            <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                            CARD PILE
                            <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                        </motion.div>

                        <motion.div 
                        animate={{ opacity : 0}}
                        transition={{ delay : 4}}
                        initial={{                            
                            "position" : "absolute",
                            "top" : "60%",
                            "left": "50%",
                            "zIndex" : "25",
                            "transform" : "translate(-50%, -50%)",
                        }}>
                            < img alt="Avatar" style={{
                                'height' : `${percentage(currentSize.width , 3.5)}px`,
                                'width' : `auto`,
                            }}
                            src={ CardStack} />
                        </motion.div>
                        
                        <motion.div style={{
                            "position" : "absolute",
                            "top" : "50%",
                            "left": "50%",
                            "zIndex" : "25",
                            "transform" : "translate(-50%, -50%)",
                            "width" : `${percentage(currentSize.width , SeatSize)}px`,
                        }}>
                            
                        </motion.div>
                        
                            <motion.div 
                                animate={{ opacity : 0}}
                                transition={{ delay : 4}} style={{
                                "position" : "absolute",
                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                "borderRadius" : "50%",
                                "color" : "#00d6ff",
                                "height" : `${percentage(currentSize.width , 6)}px`,
                                "width" : `${percentage(currentSize.width , 6)}px`,
                                "fontSize" : `${percentage(currentSize.width , 3)}px`,
                                "textAlign": "center",
                                "top" : "100%",
                                "left": "50%",
                                "transform" : "translate(-50%, -50%)",
                                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                            }}> 
                                <div style={{"height" : `${percentage(currentSize.width , 1)}px`,}}></div>
                                { GameData.CurrentChallenge.BluffData.CardPile }
                                <div style={{"height" : `${percentage(currentSize.width , 0.5)}px`,}}></div>
                            </motion.div>

                            <motion.div 
                                style={{
                                    "position" : "absolute",
                                    "width" : `${percentage(currentSize.width , SeatSize) * 2}px`,
                                }}
                                initial={{ y : 40 , opacity : 1 }}
                                animate={{ y : 0 , opacity : 0 }}
                                transition={{ delay : 1 , duration : 1}}
                                >
                                    <div 
                                        style={{
                                            "position" : "absolute",
                                            "width" : `${percentage(currentSize.width , SeatSize) * 2}px`,
                                            "height" : `${percentage(currentSize.width , SeatSize)/2}px`,  
                                            "textAlign" : "center",
                                            "left" : "-25%",
                                            "top" : "130%",                                    
                                        }}>
                                        <div style={{
                                            "position" : "absolute",            
                                            "width" : `${percentage(currentSize.width , SeatSize) * 1.25}px`, 
                                            "top" : "50%" ,
                                            "left" : "50%",
                                            "transform" : "translate(-50%, -50%)", 
                                            "color" : "#00d6ff",
                                            "borderRadius" : "15%",
                                            "zIndex" : "10",
                                            "backgroundColor" : "rgba(0,0,0,0.8)",
                                            "paddingTop" : `${percentage(currentSize.width , 0.2)}px`,
                                            "paddingBottom" : `${percentage(currentSize.width , 0.2)}px`,
                                            "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                        }}>BLUFFERS CARDS</div>

                                        
                                            <div style={{
                                                "display" : "flex",
                                                "justifyContent" : "center",
                                                "width" : `${percentage(currentSize.width , SeatSize) * 2.2}px`,
                                                "position" : "absolute",                                                        
                                                "top" : "50%" ,
                                                "left" : "50%",
                                                "transform" : "translate(-50%, -50%)",   
                                                "backgroundColor" : "rgba(0,0,0,0.5)",
                                                "paddingTop" : `${percentage(currentSize.width , 1)}px`,
                                                "paddingBottom" : `${percentage(currentSize.width , 1)}px`,
                                                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                "borderColor" : "black",
                                                "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                                                "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                                                "borderStyle" : "solid",
                                            }}>
                                                { (NumberOfCardsBluffed > 0 || NumberOfCardsBluffed === 1 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 1 || NumberOfCardsBluffed === 2 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 2 || NumberOfCardsBluffed === 3 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 3 || NumberOfCardsBluffed === 4 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 4 || NumberOfCardsBluffed === 5 ) &&
                                                    < CardBackgroundCreator />
                                                }

                                                { (NumberOfCardsBluffed > 5 || NumberOfCardsBluffed === 6 ) &&
                                                    < CardBackgroundCreator />
                                                }
                                            </div>
                                    </div>
                            </motion.div>

                            <motion.div animate={{
                                y : -30,
                            }}
                            transition={{ delay : 4}}
                            >
                                <motion.div 
                                    style={{
                                        "position" : "absolute",
                                        "width" : `${percentage(currentSize.width , SeatSize) * 2}px`,
                                    }}
                                    initial={{ y : 0 , opacity : 0 }}
                                    animate={{ y : 40 , opacity : 1 }}
                                    transition={{ delay : 2 , duration : 0.2}}
                                    >                                    
                                        <div 
                                            style={{
                                                "position" : "absolute",
                                                "width" : `${percentage(currentSize.width , SeatSize) * 2}px`,
                                                "height" : `${percentage(currentSize.width , SeatSize)/2}px`,  
                                                "textAlign" : "center",
                                                "left" : "-25%",
                                                "top" : "130%",                                    
                                            }}>
                                            <div style={{
                                                "position" : "absolute",            
                                                "width" : `${percentage(currentSize.width , SeatSize) * 1.25}px`, 
                                                "top" : "20%" ,
                                                "left" : "50%",
                                                "transform" : "translate(-50%, -50%)", 
                                                "color" : "#00d6ff",
                                                "borderRadius" : "15%",
                                                "zIndex" : "10",
                                                "backgroundColor" : "rgba(0,0,0,0.8)",
                                                "paddingTop" : `${percentage(currentSize.width , 0.2)}px`,
                                                "paddingBottom" : `${percentage(currentSize.width , 0.2)}px`,
                                                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth-3)}px #00d6ff`,
                                            }}>BLUFFER'S CARDS</div>

                                            
                                                <motion.div style={{
                                                    "display" : "flex",
                                                    "justifyContent" : "center",
                                                    "width" : `${percentage(currentSize.width , SeatSize) * 2.2}px`,
                                                    "position" : "absolute",                                                        
                                                    "top" : "100%" ,
                                                    "left" : "50%",
                                                    "transform" : "translate(-50%, -50%)",   
                                                    "backgroundColor" : "rgba(0,0,0,0.5)",
                                                    "paddingTop" : `${percentage(currentSize.width , 1)}px`,
                                                    "paddingBottom" : `${percentage(currentSize.width , 1)}px`,
                                                    "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                    "borderColor" : "black",
                                                    "borderRadius" : `${percentage(currentSize.width , 2)}px`,
                                                    "borderWidth" : `${percentage(currentSize.width , 0.15)}px`,
                                                    "borderStyle" : "solid",
                                                }}>
                                                    {GameData.CurrentChallenge.BluffData.BluffCards.map((Card) => 
                                                        <motion.div key={Card.Index}>
                                                            <motion.div initial={{ opacity : 0.2}}
                                                            animate={{ opacity : 1 , scale : 0.75}}
                                                            style={{
                                                                "marginLeft" : `${percentage(currentSize.width , 0.05)}px`,
                                                                "marginTop" : `${percentage(currentSize.width , 0.55)}px`,
                                                                "marginRight" : `${percentage(currentSize.width , 0.05)}px`,
                                                                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                                                            }}>
                                                                < PlayingCardCreator CardData={{
                                                                    Suit : Card.Suit,
                                                                    Series : Card.Value
                                                                }} Dimensions = { Dimensions } />
                                                            </motion.div> 
                                                            
                                                            <motion.div>
                                                                { Card.Value === GameData.CurrentChallenge.BluffData.Claim &&                                                                    
                                                                    < img alt="Avatar" 
                                                                        style={{
                                                                        'height' : `auto`,
                                                                        'width' : `${percentage(currentSize.width , 3)}px`,                                                                        
                                                                        }}
                                                                        src={ Correct } />                                                                    
                                                                }

                                                                { Card.Value !== GameData.CurrentChallenge.BluffData.Claim &&
                                                                    < img alt="Avatar" 
                                                                        style={{
                                                                        'height' : `auto`,
                                                                        'width' : `${percentage(currentSize.width , 3)}px`,                                                                        
                                                                        }}
                                                                        src={ Wrong } /> 
                                                                }
                                                            </motion.div>
                                                        </motion.div>
                                                    )}

                                                </motion.div>
                                        </div>
                                </motion.div>
                            </motion.div>

                            
                            

                           
            </motion.div>
        </div>
        
    )
}

export default CardPile