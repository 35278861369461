import React , { useState } from 'react';
import { motion } from "framer-motion";
import { Redirect } from 'react-router';
import useResize from 'use-resize';
import IsAccess from '../../GlobalFunctions/PracticeMode/OnBoard'
import IsGamerProfile from '../../GlobalFunctions/PracticeMode/IsGamerProfile';
import BluffImage from '../../Icons/BluffImage.svg';

const Section = () => {
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null)
    const [ IsGamerAccount , IsGamerAccountUpdate ] = useState(false)
    const [ RedirectValue , RedirectValueUpdate ] = useState(null);
    const [ ButtonColor , ButtonColorUpdate ] = useState("radial-gradient(rgba(8, 37, 48, 0.9) , rgba(0, 0, 0, 0.2) , rgba(8, 37, 48, 0.9))");
    

    if(IsAccessData === null){
        IsAccess(function (AccessValue){
            IsAccessDataUpdate(AccessValue)

            if(AccessValue === true && IsGamerAccount === false){
                IsGamerProfile(function (AccountValues){
                    if(AccountValues === true){
                        IsGamerAccountUpdate(true)
                        RedirectValueUpdate(true)
                    } else {
                        IsGamerAccountUpdate(false)
                    }
                })
            }
        })
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function onTapStart() {
        ButtonColorUpdate("radial-gradient(rgba(0, 0, 0, 0.9) , rgba(8, 37, 48, 0.9) , rgba(0, 0, 0, 0.9))")
    }

    function onTapCancel() {
        ButtonColorUpdate("radial-gradient(rgba(8, 37, 48, 0.9) , rgba(0, 0, 0, 0.2) , rgba(8, 37, 48, 0.9))")
    }

    function onTap() {
        RedirectPropertyUpdate(true)
    }

    const currentSize = SizeInfo();

    const width = currentSize.width ;
    
    return (<div style={{
    }}>
        <div>
            <div>
            <motion.div 
                    style={{
                        "width" : `${percentage(width , 40)}px`,
                        "backgroundImage" : ButtonColor,
                        'fontSize' : `${percentage(width , 4)}px`,
                        'borderRadius' : `${percentage(width , 3)}px`,
                        "borderStyle" : "solid",
                        "borderWidth" : `${percentage(width , 1)}px`,
                        "borderColor" : "black",
                        "textAlign" : "center",
                        "boxShadow" : `0 0 ${percentage(width , 2)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(width , 2)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(width , 2)}px black`,
                    }}
                    onTapStart={onTapStart} onTapCancel={onTapCancel} onTap={onTap}>
                    
                    < img alt="Avatar" 
                        style={{
                        'height' : `${percentage(width , 30)}px`,
                        'width' : `${percentage(width , 30)}px`,
                        }}
                        src={ BluffImage } />
                    <div style={{"height" : `${percentage(width , 1)}px` }} />
                    <div style={{
                        "backgroundImage" : ButtonColor,
                        "color" :  "white",
                        "boxShadow" : `0 0 ${percentage(width , 2)}px grey`,
                        "WebkitBoxShadow" : `0 0 ${percentage(width , 2)}px grey`,
                        "MozBoxShadow" : `0 0 ${percentage(width , 2)}px grey`,
                    }}>BLUFF</div>
                    <div style={{"height" : `${percentage(width , 3)}px` }} />
                </motion.div>
            </div>
        </div>

        { RedirectProperty === true && RedirectValue === null &&
            <Redirect to="/landingmobile" />
        }

        { RedirectProperty === true && RedirectValue === true &&
            <Redirect to="/freetoplay/profile" />
        }

    </div>)
    
}

export default Section