import React , { useState } from 'react';
import useResize from 'use-resize';
import { motion } from "framer-motion";
import NavigationBar from '../NavigationBar';
import ChangeMode from '../ChangeMode';
import ProfileNavigationBar from '../ProfileNavigationBar'
import Avatar from './Avatar'
import ProfileStats from './ProfileStats'
import Level from './Level'
import Rewards from './Rewards'
import Wallet from './Wallet'
import Tutorial from './Tutorial'
import PlayNow from './PlayNow'
import ActiveTable from './ActiveTable';
import Config from '../../../GlobalFunctions/Config' ;
import Tutorial1 from '../../../Icons/1.png';
import Tutorial2 from '../../../Icons/2.png';
import Tutorial3 from '../../../Icons/3.png';
import Tutorial4 from '../../../Icons/4.png';
import Tutorial5 from '../../../Icons/5.png';
import Tutorial6 from '../../../Icons/6.png';
import Tutorial7 from '../../../Icons/7.png';
import Tutorial8 from '../../../Icons/8.png';
import Analytics from '../../../GlobalFunctions/Analytics';

const Profile = () => {
    const [ TutorialProperty , TutorialPropertyUpdate ] = useState(false);
    const [ CurrentTutorialPage , CurrentTutorialPageUpdate ] = useState(1);
    const [ TutorialPageAnimation , TutorialPageAnimationUpdate ] = useState({
        y : 0 , x : 0
    })
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const TutorialCallback = (Value) => {
        if(Value === true){
            Analytics("Tutorial-Accessed") 
        }
        TutorialPropertyUpdate(Value)
    }

    const CloseTutorial = () => {
        CurrentTutorialPageUpdate(1);
        TutorialPropertyUpdate(false)
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const pageVariants = {
        initial: {
            opacity: 0.5,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0.5,
        },
    }

    var NavigationSwitchHeight = ((percentage(Dimensions.width , 24)/16) * 2) + (percentage(Dimensions.width , 24)/8)

    var PageStartingPosition = percentage(Dimensions.width , 32) + NavigationSwitchHeight ;
    var PageEndingPosition = percentage(Dimensions.width , 20) + percentage(Dimensions.height , 3);
    var PageHeight = percentage(Dimensions.height , 98) - (PageStartingPosition + PageEndingPosition);
    var AreaConsumed = percentage(Dimensions.height , 15) * 3
    var AreaLeft = PageHeight - AreaConsumed;

    const PlayerData = Config.practiceModeData ;
    const [ ActiveTableData , ActiveTableDataUpdate ] = useState(null)

    if(PlayerData.CurrentTable !== null && ActiveTableData === null){
        if(PlayerData.CurrentTable.Joined === true){
            ActiveTableDataUpdate(true);
        }
    }

    const TutorialStyle = {
        "position" : "relative",
        "top" : "45%",
        "left": "50%",
        "transform" : "translate(-50%, -50%)",
        'width' : `${percentage(Dimensions.width , 65)}px`,
        'height' : `${percentage(Dimensions.width , 120)}px`,
        "borderStyle" : "solid",
        "borderColor" : "grey",
        "borderWidth" : `${percentage(Dimensions.width , 0.35)}px`,
        "boxShadow" : `0 0 ${percentage(Dimensions.width , 12)}px black`,
        "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 12)}px black`,
        "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 12)}px black`,
        "borderRadius" : `${percentage(Dimensions.width , 5)}px`, 
        "backgroundColor" : "rgba(0,0,0,0.5)"
    }

    const TutPageStyle = {
        "width" : `${percentage(Dimensions.width , 100)}px`,
        "height" : `${percentage(Dimensions.height , 100)}px`,                
        "position" : "fixed",
    }
    

    const TutNext = () => {
        TutorialPageAnimationUpdate({
            y : 0 , x : 300 , scale : 0.5
        })
        CurrentTutorialPageUpdate(CurrentTutorialPage + 1)
    }

    const TutPrev = () => {
        TutorialPageAnimationUpdate({
            y : 0 , x : -300 , scale : 0.5
        })
        CurrentTutorialPageUpdate(CurrentTutorialPage - 1)
    }


        
    return (<div style={{
                "height" : `${Dimensions.height}px`,
                "width": `${Dimensions.width}px`,
            }} className="BlurIt">
                <div style={{
                    "position" : "fixed",
                    "top" : "3%",
                    "left" : "0%",
                    "zIndex" : "50"
                }}>
                    < ChangeMode SizeData = { Dimensions } />
                </div>

                < ProfileNavigationBar SizeData = { Dimensions } />

                <div style={{
                    "position" : "fixed",
                    "marginTop" : `${PageStartingPosition}px`,
                    "height" : `${PageHeight}px`,
                    "left": "50%",
                    "transform" : "translate(-50%, -0%)",
                }}>

                    

                    <div style={{'height' : `${percentage(AreaLeft , 20)}px`}}></div>

                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                    >

                        <div style={{
                            "display" : "flex",
                            "justifyContent" : "center",
                            
                        }}>

                            <motion.div initial={{ x: 50 , y: 50}}
                                animate={{ x: 0 , y: 0}}>
                                < Avatar SizeData = { Dimensions } />  
                            </ motion.div>
                                   

                            <div style={{"width" :  `${percentage(Dimensions.width , 0.15)}px`,}}></div>

                            <motion.div initial={{ x: -50 , y: -50}}
                                animate={{ x: 0 , y: 0}}>
                                < ProfileStats SizeData = { Dimensions } />
                            </ motion.div>

                        </div>


                        <div style={{'height' : `${percentage(AreaLeft , 20)}px`}}></div>

                        <div style={{
                            "display" : "flex",
                            "justifyContent" : "center",
                        }}>
                            <motion.div initial={{ x: 50 , y: 0}}
                                animate={{ x: 0 , y: 0}}>
                                < Level SizeData = { Dimensions } />
                            </motion.div>
                            
                            <div style={{"width" :  `${percentage(Dimensions.width , 2.15)}px`,}}></div>
                            
                            <motion.div initial={{ x: 0 , y: 100}}
                                animate={{ x: 0 , y: 0}}>
                                < Rewards SizeData = { Dimensions } />
                            </motion.div>
                            
                            <div style={{"width" :  `${percentage(Dimensions.width , 2.15)}px`,}}></div>

                            <motion.div initial={{ x: 50 , y: 0}}
                                animate={{ x: 0 , y: 0}}>
                                < Wallet SizeData = { Dimensions } />
                            </motion.div>
                        </div>

                        <div style={{'height' : `${percentage(AreaLeft , 20)}px`}}></div>


                        { ActiveTableData === null &&
                            <div style={{ 
                                "display" : "flex",
                                "justifyContent" : "center",
                            }}>
                                <motion.div initial={{ x: 200 , y: 0}}
                                    animate={{ x: 0 , y: 0}}>
                                    < Tutorial SizeData = { Dimensions } TutorialCallback = { TutorialCallback }/>
                                </motion.div>

                                <div style={{"width" :  `${percentage(Dimensions.width , 1)}px`,}}></div>

                                <motion.div initial={{ x: -200 , y: 0}}
                                    animate={{ x: 0 , y: 0}}>
                                    < PlayNow SizeData = { Dimensions } />
                                </motion.div>
                            </div>
                        }

                        { ActiveTableData === true &&
                            <motion.div initial={{ x: 0 , y: 0}}
                                animate={{ x: 0 , y: 0}}>
                                < ActiveTable SizeData = { Dimensions } />
                            </motion.div>
                        }
                    </motion.div>

                </div>

                { TutorialProperty === true &&
                    <motion.div 
                    initial={{ x: 0,  y: 0 , scale : 0.9 }}
                    animate={{ x: 0 , y: 0 ,  scale : 1 }}
                    className="BlurIt" style={{
                        "position" : "fixed",
                        "width" : `${percentage(Dimensions.width , 100)}px`,                
                        "height" : `${percentage(Dimensions.height , 100)}px`,
                        "backgroundColor" : "black", 
                        "left" : `0`, 
                        "top" : `0`,  
                        "zIndex" : "100"            
                    }}>

                    { CurrentTutorialPage === 1 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial1 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 2 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial2 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 3 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial3 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 4 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial4 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 5 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial5 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 6 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial6 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 7 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial7 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage === 8 &&
                        <motion.div 
                        initial={{ x :  TutorialPageAnimation.x , y : TutorialPageAnimation.y}}
                        animate={{ scale : 1 , x :  0 , y : 0}}
                        transition={{ duration : 0.5 }}
                        style={ TutPageStyle }>
                        < img alt="Avatar" 
                            style= { TutorialStyle }
                            src={ Tutorial8 } />  
                        </motion.div>
                    }

                    { CurrentTutorialPage < 8 &&
                        <motion.div style={{
                            "position" : "fixed",
                            "bottom" : "2%",
                            "right" : "0"
                        }}>
                            <motion.div style={{
                                "width" : `${percentage(Dimensions.width , 30)}px`,
                                "height" : `${percentage(Dimensions.width , 10)}px`,
                                "fontSize" : `${percentage(Dimensions.width , 30)/8}px`,
                                "backgroundColor" : "rgba(0,0,0,0.2)",
                                "textAlign" : "center",
                                "boxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                                "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                                "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                            }} onTap={ TutNext }>
                                <div style={{
                                    "height" : `${percentage(Dimensions.width , 2.5)}px`,
                                }}></div>
                                NEXT
                            </motion.div>
                        </motion.div>
                    }

                    { CurrentTutorialPage > 1 &&
                        <motion.div style={{
                            "position" : "fixed",
                            "bottom" : "2%",
                            "left" : "0"
                        }}>
                                <motion.div style={{
                                "width" : `${percentage(Dimensions.width , 30)}px`,
                                "height" : `${percentage(Dimensions.width , 10)}px`,
                                "fontSize" : `${percentage(Dimensions.width , 30)/8}px`,
                                "backgroundColor" : "rgba(0,0,0,0.2)",
                                "textAlign" : "center",
                                "boxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                                "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                                "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                            }} onTap={ TutPrev }>
                                <div style={{
                                    "height" : `${percentage(Dimensions.width , 2.5)}px`,
                                }}></div>
                                PREV
                            </motion.div>
                        </motion.div>
                    }

                    <motion.div style={{
                        "position" : "fixed",
                        "top" : "0",
                        "right" : "0"
                    }}>
                         <motion.div 
                         animate={{ rotate : 45 , x: 35 , y : 5}}
                         style={{
                            "width" : `${percentage(Dimensions.width , 30)}px`,
                            "height" : `${percentage(Dimensions.width , 10)}px`,
                            "fontSize" : `${percentage(Dimensions.width , 30)/8}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "textAlign" : "center",
                            "color" : "red",
                            "boxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                            "WebkitBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                            "MozBoxShadow" : `0 0 ${percentage(Dimensions.width , 6)}px #00f9ff`,
                        }} onTap={ CloseTutorial }>
                            <div style={{
                                "height" : `${percentage(Dimensions.width , 2.5)}px`,
                            }}></div>
                            CLOSE
                        </motion.div>
                    </motion.div>
                    

                    </motion.div>
                }

                <div style={{
                    "position" : "fixed",
                    "bottom" :   "3%",
                    "left" : "0%",
                    "zIndex" : "10"
                }}>
                    < NavigationBar SizeData = { Dimensions } />
                </div>
    </div>)
}

export default Profile