import Config from '../Config';


const NetworkCall = function (Result) {

    const URL = Config.url_api + "/PMG/IsAccess";
        
    fetch(URL , {
        method: 'GET',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
        },
        credentials : "include"
    }).then((response) => response.json())
    .then((json) => {
        return Result(json)
    })
}

export default NetworkCall