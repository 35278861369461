import React , { useState } from 'react';
import PlayingCardCreator from '../PlayingCardCreator';
import PlayingCardCreatorSelected from '../PlayingCardCreatorSelected';
import useResize from 'use-resize'; 
import PlayerPositionCalculator from '../PlayerPositionCalculator';
import { motion }  from "framer-motion";
import SoundTypeOne from '../../../../../Sound/SlideCardToPlayer.mp3';
import useSound from 'use-sound';
import YesIcon from '../../../../../Icons/PlayButton.png'
import CancelIcon from '../../../../../Icons/CancelButton.png'
import InfoMessage from '../../../../../Icons/WaitingForRoundResults.png'
import TimedOutMessage from './TimedOutMessage';
import socket from '../../../../../SocketConnectionManager';

const Section = (Value) => {
    const ShadowWidth = 2;
    const [ SelectionActive , SelectionActiveUpdate ] = useState(false);
    const SeatOnePosition = PlayerPositionCalculator(1)
    const PlayerValue = Value.PlayerValue ;
    const PlayerTurn = PlayerValue.Turn ;
    const [ SelectedCard , SelectedCardUpdate ] = useState(null)
    const [ playOne ] = useSound(SoundTypeOne , { volume : 0.5  , interrupt : true});
    const SoundStatus = Value.SoundStatus



    var ShadowColor = "#808080";

    if(PlayerTurn === true){
        ShadowColor = "#00f9ff" ;
    } else if(PlayerTurn === false && SelectionActive === true){
        SelectionActiveUpdate(false);
        SelectedCardUpdate(null);
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableData = Value.GameData;
    const KnowledgeBase = TableData.KnowledgeBase ;

    
    const MyCards = KnowledgeBase.PlayableCards ;

    const AllSpades = TableData.MyCards.Spades ;
    const AllHearts = TableData.MyCards.Hearts ;
    const AllDiamonds = TableData.MyCards.Diamonds ;
    const AllClubs = TableData.MyCards.Clubs ;
    const TotalCards = AllSpades.length +  AllHearts.length + AllDiamonds.length + AllClubs.length
     
    const InitialSelectedTrayValue = () => {        
            return ({
                x : 0,
                y : 50,
                scale : 0.5
            })
    }


    const SelectedCardTrayValue = () => {
        return ({
             x : 10,
             y : -100,
             scale : 1
        })
    }
    
    
    const TrayPositionProvider = () => {
        if(SeatOnePosition.top === "92%"){
            if(TotalCards <= 7){
                return("56%")
            } else if(TotalCards > 7){
                return("50%")
            }
        } else if(SeatOnePosition.top === "95%"){
            return("55%")
        } else if(SeatOnePosition.top === "96%"){
            return("55%")
        }  

        return("")
    }
    
    

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    
    const TrayStyle = () => {
        if(TotalCards <= 7 && TotalCards !== 0){
            return (
            {
                "position" : "absolute",
                "zIndex" : "60",
                "top" :  TrayPositionProvider() ,
                "borderRadius" : "10%",
                "borderColor" : "black",
                "borderStyle" : "solid",
                "backgroundColor" : "rgba(0,0,0,0.5)",
                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                "width" : `${percentage(currentSize.width , 95)}px`,
                "height" :  `${percentage(currentSize.width , 17)}px`,
                "marginLeft" : `${percentage(currentSize.width , 1.5)}px`,
                "borderWidth" : `${percentage(currentSize.width , 1.15)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
             }
            )
        } else if(TotalCards > 0 ){
            return (
                {
                    "position" : "absolute",
                    "zIndex" : "60",
                    "top" :  TrayPositionProvider() ,
                    "borderRadius" : "10%",
                    "borderColor" : "black",
                    "borderStyle" : "solid",
                    "backgroundColor" : "rgba(0,0,0,0.7)",
                    "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                    "width" : `${percentage(currentSize.width , 95)}px`,
                    "height" :  `${percentage(currentSize.width , 30)}px`,
                    "marginLeft" : `${percentage(currentSize.width , 1.5)}px`,
                    "borderWidth" : `${percentage(currentSize.width , 1.15)}px`,
                    "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                    "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                    "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                 }
                )
        } else {
            return ({
                "position" : "absolute",
                "zIndex" : "25",
                "top" :  TrayPositionProvider() ,
                "borderRadius" : "10%",
                "borderColor" : "black",
                "borderStyle" : "solid",
                "backgroundColor" : "rgba(0,0,0,0.7)",
                "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                "width" : `${percentage(currentSize.width , 95)}px`,
                "height" :  `${percentage(currentSize.width , 17)}px`,
                "marginLeft" : `${percentage(currentSize.width , 1.5)}px`,
                "borderWidth" : `${percentage(currentSize.width , 1.15)}px`,
                "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px ${ShadowColor}`,
            })
        }
    }
    

    const IsPlayable = (PlayValue) => {
        if(PlayerTurn === true){
            if(PlayValue === true){
                return "white"
            } else {
                return "grey"
            }
        } else {
            return "white"
        }
    }

    const CardSize = (PlayValue) => {
        if(PlayerTurn === true){
            if(PlayValue === true){
                return 1.1
            } else {
                return 1
            }
        } else {
            return 1
        }
    } 

    const IsSelected = (ThisCard) => {
        if(SelectedCard !== null){
            if(ThisCard.Index === SelectedCard.Index){
                return ("white")
            } else {
                if(ThisCard.Playable === false){
                    return ("grey")
                }
            }
        } 
        
        return ("grey")
    }

    const onPlayCard = () => {
        if(SoundStatus === true){
            playOne();
        }
    
        socket.emit('UserCardSubmit-CB', SelectedCard)
    }

    

    return (
    <motion.div         
        style={TrayStyle()}
        transition={{ duration : 0.2}}
        >

                { PlayerTurn === true && SelectionActive === false && TotalCards > 7 &&
                    <motion.div 
                    initial={{
                        "position" : "absolute",
                        "top" : "0%",
                        "width" : `${percentage(currentSize.width , 30)}px`,
                        "textAlign" : "center",
                        "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                        "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                        "color" : "#FDA80D",  
                    }}
                    animate={{
                        "position" : "absolute",
                        "top" : "-50%",
                        "width" : `${percentage(currentSize.width , 30)}px`,
                        "textAlign" : "center",
                        "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                        "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                        "color" : "#FDA80D",  
                    }}>  
                        <div style={{
                            "height" : `${percentage(currentSize.width , 2)}px`,
                        }}></div>
                        PLAY ANY HIGHLIGHTED CARD          
                        <div style={{
                            "height" : `${percentage(currentSize.width , 2)}px`,
                        }}></div>         
                    </motion.div>
                }

                { PlayerTurn === true && SelectionActive === false && TotalCards <= 7 &&
                    <motion.div 
                    initial={{
                        "position" : "absolute",
                        "top" : "0%",
                        "width" : `${percentage(currentSize.width , 30)}px`,
                        "textAlign" : "center",
                        "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                        "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                        "color" : "#FDA80D",  
                    }}
                    animate={{
                        "position" : "absolute",
                        "top" : "-90%",
                        "width" : `${percentage(currentSize.width , 30)}px`,
                        "textAlign" : "center",
                        "fontSize" : `${percentage(currentSize.width , 2.75)}px`,
                        "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                        "color" : "#FDA80D",  
                    }}>  
                        <div style={{
                            "height" : `${percentage(currentSize.width , 2)}px`,
                        }}></div>
                        PLAY ANY HIGHLIGHTED CARD          
                        <div style={{
                            "height" : `${percentage(currentSize.width , 2)}px`,
                        }}></div>         
                    </motion.div>
                }

                < TimedOutMessage GameData = { TableData } PlayerValue = {PlayerValue }/>

                { PlayerTurn === true && SelectionActive === true && SelectedCard !== null &&
                    <motion.div                     
                    initial={InitialSelectedTrayValue()}
                    animate={SelectedCardTrayValue()}
                    transition={{duration : 0.3 }}
                    style={{
                        "position" : "absolute",
                        "top" : "0%",
                        "left" : "30%",
                        "borderRadius" : "10%",
                        "borderColor" : "black",
                        "borderStyle" : "solid",
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                        "width" : `${percentage(currentSize.width , 30)}px`,
                        "height" :  `${percentage(currentSize.width , 20)}px`,
                        "marginLeft" : `${percentage(currentSize.width , 1.5)}px`,
                        "borderWidth" : `${percentage(currentSize.width , 0.25)}px`,
                        "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px black`,
                        "zIndex" : "-2"
                    }}>
                        <motion.div 
                        animate={{scale: 1.2}}
                        style={{
                            "position" : "absolute",
                            "top" : "20%",
                            "left": "48%",     
                            "zIndex" : "100",
                            "width" : `${percentage(currentSize.width , 50)}px`, 
                            "fontSize" : `${percentage(currentSize.width , 2)}px`,
                            "textAlign" : "center",
                            "color" : "#FDA80D",
                        }}>                                 
                            < PlayingCardCreatorSelected 
                            CardData={{
                            Background : "white",
                            Suit : SelectedCard.Suit,
                            Series : SelectedCard.Value
                            }} Dimensions = { Dimensions } />
                        </motion.div>

                        <motion.div 
                        initial={{ opacity : 0}}
                        animate={{ opacity : 1}}                        
                        transition={{ duration : 0.2}}
                        onTapStart={()=> {
                            if(SoundStatus === true){
                                playOne();
                            }
                            
                            SelectionActiveUpdate(false)
                        }}                        
                        style={{
                            "position" : "absolute",
                            "transform" : "translate(-50%, -50%)",
                            "top" : "50%",
                            "left": "-30%",     
                            "zIndex" : "100" ,
                            "width" : `${percentage(currentSize.width , 25)}px`, 
                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",
                            "textAlign" : "center",
                            "color" : "red",  
                            "borderRadius" : "10%",    
                            "borderStyle" : "solid",                      
                            "borderWidth" : `${percentage(currentSize.width , 0.45)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px red`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px red`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px red`,
                        }}>              
                            <motion.div style={{"height" :  `${percentage(currentSize.width , 2)}px`,}}></motion.div>
                            <motion.div style={{
                            }}
                            >
                                < img alt="Avatar" 
                                style={{
                                'width' : `${percentage(currentSize.width , 20)}px`,
                                "height" : "auto",
                                }}
                                src={ CancelIcon } />
                            </motion.div>
                            <motion.div style={{"height" :  `${percentage(currentSize.width , 1.5)}px`,}}></motion.div>
                            
                        </motion.div>

                        <motion.div                         
                        initial={{ opacity : 0}}
                        animate={{ opacity : 1}}
                        onTapStart={ onPlayCard }
                        transition={{ duration : 0.2}}
                        style={{
                            "position" : "absolute",
                            "transform" : "translate(-50%, -50%)",
                            "top" : "50%",
                            "left": "130%",     
                            "zIndex" : "100" ,
                            "width" : `${percentage(currentSize.width , 27)}px`, 
                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                            "backgroundColor" : "rgba(0,0,0,0.2)",
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.5))",                            
                            "textAlign" : "center",                            
                            "color" : "#fda80d",  
                            "borderRadius" : "10%",    
                            "borderStyle" : "solid",   
                            "display": "flex",                   
                            "borderWidth" : `${percentage(currentSize.width , 0.85)}px`,
                            "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px #fda80d`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px #fda80d`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px #fda80d`,
                        }}>           

                                <motion.div style={{                                    
                                }}
                                >
                                    < img alt="Avatar" 
                                    style={{
                                    'width' : `${percentage(currentSize.width , 9)}px`,
                                    "marginTop"  : `${percentage(currentSize.width , 0.55)}px`,
                                    "marginLeft"  : `${percentage(currentSize.width , 2.25)}px`,
                                    "height" : "auto",
                                    }}
                                    src={ YesIcon } />
                                </motion.div>

                                <motion.div style={{    
                                    "marginTop"  : `${percentage(currentSize.width , 0.75)}px`,
                                    "fontSize" : `${percentage(currentSize.width , 3.5)}px`, 
                                    "paddingLeft"  : `${percentage(currentSize.width , 2)}px`,                             
                                }}>
                                    <motion.div>PLAY</motion.div>
                                    <motion.div>NOW</motion.div>

                                </motion.div>
                        </motion.div>


                    </motion.div>
                }
            


                { TotalCards === 0 &&
                    <div style={{
                        "position" : "absolute",
                        "transform" : "translate(-50%, -50%)",
                        "top" : "50%",
                        "left": "50%",     
                        "zIndex" : "100",
                        "backgroundColor" : "rgba(0,0,0,0.5)",
                        "backgroundImage" : "linear-gradient(rgba(0,0,0,0.9) , rgba(0,0,0,0.8), rgba(0,0,0,0.7))",
                        "fontSize" : `${percentage(currentSize.width , 5)}px`, 
                        "width" : `${percentage(currentSize.width , 95)}px`, 
                        "textAlign" : "center",
                    }}>
                        <motion.div style={{
                            }}>
                            < img 
                            style={{
                                "width" : `${percentage(currentSize.width , 50)}px`,                    
                            }}
                            alt="Avatar" src={ InfoMessage } />
                        </motion.div>  
                    </div>
                }

                
                
                { TotalCards > 0 && SelectionActive === false &&
                <div style={{
                    "position" : "absolute",
                    "zIndex" : "100",
                }}>

                    <div style={{
                        "height" : `${percentage(currentSize.width , 3)}px`,
                    }}></div>

                    <div style={{
                        "display" : "flex",
                        "flexDirection" : "row",
                        "flexWrap" : "wrap",
                        "justifyContent" : "center",
                        "margin" : "0 auto",
                        "width" : `${percentage(currentSize.width , 95)}px`,
                    }}>
                        {MyCards.map((Card) => 
                            <div                             
                            key={Card.Index}>
                            <motion.div
                            onTapStart={()=>{
                                if(Card.Playable === true && PlayerTurn === true){

                                    if(SoundStatus === true){
                                        playOne();
                                    }
                                    SelectionActiveUpdate(true);
                                    SelectedCardUpdate(Card)
                                }
                            }}
                            animate={{
                                "position" : "absolute",
                                "height" : `${percentage(currentSize.width , 11)}px`,
                                "width" : `${percentage(currentSize.width , 12)}px`,
                                "marginBottom" : `${percentage(currentSize.width , 2)}px`,
                                "zIndex" : "10",
                                scale : CardSize(Card.Playable)
                            }}>

                            </motion.div>
                            
                            <motion.div 
                            animate={{ scale : CardSize(Card.Playable) }}
                            initial={{
                                "position" : "relative",
                                "height" : `${percentage(currentSize.width , 11)}px`,
                                "width" : `${percentage(currentSize.width , 12)}px`,
                                "marginBottom" : `${percentage(currentSize.width , 2)}px`,                                
                            }}
                            >
                                    < PlayingCardCreator 
                                    CardData={{
                                    Background : IsPlayable(Card.Playable),
                                    Suit : Card.Suit,
                                    Series : Card.Value
                                }} Dimensions = { Dimensions } />

                            </motion.div> 

                            </div>
                        )}
                    </div>

                    <div style={{
                        "height" : `${percentage(currentSize.width , 3)}px`,
                    }}></div>

                </div>

            }

            { TotalCards > 0 && SelectionActive === true && SelectedCard !== null &&
                <div>

                    <div style={{
                        "height" : `${percentage(currentSize.width , 3)}px`,
                    }}></div>

                    <div style={{
                        "display" : "flex",
                        "flexDirection" : "row",
                        "flexWrap" : "wrap",
                        "justifyContent" : "center",
                        "margin" : "0 auto",
                        "width" : `${percentage(currentSize.width , 95)}px`,
                    }}>
                        {MyCards.map((Card) => 
                            <div                             
                            key={Card.Index}>
                            <motion.div
                            onTapStart={()=> {
                                playOne();
                                SelectionActiveUpdate(false)
                            }}
                            animate={{
                                "position" : "absolute",
                                "height" : `${percentage(currentSize.width , 11)}px`,
                                "width" : `${percentage(currentSize.width , 12)}px`,
                                "marginBottom" : `${percentage(currentSize.width , 2)}px`,
                                "zIndex" : "10",
                                scale : CardSize(Card.Playable),
                            }}>

                            </motion.div>
                            
                            { SelectedCard.Index !== Card.Index &&
                                <motion.div 
                                initial={{
                                    "position" : "relative",
                                    "height" : `${percentage(currentSize.width , 11)}px`,
                                    "width" : `${percentage(currentSize.width , 12)}px`,
                                    "marginBottom" : `${percentage(currentSize.width , 2)}px`,
                                    scale : CardSize(Card.Playable)
                                }}
                                >
                                        < PlayingCardCreator 
                                        CardData={{
                                        Background : IsSelected(Card),
                                        Suit : Card.Suit,
                                        Series : Card.Value
                                    }} Dimensions = { Dimensions } />

                                </motion.div> 
                            }

                            { SelectedCard.Index === Card.Index &&
                                <motion.div 
                                initial={{
                                    "position" : "relative",
                                    "height" : `${percentage(currentSize.width , 11)}px`,
                                    "width" : `${percentage(currentSize.width , 12)}px`,
                                    "marginBottom" : `${percentage(currentSize.width , 2)}px`,
                                    scale : CardSize(Card.Playable)
                                }}
                                
                                >
                                    <motion.div 
                                    animate={{ scale : 0 , opacity : 0}}
                                    transition={{ duration : 0.3}}>
                                        < PlayingCardCreator 
                                            CardData={{
                                            Background : IsSelected(Card),
                                            Suit : Card.Suit,
                                            Series : Card.Value
                                        }} Dimensions = { Dimensions } />
                                    </motion.div>
                                    

                                    <motion.div style={{
                                        "position" : "absolute",
                                        "top" : "0%",
                                        "left" : "9%",
                                        "height" : `${percentage(currentSize.width , 10)}px`,
                                        "width" : `${percentage(currentSize.width , 9)}px`,
                                        "borderStyle" : "solid",
                                        "borderColor" : "black",
                                        "backgroundColor" : "rgba(0,0,0,0.5)",
                                        "borderWidth" : `${percentage(currentSize.width , 0.5)}px`,
                                    }}>

                                    </motion.div>

                                </motion.div> 
                            }

                            </div>
                        )}
                    </div>

                    <div style={{
                        "height" : `${percentage(currentSize.width , 3)}px`,
                    }}></div>

                </div>

            }

    </motion.div>
    )
}

export default Section