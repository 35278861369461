import React , { useState } from 'react';
import useResize from 'use-resize'; 
import socket from '../../../../SocketConnectionManager';
import useSetTimeout from 'use-set-timeout';
import { motion } from 'framer-motion';
import Config from '../../../../GlobalFunctions/Config' ;
import EventScore from './SoundAssets/EventFx';

const Section = (Values) => {
    const [ MusicOn , MusicOnUpdate ] = useState(Config.DefaultBluffSound);
    const [ ShowEmote , ShowEmoteUpdate ] = useState(null)
    const PlayerValues = Values.PlayerValues;
    const SeatNumber = Values.Seat; 
    const [ ShadowWidth ] = useState(2);
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    if(Config.DefaultBluffSound === null){
        Config.DefaultBluffSound = true
        MusicOnUpdate(true)
    }

    socket.on('onStopMusic' , function (){
        MusicOnUpdate(false)
    })

    socket.on('onStartMusic' , function (){
        MusicOnUpdate(true)
    })

    const currentSize = SizeInfo();

    socket.on("EmoteAlert" , function(Data){
        if(PlayerValues !== null){
            if(Data.PlayerSeat === SeatNumber){
                ShowEmoteUpdate(Data)
            } else {
                ShowEmoteUpdate(null)
            }
        }
    })


    const Emoter = (EmoteObject) => {
        const Values = EmoteObject.EmoteValue
        const [ShowEmoter , ShowEmoterUpdate ] = useState(true)

        useSetTimeout(()=>{
            ShowEmoterUpdate(false)
            ShowEmoteUpdate(null)
        } , 3000)

        var AnimateX = 0;
        var AnimateY = -20;


        return (
            <div>
                { ShowEmoter === true && 
                ( Values.EmoteType === 9 || Values.EmoteType === 17 ) &&
                Values.PlayerSeat === PlayerValues.Seat && 
                    <motion.div 
                        initial={{ x: 0 , y: 20 , opacity : 1}}
                        animate={{ x: AnimateX , y: AnimateY , opacity : 1}}>
                        
                        
                        <div style={{
                            "width" : `${percentage(currentSize.width , SeatSize)}px`,
                            "height" : `${percentage(currentSize.width , SeatSize/2.5)}px`,
                            "backgroundImage" : "linear-gradient(rgba(0,0,0,0.7) ,rgba(0,0,0,0.9), rgba(0,0,0,0.9))",
                            "borderBottomStyle" : "solid",
                            "borderTopStyle" : "solid",
                            "borderColor" : "#39FF14",
                            "color" : "#39FF14",
                            "borderTopWidth" : `${percentage(currentSize.width , 0.5)}px`,
                            "borderBottomWidth" : `${percentage(currentSize.width , 1)}px`,
                            "textAlign" : "center",
                            "borderRadius" : "50%",
                            "boxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px #39FF14`,
                            "WebkitBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px #39FF14`,
                            "MozBoxShadow" : `0 0 ${percentage(currentSize.width , ShadowWidth)}px #39FF14`,
                        }}>
                
                        <div style={{
                            "position" :"absolute",
                            "width" : `${percentage(currentSize.width , SeatSize)}px`,
                            "textAlign" : "center",
                            "top" : "50%",
                            "fontSize" : `${percentage(currentSize.width , 3)}px`,
                            "transform" : "translate(0%, -50%)",
                        }}>
                            
                            { Values.EmoteType === 9 && "PASS" }
                            { Values.EmoteType === 17 && "TIMED OUT" }

                        
                        </div>

                        </div>

                    </motion.div>
            }
            </div>
        )
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    var SeatSize = 20


    return (<div>
               { ShowEmote !== null &&  < Emoter EmoteValue = {ShowEmote} /> }

               
               { ShowEmote !== null && MusicOn === true && ShowEmote.EmoteType === 17 && < EventScore Status={ true } /> } 
               { ShowEmote !== null && MusicOn === true && ShowEmote.EmoteType === 9 && < EventScore Status={ true } /> }  
            </div>)

}

export default Section