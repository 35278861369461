import React , { useState } from 'react';
import { motion } from 'framer-motion';

const UserName = (Values) => {
    const Seat = Values.Seat ;
    const BlockSize = Values.BlockSize;
    const [ BlockShadowWidth  ] = useState(10);

    const ShadowColor = () => {
        if(Values.PlayerValues.IsHandWinner === true){
            return "#FDA80D"
        } else {
            return "#FDA80D"
        }
    }

    const BlockShadowColor = ShadowColor();

    
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const width = BlockSize ;

    const AnimationType = () => {
        if(Seat === 1 || Seat === 2 || Seat === 3){
            return ({
                rotateZ : 20 , 
                scale : 0.8 , 
                opacity : 1 , 
                "left" : "60%",
                "position" : "absolute",
                "color" : "#FDA80D",
                "top" : "35%",
                "fontSize" : `${percentage(width , 11)}px`,
                "width" : `${percentage(width , 70)}px`,
                "textAlign" : "center",
                "borderTopLeftRadius" : "50%",
                "borderTopRightRadius" : "50%",
                "borderBottomLeftRadius" : "50%",
                "borderBottomRightRadius" : "50%",
                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,            
            })
        } else {
            return ({
                rotateZ : -20 , 
                scale : 0.8 , 
                opacity : 1 ,
                "left" : "-30%",
                "position" : "absolute",
                "color" : "#FDA80D",
                "top" : "35%",
                "fontSize" : `${percentage(width , 11)}px`,
                "width" : `${percentage(width , 70)}px`,
                "textAlign" : "center",
                "borderTopLeftRadius" : "50%",
                "borderTopRightRadius" : "50%",
                "borderBottomLeftRadius" : "50%",
                "borderBottomRightRadius" : "50%",
                "boxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "WebkitBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,
                "MozBoxShadow" : `0 0 ${percentage(width , BlockShadowWidth)}px ${BlockShadowColor}`,            
            })
        }
    }

    return (
            <motion.div 
            className="BlurIt"
            initial={{ opacity : 0 }}
            animate={ AnimationType()}>
                <div style={{
                    "height" :  `${percentage(width , 11)}px`,
                }}></div>
                <div>DEALER</div>
                <div style={{
                    "height" :  `${percentage(width , 11)}px`,
                }}></div>
            </motion.div>
        
    )
}

export default UserName