import React from 'react';
import InfoProvider from './PlayerCardControls/InfoProvider';
import SelectBids from './PlayerCardControls/SelectBids';
import PlayerPositionCalculator from './PlayerPositionCalculator';
import { motion }  from "framer-motion";
import OnTurnCardStackProvider from './PlayerCardControls/OnTurnCardStack';

const PlayerControls = (Value) => {
    const TableData = Value.GameData;
    const BidSubmission = Value.BidSubmission ;
    const SoundStatus = Value.Music ;

    const PlayerData = (GameData) => {
        if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerValue  = PlayerData(Value.GameData);
    
    var SeatOnePosition = PlayerPositionCalculator(1)
    SeatOnePosition.left="0%"
    
    
        return (<div style={{
            "position" : "fixed",
            "bottom" : "20%",
            "left" : "0%",
            "zIndex" : "100",
            "height" :  `50%`,
            "width" : `100%`,
        }}>
    
            { TableData.IsBidding === true && BidSubmission !== true &&            
                < SelectBids GameData = { TableData } SoundStatus = {SoundStatus} /> 
            }

           
            { TableData.IsBidding === false && 
                TableData.CardDistribution === false &&
                TableData.RoundEnded !== true &&
                <motion.div 
                initial={{ x : 0 , y : 70, opacity : 0}}
                animate={{ x : 0 , y : 70, opacity : 1}}
                transition={{ duration : 0.3 , delay : 0.3}}
                style={
                    SeatOnePosition
                }>
                    < OnTurnCardStackProvider GameData = { TableData } PlayerValue = { PlayerValue } SoundStatus = {SoundStatus} /> 
                </motion.div>
            }

            { BidSubmission === true &&          
                <motion.div 
                initial={{ x : 0 , y : 70, opacity : 0}}
                animate={{ x : 0 , y : 70, opacity : 1}}
                transition={{ duration : 0.3 , delay : 0.3}}
                style={
                    SeatOnePosition
                }>
                    < OnTurnCardStackProvider GameData = { TableData } PlayerValue = { PlayerValue } SoundStatus = {SoundStatus} /> 
                </motion.div>
            }

            { TableData.IsBidding === null && TableData.RoundStarting !== true &&
                < motion.div 
                initial={{ x : 0 , y : 0}}
                animate={{ x : 0 , y : 70}}
                style={
                    SeatOnePosition
                }>
                    < InfoProvider GameData = { TableData }/> 
                </motion.div>
            }
            
        </div>
        )
}

export default PlayerControls